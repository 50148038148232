import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import CommonBG from '../uiElements/CommonBG';
import { HeaderOptions } from '../uiElements/Header';
import RightPane from './RightPane';
import MailChange from './settingsItems/MailChange';
import Privacy from './settingsItems/PrivacyView';
import ProfileSettings from './settingsItems/ProfileSettings';
import PWSettings from './settingsItems/PWSettings';
import Term from './settingsItems/TermView';
import SettingsMenu from './SettingsMenu';
import { relativeSettingsPaths } from './settingsPaths';

const headerOptions: HeaderOptions = {
  buttonType: 'logout',
  showEmail: false,
};

interface SettingsMainProps {}
const SettingsMain: React.FC<SettingsMainProps> = () => {
  return (
    <CommonBG withHeader withFooter headerOptions={headerOptions}>
      <Content>
        <SettingsMenu />
        <RightPane>
          <Routes>
            <Route
              path={relativeSettingsPaths.profile}
              element={<ProfileSettings />}
            />
            <Route
              path={relativeSettingsPaths.password}
              element={<PWSettings />}
            />
            <Route path={relativeSettingsPaths.mail} element={<MailChange />} />
            <Route path={relativeSettingsPaths.term} element={<Term />} />
            <Route path={relativeSettingsPaths.privacy} element={<Privacy />} />
          </Routes>
        </RightPane>
      </Content>
    </CommonBG>
  );
};

// NOTE: min-height = 100vh - headerHeight - footerHeight;
const Content = styled.div`
  display: flex;
  margin: 0 4rem;
  min-height: calc(100vh - 8rem - 6rem);
`;

export default SettingsMain;
