import { SubButton as _Button } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Data, Headers } from 'react-csv/components/CommonPropTypes';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  GetAdminAllStage5GroupsQuery,
  useGetAdminAllStage5GroupsLazyQuery,
} from '../../../api/__generated__/stage5.generated';
import {
  EventDocument,
  useEventRecords,
  useEventStats,
} from '../../../api/event';
import { UserOrgInfo, UserRecords } from '../../../api/firestoreTypes';
import {
  OrgMemberRecord,
  useOrganizationMembers,
  useOrganizationUserOrgs,
} from '../../../api/organization';
import {
  DataArray,
  calcAvg,
  calcRank,
  calcRanks,
  toDataArrayList,
} from '../../../lib/stageResults';
import { options_ind, options_occ } from '../../../lib/userProfiles';
import { appActions } from '../../../redux/actions/appActions';
import Icon_Communicate from '../../../static/svg/admin/category_communicate.svg';
import Icon_Create from '../../../static/svg/admin/category_create.svg';
import Icon_Energy from '../../../static/svg/admin/category_energy.svg';
import Icon_Program from '../../../static/svg/admin/category_program.svg';
import Icon_Switch from '../../../static/svg/admin/category_switch.svg';
import Colors, { colorWithAlpha } from '../../../styles/colors';
import { EventStatus, GroupMembersInfo } from '../../../types';
import { useEventCsv } from '../adminEvents/hooks/useEventCsv';
import RankStatus from './RankStatus';

type EventControlParticipantManagerProps = {
  type: 'control';
  participantUids: string[];
  onUpdateParticipantUids?: null;
  onMoveToResult: (uid: string) => void;
  event?: EventDocument | null;
  orgId: string;
  isAdmin: boolean;
  onCsvDownload: () => void;
  csvClicked: boolean;
  statusButton?: boolean | false;
  showRankStatus?: boolean | false;
};

const stages = ['stage1', 'stage2', 'stage3', 'stage4', 'stage5'] as const;

export interface CsvData {
  data: Data;
  headers: Headers;
  filename: string;
  onCsvDownload: () => void;
}

const getIndustryName = (industryId: string) => {
  if (industryId) {
    return (
      options_ind.find(e => e.value.toString() === industryId.toString())
        ?.label ?? ''
    );
  }
  return '';
};

const getOccupationName = (occupationId: string) => {
  const list = options_occ.map(e => e.options).flat();
  if (occupationId) {
    return (
      list?.find(e => e.value.toString() === occupationId.toString())?.label ??
      ''
    );
  }
  return '';
};

export type AdminAllStage5Groups = Array<GroupMembersInfo>;

const EventControlParticipantManager: React.FC<EventControlParticipantManagerProps> =
  props => {
    const navigate = useNavigate();
    const { participantUids, type, orgId, isAdmin, statusButton } = props;
    const event = props.event;
    const [eventRecords] = useEventRecords(event?.eventId ?? null);
    const eventId = event?.eventId;

    const [userOrgs] = useOrganizationUserOrgs(
      orgId === 'create' ? null : orgId
    );

    const userRanks = useMemo(() => {
      if (eventRecords === undefined) {
        return undefined;
      }
      if (!event) {
        return undefined;
      }
      const ret: { [key: string]: DataArray } = {};

      eventRecords.forEach(e => {
        const scores = stages.map(
          stage => (e.data() as UserRecords).calculatedScores[stage].score ?? -1
        ) as DataArray;
        ret[e.id] = calcRanks(event.preset ?? '1', scores);
      });
      return ret;
    }, [eventRecords]);

    const [eventStats, loading, error] = useEventStats(eventId ?? null);

    const userDeviations = useMemo(() => {
      if (eventRecords === undefined) {
        return undefined;
      }
      if (eventStats === undefined) {
        return undefined;
      }
      const ret: { [key: string]: DataArray } = {};

      eventRecords.forEach(e => {
        const deviations = stages.map(stage => {
          if (event?.status !== EventStatus.Ended) {
            return undefined;
          }
          const score =
            (e.data() as UserRecords).calculatedScores?.[stage]?.score ?? -1;
          const eventAvg = eventStats?.[stage]?.avg ?? score;
          const eventStd = eventStats?.[stage]?.std ?? 1;
          if (eventStd == 0) {
            return 50;
          }
          return 50 + 10 * ((score - eventAvg) / eventStd);
        }) as DataArray;
        ret[e.id] = deviations;
      });
      return ret;
    }, [event?.status, eventRecords, eventStats]);

    const userRecords = useMemo(() => {
      if (eventRecords === undefined) {
        return undefined;
      }
      const ret: { [key: string]: UserRecords } = {};
      eventRecords.forEach(e => {
        ret[e.id] = e.data() as UserRecords;
      });
      return ret;
    }, [eventRecords]);

    const averageScore = useMemo(() => {
      if (eventRecords === undefined) {
        return undefined;
      }
      if (eventStats === undefined) {
        return undefined;
      }
      if (event?.status !== EventStatus.Ended) {
        return undefined;
      }
      const scoreList = toDataArrayList(
        eventRecords.docs.map(e => {
          return stages.map(stage => {
            return (e.data() as UserRecords).calculatedScores?.[stage]?.score;
          });
        })
      );
      return calcAvg(scoreList);
    }, [event?.status, eventRecords, eventStats]);

    const averageDeviations = useMemo(() => {
      if (averageScore === undefined) {
        return undefined;
      }
      if (eventStats === undefined) {
        return undefined;
      }
      if (event?.status !== EventStatus.Ended) {
        return undefined;
      }
      return stages.map((stage, i) => {
        if (eventStats[stage]?.avg == null || eventStats[stage]?.std == null) {
          return undefined;
        }
        const eventAvg = eventStats[stage].avg ?? averageScore[i];
        const eventStd = eventStats[stage].std ?? 1;
        if (eventStd == 0) {
          return 50;
        }
        return 50 + 10 * ((averageScore[i] - eventAvg) / eventStd);
      }) as DataArray;
    }, [averageScore, event?.status, eventStats]);

    const averageRank = useMemo(() => {
      if (averageScore === undefined) {
        return undefined;
      }
      if (!event) {
        return undefined;
      }
      return calcRanks(event.preset ?? '1', averageScore);
    }, [averageScore]);

    const userOrgTable = useMemo(() => {
      const ret: { [key: string]: UserOrgInfo } = {};
      if (userOrgs === undefined) {
        return undefined;
      }
      userOrgs.forEach(e => {
        ret[e.uid] = e;
      });
      return ret;
    }, [userOrgs]);

    const { orgMembers, orgMemberDoc } = useOrganizationMembers(
      orgId === 'create' ? null : orgId
    );
    const { orgMemberDoc: riddlerAdminMemberDoc } = useOrganizationMembers(
      isAdmin ? 'adminRiddler' : null
    );
    const { orgMemberDoc: dentsuAdminMemberDoc } = useOrganizationMembers(
      isAdmin ? 'adminDentsu' : null
    );

    const adminMemberDoc: Record<string, OrgMemberRecord | undefined> =
      useMemo(() => {
        return Object.fromEntries(
          Object.entries(dentsuAdminMemberDoc).concat(
            Object.entries(riddlerAdminMemberDoc)
          )
        );
      }, [dentsuAdminMemberDoc, riddlerAdminMemberDoc]);

    const [groups, setGroups] = useState<AdminAllStage5Groups>();

    const onFetch = (data: GetAdminAllStage5GroupsQuery) => {
      const g = data.getAdminAllStage5Groups;
      setGroups(g);
    };

    const [runQuery, { called, refetch }] = useGetAdminAllStage5GroupsLazyQuery(
      {
        onCompleted: onFetch,
      }
    );

    const fetch = useCallback(() => {
      if (eventId === undefined) {
        return;
      }

      if (called) {
        refetch?.({
          input: {
            eventId,
          },
        });
      }
    }, [called, eventId, refetch, runQuery]);

    const [time, updateTime] = useState(Date.now());

    useEffect(() => {
      const timeoutId = setTimeout(() => updateTime(Date.now()), 15000);

      if (refetch) {
        refetch();
      }
      return () => {
        clearTimeout(timeoutId);
      };
    }, [time]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
      fetch();
    }, [eventId, fetch]);

    const { csv, fetchDoneRef } = useEventCsv({
      averageDeviations,
      averageRank,
      csvClicked: props.csvClicked,
      event,
      onCsvDownload: props.onCsvDownload,
      orgMemberDoc,
      participantUids,
      userRanks,
      userDeviations,
      userOrgTable,
    });

    const dispatch = useDispatch();
    return (
      <ParticipantManagerWrapper>
        {csv && props.csvClicked && (
          <CSVLink
            data={csv.data}
            filename={csv.filename}
            headers={csv.headers}
            ref={fetchDoneRef}
          />
        )}
        <TableWrapper>
          <Table>
            <THead type={type}>
              <TR>
                <TH>氏名</TH>
                {
                  <>
                    <TH>入社年</TH>
                    <TH>社員ID</TH>
                    <TH>部署</TH>
                    <TH>業種</TH>
                    <TH>職種</TH>
                    <TH>
                      <Rank>
                        <SVG src={Icon_Switch} />
                      </Rank>
                      <Rank>
                        <SVG src={Icon_Program} />
                      </Rank>
                      <Rank>
                        <SVG src={Icon_Create} />
                      </Rank>
                      <Rank>
                        <SVG src={Icon_Energy} />
                      </Rank>
                      <Rank>
                        <SVG src={Icon_Communicate} />
                      </Rank>
                    </TH>
                    {statusButton && <TH></TH>}
                  </>
                }
                {isAdmin && (
                  <>
                    <TH>権限</TH>
                  </>
                )}
              </TR>
            </THead>
            <TBody type={type}>
              {!isAdmin && orgMembers === null ? (
                <TR>
                  <TD>
                    <LoadingText>読み込み中...</LoadingText>
                  </TD>
                </TR>
              ) : (
                <>
                  {participantUids.length == 0 ? (
                    <TR>
                      <TD>
                        <LoadingText>
                          {isAdmin ? '管理者' : '参加者'}がいません。
                        </LoadingText>
                      </TD>
                    </TR>
                  ) : (
                    <>
                      {participantUids.map((p, i) => (
                        <TR
                          key={i}
                          onClick={() => {
                            if (orgMemberDoc[p]?.enable) {
                              navigate(`/admin/accounts/${p}`);
                            } else {
                              dispatch(
                                appActions.setErrorOverlayState({
                                  errorType: 'CommonError',
                                  message:
                                    'このアカウントは削除されています。復旧をご希望される場合、お問い合わせよりご連絡ください',
                                })
                              );
                            }
                          }}
                        >
                          <TD>
                            <Name>
                              {orgMemberDoc[p]?.fullName ??
                                adminMemberDoc[p]?.fullName ??
                                `不明ユーザー (${p})`}
                            </Name>
                            <Mail>
                              {orgMemberDoc[p]?.email ??
                                adminMemberDoc[p]?.email ??
                                ''}
                            </Mail>
                          </TD>
                          {
                            <>
                              <TD>{userOrgTable?.[p]?.startYear ?? ''}</TD>
                              <TD>{userOrgTable?.[p]?.employeeId ?? ''}</TD>
                              <TD>{userOrgTable?.[p]?.department ?? ''}</TD>
                              <TD>
                                {getIndustryName(
                                  userOrgTable?.[p]?.industryId ?? ''
                                )}
                              </TD>
                              <TD>
                                {getOccupationName(
                                  userOrgTable?.[p]?.occupationId ?? ''
                                )}
                              </TD>
                              <TD>
                                {[0, 1, 2, 3, 4].map(e => (
                                  <RankStatus
                                    stageId={e}
                                    key={e}
                                    userId={p}
                                    groups={groups}
                                    userRecords={userRecords?.[p]}
                                    userRanks={userRanks?.[p]}
                                  />
                                ))}
                              </TD>
                              {statusButton && (
                                <TD>
                                  <Button
                                    size={'medium'}
                                    color={'negative'}
                                    onMouseDown={() => {
                                      navigate(
                                        `/admin/events/${eventId}/control/${p}/status`
                                      );
                                    }}
                                  >
                                    進行管理
                                  </Button>
                                </TD>
                              )}
                            </>
                          }

                          {isAdmin && (
                            <>
                              <TD>
                                {riddlerAdminMemberDoc[p]
                                  ? 'RIDDLER'
                                  : dentsuAdminMemberDoc[p]
                                  ? '運営企業'
                                  : 'リーダー'}
                              </TD>
                            </>
                          )}
                        </TR>
                      ))}
                    </>
                  )}
                </>
              )}
            </TBody>
          </Table>
        </TableWrapper>
      </ParticipantManagerWrapper>
    );
  };

const ParticipantManagerWrapper = styled.div``;

const TableWrapper = styled.div`
  width: 100%;
  display: block;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  font-size: 1.4rem;
  text-align: left;
  display: table;

  th:nth-child(2),
  td:nth-child(2) {
    flex: initial;
    width: 7rem;
  }
  th:nth-child(3),
  td:nth-child(3) {
    flex: initial;
    width: 7rem;
  }
  th:nth-child(7),
  td:nth-child(7) {
    flex: initial;
    display: flex;
    width: 254px;
    margin-left: 1rem;
  }
`;

const THead = styled.thead`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.gray4};
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.6rem 0;

  th:first-child {
    ${(p: ParticipantsListItemProps) =>
      p.type === 'control' &&
      `
        width: 30%;
        /*min-width: 30%;*/
    `}
  }
`;

const TBody = styled.tbody`
  cursor: default;
  width: 100%;
  display: block;

  td:first-child {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 30%;
    /*min-width: 30%;*/
  }

  tr {
    padding: 0.6rem 0;
    position: relative;
    border-bottom: 1px solid ${Colors.gray4};
    transition: 0.2s;
  }

  tr:hover {
    background-color: ${colorWithAlpha('gray4', 0.5)};

    ${(_: ParticipantsListItemProps) =>
      `
      & ${ParticipantRemoveButton} {
        display: block !important;
      }
      `}
    }
  }
`;

const TR = styled.tr`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TH = styled.th`
  flex: 1;
  margin-left: 1rem;

  &:last-child {
    display: flex;
    width: 254px;
    margin-left: 1rem;
  }
`;

const TD = styled.td`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 1rem;

  white-space: nowrap;
  overflow: hidden;
`;

const ParticipantRemoveButton = styled.div`
  cursor: pointer;
  position: absolute;
  display: none;
  line-height: 2.4rem;
  height: 2.4rem;
  right: 1.6rem;
  top: 1.6rem;
  transition: 0.2s;
  color: ${Colors.gray6};

  svg {
    width: 20px !important;
    height: 20px !important;
  }

  &:hover {
    color: ${Colors.error};
  }
`;

type ParticipantsListItemProps = Pick<
  EventControlParticipantManagerProps,
  'type'
>;

const Name = styled.div`
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Mail = styled.div`
  font-size: 1.2rem;
  color: ${Colors.gray6};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LoadingText = styled.div`
  color: ${Colors.gray6};
  padding: 0.6rem 0;
  &:hover {
    background-color: initial;
  }
`;

const Rank = styled.div`
  width: 50px;
  line-height: 3.2rem;
  text-align: center;
  font-size: 2.8rem;
  font-weight: bold;
  color: ${Colors.accent};
  border-right: 1px solid ${Colors.gray4};
  height: 36px;

  &:last-child {
    border: none;
  }
`;

const Button = styled(_Button)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

export default EventControlParticipantManager;
