import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import { useEventAvailable } from '../../api/event';
import { useStartTimesEffect } from '../../api/stages';
import { appActions } from '../../redux/actions/appActions';
import Information from '../mypage/Information';
import EventResult from './eventResultPage/EventResultPage';
import Stages from './stageProviders/Stages';

interface EventRouterProps {}

export const EventsRouter: React.FC<EventRouterProps> = () => {
  const [eventAvailable, eventLoading] = useEventAvailable();
  const match = useMatch('/events/:eventId');
  useStartTimesEffect();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (eventLoading) {
      dispatch(appActions.setLoadingState({ visible: true }));
    } else {
      dispatch(appActions.setLoadingState({ visible: false }));
    }
  }, [dispatch, eventLoading]);

  if (eventLoading) {
    return null;
  }
  if (!eventAvailable) {
    return <Navigate to='/events' />;
  }

  return (
    <Routes>
      <Route
        path='/:eventId'
        element={() => (
          <Navigate to={`/events/${match?.params.eventId}/information`} />
        )}
      />
      <Route path='/:eventId/information' element={<Information />}></Route>
      <Route path='/:eventId/stages' element={<Stages />} />
      <Route path='/:eventId/stages/*' element={<Stages />} />
      <Route path='/:eventId/result' element={<EventResult />} />
    </Routes>
  );
};

export default EventsRouter;
