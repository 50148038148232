import { MainButton as _Button } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  CreateGroupMutation,
  useCreateGroupMutation,
} from '../../../../api/__generated__/stage5.generated';
import useLocalStorage from '../../../../lib/useLocalStorage';
import { useRetryableMutationWithUI } from '../../../../lib/useRetryableMutationWithUI';
import { gameActions } from '../../../../redux/actions/gameActions';
import { Stage5GroupInfo } from '../../../../redux/reducers/gameReducer';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import { useEventId } from '../../../../redux/selectors/gameSelectors';
import CommonBG from '../../../uiElements/CommonBG';

interface GroupMenuProps {
  onJoinInput: () => void;
  onBack: () => void;
}
const GroupMenu: React.FC<GroupMenuProps> = props => {
  const dispatch = useDispatch();
  const eventId = useEventId();
  const [inputGroupId, setInputGroupId] = React.useState<string>('');
  const user = useCurrentUser();
  const [persistantGroupInfo, setPersistantGroupInfo] =
    useLocalStorage<Stage5GroupInfo | null>(
      eventId + ':stage5GroupInfo:' + user?.uid,
      null
    );

  const onCreateGroupCompleted = React.useCallback(
    (res: CreateGroupMutation) => {
      const data = res.createGroup;
      if (data) {
        dispatch(
          gameActions.setStage5GroupInfo({
            groupId: data.groupDocId,
            searchId: data.searchId,
          })
        );
        setPersistantGroupInfo({
          groupId: data.groupDocId,
          searchId: data.searchId,
        });
      }
    },
    [dispatch]
  );

  const [createGroup] = useRetryableMutationWithUI(useCreateGroupMutation, {
    hookOptions: {
      onCompleted: onCreateGroupCompleted,
    },
    loading: { options: { text: 'グループを作成中...' } },
  });

  const onCreateGroup = React.useCallback(() => {
    if (eventId === null) {
      return;
    }
    console.log('createGroup', eventId);

    createGroup({
      variables: {
        input: {
          eventId,
        },
      },
    });
  }, [createGroup, eventId]);

  return (
    <CommonBG>
      <GroupMenuWrapper>
        <Button onClick={onCreateGroup}>グループを作成</Button>
        <Button onClick={props.onJoinInput}>グループに参加</Button>
        <BackButton onClick={props.onBack}>戻る</BackButton>
      </GroupMenuWrapper>
    </CommonBG>
  );
};

const GroupMenuWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

const Button = styled(_Button).attrs({
  variant: 'main',
  color: 'positive',
  size: 'large',
})`
  display: block;
  margin: 7rem 0;
`;

const BackButton = styled(_Button).attrs({
  variant: 'main',
  color: 'negative',
  size: 'large',
})`
  display: block;
  margin: 7rem auto 0;
`;

export default GroupMenu;
