import {
  TextWindow,
  MainButton as _Button,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useEventId } from '../../../redux/selectors/gameSelectors';
import Colors from '../../../styles/colors';
import { HeaderOptions } from '../../uiElements/Header';

const headerOptions: HeaderOptions = {
  buttonType: 'logout',
  showEmail: false,
  logoType: 'CT',
};

interface InformationProps {}
const Preset2Information: React.FC<InformationProps> = () => {
  const navigate = useNavigate();
  const eventId = useEventId();

  const goTo = (url: string) => {
    navigate(url);
  };

  return (
    <ContentWrapper>
      <StageCaption>テストを始める前に</StageCaption>

      <Time>所要時間：約20分</Time>

      <VideoWrapper>
        <video controls>
          <source
            src='https://storage.googleapis.com/specc-ct-movie/0-1%E5%89%8D%E8%AA%AC.mp4'
            type='video/mp4'
          />
          <p>Your browser doesn't support HTML5 video.</p>
        </video>
      </VideoWrapper>

      <MainText>
        テストはステージ1から5まで、全部で5つのステージがあります。どのステージから受験しても問題ありません。各ステージの所要時間は約６〜８分です。
      </MainText>

      <Stages>
        <li>
          <div>ステージ１〜４</div>
          <div>
            <p>
              <b>個人受験</b>
            </p>
            <p>
              <b>ステージ１から４</b>については、
              <b>受験期間内のお好きなタイミング</b>
              で受験することができます。
            </p>
          </div>
        </li>
        <li>
          <div>ステージ５</div>
          <div>
            <p>
              <b>グループ受験</b>
            </p>
            <p>
              <b>ステージ5</b>は、グループでの受験が必要となります。
              <b>別途メールにて案内</b>
              致しますので、そちらをご確認ください。
            </p>
          </div>
        </li>
      </Stages>

      <h3>注意事項</h3>
      <Notes>
        <li>・手元にメモと筆記用具の準備をしてください。</li>
        <li>・Webサイトのウインドウをフルスクリーンにして受験してください。</li>
        <li>・全てのステージを一度に受験する必要はありません。</li>
        <li>
          ・一度ステージの受験を開始してしまうと、そのステージを中断することは出来ません。
        </li>
      </Notes>

      <h3>推奨環境</h3>
      <Notes>
        <li>
          <div>OS</div>
          <div>Windows 10以降 / Mac OS X</div>
        </li>
        <li>
          <div>ブラウザ</div>
          <div>Google Chrome / Firefox 最新版</div>
        </li>
        <li>
          <div>ブラウザの設定</div>
          <div>JavaScript/Cookieを許可</div>
        </li>
        <li>
          <div>ウィンドウサイズ</div>
          <div>横900px / フルスクリーン推奨</div>
        </li>
        <li>
          <div>メモリ</div>
          <div>1GB以上</div>
        </li>
        <li>
          <div>通信速度</div>
          <div>下り10 Mbps以上</div>
        </li>
        <li>
          <div>ブラウザの言語設定</div>
          <div>日本語</div>
        </li>
        <li>
          ※推奨環境以外では、ご利用できないもしくは正しく表示されない場合があります。
        </li>
        <li>
          ※推奨環境下のご利用でも、OSとブラウザの組み合わせ、ブラウザの設定等の影響により正常に表示されなかったり、フリーズ状態になる場合があります。
        </li>
      </Notes>

      <Buttons>
        <Button color='negative' size='large' onClick={() => goTo(`/events/`)}>
          戻る
        </Button>
        <Button
          color='positive'
          size='large'
          onClick={() => goTo(`/events/${eventId}/stages`)}
        >
          スタート
        </Button>
      </Buttons>
    </ContentWrapper>
  );
};

const InformationWrapper = styled.div``;

const ContentWrapper = styled.div`
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4rem;
  font-size: 1.6rem;

  h3 {
    font-size: 2.4rem;
    margin: 16px 0;
  }

  iframe {
    border: solid 2px #6c7680;
  }
`;

const Time = styled.div`
  text-align: right;
  width: 100%;
  margin-bottom: 10px;
`;

const StageCaption = styled(TextWindow)`
  margin: 5rem 0;
  margin-bottom: 2.8rem;
  display: inline-block;
  width: 58rem;
  padding: 0 !important;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    border: 3px solid ${Colors.gray8};
    width: 10px;
    height: calc(100% - 6px);
    position: absolute;
    top: 0;
  }
  &:before {
    border-right: none;
    left: 0;
  }
  &:after {
    border-left: none;
    right: 0;
  }
`;

const Buttons = styled.div`
  margin: 6rem auto 0;
  padding: 0 8rem;
  display: flex;
  justify-contents: center;

  button {
    margin-right: 40px;
  }
  button:last-child {
    margin-right: 0;
  }
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  width: 100%;
  margin-bottom: 4rem;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MainText = styled.p`
  margin: 20px 0;
`;

const Stages = styled.ul`
  list-style: none;
  border-top: 1px solid black;

  li {
    border-bottom: 1px solid black;
    padding: 16px 20px;

    display: flex;

    > div:first-child {
      width: 150px;
    }
    > div:last-child {
      flex: 1;

      > p:first-child {
        margin-bottom: 10px;
      }
      > p:last-child {
        b {
          text-decoration: underline;
        }
      }
    }
  }
`;

const Notes = styled.ul`
  list-style: none;
  background: rgba(206, 212, 218, 0.25);
  padding: 12px 20px;

  font-size: 1.3rem;

  li {
    display: flex;
    margin-bottom: 8px;

    > div:first-child {
      width: 160px;
    }
  }
  li:last-child {
    margin-bottom: 0;
  }
`;

const VideoWrapper = styled.div`
  margin: 1rem auto;
  margin-bottom: 0;
  border: solid 2px #6c7680;
  margin-left: -100px;
  margin-right: -100px;

  video {
    width: 100%;
    height: 100%;
    margin-bottom: -5px;
  }
`;

export default Preset2Information;
