import {
  MainButton as Button,
  Input,
  TextWindow,
  ErrorText as _ErrorText,
  Window as _Window,
} from '@riddler-co-jp/specc-ui-components';
import {
  Validators,
  emailFormatValidator,
  useValidator,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { HandleFirebaseErrorResult } from '../../lib/firebase/firebaseErrorHandlers';
import useFirebaseError from '../../lib/firebase/useFirebaseError';
import Colors from '../../styles/colors';
import CommonBG from '../uiElements/CommonBG';
import { HeaderOptions } from '../uiElements/Header';

const headerOptions: HeaderOptions = {
  buttonType: 'toLogin',
  hideHomeButton: true,
};

interface PasswordResetViewProps {
  error: HandleFirebaseErrorResult | null;
  done: boolean;
  onPasswordReset(email: string): void;
}
const PasswordResetView: React.FC<PasswordResetViewProps> = props => {
  console.log(props.error);
  const { emailError, pwError, otherError, resetError } = useFirebaseError(
    props.error
  );

  const [email, setEmail] = React.useState<string>('');

  const onPasswordReset = React.useCallback(() => {
    props.onPasswordReset(email);
  }, [props, email]);

  const inputOnChange = React.useCallback(
    (val: string) => {
      setEmail(val);
      resetError('email');
    },
    [resetError]
  );

  const validators = React.useMemo<Validators>(
    () => [
      [() => !!emailError, emailError ?? ''],
      [(val, state) => val === '' || state.isFocused],
      emailFormatValidator,
    ],
    [emailError]
  );
  const [validator, valid] = useValidator(validators);

  if (props.done) {
    return (
      <CommonBG withHeader headerOptions={headerOptions} withFooter>
        <Wrapper>
          <Text>
            {
              'パスワードを再設定するためのURLをメールで送信しました。\nメールに記載のURLをご確認ください。'
            }
          </Text>
        </Wrapper>

        <PasswordResetButton
          size={'large'}
          color={'positive'}
          onClick={onPasswordReset}
          disabled={!valid}
        >
          確認メールを再送
        </PasswordResetButton>
      </CommonBG>
    );
  }

  return (
    <CommonBG withHeader headerOptions={headerOptions} withFooter>
      <Wrapper>
        <Text>
          {
            'パスワードを再設定するためのURLをメールで送信します。\n登録済みのメールアドレスを入力してください。'
          }
        </Text>
      </Wrapper>
      <InputItem>
        <Input
          name='email'
          autoComplete='email'
          value={email}
          onChange={inputOnChange}
          validator={validator}
        />
        {props.done && (
          <InfoText>パスワード確認メールが送信されました。</InfoText>
        )}
        {pwError && <ErrorText>{pwError}</ErrorText>}
        {otherError && <ErrorText>{otherError}</ErrorText>}
      </InputItem>

      <PasswordResetButton
        size={'large'}
        color={'positive'}
        onClick={onPasswordReset}
        disabled={!valid}
      >
        送信
      </PasswordResetButton>
    </CommonBG>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-top: 8rem;
`;

const Text = styled(TextWindow)`
  line-height: 4rem;
  margin: 0 auto;
  padding: 6rem;
`;

const InputItem = styled.div`
  margin: 4rem auto;
  width: 32rem;
`;

const PasswordResetButton = styled(Button)`
  display: block;
  margin: 3rem auto 4rem;
`;

const InfoText = styled.p`
  color: ${Colors.gray8};
  font-size: 1.5rem;
  position: relative;
`;

const ErrorText = styled(_ErrorText)`
  height: initial;
`;

export default PasswordResetView;
