import 'firebase/auth';

import firebase from 'firebase/app';
import * as React from 'react';
import { Navigate } from 'react-router-dom';

import { useQueryParams } from '../../../lib/useQueryParams';
import ConfirmEmail from './ConfirmEmail/ConfirmEmail';
import ConfirmPasswordReset from './ConfirmPasswordReset/ConfirmPasswordReset';
import RecoverEmail from './RecoverEmail/RecoverEmail';

// const errorCodes = {
//   codeExpired: 'auth/expired-action-code',
//   codeInvalid: 'auth/invalid-action-code',
//   userDisabled: 'auth/user-disabled',
//   userNotFound: 'auth/user-not-found',
// } as const;

const operations = {
  emailSignin: 'EMAIL_SIGNIN',
  resetPassword: 'PASSWORD_RESET',
  recoverEmail: 'RECOVER_EMAIL',
  revertSecondFactorAddition: 'REVERT_SECOND_FACTOR_ADDITION',
  verifyAndChangeEmail: 'VERIFY_AND_CHANGE_EMAIL',
  verifyEmail: 'VERIFY_EMAIL',
} as const;

const modes = {
  resetPassword: 'resetPassword',
  recoverEmail: 'recoverEmail',
  verifyEmail: 'verifyEmail',
} as const;

type AuthActionControllerState =
  | {
      pageType: 'loading';
      mode: string;
      code: string;
      actionCodeInfo: firebase.auth.ActionCodeInfo | null;
    }
  | {
      pageType: 'invalid';
      mode: string | null;
      code: string | null;
      actionCodeInfo: firebase.auth.ActionCodeInfo | null;
    }
  | {
      pageType: typeof modes[keyof typeof modes];
      mode: string;
      code: string;
      actionCodeInfo: firebase.auth.ActionCodeInfo;
    };
type AuthActionControllerAction = {
  type: 'SET_ACTION_CODE';
  payload: firebase.auth.ActionCodeInfo | null;
};
const authActionControllerReducer = (
  state: AuthActionControllerState,
  action: AuthActionControllerAction
): AuthActionControllerState => {
  const { type, payload } = action;
  if (state.pageType === 'invalid') return state;
  switch (type) {
    case 'SET_ACTION_CODE': {
      if (payload === null) {
        return {
          ...state,
          pageType: 'invalid',
        };
      }
      switch (payload.operation) {
        case operations.resetPassword:
          if (state.mode === modes.resetPassword) {
            return {
              ...state,
              pageType: modes.resetPassword,
              actionCodeInfo: payload,
            };
          }
          break;
        case operations.verifyEmail:
          if (state.mode === modes.verifyEmail) {
            return {
              ...state,
              pageType: modes.verifyEmail,
              actionCodeInfo: payload,
            };
          }
          break;
        case operations.recoverEmail:
          if (state.mode === modes.recoverEmail) {
            return {
              ...state,
              pageType: modes.recoverEmail,
              actionCodeInfo: payload,
            };
          }
          break;
        default:
      }
      return {
        ...state,
        pageType: 'invalid',
      };
    }
  }
};

interface AuthActionProps {}
const AuthAction: React.FC<AuthActionProps> = () => {
  const query = useQueryParams();
  const _mode = query.get('mode');
  const _code = query.get('oobCode');
  const [state, dispatch] = React.useReducer(
    authActionControllerReducer,
    _mode === null || _code === null
      ? {
          pageType: 'invalid',
          mode: _mode,
          code: _code,
          actionCodeInfo: null,
        }
      : {
          pageType: 'loading',
          mode: _mode,
          code: _code,
          actionCodeInfo: null,
        }
  );
  const { code, mode } = state;

  React.useEffect(() => {
    if (code === null || mode === null) return;
    firebase
      .auth()
      .checkActionCode(code)
      .then(actionCodeInfo => {
        dispatch({ type: 'SET_ACTION_CODE', payload: actionCodeInfo });
      })
      .catch(r => {
        dispatch({ type: 'SET_ACTION_CODE', payload: null });
        console.error('e', r);
      });
  }, [code, mode]);

  switch (state.pageType) {
    case 'loading':
      return null;
    case 'resetPassword':
      return (
        <ConfirmPasswordReset
          code={state.code}
          codeInfo={state.actionCodeInfo}
        />
      );
    case 'recoverEmail':
      return <RecoverEmail code={state.code} codeInfo={state.actionCodeInfo} />;
    case 'verifyEmail':
      return <ConfirmEmail code={state.code} codeInfo={state.actionCodeInfo} />;
    default:
      return <Navigate to='/' />;
  }
};

export default AuthAction;
