import * as React from 'react';
import SVG from 'react-inlinesvg';
import { Route, Routes, useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useEndedEventIds } from '../../api/event';
import { useEndedTrainingIds } from '../../api/training';
import { useSetLoadingState } from '../../redux/actions/appActions';
import { useCurrentUser } from '../../redux/selectors/authSelectors';
import PlayerImg from '../../static/svg/MyPage_Player.svg';
import EventTable from '../eventProvider/EventTable';
import EventTrainingTable from '../trainingProvider/EventTrainingTable';
import CommonBG from '../uiElements/CommonBG';
import HistoryResult from './HistoryResult';

interface HistoryProps {}
const History: React.FC<HistoryProps> = () => {
  const user = useCurrentUser();
  const setLoadingState = useSetLoadingState();
  const navigate = useNavigate();
  const [eventIds, loading] = useEndedEventIds(user);
  const [trainingIds, loading3] = useEndedTrainingIds(user);
  const match = useMatch('/history/:eventId');
  const eventId = match?.params.eventId ?? null;
  const [prevEventId, setPrevEventId] = React.useState<string | null>(null);
  const [nextEventId, setNextEventId] = React.useState<string | null>(null);

  const setEventId = React.useCallback(
    (eId: string) => {
      navigate('/history/' + eId);
    },
    [navigate]
  );

  React.useEffect(() => {
    setLoadingState({ visible: loading });
  }, [loading, setLoadingState]);

  React.useEffect(() => {
    console.log(loading, eventIds?.length, trainingIds?.length, 'redirect');
    if (!loading && !loading3 && !eventIds?.length && !trainingIds?.length) {
      navigate('/');
    }
  }, [eventIds, trainingIds, navigate, loading, loading3]);

  React.useEffect(() => {
    if (loading || !eventIds || !eventId) return;
    const idx = eventIds.indexOf(eventId);
    setPrevEventId(idx !== -1 && eventIds[idx + 1] ? eventIds[idx + 1] : null);
    setNextEventId(idx !== -1 && eventIds[idx - 1] ? eventIds[idx - 1] : null);
  }, [eventId, eventIds, loading]);

  return (
    <Routes>
      <Route
        path='/'
        element={
          <CommonBG
            withHeader
            withFooter
            headerOptions={{ buttonType: 'logout' }}
          >
            <EventsWrapper>
              <Content>
                <Player>
                  <SVG src={PlayerImg} />
                  <p>{user?.displayName ?? 'NoName'}</p>
                </Player>

                <EventTrainingTable
                  eventIds={eventIds ?? []}
                  trainingIds={trainingIds ?? []}
                  type='eventsTrainingsResultView'
                  showResult={setEventId}
                />
                {loading && <Loading>Loading...</Loading>}
              </Content>
            </EventsWrapper>
          </CommonBG>
        }
      />
      <Route
        path='/*'
        element={
          <HistoryResult prevEventId={prevEventId} nextEventId={nextEventId} />
        }
      />
    </Routes>
  );
};

const EventsWrapper = styled.div``;

const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2.4rem 8rem 0;
`;

const Player = styled.div`
  display: flex;
  align-items: center;
  height: 7rem;
  line-height: 7rem;
  font-size: 2.8rem;
  margin: 0 auto;

  p {
    font-weight: bold;
  }

  svg {
    width: 4rem;
    height: 4rem;
    margin-right: 4rem;
  }
`;

const Loading = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem 0;
`;

export default History;
