import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubButton as _Button } from '@riddler-co-jp/specc-ui-components';
import format from 'date-fns/format';
import { ja } from 'date-fns/locale';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import {
  useEventData,
  useEventRecords,
  useEventStats,
} from '../../../api/event';
import { useUserInfo } from '../../../api/user';
import { presets } from '../../../lib/preset';
import { DataArray, calcRank, getRankMessage } from '../../../lib/stageResults';
import Icon_Communicate from '../../../static/svg/admin/category_communicate.svg';
import Icon_Create from '../../../static/svg/admin/category_create.svg';
import Icon_Energy from '../../../static/svg/admin/category_energy.svg';
import Icon_Program from '../../../static/svg/admin/category_program.svg';
import Icon_Switch from '../../../static/svg/admin/category_switch.svg';
import Colors from '../../../styles/colors';
import { EventStatus } from '../../../types';
import RadarChart from '../../eventProvider/eventResultPage/RadarChart';
import AdminCommonBG from '../AdminCommonBG';
import { UserEvents } from './AdminUserDetail';

const stages = ['stage1', 'stage2', 'stage3', 'stage4', 'stage5'] as const;
interface AdminUserHistoryProps {}
const AdminUserHistory: React.FC<AdminUserHistoryProps> = props => {
  const { userId, eventId } = useParams<'userId' | 'eventId'>();

  const [currentUserInfo] = useUserInfo(userId);
  const [events] = useEventData(eventId ?? null);
  const [eventStats] = useEventStats(eventId ?? null);
  const [eventRecords] = useEventRecords(eventId ?? null);
  const [totalStats] = useEventStats('total');

  const userEvents = React.useMemo(() => {
    if (!eventRecords) {
      return null;
    }
    if (!events) {
      return null;
    }
    const ret = {
      id: eventId,
      record: eventRecords?.docs.filter(e => e.id === userId)[0].data(),
    } as UserEvents;
    ret.stats = eventStats;
    ret.info = events;

    ret.scores = stages.map(
      stage => ret.record.calculatedScores?.[stage].score ?? -1
    ) as DataArray;
    ret.deviations = stages.map(stage => {
      const score = ret.record.calculatedScores?.[stage]?.score ?? -1;
      if (score < 0) {
        return 0;
      }
      if (events?.status !== EventStatus.Ended) {
        return 0;
      }
      const eventAvg = ret.stats?.[stage].avg ?? score;
      const eventStd = ret.stats?.[stage].std ?? 1;
      if (eventStd == 0) {
        return 50;
      }
      return 50 + 10 * ((score - eventAvg) / (eventStd === 0 ? 1 : eventStd));
    }) as DataArray;

    ret.allUserDeviations = stages.map(stage => {
      const score = ret.record.calculatedScores?.[stage].score ?? -1;
      if (score < 0) {
        return 0;
      }
      if (events?.status !== EventStatus.Ended) {
        return 0;
      }
      const avg = totalStats?.[stage].avg ?? score;
      const std = totalStats?.[stage].std ?? 1;
      if (std == 0) {
        return 50;
      }
      return 50 + 10 * ((score - avg) / std);
    }) as DataArray;

    ret.ranks = ret.scores.map((e, i) =>
      calcRank(events.preset ?? '1', e, i)
    ) as DataArray;
    return ret;
  }, [eventRecords, eventStats, events, userId, totalStats]);

  const loaded = (userEvents ?? null) !== null;
  const Results = () => {
    const _list = [];
    const _icons = [
      Icon_Switch,
      Icon_Program,
      Icon_Create,
      Icon_Energy,
      Icon_Communicate,
    ];
    const _categories = [
      'Switch',
      'Program',
      'Create',
      'Energy',
      'Communicate',
    ];
    const preset = presets.find(
      e => e.preset_id === (userEvents?.info?.preset ?? '1')
    );
    if (!preset) {
      return <></>;
    }
    const names = preset.tests.map(e => e.name);
    for (let i = 0; i < 5; i++) {
      const devi = userEvents?.deviations?.[i] ?? 0;
      const allDevi = userEvents?.allUserDeviations?.[i] ?? 0;
      _list.push(
        <TR key={_categories[i]}>
          <TD>
            <SVG src={_icons[i]} />
            <p>{_categories[i]}</p>
            <p>{names[i]}</p>
          </TD>
          <TD>{getRankMessage(userEvents?.ranks?.[i] ?? -1)}</TD>
          <TD>{devi === 0 ? '-' : devi.toFixed(1)}</TD>
          <TD>{allDevi === 0 ? '-' : allDevi.toFixed(1)}</TD>
        </TR>
      );
    }

    return <>{_list}</>;
  };

  return (
    <AdminCommonBG backButton title='イベント履歴'>
      <AdminEventControllerWrapper>
        <Row>
          <DetailTable>
            <THead>
              <TR>
                <TH>氏名</TH>
                <TH>開催期間</TH>
                <TH>イベント名</TH>
                <TH>プリセット</TH>
              </TR>
            </THead>

            <TBody>
              <TR>
                <TD>
                  <Link to={`/admin/accounts/${userId}`}>
                    {currentUserInfo?.displayName ??
                      currentUserInfo?.fullName ??
                      ''}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                </TD>
                <TD>
                  {' '}
                  {userEvents?.info?.scheduledStartTime &&
                    format(
                      userEvents?.info?.scheduledStartTime,
                      'yyyy年M月d日(E) HH:mm',
                      { locale: ja }
                    )}
                  {' - '}
                  {userEvents?.info?.scheduledEndTime &&
                    format(
                      userEvents?.info?.scheduledEndTime,
                      'yyyy年M月d日(E) HH:mm',
                      { locale: ja }
                    )}
                </TD>
                <TD>
                  <Link to={`/admin/events/${eventId}/control`}>
                    {userEvents?.info?.name ?? ''}
                    <FontAwesomeIcon icon={faChevronRight} />
                  </Link>
                </TD>
                <TD>テストプリセット１</TD>
              </TR>
            </TBody>
          </DetailTable>
        </Row>
        <Row>
          <Item>
            <ItemTitle>テスト成績</ItemTitle>
            <ResultTable>
              <THead>
                <TR>
                  <TH>テスト項目</TH>
                  <TH>ランク</TH>
                  <TH>イベント内偏差値</TH>
                  <TH>全受験者内偏差値</TH>
                </TR>
              </THead>

              <TBody>
                <Results />
              </TBody>
            </ResultTable>
          </Item>
        </Row>

        <Row>
          <Item>
            <ItemTitle>偏差値グラフ</ItemTitle>
            <ItemBody>
              <RadarChartWrapper>
                <RadarChart
                  deviations={userEvents?.deviations ?? [0, 0, 0, 0, 0]}
                  width={300}
                  loaded={loaded}
                />
              </RadarChartWrapper>
            </ItemBody>
          </Item>
        </Row>
      </AdminEventControllerWrapper>
    </AdminCommonBG>
  );
};

const RadarChartWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

const AdminEventControllerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Row = styled.div`
  &:last-child {
    border-bottom: none;
  }
`;

const Item = styled.div`
  width: 100%;
`;

const ItemTitle = styled.div`
  font-size: 2.4rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const ItemBody = styled.div`
  width: 100%;
`;

const Button = styled(_Button)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

const DetailTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;

  tbody {
    tr {
      border: none;
    }
  }

  tr {
    td:nth-child(1) {
      /*width: 40%;*/
    }
    td:nth-child(2) {
      width: 24rem;
    }
    td:nth-child(3) {
      p {
        margin-right: 1rem;
      }
      p:last-child {
        margin-right: 0;
      }
    }

    td:last-child {
      padding-right: 0;
    }
  }
`;

const ResultTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;

  tr {
    td:nth-child(1) {
      display: flex;
      align-items: center;

      > :nth-child(2) {
        font-weight: bold;
        width: 14rem;
      }
    }
    td:nth-child(2) {
      color: ${Colors.accent};
      font-weight: bold;
      font-size: 2.8rem;
    }
    td:nth-child(3) {
      color: ${Colors.accent};
      font-weight: bold;
      font-size: 2.8rem;
    }

    td:last-child {
      padding-right: 0;
    }
  }

  td {
    padding: 1rem 0;
  }
`;
const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;
`;

const TD = styled.td`
  font-size: 1.4rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }

  a {
    color: ${Colors.accent};
    font-weight: bold;
    text-decoration: none;

    svg {
      margin-left: 0.6rem;
    }
  }
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;
  border-bottom: 1px solid ${Colors.gray4};
`;

interface DeleteButton {
  disabled: boolean;
}

const DeleteButton = styled.div`
  color: ${Colors.error};
  cursor: pointer;
  display: initial;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  transition: 0.2s;

  &:hover {
    background: ${Colors.gray4};
  }

  ${(p: DeleteButton) =>
    p.disabled &&
    `
    cursor: default;
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      background: initial;
    }
    `}
`;

export default AdminUserHistory;
