import { MainButton as _Button } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useMemo } from 'react';
import {
  Link as _Link,
  useMatch,
  useNavigate,
  useParams,
} from 'react-router-dom';
import styled from 'styled-components';

import { stageIds } from '../../api/stages';
import { useTrainingData } from '../../api/training';
import { curriculumsToSubject } from '../../lib/organization';
import Communicate_Disabled from '../../static/jpg/st/Communicate_disabled.jpg';
import Communicate from '../../static/jpg/st/Communicate.jpg';
import Create_Disabled from '../../static/jpg/st/Create_disabled.jpg';
import Create from '../../static/jpg/st/Create.jpg';
import Energy_Disabled from '../../static/jpg/st/Energy_disabled.jpg';
import Energy from '../../static/jpg/st/Energy.jpg';
import Program_Disabled from '../../static/jpg/st/Program_disabled.jpg';
import Program from '../../static/jpg/st/Program.jpg';
import Switch_Disabled from '../../static/jpg/st/Switch_disabled.jpg';
import Switch from '../../static/jpg/st/Switch.jpg';

interface TrainingStageSelectorProps {}
const TrainingStageSelector: React.FC<TrainingStageSelectorProps> = () => {
  const match = useMatch('/');

  const { trainingId } = useParams<'trainingId'>();
  const navigate = useNavigate();

  const goTo = (url: string) => {
    navigate(url);
  };

  const data = {
    1: {
      url: 'https://st-switch.specc-dev.riddler.co.jp/',
      thumb: Switch,
      thumb_disabled: Switch_Disabled,
    },
    2: {
      url: 'https://st-program.specc-dev.riddler.co.jp/',
      thumb: Program,
      thumb_disabled: Program_Disabled,
    },
    3: {
      url: 'https://st-create.specc-dev.riddler.co.jp/',
      thumb: Create,
      thumb_disabled: Create_Disabled,
    },
    4: {
      url: 'https://st-energy.specc-dev.riddler.co.jp/',
      thumb: Energy,
      thumb_disabled: Energy_Disabled,
    },
    5: {
      url: 'https://st-comm.specc-dev.riddler.co.jp/',
      thumb: Communicate,
      thumb_disabled: Communicate_Disabled,
    },
  };

  const [training] = useTrainingData(trainingId ?? null);
  const subject = useMemo(() => {
    return curriculumsToSubject(training?.curriculums ?? []);
  }, [training]);

  return (
    <Wrap>
      <Details>
        <li style={{ flex: '3' }}>「アイデアを生み出す」ための力</li>
        <li style={{ flex: '2' }}>「思考をサポートする」ための力</li>
      </Details>
      <Trainings>
        {stageIds.map((i, index) => (
          <Single
            key={i}
            disabled={!subject[index]}
            href={data[i].url + trainingId + '/'}
          >
            <img
              src={!subject[index] ? data[i].thumb_disabled : data[i].thumb}
            />
          </Single>
        ))}
      </Trainings>
      <Buttons>
        <Button color='negative' size='large' onClick={() => goTo(`/events/`)}>
          戻る
        </Button>
      </Buttons>
    </Wrap>
  );
};

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  width: 100%;
  max-width: 160px;
  text-align: center;
  margin: 0 auto;
`;

const Buttons = styled.div`
  margin: 3rem auto 0;
  margin-bottom: 4rem;
  padding: 0 8rem;
  display: flex;
  justify-contents: center;
`;

const Wrap = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

interface SingleProps {
  disabled: boolean;
}

const Single = styled.a`
  text-decoration: none;
  flex: 1;
  border-radius: 10px;
  margin-right: 6px;
  overflow: hidden;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    z-index: 2;
    transform: scale(1.08);
  }

  img {
    width: 100%;
    height: auto;
    margin-bottom: -4px;
  }

  &:last-child {
    margin-right: 0;
  }

  &:nth-child(1) {
    background: #6c98ff;
    box-shadow: 0 5px 0px #4167e4;
  }
  &:nth-child(2) {
    background: #00e689;
    box-shadow: 0 5px 0px #00b67f;
  }
  &:nth-child(3) {
    background: #ffb943;
    box-shadow: 0 5px 0px #d28400;
  }
  &:nth-child(4) {
    background: #ff6ebb;
    box-shadow: 0 5px 0px #c63583;
  }
  &:nth-child(5) {
    background: #956fff;
    box-shadow: 0 5px 0px #6338db;
  }

  ${(p: SingleProps) => {
    return p.disabled
      ? `
        pointer-events: none;
        box-shadow: none !important;
    `
      : ``;
  }}
`;

const Details = styled.ul`
  font-size: 1.6rem;
  font-weight: bold;
  display: flex;
  margin-bottom: 10px;

  li {
    list-style: none;
    background: #e9ecef;
    padding: 4px;
    margin-right: 6px;

    &:last-child {
      margin: 0;
    }
  }
`;

const Trainings = styled.div`
  display: flex;
  margin-bottom: 40px;
`;

export default TrainingStageSelector;
