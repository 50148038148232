import {
  DatePicker,
  Input,
  TimeNumber,
} from '@riddler-co-jp/specc-ui-components';
import { Validators, useValidator } from '@riddler-co-jp/specc-ui-components';
import {
  Select,
  SelectOptions,
  SelectOverrideStyles,
} from '@riddler-co-jp/specc-ui-components';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import * as React from 'react';
import { OptionTypeBase, ValueType } from 'react-select';
import styled from 'styled-components';

import { EventDocument } from '../../../api/event';
import { presets } from '../../../lib/preset';
import Colors from '../../../styles/colors';
import _Button from '../../uiElements/deprecated/Button';
import PresetDetail from '../UIelements/AdminPresetDetail';
import EditParticipantManager from '../UIelements/EditParticipantManager';

interface AdminEventEditorProps {
  event: EventDocument;
  onUpdate?: React.Dispatch<React.SetStateAction<EventDocument | null>>;
  participantUids: string[];
  allowPresets: string[];
  onUpdateParticipantUids: React.Dispatch<React.SetStateAction<string[]>>;
  onConfirm?: () => void;
  onCancel?: () => void;
  changeValidCallback: (valid: boolean) => void;
}

export const setTime = (d: Date, v: TimeNumber) =>
  setHours(setMinutes(d, v.minute), v.hour);
export const date2UnixSec = (d: Date) => Math.floor(d.getTime() / 1000);

type OnChangeType = (x: ValueType<OptionTypeBase, false>) => void;
type OptionType = {
  value: string;
  label: string;
};

const AdminEventEditor: React.FC<AdminEventEditorProps> = props => {
  const [startDateIsValid, setStartDateValid] = React.useState<boolean>(true);
  const [endDateIsValid, setEndDateValid] = React.useState<boolean>(true);
  const [isDateValid, setDateValid] = React.useState<boolean>(true);
  const ev = props.event;
  const {
    onUpdate,
    participantUids,
    onUpdateParticipantUids,
    onConfirm,
    onCancel,
  } = props;

  const onUpdateEvent = React.useCallback(
    (next: EventDocument | ((old: EventDocument) => EventDocument)) => {
      console.log('onUpdateEvent', next);
      if (typeof next === 'function') {
        onUpdate?.(next(ev));
      } else {
        onUpdate?.(next);
      }
    },
    [ev, onUpdate]
  );

  /**
   * MEMO:
   * EventDocument[K] が function type のときにバグるね...
   */
  const onUpdateValue = React.useCallback(
    <K extends keyof EventDocument>(
      key: K,
      next: EventDocument[K] | ((old: EventDocument) => EventDocument[K])
    ) => {
      if (typeof next === 'function') {
        onUpdateEvent({ ...ev, [key]: next(ev) });
      } else {
        onUpdateEvent({ ...ev, [key]: next });
      }
    },
    [ev, onUpdateEvent]
  );

  const onUpdatePlace = React.useCallback(
    (e: string) => {
      onUpdateValue('place', e);
    },
    [onUpdateValue]
  );
  const onUpdateName = React.useCallback(
    (e: string) => {
      onUpdateValue('name', e);
    },
    [onUpdateValue]
  );

  const startDate = React.useMemo(() => {
    return ev.scheduledStartTime;
  }, [ev]);
  const endDate = React.useMemo(() => {
    return ev.scheduledEndTime;
  }, [ev]);

  const onUpdateStartDate = React.useCallback(
    (d: Date) => {
      onUpdateValue('scheduledStartTime', d);

      {
        const d1 = new Date();
        const d2 = new Date(d);
        d1.setHours(0, 0, 0, 0);
        d2.setHours(0, 0, 0, 0);
        setStartDateValid(d1.getTime() <= d2.getTime());
      }
      {
        const d1 = d;
        const d2 = endDate;
        d1.setHours(0, 0, 0, 0);
        setDateValid(d1.getTime() <= d2.getTime());
      }
    },
    [endDate, onUpdateValue]
  );

  const onUpdateEndDate = React.useCallback(
    (d: Date) => {
      d.setHours(23, 59, 59, 0);
      onUpdateValue('scheduledEndTime', d);
      {
        const d1 = new Date();
        const d2 = new Date(d);
        d1.setHours(0, 0, 0, 0);
        d2.setHours(0, 0, 0, 0);
        setEndDateValid(d1.getTime() <= d2.getTime());
      }
      {
        const d1 = startDate;
        const d2 = d;
        d1.setHours(0, 0, 0, 0);
        setDateValid(d1.getTime() <= d2.getTime());
      }
    },
    [onUpdateValue, startDate]
  );

  const options: SelectOptions<OptionType> = React.useMemo(() => {
    return presets
      .map(item => ({
        value: item.preset_id,
        label: item.preset_name,
      }))
      .filter(item => props.allowPresets.includes(item.value));
  }, [presets, props.allowPresets]);

  const onChangePreset = React.useCallback(
    (e: OptionType | null) => {
      console.log(e);
      if (e) {
        const value = parseInt(e.value);
        onUpdateValue('preset', '' + value);
      }
    },
    [onUpdateValue]
  );

  const selectStyles: SelectOverrideStyles = {
    container: {
      width: '24rem',
      fontSize: '1.3rem',
    },
    option: {
      fontSize: '1.3rem',
    },
    singleValue: {
      fontSize: '1.3rem',
    },
  };

  const nameValidators = React.useMemo<Validators>(
    () => [
      [val => !/^.{0,30}$/.test(val), '30文字以内で入力してください。'],
      [val => val === '', 'イベント名を入力してください。'],
    ],
    []
  );
  const [nameValidator, nameIsValid] = useValidator(nameValidators);

  const placeValidators = React.useMemo<Validators>(
    () => [[val => !/^.{0,20}$/.test(val), '20文字以内で入力してください。']],
    []
  );
  const [placeValidator, placeIsValid] = useValidator(placeValidators);

  React.useEffect(() => {
    props.changeValidCallback(
      nameIsValid && placeIsValid && startDateIsValid && endDateIsValid
    );
  }, [endDateIsValid, nameIsValid, placeIsValid, props, startDateIsValid]);

  const preset = React.useMemo(() => {
    return (
      presets.find(item => item.preset_id === (ev.preset ?? '1')) ?? presets[0]
    );
  }, [ev.preset]);

  return (
    <AdminEventEditorWrapper>
      <Row>
        <Item width={360}>
          <ItemTitle>イベント名</ItemTitle>
          <ItemBody>
            <Input
              value={ev.name}
              onChange={x => {
                onUpdateName(x);
              }}
              validator={nameValidator}
            />
          </ItemBody>
        </Item>
      </Row>
      <Row>
        <Item>
          <ItemTitle>プリセット</ItemTitle>
          <ItemBody>
            <Select
              options={options}
              overrideStyles={selectStyles}
              onChange={onChangePreset}
              defaultValue={options.find(x => x.value === (ev.preset ?? '1'))}
            />
          </ItemBody>
        </Item>
      </Row>
      <PresetDetail tests={preset.tests} />
      <Row>
        <Item marginRight={20}>
          <ItemTitle>開始日</ItemTitle>
          <ItemBody>
            <DatePicker selected={startDate} onChange={onUpdateStartDate} />
          </ItemBody>
        </Item>
        <Item>
          <ItemTitle>終了日</ItemTitle>

          <ItemBody>
            <DatePicker selected={endDate} onChange={onUpdateEndDate} />
          </ItemBody>
        </Item>
      </Row>
      {isDateValid && !(startDateIsValid && endDateIsValid) && (
        <Row>
          <Err>現在より未来の日付を設定してください。</Err>
        </Row>
      )}
      {!isDateValid && (
        <Row>
          <Err>終了日は開始日より後の日付を設定してください。</Err>
        </Row>
      )}
      <Row>
        <Item width={360}>
          <ItemTitle>会場</ItemTitle>
          <ItemBody>
            <Input
              value={ev.place ?? ''}
              onChange={x => {
                onUpdatePlace(x);
              }}
              validator={placeValidator}
            />
          </ItemBody>
        </Item>
      </Row>
      <Row>
        <Item width={420}>
          <ItemTitle>参加者</ItemTitle>
          <ItemBody>
            <EditParticipantManager
              type='edit'
              orgId={ev.orgId}
              participantUids={participantUids}
              onUpdateParticipantUids={onUpdateParticipantUids}
              isAdmin={false}
            />
          </ItemBody>
        </Item>
      </Row>
    </AdminEventEditorWrapper>
  );
};

const AdminEventEditorWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-top: 2rem;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
`;

interface ItemStyleProps {
  width?: number;
  marginRight?: number;
}

const Item = styled.div`
  ${(p: ItemStyleProps) => (p.width != null ? `width: ${p.width}px;` : '')}
  ${(p: ItemStyleProps) =>
    p.marginRight != null ? `margin-right: ${p.marginRight}px;` : ''}

  input {
    font-size: 1.3rem;
    padding: 0.8rem 0.7rem;
    border: ${Colors.gray6} 2px solid;
    border-radius: 0.4rem;

    &::placeholder {
      color: ${Colors.gray4};
    }
  }
`;

const ItemTitle = styled.div`
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-bottom: 0.6rem;
  font-weight: 500;
`;

const ItemBody = styled.div`
  width: 100%;
`;

const LongInput = styled.input`
  width: 100%;
`;

const Button = styled(_Button)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

const InlineButton = styled(Button)`
  display: inline-block;
`;

const Buttons = styled.div`
  width: 100%;
  text-align: right;
  padding: 0 2rem;
  box-sizing: border-box;
`;

const Err = styled.div`
  color: red;
  margin-top: -1rem;
  font-size: 1.3rem;
`;

export default AdminEventEditor;
