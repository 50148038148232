import { faChevronDown, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CheckBox,
  Select,
  SelectOverrideStyles,
  SubButton,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useRef } from 'react';
import styled from 'styled-components';

import Colors from '../../../styles/colors';

interface FilterItem {
  type: 'input' | 'select' | 'checkbox';
  name: string;
  label: string;
  value?: any;
  options?: any[];
  defaultValue?: any;
  width?: number;
}

interface FilterStates {
  params: FilterItem[];
}

interface FilterProps {
  item: FilterItem[];
  onSend: (params: any) => void;
}
const Filter: React.FC<FilterProps> = props => {
  const [componentKey, setComponentKey] = React.useState<number>(1);

  const defaultFilterStates: FilterItem[] = [];
  props.item.map((single: FilterItem) => {
    defaultFilterStates.push(single);
  });

  const defaultParams: FilterStates = {
    params: defaultFilterStates,
  };

  const [open, setOpen] = React.useState(false);
  const [filterStates, setFilterStates] =
    React.useState<FilterStates>(defaultParams);
  const elm = useRef(null);
  let bodyHeight = 0;

  if ((elm!.current! as HTMLElement) !== null) {
    bodyHeight = (elm!.current! as HTMLElement)!.offsetHeight || 0;
  }

  const selectStyles: SelectOverrideStyles = {
    container: {
      width: '24rem',
      fontSize: '1.3rem',
    },
    option: {
      fontSize: '1.3rem',
    },
    singleValue: {
      fontSize: '1.3rem',
    },
    menu: {
      zIndex: 99,
    },
  };

  return (
    <Wrapper open={open} key={componentKey}>
      <Title
        onClick={() => {
          setOpen(!open);
        }}
      >
        <FontAwesomeIcon icon={faSearch} />
        <p>検索条件</p>
        <Chevron open={open}>
          <FontAwesomeIcon icon={faChevronDown} />
        </Chevron>
      </Title>
      <Body open={open} bodyHeight={bodyHeight}>
        <InnerBody ref={elm}>
          <FilterItemWrap>
            {props.item.map((single, i) => {
              if (single.type == 'input') {
                return (
                  <ItemInput
                    width={single.width}
                    key={single.name}
                    placeholder={single.label}
                    onChange={e => {
                      single.value = e.target.value;
                      filterStates.params.map((p: FilterItem, i: number) => {
                        if (p.name == single.name) {
                          filterStates.params[i].value = e.target.value;
                        }
                      });
                      setFilterStates(filterStates);
                    }}
                  />
                );
              }
              if (single.type == 'checkbox') {
                console.log(
                  filterStates.params[i].value,
                  filterStates.params[i].defaultValue
                );
                return (
                  <CheckBoxWrap key={single.name}>
                    <CheckBox
                      id={single.name}
                      label={single.label}
                      value={
                        filterStates.params[i].value ??
                        filterStates.params[i].defaultValue
                      }
                      onChange={e => {
                        single.value = e;
                        filterStates.params.map((p: FilterItem, i: number) => {
                          if (p.name == single.name) {
                            filterStates.params[i].value = e;
                          }
                        });
                        setFilterStates(() => {
                          return { params: filterStates.params };
                        });
                      }}
                    />
                  </CheckBoxWrap>
                );
              }
              if (single.type == 'select' && single.options != null) {
                return (
                  <SelectWrap key={single.name}>
                    <Select
                      options={single.options}
                      defaultValue={filterStates.params[i].defaultValue}
                      overrideStyles={selectStyles}
                      onChange={e => {
                        if (e === null) {
                          return;
                        }
                        console.log(e);
                        single.value = e.value;
                        filterStates.params.map((p: FilterItem, i: number) => {
                          if (p.name === single.name) {
                            filterStates.params[i].value = e.value;
                          }
                        });
                        console.log(filterStates);
                        setFilterStates(filterStates);
                      }}
                    />
                  </SelectWrap>
                );
              }
            })}

            <Buttons>
              <ResetButton
                onMouseDown={e => {
                  filterStates.params.map((p: FilterItem, i: number) => {
                    if (p.type == 'input') {
                      filterStates.params[i].value = '';
                    }
                    if (p.type == 'select') {
                      props.item.map(single => {
                        if (single.name == p.name) {
                          single.value = single.defaultValue;
                          filterStates.params[i].value =
                            single.defaultValue.value;
                        }
                      });
                    }
                  });
                  setFilterStates(filterStates);

                  setComponentKey(componentKey + 1);
                }}
              >
                リセットする
              </ResetButton>
              <SubButton
                size={'medium'}
                color={'positive'}
                onMouseDown={e => {
                  const param: any = {};
                  filterStates.params.map((single: FilterItem) => {
                    if (
                      single.value !== null &&
                      single.value !== undefined &&
                      (single.type === 'select' || single.value !== '')
                    ) {
                      param[single.name] = single.value;
                    }
                  });
                  props.onSend(param);
                }}
              >
                適用する
              </SubButton>
            </Buttons>
          </FilterItemWrap>
        </InnerBody>
      </Body>
    </Wrapper>
  );
};

interface widthProps {
  width?: number;
}

const ItemInput = styled.input`
  font-size: 1.3rem;
  padding: 0.8rem 0.7rem;
  border: ${Colors.gray6} 2px solid;
  border-radius: 0.4rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  &::placeholder {
    color: ${Colors.gray6};
  }

  ${(p: widthProps) =>
    p.width === null
      ? `
      `
      : `
        width: ${p.width}px;
  `}
`;

const CheckBoxWrap = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;

  label {
    font-size: 1.3rem !important;
  }
`;

const SelectWrap = styled.div`
  margin-right: 1rem;
  margin-bottom: 1rem;
`;

const FilterItemWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const FilterItem = styled.div``;

const ResetButton = styled.div`
  color: ${Colors.error};
  transition: 0.2s;
  position: relative;
  height: 4rem;
  line-height: 4rem;
  min-width: 8rem;
  padding: 0 1.6rem;
  font-size: 1.6rem;
  border-radius: 0.5rem;
  margin-right: 1rem;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: ${Colors.gray4};
  }
`;

const Buttons = styled.div`
  display: flex;
  margin-left: auto;
`;

const Wrapper = styled.div`
  background: ${Colors.gray2};
  margin: 2rem 0;
  margin-bottom: 0;
  border-radius: 5px;

  ${(p: bodyProps) =>
    p.open === true
      ? `
        overflow: initial;
      `
      : `
        overflow: hidden;
  `}
`;

const Chevron = styled.div`
  svg {
    transition: 0.2s;

    ${(p: bodyProps) =>
      p.open === true
        ? `
          transform: rotate(180deg);
        `
        : `
    `}
  }
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  cursor: pointer;
  padding: 1rem 1.4rem;
  transition: 0.2s;
  border-radius: 5px;

  svg {
    margin-right: 0.6rem;
  }

  p {
    flex: 1;
  }

  &:hover {
    background: ${Colors.gray4};
  }
`;

interface bodyProps {
  open: boolean;
  bodyHeight?: number;
}
const Body = styled.div`
  transition: 0.2s;
  ${(p: bodyProps) =>
    p.open === true
      ? `
      height: ${p.bodyHeight}px;
      opacity: 1.0;
      `
      : `
      height: 0;
      opacity: 0.0;
  `}
`;
const InnerBody = styled.div`
  padding: 1rem 1.4rem;
`;

export default Filter;
