import { SubButton as _Button } from '@riddler-co-jp/specc-ui-components';
import format from 'date-fns/format';
import { ja } from 'date-fns/locale';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { EventDocument } from '../../../api/event';
import { presets } from '../../../lib/preset';
import Colors from '../../../styles/colors';
import { EventStatus } from '../../../types';
import { AdminRole } from '../AdminAdministratorDetail';
import PresetDetail from '../UIelements/AdminPresetDetail';
import ResultDetail from '../UIelements/AdminResultDetail';
import EventControlParticipantManager from '../UIelements/EventControlParticipantManager';

interface AdminEventControllerProps {
  event: EventDocument;
  participantUids: string[];
  onCancel?: () => void;
  onStartEvent: () => void;
  onEndEvent: () => void;
  onRemoveEvent: () => void;
  role: AdminRole;
  onCsvDownload: () => void;
  csvClicked: boolean;
}

export const eventStatusShortText = (ev: EventDocument) => {
  switch (ev.status) {
    case EventStatus.Ongoing:
      return '開催中';
    case EventStatus.Preparing:
      return '未開催';
    case EventStatus.Ending:
      return '終了中のテスト';
    case EventStatus.Ended:
      return '終了したテスト';
    case EventStatus.Removed:
      return '削除済み';
    default:
      return '不明';
  }
};

const eventStatusText = (ev: EventDocument) => {
  switch (ev.status) {
    case EventStatus.Ongoing:
      return 'このイベントは開催中です。\n最終結果は未集計の状態です。';
    case EventStatus.Preparing:
      return 'このイベントは未開催の状態です。\n準備ができたら「テストを開催する」ボタンをクリックしてください。';
    case EventStatus.Ending:
      return 'このイベントは既に終了しています。';
    case EventStatus.Ended:
      return 'このイベントは既に終了しています。';
    case EventStatus.Removed:
      return 'このイベントは削除済みです。';
    default:
      return '不明なイベント状態です。';
  }
};
const eventStatusButtonText = (ev: EventDocument) => {
  switch (ev.status) {
    case EventStatus.Ongoing:
      return '終了する';
    case EventStatus.Preparing:
      return 'テストを開催する';
    default:
      return '';
  }
};

const eventResultText = (ev: EventDocument) => {
  const resCalced = ev.resultCalculatedOn;
  if (resCalced == null) {
    return '最終結果は未集計の状態です。';
  }

  const formatted = format(resCalced, 'yyyy/MM/dd HH:mm:ss');
  return `最終結果は ${formatted} に計算されました。`;
};

const AdminEventController: React.FC<AdminEventControllerProps> = props => {
  const ev = props.event;
  const navigate = useNavigate();

  const {
    role,
    participantUids,
    onCancel,
    onStartEvent,
    onEndEvent,
    onRemoveEvent,
  } = props;
  const onClickCancel = React.useCallback(() => onCancel?.(), [onCancel]);

  const onMoveToOverview = React.useCallback(() => {
    navigate(`/admin/events/${ev.eventId}/overview`);
  }, [ev.eventId, navigate]);

  const onMoveToOverview5 = React.useCallback(() => {
    navigate(`/admin/events/${ev.eventId}/overview5`);
  }, [ev.eventId, navigate]);

  const onMoveToResult = React.useCallback(
    (uid: string) => {
      navigate(`/admin/events/${ev.eventId}/result/${uid}`);
    },
    [ev.eventId, navigate]
  );

  const confirmRemoveEvent = React.useCallback(() => {
    if (window.confirm(`イベント「${ev.name}」を削除しますか？`)) {
      onRemoveEvent();
    }
  }, [ev.name, onRemoveEvent]);

  const onEventStatusButton = React.useCallback(() => {
    if (ev.status === EventStatus.Preparing) {
      // 開始
      onStartEvent();
    }
    if (ev.status === EventStatus.Ongoing) {
      // 終了
      onEndEvent();
    }
  }, [ev.status, onEndEvent, onStartEvent]);
  return (
    <AdminEventControllerWrapper>
      <Row>
        <DetailTable>
          <THead>
            <TR>
              <TH>イベント名</TH>
              <TH>プリセット</TH>
              <TH>開催期間</TH>
              <TH>会場</TH>
            </TR>
          </THead>

          <TBody>
            <TR>
              <TD>{ev.name}</TD>
              <TD>
                {
                  presets.filter(e => e.preset_id === (ev.preset ?? '1'))[0]
                    .preset_name
                }
              </TD>
              <TD>
                {ev.scheduledStartTime &&
                  format(ev.scheduledStartTime, 'yyyy年M月d日(E) HH:mm', {
                    locale: ja,
                  })}
                {' - '}
                {ev.scheduledEndTime &&
                  format(ev.scheduledEndTime, 'yyyy年M月d日(E) HH:mm', {
                    locale: ja,
                  })}
              </TD>
              <TD>{ev.place == null ? '(指定なし)' : ev.place}</TD>
            </TR>
          </TBody>
        </DetailTable>
      </Row>
      <Row>
        <Item>
          <ItemTitle>ステータス</ItemTitle>
          <ItemBody>
            <CurrentStatus>
              <SingleStatus active={ev.status == EventStatus.Preparing}>
                未開催
              </SingleStatus>
              <SingleStatus active={ev.status == EventStatus.Ongoing}>
                開催中
              </SingleStatus>
              <SingleStatus active={ev.status == EventStatus.Ending}>
                スコア計算中
              </SingleStatus>
              <SingleStatus active={ev.status == EventStatus.Ended}>
                テスト終了
              </SingleStatus>
            </CurrentStatus>
            {props.role !== 'organizationAdmin' &&
              eventStatusButtonText(ev) !== '' && (
                <EventStatusText>
                  <p>{eventStatusText(ev)}</p>
                  <InlineButton
                    size='medium'
                    color='positive'
                    onClick={onEventStatusButton}
                  >
                    {eventStatusButtonText(ev)}
                  </InlineButton>
                </EventStatusText>
              )}
          </ItemBody>
        </Item>
      </Row>
      <Row>
        <Item>
          <ItemTitle>テスト内容</ItemTitle>
          <ItemBody>
            <PresetDetail
              tests={presets[parseInt(ev.preset ?? '1') - 1].tests}
            />
          </ItemBody>
        </Item>
      </Row>

      {ev.status == EventStatus.Ended && props.role !== 'organizationAdmin' && (
        <Row>
          <Item>
            <ItemTitle>テスト結果</ItemTitle>
            <ItemBody>
              <ResultDetail
                event={ev}
                eventId={ev.eventId}
                tests={presets[0].tests}
                role={role}
              />
            </ItemBody>
          </Item>
        </Row>
      )}
      {/*<Row>
        <Item>
          <ItemTitle>最終結果の集計状態</ItemTitle>
          <ItemBody>
            <ItemText>{eventResultText(ev)}</ItemText>
          </ItemBody>
        </Item>
      </Row>
      <Row>
        <Item>
          <ItemTitle>全体ビュー</ItemTitle>
          <ItemBody>
            <InlineButton onClick={onMoveToOverview} scale={0.6}>
              全体ビューへ
            </InlineButton>
            <InlineButton onClick={onMoveToOverview5} scale={0.6}>
              Stage5ビューへ
            </InlineButton>
          </ItemBody>
        </Item>
      </Row>*/}
      <Row>
        <Item>
          <ItemTitle>参加者</ItemTitle>
          <ItemBody>
            <EventControlParticipantManager
              type='control'
              event={ev}
              participantUids={participantUids}
              onMoveToResult={onMoveToResult}
              orgId={ev.orgId}
              isAdmin={false}
              onCsvDownload={props.onCsvDownload}
              csvClicked={props.csvClicked}
              statusButton={ev.status == EventStatus.Ongoing}
              showRankStatus
            />
          </ItemBody>
        </Item>
      </Row>
      <Buttons>
        <DeleteButton
          onClick={confirmRemoveEvent}
          disabled={ev.status !== EventStatus.Preparing}
        >
          イベントを削除する
        </DeleteButton>
      </Buttons>
    </AdminEventControllerWrapper>
  );
};

const AdminEventControllerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Row = styled.div`
  margin-bottom: 2rem;

  &:last-child {
    border-bottom: none;
  }
`;

const Item = styled.div`
  width: 100%;
`;

const ItemTitle = styled.div`
  font-size: 2.4rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const ItemBody = styled.div`
  width: 100%;
`;

const ItemText = styled.span`
  display: inline-block;
  font-size: 1.3rem;
`;

const Button = styled(_Button)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

const InlineButton = styled(Button)`
  display: inline-block;
  font-weight: bold;
`;

const Buttons = styled.div`
  width: 100%;
  text-align: right;
  padding: 0 2rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
`;

const DetailTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;

  tr {
    td:nth-child(1) {
      /*width: 40%;*/
    }
    td:nth-child(2) {
      width: 18rem;
    }
    td:nth-child(3) {
      width: 20rem;

      p {
        margin-right: 1rem;
      }
      p:last-child {
        margin-right: 0;
      }
    }

    td:last-child {
      width: 20rem;
      padding-right: 0;
    }
  }
`;

const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;

  tr:last-child {
    border: none !important;
  }
`;

const TD = styled.td`
  font-size: 1.4rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;
  border-bottom: 1px solid ${Colors.gray4};
`;

const EventStatusText = styled.div`
  padding: 1rem 1.6rem;
  white-space: pre-wrap;
  border-radius: 5px;
  font-size: 1.4rem;
  background: ${Colors.gray2};
  display: flex;

  p {
    flex: 1;
  }
`;

interface DeleteButton {
  disabled: boolean;
}

const DeleteButton = styled.div`
  color: ${Colors.error};
  cursor: pointer;
  display: initial;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  transition: 0.2s;

  &:hover {
    background: ${Colors.gray4};
  }

  ${(p: DeleteButton) =>
    p.disabled &&
    `
    cursor: default;
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      background: initial;
    }
    `}
`;

const CurrentStatus = styled.div`
  display: flex;
  border: 2px solid ${Colors.primary};
  border-radius: 5px;
  margin-top 2rem;
  margin-bottom: 1rem;
`;

interface SingleStatusProps {
  active?: boolean;
}
const SingleStatus = styled.div`
  flex: 1;
  padding: 1.8rem 1rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  color: ${Colors.gray6};
  position: relative;

  border-right: 2px solid ${Colors.primary};

  &:last-child {
    border: none;
  }

  &:after {
    content: '';
    z-index: 1;
    height: 12px;
    width: 12px;
    display: block;
    position: absolute;
    border-top: 2px solid ${Colors.primary};
    border-right: 2px solid ${Colors.primary};
    transform: rotate(45deg);
    background: white;
    top: 22px;
    right: -8px;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  ${(p: SingleStatusProps) =>
    p.active &&
    `
      background: ${Colors.primary};
      color: white;

      &:after {
        background: ${Colors.primary};
      }
    `}
`;

export default AdminEventController;
