import * as React from 'react';
import SVG from 'react-inlinesvg';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  useEndedTrainingIds,
  useJoinableTrainingIds,
} from '../../api/training';
import { useCurrentUser } from '../../redux/selectors/authSelectors';
import PlayerImg from '../../static/svg/MyPage_Player.svg';
import TrainingTable from '../trainingProvider/TrainingTable';
import CommonBG from '../uiElements/CommonBG';

interface TrainingsProps {}
const Trainings: React.FC<TrainingsProps> = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();

  const onJoin = (trainingId?: string) => {
    navigate(`/trainings/${trainingId}/information`);
  };

  const [trainingIds, loading] = useJoinableTrainingIds(user);

  return (
    <CommonBG withHeader withFooter headerOptions={{ buttonType: 'logout' }}>
      <TrainingsWrapper>
        <Content>
          <Player>
            <SVG src={PlayerImg} />
            <p>{user?.displayName ?? 'NoName'}</p>
          </Player>

          <TrainingTable
            trainingIds={trainingIds ?? []}
            type='trainingsView'
            onJoin={onJoin}
          />
          {loading && <Loading>Loading...</Loading>}
        </Content>
      </TrainingsWrapper>
    </CommonBG>
  );
};

const TrainingsWrapper = styled.div``;

const Content = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 2.4rem 8rem 0;
`;

const Player = styled.div`
  display: flex;
  align-items: center;
  height: 7rem;
  line-height: 7rem;
  font-size: 2.8rem;
  margin: 0 auto;

  p {
    font-weight: bold;
  }

  svg {
    width: 4rem;
    height: 4rem;
    margin-right: 4rem;
  }
`;

const Loading = styled.div`
  width: 100%;
  text-align: center;
  font-size: 1.5rem;
  padding: 1rem 0;
`;

export default Trainings;
