import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  StageIcon,
  StageTitle,
} from '@riddler-co-jp/specc-ui-components';
import React from 'react';
import styled from 'styled-components';

import { getResultCommnet } from '../../../lib/resultComments';
import { getRankMessage } from '../../../lib/stageResults';
import ResultTableRankImg from '../../../static/png/result/Result_Table_Rank.png';
import { colorWithAlpha } from '../../../styles/colors';
import StageNumber from '../../uiElements/StageNumber';

type Props = {
  ranks: [number, number, number, number, number];
  loaded: boolean;
  preset: '1' | '2';
  eventId: string;
};

const resultData: {
  [key: string]: {
    [key: number]: {
      url: string;
    };
  };
} = {
  '1': {
    1: {
      url: 'https://ct1-switch.specc-dev.riddler.co.jp/',
    },
    2: {
      url: 'https://ct1-program.specc-dev.riddler.co.jp/',
    },
    3: {
      url: 'https://ct1-create.specc-dev.riddler.co.jp/',
    },
    4: {
      url: 'https://ct1-energy.specc-dev.riddler.co.jp/',
    },
    5: {
      url: 'https://ct1-comm.specc-dev.riddler.co.jp/',
    },
  },
  '2': {
    1: {
      url: 'https://ct2-switch.specc-dev.riddler.co.jp/',
    },
    2: {
      url: 'https://ct2-program.specc-dev.riddler.co.jp/',
    },
    3: {
      url: 'https://ct2-create.specc-dev.riddler.co.jp/',
    },
    4: {
      url: 'https://ct2-energy.specc-dev.riddler.co.jp/',
    },
    5: {
      url: 'https://ct2-comm.specc-dev.riddler.co.jp/',
    },
  },
};

const ResultRow: React.FC<{
  stageNum: 1 | 2 | 3 | 4 | 5;
  rank: number | undefined;
  comment: string;
  preset: '1' | '2';
  eventId: string;
}> = props => {
  const resultUrl = resultData[props.preset][props.stageNum].url;
  const curriculums = ['switch', 'program', 'create', 'energy', 'comm'];

  return (
    <Row>
      <Line>
        <div></div>
        <div></div>
      </Line>
      <HeadingWrapper>
        <NumberWrapper>
          <StageNumber stageNum={props.stageNum} variant='black' />
        </NumberWrapper>
        <IconWrapper>
          <StageIcon stageNum={props.stageNum} />
        </IconWrapper>
        <TitleWrapper>
          <StageTitle stageNum={props.stageNum} />
        </TitleWrapper>
      </HeadingWrapper>
      <RankTitle src={ResultTableRankImg} />
      <RankText>{getRankMessage(props.rank ?? -1)}</RankText>
      <DetailText>
        <p>{props.comment}</p>
        {props.preset === '2' && (
          <Button
            variant='sub'
            size='medium'
            color='negative'
            onClick={() => {
              location.href =
                resultUrl +
                'events/' +
                props.eventId +
                '/result/' +
                curriculums[props.stageNum - 1];
            }}
          >
            詳細を見る
            <FontAwesomeIcon icon={faChevronRight} />
          </Button>
        )}
      </DetailText>
    </Row>
  );
};
const Line = styled.div`
  height: 100%;
  width: 16px;
  background: #353a40;
  position: absolute;

  > div {
    position: absolute;
    width: 100%;
    height: 3px;
    background: white;
    bottom: 12px;
  }

  > div:last-child {
    bottom: 5px;
  }
`;

const Row = styled.div`
  position: relative;
  width: 95rem;
  margin: 2rem 0 0;
  background-color: ${colorWithAlpha('gray4', 0.25)};
  display: flex;
  align-items: center;
`;

const HeadingWrapper = styled.div`
  margin-left: 4.5rem;
  height: 6rem;
  width: 27rem;
  display: flex;
  align-items: center;
`;
const NumberWrapper = styled.div`
  height: 6rem;
`;
const IconWrapper = styled.div`
  margin-left: 0.8rem;
  height: 3.3rem;
`;
const TitleWrapper = styled.div`
  margin-left: 0.8rem;
  height: 1.7rem;
`;

const RankTitle = styled.img`
  margin-left: 4rem;
  height: 2.8rem;
`;

const RankText = styled.div`
  color: ${colorWithAlpha('primary', 1)};
  font-size: 5rem;
  font-weight: bold;
  font-family: 'Open Sans';
  text-align: left;
  vertical-align: middle;
  height: 7rem;
  width: 6.5rem;
  margin-left: 1.2rem;
`;

const DetailText = styled.div`
  font-size: 1.6rem;
  margin-left: 3.5rem;
  padding-right: 1.5rem;
  width: 40rem;
  word-break: break-all;

  p {
    margin-top: 10px;
    margin-bottom: 10px;
  }
  button {
    margin-bottom: 10px;
    display: block;
    margin-left: auto;

    svg {
      margin-left: 10px;
    }
  }
`;

const ResultTable: React.FC<Props> = props => {
  return (
    <Table>
      {props.ranks.map((v, i) => (
        <ResultRow
          key={i}
          preset={props.preset}
          eventId={props.eventId}
          stageNum={(i + 1) as 1 | 2 | 3 | 4 | 5}
          rank={props.loaded ? v : undefined}
          comment={props.loaded ? getResultCommnet(i, v) : ''}
        />
      ))}
    </Table>
  );
};

const Table = styled.div``;

export default ResultTable;
