import firebase from 'firebase/app';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useSetErrorOverlayState,
  useSetLoadingState,
} from '../../../../redux/actions/appActions';
import CommonBG from '../../../uiElements/CommonBG';
import ConfirmEmailView from './ConfirmEmailView';

interface ConfirmEmailProps {
  code: string;
  codeInfo: firebase.auth.ActionCodeInfo;
}

const ConfirmEmail: React.FC<ConfirmEmailProps> = ({ code }) => {
  const navigate = useNavigate();

  const setErrorOverlayState = useSetErrorOverlayState();
  const setLoadingState = useSetLoadingState();

  const [visible, setVisible] = React.useState(false);
  const onClick = React.useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  React.useEffect(() => {
    setLoadingState({
      visible: true,
      text: '通信中...',
    });
    firebase
      .auth()
      .applyActionCode(code)
      .then(() => {
        setVisible(true);
        // Password reset has been confirmed and new password updated.
      })
      .catch(() => {
        setErrorOverlayState({ errorType: 'CommonError' });
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      })
      .finally(() => {
        setLoadingState({ visible: false });
      });
  }, [code, setErrorOverlayState, setLoadingState]);
  return visible ? <ConfirmEmailView onClick={onClick} /> : <CommonBG />;
};

export default ConfirmEmail;
