import { EventDocument } from '../../../api/event';
import { OrganizationDocument } from '../../../api/organization';
import { TrainingDocument } from '../../../api/training';
import { AccountParams } from '../adminUsers/AdminUserAccounts';

export const adminUserAccountsSorter: any = {
  fullName: {
    ASC: (a: AccountParams, b: AccountParams) => {
      if (a.userData.ruby === null) a.userData.ruby = '';
      if (b.userData.ruby === null) b.userData.ruby = '';

      return b.userData.ruby > a.userData.ruby ? -1 : 1;
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      if (a.userData.ruby === null) a.userData.ruby = '';
      if (b.userData.ruby === null) b.userData.ruby = '';

      return b.userData.ruby < a.userData.ruby ? -1 : 1;
    },
  },
  startYear: {
    ASC: (a: AccountParams, b: AccountParams) => {
      return b.userOrg.startYear < a.userOrg.startYear ? -1 : 1;
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      return b.userOrg.startYear > a.userOrg.startYear ? -1 : 1;
    },
  },
  employeeId: {
    ASC: (a: AccountParams, b: AccountParams) => {
      if (a.userOrg.employeeId === null) a.userOrg.employeeId = '';
      if (b.userOrg.employeeId === null) b.userOrg.employeeId = '';
      return b.userOrg.employeeId < a.userOrg.employeeId ? -1 : 1;
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      if (a.userOrg.employeeId === null) a.userOrg.employeeId = '';
      if (b.userOrg.employeeId === null) b.userOrg.employeeId = '';
      return b.userOrg.employeeId > a.userOrg.employeeId ? -1 : 1;
    },
  },
  industry: {
    ASC: (a: AccountParams, b: AccountParams) => {
      if (a.userOrg.industryId === undefined || a.userOrg.industryId === null)
        a.userOrg.industryId = '';
      if (b.userOrg.industryId === undefined || b.userOrg.industryId === null)
        b.userOrg.industryId = '';
      return b.userOrg.industryId < a.userOrg.industryId ? -1 : 1;
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      if (a.userOrg.industryId === undefined || a.userOrg.industryId === null)
        a.userOrg.industryId = '';
      if (b.userOrg.industryId === undefined || b.userOrg.industryId === null)
        b.userOrg.industryId = '';
      return b.userOrg.industryId > a.userOrg.industryId ? -1 : 1;
    },
  },
  occupation: {
    ASC: (a: AccountParams, b: AccountParams) => {
      if (
        a.userOrg.occupationId === undefined ||
        a.userOrg.occupationId === null
      )
        a.userOrg.occupationId = '';
      if (
        b.userOrg.occupationId === undefined ||
        b.userOrg.occupationId === null
      )
        b.userOrg.occupationId = '';
      return b.userOrg.occupationId < a.userOrg.occupationId ? -1 : 1;
    },
    DESC: (a: AccountParams, b: AccountParams) => {
      if (
        a.userOrg.occupationId === undefined ||
        a.userOrg.occupationId === null
      )
        a.userOrg.occupationId = '';
      if (
        b.userOrg.occupationId === undefined ||
        b.userOrg.occupationId === null
      )
        b.userOrg.occupationId = '';
      return b.userOrg.occupationId > a.userOrg.occupationId ? -1 : 1;
    },
  },
};

export const organizationSorter: any = {
  name: {
    ASC: (a: OrganizationDocument, b: OrganizationDocument) => {
      if (a.name === null) a.name = '';
      if (b.name === null) b.name = '';

      return b.name > a.name ? -1 : 1;
    },
    DESC: (a: OrganizationDocument, b: OrganizationDocument) => {
      if (a.name === null) a.name = '';
      if (b.name === null) b.name = '';

      return b.name < a.name ? -1 : 1;
    },
  },
};
