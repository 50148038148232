import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import format from 'date-fns/format';
import { ja } from 'date-fns/locale';
import * as React from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useEventData, useEventRecords, useEventStats } from '../../api/event';
import { useUserInfo } from '../../api/user';
import Colors from '../../styles/colors';
import AdminCommonBG from './AdminCommonBG';
import { UserEvents } from './adminUsers/AdminUserDetail';

interface ParamTypes {
  userId: string;
  eventId: string;
}

interface AdminUserTrainingHistoryProps {}
const AdminUserTrainingHistory: React.FC<AdminUserTrainingHistoryProps> =
  props => {
    const { userId, eventId } = useParams<'userId' | 'eventId'>();

    const [currentUserInfo] = useUserInfo(userId ?? null);

    const [events] = useEventData(eventId ?? null);
    const [eventStats] = useEventStats(eventId ?? null);
    const [eventRecords] = useEventRecords(eventId ?? null);
    const [totalStats] = useEventStats('total');

    const userEvents = React.useMemo(() => {
      if (!eventRecords) {
        return null;
      }
      const ret = {
        id: eventId,
        record: eventRecords?.docs.filter(e => e.id === userId)[0].data(),
      } as UserEvents;
      ret.stats = eventStats;
      ret.info = events;

      return ret;
    }, [eventRecords, eventId, eventStats, events, userId]);

    return (
      <AdminCommonBG backButton title='イベント履歴'>
        <AdminEventControllerWrapper>
          <Row>
            <DetailTable>
              <THead>
                <TR>
                  <TH>氏名</TH>
                  <TH>開催期間</TH>
                  <TH>イベント名</TH>
                </TR>
              </THead>

              <TBody>
                <TR>
                  <TD>
                    <Link to={`/admin/accounts/${userId}`}>
                      {currentUserInfo?.displayName ??
                        currentUserInfo?.fullName ??
                        ''}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                  </TD>
                  <TD>
                    {' '}
                    {userEvents?.info?.scheduledStartTime &&
                      format(
                        userEvents?.info?.scheduledStartTime,
                        'yyyy年M月d日(E) HH:mm',
                        { locale: ja }
                      )}
                    {' - '}
                    {userEvents?.info?.scheduledEndTime &&
                      format(
                        userEvents?.info?.scheduledEndTime,
                        'yyyy年M月d日(E) HH:mm',
                        { locale: ja }
                      )}
                  </TD>
                  <TD>
                    <Link to={`/admin/training/${eventId}/control`}>
                      {userEvents?.info?.name ?? ''}
                      <FontAwesomeIcon icon={faChevronRight} />
                    </Link>
                  </TD>
                </TR>
              </TBody>
            </DetailTable>
          </Row>
        </AdminEventControllerWrapper>
      </AdminCommonBG>
    );
  };
const AdminEventControllerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Row = styled.div`
  &:last-child {
    border-bottom: none;
  }
`;

const DetailTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 3rem;

  tbody {
    tr {
      border: none;
    }
  }

  tr {
    td:nth-child(1) {
      /*width: 40%;*/
    }
    td:nth-child(2) {
      width: 24rem;
    }
    td:nth-child(3) {
      p {
        margin-right: 1rem;
      }
      p:last-child {
        margin-right: 0;
      }
    }

    td:last-child {
      padding-right: 0;
    }
  }
`;

const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;
`;

const TD = styled.td`
  font-size: 1.4rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }

  a {
    color: ${Colors.accent};
    font-weight: bold;
    text-decoration: none;

    svg {
      margin-left: 0.6rem;
    }
  }
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;
  border-bottom: 1px solid ${Colors.gray4};
`;

interface DeleteButton {
  disabled: boolean;
}

const DeleteButton = styled.div`
  color: ${Colors.error};
  cursor: pointer;
  display: initial;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  transition: 0.2s;

  &:hover {
    background: ${Colors.gray4};
  }

  ${(p: DeleteButton) =>
    p.disabled &&
    `
    cursor: default;
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      background: initial;
    }
    `}
`;

export default AdminUserTrainingHistory;
