import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import {
  EventDocument,
  getEventData,
  useEventMembersData,
} from '../../api/event';
import {
  TrainingDocument,
  getTrainingData,
  useTrainingMembersData,
} from '../../api/training';
import LogoCt from '../../static/svg/Logo-Ct-mini.svg';
import LogoSt from '../../static/svg/Logo-St-mini.svg';
import Colors from '../../styles/colors';
import { EventStatus, TrainingStatus } from '../../types';
import Button from '../uiElements/deprecated/Button';

const eventTrainingTableTypes = [
  'eventsTrainingsView',
  'eventsTrainingsResultView',
] as const;
type EventTrainingTableType = typeof eventTrainingTableTypes[number];
const tableTopText: Record<EventTrainingTableType, string> = {
  eventsTrainingsView: '参加予定の研修一覧',
  eventsTrainingsResultView: '過去に参加した研修一覧',
};

type EventTrainingTableProps =
  | ({
      eventIds: string[];
      trainingIds: string[];
    } & {
      type: 'eventsTrainingsView';
      onEventJoin: (eventId: string) => void;
      onTrainingJoin: (eventId: string) => void;
      onAdminEdit?: undefined;
      onAdminControl?: undefined;
      onAdminCreate?: undefined;
      showResult?: undefined;
      activeEventId?: undefined;
    })
  | ({
      eventIds: string[];
      trainingIds: string[];
    } & {
      type: 'eventsTrainingsResultView';
      onEventJoin?: undefined;
      onTrainingJoin?: undefined;
      onAdminEdit?: undefined;
      onAdminControl?: undefined;
      onAdminCreate?: undefined;
      activeEventId?: undefined;
      showResult?: (eventId: string) => void;
    });

const EventTrainingTable: React.FC<EventTrainingTableProps> = props => {
  const { eventIds, trainingIds } = props;
  const [eventDatas, setEventDatas] = React.useState<EventDocument[]>([]);
  const [trainingDatas, setTrainingDatas] = React.useState<TrainingDocument[]>(
    []
  );
  const eventTrainingDatasSorted = React.useMemo(
    () =>
      (eventDatas as (EventDocument | TrainingDocument)[])
        .concat(trainingDatas)
        .sort(
          (a, b) =>
            b.scheduledStartTime.getTime() - a.scheduledStartTime.getTime()
        ),
    [eventDatas, trainingDatas]
  );

  React.useEffect(() => {
    setEventDatas([]);
    eventIds.forEach(eid => {
      getEventData(eid).then(data => {
        if (data === null) return;
        setEventDatas(prev => {
          if (prev.some(e => e.eventId === data.eventId)) return prev;
          return [...prev, data];
        });
      });
    });
  }, [eventIds]);

  React.useEffect(() => {
    setTrainingDatas([]);
    trainingIds.forEach(trainingId => {
      getTrainingData(trainingId).then(data => {
        if (data === null) return;
        setTrainingDatas(prev => {
          if (prev.some(e => e.trainingId === data.trainingId)) return prev;
          return [...prev, data];
        });
      });
    });
  }, [trainingIds]);

  return (
    <EventTrainingTableWrapper>
      <TopInfo>
        <TopText>{tableTopText[props.type]}</TopText>
      </TopInfo>
      <Table>
        <THead>
          <TR>
            <TH>開催期間</TH>
            <TH>内容</TH>
            <TH>イベント名</TH>
            <TH>会場</TH>
            <TH />
          </TR>
        </THead>

        <TBody>
          {eventTrainingDatasSorted.map(data =>
            'eventId' in data ? (
              <EventRow key={data.eventId} eventData={data} {...props} />
            ) : (
              <TrainingRow
                key={data.trainingId}
                trainingData={data}
                {...props}
              />
            )
          )}
        </TBody>
      </Table>
    </EventTrainingTableWrapper>
  );
};

type EventRowProps = EventTrainingTableProps & {
  eventData: EventDocument;
};
const EventRow: React.FC<EventRowProps> = props => {
  const ev = props.eventData;
  const eventId = ev.eventId;
  const [initEventMembers] = useEventMembersData(eventId);
  const participants = initEventMembers?.participants ?? null;
  const participantsCount =
    participants !== null ? String(participants.length) : '--';

  if (ev.status === EventStatus.Removed) {
    return null;
  }

  if (props.type === 'eventsTrainingsResultView' && ev.status !== 'ENDED') {
    return null;
  }

  return (
    <TR>
      <TD>
        <p>
          {ev.scheduledStartTime &&
            format(ev.scheduledStartTime, 'yyyy年M月d日(E) HH:mm', {
              locale: ja,
            })}
        </p>
        <p>
          {' - '}
          {ev.scheduledEndTime &&
            format(ev.scheduledEndTime, 'yyyy年M月d日(E) HH:mm', {
              locale: ja,
            })}
        </p>
      </TD>
      <TD>
        <SVG src={LogoCt} />
      </TD>
      <TD>
        <p>{ev.name}</p>
      </TD>
      <TD>
        <p>{ev.place}</p>
      </TD>
      {props.type === 'eventsTrainingsView' && (
        <TD>
          <RowButton
            variant='dark'
            disabled={ev.status !== EventStatus.Ongoing}
            onClick={() => props.onEventJoin(ev.eventId)}
          >
            テストをはじめる
          </RowButton>
        </TD>
      )}
      {props.type === 'eventsTrainingsResultView' && (
        <TD>
          <RowButton
            variant='dark'
            onClick={() => props.showResult && props.showResult(eventId)}
          >
            結果を表示する
          </RowButton>
        </TD>
      )}
    </TR>
  );
};

type TrainingRowProps = EventTrainingTableProps & {
  trainingData: TrainingDocument;
};
const TrainingRow: React.FC<TrainingRowProps> = props => {
  const tr = props.trainingData;
  const trainingId = tr.trainingId;
  const [initTrainingMembers] = useTrainingMembersData(trainingId);
  const participants = initTrainingMembers?.participants ?? null;
  const participantsCount =
    participants !== null ? String(participants.length) : '--';

  if (props.type === 'eventsTrainingsResultView' && tr.status !== 'ENDED') {
    return null;
  }

  return (
    <TR>
      <TD>
        <p>
          {tr.scheduledStartTime &&
            format(tr.scheduledStartTime, 'yyyy年M月d日(E) HH:mm', {
              locale: ja,
            })}
        </p>
        <p>
          {' - '}
          {tr.scheduledEndTime &&
            format(tr.scheduledEndTime, 'yyyy年M月d日(E) HH:mm', {
              locale: ja,
            })}
        </p>
      </TD>
      <TD>
        <SVG src={LogoSt} />
      </TD>
      <TD>
        <p>{tr.name}</p>
      </TD>
      <TD>
        <p>{tr.place}</p>
      </TD>
      {props.type === 'eventsTrainingsView' && (
        <TD>
          <RowButton
            variant='dark'
            disabled={tr.status !== TrainingStatus.Ongoing}
            onClick={() => props.onTrainingJoin(tr.trainingId)}
          >
            実習をはじめる
          </RowButton>
        </TD>
      )}
      {props.type === 'eventsTrainingsResultView' && <TD />}
    </TR>
  );
};

const EventTrainingTableWrapper = styled.div`
  overflow: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2.4rem;
`;

const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;
`;

const TD = styled.td`
  font-size: 1.4rem;
  border-bottom: 1px solid ${Colors.gray8};
  padding: 0.8rem 1.4rem;
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  border-bottom: 1px solid ${Colors.gray8};
  padding: 1.5rem 0 1.5rem 1.4rem;
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;

  td:nth-child(1) {
    width: 12rem;
  }
  td:nth-child(2) {
    width: 10rem;
  }

  td:last-child {
    width: 15.5rem;
    padding-right: 0;
  }
`;

const RowButton = styled(Button).attrs({
  scale: 0.5,
})`
  border-radius: 5px;
  padding: 0.6rem 1.6rem;
  height: initial;
  width: 100%;
`;

const TopInfo = styled.div`
  width: 100%;
  margin-top: 1.8rem;
  border-bottom: 2px solid ${Colors.gray8};
  padding: 1.2rem 0;
  position: relative;
`;
const TopText = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  display: inline-block;
`;

export default EventTrainingTable;
