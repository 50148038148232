import format from 'date-fns/format';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import {
  Headers,
  Data as ReactCsvData,
} from 'react-csv/components/CommonPropTypes';
import { Data } from 'react-firebase-hooks/firestore/dist/firestore/types';

import { UserInfo, UserOrgInfo } from '../../../../api/firestoreTypes';
import { DataArray, getRankMessage } from '../../../../lib/stageResults';
import { AdminRole } from '../../AdminAdministratorDetail';
import { UserEvents } from '../AdminUserDetail';

export type UserCsvParam = {
  currentUserInfo: Data<UserInfo, '', ''> | undefined;
  currentOrgInfo: Data<UserOrgInfo, '', ''> | undefined;
  role: AdminRole;
  userAvgRank: DataArray | undefined;
  userEventHistory: UserEvents[];
  userId: string | undefined;
};
export interface CsvData {
  data: ReactCsvData;
  headers: Headers;
  filename: string;
  onCsvDownload: () => void;
}

export const useUserCsv = (params: UserCsvParam) => {
  const {
    currentUserInfo,
    currentOrgInfo,
    role,
    userAvgRank,
    userEventHistory,
    userId,
  } = params;

  const [csvClicked, setCsvClicked] = useState<boolean>(false);
  const onCsvDownload = useCallback(() => {
    setCsvClicked(false);
  }, []);

  const onCsvDownloadButton = useCallback(() => {
    setCsvClicked(true);
  }, []);
  const csv = useMemo(() => {
    if (!['riddlerAdmin', 'dentsuAdmin'].includes(role)) {
      return undefined;
    }
    if (!currentUserInfo || !currentOrgInfo) {
      return undefined;
    }
    if (!userAvgRank) {
      return undefined;
    }
    if (!userId) {
      return undefined;
    }
    const data = userEventHistory.map((e: UserEvents) => {
      const eventDate =
        `${format(e.info!.scheduledStartTime, 'yyyy年M月d日')} ` +
        `${format(e.info!.scheduledStartTime, 'HH:mm')} - ` +
        `${format(e.info!.scheduledEndTime, 'HH:mm')}`;
      return {
        fullName: currentUserInfo.fullName ?? '',
        email: currentUserInfo.email ?? '',
        employeeId: currentOrgInfo.employeeId,
        name: e.info?.name ?? '',
        eventDate,
        rank1: getRankMessage(e.ranks?.[0] ?? -1),
        rank2: getRankMessage(e.ranks?.[1] ?? -1),
        rank3: getRankMessage(e.ranks?.[2] ?? -1),
        rank4: getRankMessage(e.ranks?.[3] ?? -1),
        rank5: getRankMessage(e.ranks?.[4] ?? -1),
        deviation1: e.deviations?.[0] === 0 ? '-' : e.deviations?.[0],
        deviation2: e.deviations?.[1] === 0 ? '-' : e.deviations?.[1],
        deviation3: e.deviations?.[2] === 0 ? '-' : e.deviations?.[2],
        deviation4: e.deviations?.[3] === 0 ? '-' : e.deviations?.[3],
        deviation5: e.deviations?.[4] === 0 ? '-' : e.deviations?.[4],
      };
    });

    data.unshift({
      fullName: currentUserInfo.fullName ?? '',
      email: currentUserInfo.email ?? '',
      employeeId: currentOrgInfo.employeeId ?? '',
      name: 'イベント平均',
      eventDate: '',
      rank1: getRankMessage(userAvgRank[0] ?? -1),
      rank2: getRankMessage(userAvgRank[1] ?? -1),
      rank3: getRankMessage(userAvgRank[2] ?? -1),
      rank4: getRankMessage(userAvgRank[3] ?? -1),
      rank5: getRankMessage(userAvgRank[4] ?? -1),
      deviation1: '',
      deviation2: '',
      deviation3: '',
      deviation4: '',
      deviation5: '',
    });

    return {
      data: data,
      headers: [
        { label: '氏名', key: 'fullName' },
        { label: 'メールアドレス', key: 'email' },
        { label: '社員ID', key: 'employeeId' },
        { label: 'イベント名', key: 'name' },
        { label: '期間', key: 'eventDate' },
        { label: 'ステージ1ランク', key: 'rank1' },
        { label: 'ステージ2ランク', key: 'rank2' },
        { label: 'ステージ3ランク', key: 'rank3' },
        { label: 'ステージ4ランク', key: 'rank4' },
        { label: 'ステージ5ランク', key: 'rank5' },
        { label: 'ステージ1偏差値', key: 'deviation1' },
        { label: 'ステージ2偏差値', key: 'deviation2' },
        { label: 'ステージ3偏差値', key: 'deviation3' },
        { label: 'ステージ4偏差値', key: 'deviation4' },
        { label: 'ステージ5偏差値', key: 'deviation5' },
      ],
      filename: `user_history_${userId}.csv`,
      onCsvDownload,
    } as CsvData;
  }, [
    currentOrgInfo,
    currentUserInfo,
    onCsvDownload,
    role,
    userAvgRank,
    userEventHistory,
    userId,
  ]);

  const fetchDoneRef =
    useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  useEffect(() => {
    if (csv && csvClicked) {
      window.setTimeout(() => {
        fetchDoneRef.current?.link.click();
      }, 30);
    }
  }, [csv, csvClicked]);

  return { csv, csvClicked, fetchDoneRef, onCsvDownloadButton };
};
