import firebase from 'firebase/app';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { useUpdateUserInfoMutation } from '../../../../api/__generated__/user.generated';
import {
  useSetErrorOverlayState,
  useSetLoadingState,
  useSetNoticeOverlayState,
} from '../../../../redux/actions/appActions';
import RecoverEmailView from './RecoverEmailView';

interface RecoverEmailProps {
  code: string;
  codeInfo: firebase.auth.ActionCodeInfo;
}

const RecoverEmail: React.FC<RecoverEmailProps> = ({ code, codeInfo }) => {
  const navigate = useNavigate();

  const setNoticeOverlayState = useSetNoticeOverlayState();
  const setErrorOverlayState = useSetErrorOverlayState();
  const setLoadingState = useSetLoadingState();
  const [updateUserInfo] = useUpdateUserInfoMutation();

  const previousEmail = codeInfo.data.email ?? '';
  const onClick = React.useCallback(() => {
    setLoadingState({
      visible: true,
      text: '通信中...',
    });
    updateUserInfo({
      variables: {
        input: {
          email: previousEmail,
        },
      },
    });
    firebase
      .auth()
      .applyActionCode(code)
      .then(() => {
        setNoticeOverlayState({
          errorType: 'CommonError',
          message:
            'メールアドレスの変更取り消しが完了しました。トップページから再度ログインしてください。',
          onBack: () => navigate('/'),
          backButtonText: 'トップページへ',
        });
        // Password reset has been confirmed and new password updated.
      })
      .catch(() => {
        setErrorOverlayState({ errorType: 'CommonError' });
        // Error occurred during confirmation. The code might have expired or the
        // password is too weak.
      })
      .finally(() => {
        setLoadingState({ visible: false });
      });
  }, [
    code,
    navigate,
    previousEmail,
    setErrorOverlayState,
    setLoadingState,
    setNoticeOverlayState,
    updateUserInfo,
  ]);
  return <RecoverEmailView onClick={onClick} previousEmail={previousEmail} />;
};
export default RecoverEmail;
