import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubButton as _Button } from '@riddler-co-jp/specc-ui-components';
import { DatePicker } from '@riddler-co-jp/specc-ui-components';
import {
  Select,
  SelectOptions,
  SelectOverrideStyles,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import Colors from '../../styles/colors';
import AdminCommonBG from './AdminCommonBG';

interface ParamTypes {}
type OptionType = {
  value: string;
  label: string;
};

interface AdminAnalyzeProps {}
const AdminAnalyze: React.FC<AdminAnalyzeProps> = props => {
  // dummy data
  const join = ['指定しない', '2020', '2021'];
  const options_join: SelectOptions<OptionType> = join.map(item => ({
    value: item,
    label: item,
  }));
  const ind = ['指定しない', 'IT・通信・インターネット'];
  const options_ind: SelectOptions<OptionType> = ind.map(item => ({
    value: item,
    label: item,
  }));
  const occ = ['指定しない', 'クリエイティブ - 編集・制作'];
  const options_occ: SelectOptions<OptionType> = occ.map(item => ({
    value: item,
    label: item,
  }));
  const org = ['全ての企業', 'カスタマー企業A社'];
  const options_org: SelectOptions<OptionType> = org.map(item => ({
    value: item,
    label: item,
  }));
  const preset = ['全てのプリセット', 'テストプリセット１'];
  const options_preset: SelectOptions<OptionType> = preset.map(item => ({
    value: item,
    label: item,
  }));

  const startDate = new Date();
  const endDate = new Date();

  const selectStyles: SelectOverrideStyles = {
    container: {
      width: '24rem',
      fontSize: '1.3rem',
    },
    option: {
      fontSize: '1.3rem',
    },
    singleValue: {
      fontSize: '1.3rem',
    },
  };

  return (
    <AdminCommonBG title='分析'>
      <AdminEventControllerWrapper>
        <ContentTitle>分析データのダウンロード</ContentTitle>
        <RowWrapper>
          <Item flex={'50%'}>
            <ItemTitle>氏名</ItemTitle>
            <ItemBody>
              <LongInput
                value={''}
                placeholder={'氏名'}
                onChange={() => console.log('氏名')}
              />
            </ItemBody>
          </Item>
          <Item width={'120px'} marginRight={'2rem'}>
            <ItemTitle>年齢</ItemTitle>
            <ItemBody>
              <LongInput
                value={''}
                placeholder={'年齢'}
                onChange={() => console.log('年齢')}
              />
            </ItemBody>
          </Item>
          <Item width={'160px'}>
            <ItemTitle>入社年</ItemTitle>
            <ItemBody>
              <SelectWrapper>
                <Select
                  options={options_join}
                  overrideStyles={selectStyles}
                  onChange={() => console.log('change')}
                />
              </SelectWrapper>
            </ItemBody>
          </Item>
        </RowWrapper>
        <RowWrapper>
          <Item>
            <ItemTitle>所属企業</ItemTitle>
            <ItemBody>
              <SelectWrapper>
                <Select
                  options={options_org}
                  overrideStyles={selectStyles}
                  onChange={() => console.log('change')}
                />
              </SelectWrapper>
            </ItemBody>
          </Item>
          <Item width={'100%'}>
            <ItemTitle>部署</ItemTitle>
            <ItemBody>
              <LongInput
                value={''}
                placeholder={'部署'}
                onChange={() => console.log('busho')}
              />
            </ItemBody>
          </Item>
        </RowWrapper>
        <RowWrapper>
          <Item width={'100%'}>
            <ItemTitle>業種</ItemTitle>
            <ItemBody>
              <SelectWrapper>
                <Select
                  options={options_ind}
                  overrideStyles={selectStyles}
                  onChange={() => console.log('change')}
                />
              </SelectWrapper>
            </ItemBody>
          </Item>
          <Item width={'100%'}>
            <ItemTitle>職種</ItemTitle>
            <ItemBody>
              <SelectWrapper>
                <Select
                  options={options_occ}
                  overrideStyles={selectStyles}
                  onChange={() => console.log('change')}
                />
              </SelectWrapper>
            </ItemBody>
          </Item>
        </RowWrapper>
        <RowWrapper>
          <Item flex={'50%'}>
            <ItemTitle>プリセット</ItemTitle>
            <ItemBody>
              <SelectWrapper>
                <Select
                  options={options_preset}
                  overrideStyles={selectStyles}
                  onChange={() => console.log('change')}
                />
              </SelectWrapper>
            </ItemBody>
          </Item>
        </RowWrapper>
        <RowWrapper>
          <Item width={'100%'}>
            <ItemTitle>受験日</ItemTitle>
            <ItemBody>
              <DateWrapper>
                <DatePicker
                  selected={startDate}
                  onChange={() => console.log('change')}
                />
                <p>{'〜'}</p>
                <DatePicker
                  selected={endDate}
                  onChange={() => console.log('change')}
                />
              </DateWrapper>
            </ItemBody>
          </Item>
        </RowWrapper>
        <Button color='positive' size='medium'>
          適用する
        </Button>
        <Result>
          <p>
            <b>0件のデータが見つかりました。</b>
          </p>
          <p>アカウントのデータをCSVファイルとしてダウンロードできます。</p>
          <Button disabled color='positive' size='medium'>
            <FontAwesomeIcon icon={faFileExport} />
            CSVのダウンロード
          </Button>
        </Result>
      </AdminEventControllerWrapper>
    </AdminCommonBG>
  );
};

const Result = styled.div`
  background: ${Colors.gray2};
  border-radius: 5px;
  padding: 1rem 2rem;
  margin-top: 2rem;

  p {
    font-size: 1.6rem;
  }

  button {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const SelectWrapper = styled.div`
  > div {
    width: 100%;
  }
`;

const LongInput = styled.input`
  width: calc(100% - 1.6rem);
`;

const AdminEventControllerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  max-width: 660px;
  margin-left: auto;
  margin-right: auto;
`;

const RowWrapper = styled.div`
  display: flex;
  margin-bottom: 1.6rem;

  > div {
    flex: 1;
    max-width: calc(50% - 1rem);
  }

  > :first-child {
    margin-right: 2rem;
  }
`;

const Row = styled.div`
  &:last-child {
    border-bottom: none;
  }
`;

interface ItemStyleProps {
  width?: string;
  flex?: string;
  marginRight?: string;
}

const Item = styled.div`
  ${(p: ItemStyleProps) => (p.width != null ? `width: ${p.width};` : '')}
  ${(p: ItemStyleProps) =>
    p.marginRight != null ? `margin-right: ${p.marginRight};` : ''}

  ${(p: ItemStyleProps) =>
    p.flex != null ? `flex: ${p.flex} !important;` : ''}

  input {
    font-size: 1.3rem;
    padding: 0.8rem 0.7rem;
    border: ${Colors.gray6} 2px solid;
    border-radius: 0.4rem;

    value={''}&::
    placeholder {
      color: ${Colors.gray4};
    }
  }
`;

const ItemTitle = styled.div`
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-bottom: 0.6rem;
  font-weight: 500;
`;

const ItemBody = styled.div`
  width: 100%;
`;

const DateWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    font-size: 1.6rem;
    margin: 0 1rem;
  }
`;

const ItemText = styled.span`
  display: inline-block;
  font-size: 1.3rem;
`;

const Button = styled(_Button)`
  min-width: 0;
  padding: 0 1.5rem;
  font-weight: bold;

  svg {
    margin-right: 1rem;
  }
`;

interface DeleteButton {
  disabled: boolean;
}

const ContentTitle = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-size: 2.4rem;
  line-height: 2.5rem;
  font-weight: bold;
`;

const DeleteButton = styled.div`
  color: ${Colors.error};
  cursor: pointer;
  display: block;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  transition: 0.2s;
  right: 0;
  position: absolute;

  &:hover {
    background: ${Colors.gray4};
  }

  ${(p: DeleteButton) =>
    p.disabled &&
    `
  cursor: default;
  opacity: 0.4;
  pointer-events: none;

  &:hover {
    background: initial;
  }
  `}
`;
export default AdminAnalyze;
