import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useRemoveTrainingMutation } from '../../api/__generated__/training.generated';
import {
  TrainingWithMembersNonNull,
  useTrainingMembersData,
} from '../../api/training';
import { useRetryableMutationWithUI } from '../../lib/useRetryableMutationWithUI';
import { appActions } from '../../redux/actions/appActions';
import Icon_Communicate from '../../static/svg/admin/category_communicate.svg';
import Icon_Create from '../../static/svg/admin/category_create.svg';
import Icon_Energy from '../../static/svg/admin/category_energy.svg';
import Icon_Program from '../../static/svg/admin/category_program.svg';
import Icon_Switch from '../../static/svg/admin/category_switch.svg';
import Colors from '../../styles/colors';
import { trainingStatusShortText } from '../admin/adminTrainings/AdminTrainingController';
import AdminPopup from '../admin/UIelements/AdminPopup';
import MenuPopup from '../admin/UIelements/MenuPopup';
import SortTH from '../admin/UIelements/sorter/SortTH';

type TrainingTableProps = {
  trainingDatas: TrainingWithMembersNonNull[];
} & {
  onJoin?: undefined;
  onAdminEdit: (eventId: string) => void;
  onAdminControl: (eventId: string) => void;
  onAdminCreate: () => void;
  onAdminDelete: () => void;
  showResult?: undefined;
  activeEventId?: undefined;
  sortObj: any;
  onChangeSort: (sortObj: any) => void;
};

const AdminTrainingTable: React.FC<TrainingTableProps> = props => {
  const listHeader = [
    { label: '開催期間', key: 'scheduledStartTime' },
    { label: 'イベント名', key: 'name' },
    { label: 'ステータス', key: 'status' },
    { label: '会場', key: 'place' },
    { label: 'カリキュラム', key: 'subject' },
    { label: '参加者', key: 'participantsCount' },
  ];

  return (
    <TrainingTableWrapper>
      <Table>
        <THead>
          <TR>
            {listHeader.map(single => {
              return (
                <SortTH
                  key={single.key}
                  sortKey={single.key}
                  currentSortObj={props.sortObj}
                  onClick={props.onChangeSort}
                >
                  {single.label}
                </SortTH>
              );
            })}
            <TH></TH>
          </TR>
        </THead>

        <TBody>
          {props.trainingDatas.map(data => (
            <TrainingRow key={data.trainingId} trainingData={data} {...props} />
          ))}
        </TBody>
      </Table>
    </TrainingTableWrapper>
  );
};

type TrainingRowProps = TrainingTableProps & {
  trainingData: TrainingWithMembersNonNull;
};
const TrainingRow: React.FC<TrainingRowProps> = props => {
  const tr = props.trainingData.training;
  const members = props.trainingData.members;
  const trainingId = tr.trainingId;
  const [initTrainingMembers] = useTrainingMembersData(trainingId);
  const participants = initTrainingMembers?.participants ?? null;
  const participantsCount =
    participants !== null ? String(participants.length) : '--';

  const onDeleted = React.useCallback(() => {
    props.onAdminDelete();
    return;
  }, [props]);

  const [removeTraining] = useRetryableMutationWithUI(
    useRemoveTrainingMutation,
    {
      hookOptions: {
        variables: {
          input: {
            trainingId: trainingId,
          },
        },
        onCompleted: onDeleted,
      },
    }
  );

  /*  if (ev.status !== 'ENDED') {
    return null;
  }

  if (ev.status === EventStatus.Removed) {
    return null;
  }*/
  const dispatch = useDispatch();

  const editableTrainingContents = [
    {
      title: '詳細',
      onClick: () => {
        props.onAdminControl(tr.trainingId);
      },
    },
    {
      title: '編集',
      onClick: () => {
        // Task Edit Event *
        props.onAdminEdit(tr.trainingId);
      },
    },
    {
      title: '削除する',
      onClick: () => {
        dispatch(
          appActions.setErrorOverlayState({
            errorType: 'CommonError',
            message: 'トレーニング「' + tr.name + '」を削除しますか。',
            backButtonText: 'いいえ',
            retryButtonText: 'はい',
            onRetry: () => {
              removeTraining();
            },
          })
        );
      },
      red: true,
    },
  ];

  return (
    <TR onClick={() => props.onAdminControl(tr.trainingId)}>
      {/* props.onAdminControl(ev.eventId) */}
      <TD>
        {
          <>
            <p>
              {format(tr.scheduledStartTime, 'yyyy年M月d日(E) HH:mm', {
                locale: ja,
              })}
            </p>
            <p>
              {' - '}
              {format(tr.scheduledEndTime, 'yyyy年M月d日(E) HH:mm', {
                locale: ja,
              })}
            </p>
          </>
        }
      </TD>
      <TD>
        <p>{tr.name}</p>
      </TD>
      <TD>
        <p>{trainingStatusShortText(tr)}</p>
      </TD>
      <TD>
        <p>{tr.place}</p>
      </TD>
      <TD>
        {/* WIP */}
        <Rank selected={tr.curriculums.includes('switch')}>
          <SVG src={Icon_Switch} />
        </Rank>
        <Rank selected={tr.curriculums.includes('program')}>
          <SVG src={Icon_Program} />
        </Rank>
        <Rank selected={tr.curriculums.includes('create')}>
          <SVG src={Icon_Create} />
        </Rank>
        <Rank selected={tr.curriculums.includes('energy')}>
          <SVG src={Icon_Energy} />
        </Rank>
        <Rank selected={tr.curriculums.includes('communicate')}>
          <SVG src={Icon_Communicate} />
        </Rank>
      </TD>
      <TD>
        <p>{participantsCount}人</p>
      </TD>
      <TD>
        <AdminPopup
          width={100}
          key='popup'
          content={<MenuPopup contents={editableTrainingContents} />}
        >
          <InfoButton>
            <FontAwesomeIcon icon={faEllipsisV} />
          </InfoButton>
        </AdminPopup>
      </TD>
    </TR>
  );
};

const InfoButton = styled.div`
  display: none;
  border-radius: 99px;
  width: 36px;
  height: 36px;
  transition: 0.2s;
  color: ${Colors.gray6};

  svg {
    margin: 9px;
    width: 18px !important;
    height: 18px;
  }

  &:hover {
    background: white;
  }
`;

const TrainingTableWrapper = styled.div``;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 0px;
`;

const THead = styled.thead`
  width: 100%;
  z-index: 1;
  position: relative;
`;

const TBody = styled.tbody`
  width: 100%;

  tr {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: ${Colors.gray2};

      & ${InfoButton} {
        display: block;
      }
    }
  }
`;

const TD = styled.td`
  font-size: 1.4rem;
  border-bottom: 1px solid ${Colors.gray4};
  padding: 0.8rem 1.4rem;
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  border-bottom: 1px solid ${Colors.gray4};
  padding: 1.5rem 0 1.5rem 1.4rem;
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;

  td:nth-child(1) {
    width: 18rem;
  }
  td:nth-child(2) {
    width: 15rem;
    p {
      white-space: initial;
    }
  }
  td:nth-child(3) {
    width: 10rem;
  }
  td:nth-child(4) {
    width: 10rem;
  }
  td:nth-child(5) {
    width: 26rem;
  }

  td:nth-child(6) {
    width: 6rem;
    padding-right: 0;
  }

  td:last-child {
    width: 38px;
  }
`;

interface RankProps {
  selected: boolean;
}

const Rank = styled.div`
  width: 50px;
  line-height: 3.2rem;
  text-align: center;
  font-size: 2.8rem;
  font-weight: bold;
  color: ${Colors.accent};
  border-right: 1px solid ${Colors.gray4};
  height: 36px;
  float: left;

  svg {
    opacity: ${(p: RankProps) => (p.selected ? '1.0' : '0.3')};

    .cls-1 {
      fill: ${Colors.accent} !important;
    }
  }

  &:last-child {
    border: none;
  }
`;

export default AdminTrainingTable;
