import * as React from 'react';
import { useMatch, useNavigate } from 'react-router-dom';

import { useCurrentUser } from '../../redux/selectors/authSelectors';
import ResultDetail from '../eventProvider/eventResultPage/ResultDetail';
import CommonBG from '../uiElements/CommonBG';
import ProfileBar from '../uiElements/ProfileBar';

interface HistoryResultProps {
  prevEventId: string | null;
  nextEventId: string | null;
}
const HistoryResult: React.FC<HistoryResultProps> = props => {
  const navigate = useNavigate();
  const user = useCurrentUser();
  const match = useMatch('/history/:eventId');
  const eventId = match?.params.eventId ?? null;
  const prevHref = props.prevEventId
    ? '/history/' + props.prevEventId
    : undefined;
  const nextHref = props.nextEventId
    ? '/history/' + props.nextEventId
    : undefined;
  React.useEffect(() => {
    if (eventId === null) {
      navigate('/history');
    }
  }, [eventId, navigate]);
  return (
    <CommonBG withHeader withFooter headerOptions={{ buttonType: 'logout' }}>
      <ProfileBar user={user} />
      <ResultDetail
        uid={user?.uid}
        eventId={eventId}
        listHref='/history'
        prefHref={prevHref}
        nextHref={nextHref}
      />
    </CommonBG>
  );
};

export default HistoryResult;
