import {
  TextWindow,
  MainButton as _Button,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useEventData } from '../../api/event';
import { useEventId } from '../../redux/selectors/gameSelectors';
import Colors from '../../styles/colors';
import CommonBG from '../uiElements/CommonBG';
import { HeaderOptions } from '../uiElements/Header';
import Preset1Information from './EventPresets/Preset1Information';
import Preset2Information from './EventPresets/Preset2Information';

const headerOptions: HeaderOptions = {
  buttonType: 'logout',
  showEmail: false,
  logoType: 'CT',
};

interface InformationProps {}
const Information: React.FC<InformationProps> = () => {
  const navigate = useNavigate();
  const eventId = useEventId();
  const [event] = useEventData(eventId);

  const goTo = (url: string) => {
    navigate(url);
  };

  return (
    <CommonBG withHeader withFooter headerOptions={headerOptions}>
      <InformationWrapper>
        {event?.preset === '1' ? (
          <Preset1Information />
        ) : event?.preset === '2' ? (
          <Preset2Information />
        ) : (
          <></>
        )}
      </InformationWrapper>
    </CommonBG>
  );
};

const InformationWrapper = styled.div``;

const ContentWrapper = styled.div`
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 4rem;
  font-size: 1.6rem;

  h3 {
    font-size: 2.4rem;
    margin: 16px 0;
  }

  iframe {
    border: solid 2px #6c7680;
  }
`;

const Time = styled.div`
  text-align: right;
  width: 100%;
  margin-bottom: 10px;
`;

const StageCaption = styled(TextWindow)`
  margin: 5rem 0;
  margin-bottom: 2.8rem;
  display: inline-block;
  width: 58rem;
  padding: 0 !important;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    border: 3px solid ${Colors.gray8};
    width: 10px;
    height: calc(100% - 6px);
    position: absolute;
    top: 0;
  }
  &:before {
    border-right: none;
    left: 0;
  }
  &:after {
    border-left: none;
    right: 0;
  }
`;

const Buttons = styled.div`
  margin: 6rem auto 0;
  padding: 0 8rem;
  display: flex;
  justify-contents: center;

  button {
    margin-right: 40px;
  }
  button:last-child {
    margin-right: 0;
  }
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  width: 100%;
  margin-bottom: 4rem;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MainText = styled.p`
  margin: 20px 0;
`;

const Stages = styled.ul`
  list-style: none;
  border-top: 1px solid black;

  li {
    border-bottom: 1px solid black;
    padding: 16px 20px;

    display: flex;

    > div:first-child {
      width: 150px;
    }
    > div:last-child {
      flex: 1;

      > p:first-child {
        margin-bottom: 10px;
      }
      > p:last-child {
        b {
          text-decoration: underline;
        }
      }
    }
  }
`;

const Notes = styled.ul`
  list-style: none;
  background: rgba(206, 212, 218, 0.25);
  padding: 12px 20px;

  font-size: 1.3rem;

  li {
    display: flex;
    margin-bottom: 8px;

    > div:first-child {
      width: 160px;
    }
  }
  li:last-child {
    margin-bottom: 0;
  }
`;

const VideoWrapper = styled.div`
  margin: 1rem auto;
  margin-bottom: 0;
  border: solid 2px #6c7680;
  margin-left: -100px;
  margin-right: -100px;

  video {
    width: 100%;
    height: 100%;
    margin-bottom: -5px;
  }
`;

export default Information;
