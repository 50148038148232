import { MainButton } from '@riddler-co-jp/specc-ui-components';
import { TextWindow } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import CommonBG from '../../../uiElements/CommonBG';
import { HeaderOptions } from '../../../uiElements/Header';

const headerOptions: HeaderOptions = {
  hideHomeButton: true,
  hideSettingButton: true,
};
interface RecoverEmailViewProps {
  previousEmail: string;
  onClick: () => void;
}

const RecoverEmailView: React.FC<RecoverEmailViewProps> = ({
  previousEmail,
  onClick,
}) => {
  return (
    <CommonBG withHeader headerOptions={headerOptions} withFooter>
      <Wrapper>
        <Text>
          {'メールアドレスの変更を取り消しますか？\n'}
          <SubText>
            {'変更を取り消すと登録メールアドレスは以下に再設定されます。\n' +
              previousEmail +
              '\n'}
          </SubText>
        </Text>
        <TopButton size='large' color='positive' onClick={onClick}>
          取り消す
        </TopButton>
      </Wrapper>
    </CommonBG>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-top: 16rem;
`;

const SubText = styled.div`
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 4.5rem;
  margin-top: 3rem;
`;

const Text = styled(TextWindow)`
  line-height: 4rem;
  margin: 0 auto;
  padding: 6rem;
  margin-bottom: 4rem;
`;

const TopButton = styled(MainButton)`
  margin: 0 auto;
  margin-bottom: 10rem;
  display: block;
`;

export default RecoverEmailView;
