import { SubButton as _Button } from '@riddler-co-jp/specc-ui-components';
import { TextWindow as _TextWindow } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { Stage5GroupDocument } from '../../../../api/stage5';
import { useStage5Flags } from '../../../../redux/selectors/gameSelectors';
import CommonBG from '../../../uiElements/CommonBG';
import GroupIdIndicator from './GroupIdIndicator';

interface GameBeforeStartProps {
  groupDocData: Stage5GroupDocument;
  onStart: () => void;
}

const GameBeforeStart: React.FC<GameBeforeStartProps> = props => {
  const { isPresenter } = useStage5Flags(props.groupDocData);
  const presenterIndex = props.groupDocData.members.findIndex(
    v => v === props.groupDocData.presenter
  );
  const setNum = presenterIndex === -1 ? null : presenterIndex + 1;
  const text =
    setNum === null
      ? 'エラーが発生しました。'
      : `第${setNum}セットを開始します。` +
        (isPresenter
          ? 'あなたは「出題者」です。'
          : 'あなたは「解答者」です。\n出題者のスタートをお待ちください。');

  return (
    <CommonBG>
      <GameBeforeStartWrapper>
        <TextWindow bracket>
          <p>{text}</p>
          {isPresenter && (
            <Button
              size='large'
              color='positive'
              onClick={() => props.onStart()}
            >
              スタート
            </Button>
          )}
        </TextWindow>
      </GameBeforeStartWrapper>
      <GroupIdIndicator searchId={props.groupDocData.searchId} />
    </CommonBG>
  );
};

const GameBeforeStartWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
`;

const Button = styled(_Button)`
  margin-top: 2rem;
`;

const TextWindow = styled(_TextWindow)`
  padding: 3.8rem 4rem;
`;

export default GameBeforeStart;
