import {
  MainButton,
  Button as _Button,
} from '@riddler-co-jp/specc-ui-components';
import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import * as React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import { EventStats, useEventData } from '../../../api/event';
import { UserRecords } from '../../../api/firestoreTypes';
import {
  DataArray,
  calcRank,
  calcRanks,
  getRankMessage,
} from '../../../lib/stageResults';
import Colors, { colorWithAlpha } from '../../../styles/colors';
import RadarChart from './RadarChart';
import ResultTable from './ResultTable';

const ResultTitleImg: React.FC = () => (
  <ResultTitleSvg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 170.016 26.112'
  >
    <path
      id='パス_1187'
      data-name='パス 1187'
      className='cls-1'
      d='M27.328.352v-5.6c0-2.24-.48-3.808-1.792-4.8a7,7,0,0,0,1.728-4.928v-1.536c0-7.2-3.3-9.248-8.832-9.248H2.24V.352H12V-6.272h3.68c1.344,0,1.92.576,1.92,1.888V.352ZM17.76-15.168c0,.8-.48,1.472-1.92,1.472H12v-3.456h3.84c1.408,0,1.92.448,1.92,1.7ZM56.448.352V-7.84H43.1c-1.792,0-2.048-.576-2.048-1.76H55.3v-6.144H41.088A1.731,1.731,0,0,1,43.1-17.5H56.448V-25.76H41.408c-8.512,0-9.92,4.608-9.92,11.232v4.96c0,5.888,2.208,9.92,9.824,9.92Zm29.216-8V-9.312c0-3.84-2.784-6.72-8.1-6.72h-7.1c-.576,0-.736-.32-.736-.7v-.128c0-.352.224-.608.736-.608H83.936V-25.76H68.8c-5.728,0-9.088,2.24-9.088,8.064v.928c0,5.024,2.56,7.68,9.024,7.68H75.1c.416,0,.64.16.64.608v.1c0,.416-.16.608-.64.608H60.224V.32H77.056C83.3.32,85.664-3.392,85.664-7.648Zm29.152-2.144V-25.76H105.28v15.136a2.119,2.119,0,0,1-2.336,2.368h-2.272c-1.376,0-2.208-.768-2.208-2.368V-25.76h-9.5V-9.792C88.96-4,91.872.352,100.256.352h3.168C111.84.352,114.816-4,114.816-9.792ZM143.648.352V-8.224H130.88a2.124,2.124,0,0,1-2.4-2.208V-25.76h-9.824V-9.312c0,6.3,3.424,9.664,10.336,9.664Zm28.608-17.6V-25.76H146.624v8.512h8.16V.352h9.76v-17.6Z'
      transform='translate(-2.24 25.76)'
    />
  </ResultTitleSvg>
);
const ResultTitleSvg = styled.svg`
  width: 17rem;
  .cls-1 {
    fill: ${colorWithAlpha('primary', 1)};
  }
`;

const stages = ['stage1', 'stage2', 'stage3', 'stage4', 'stage5'] as const;

interface ResultProps {
  eventStartDate?: Date;
  eventEndDate?: Date;
  eventName?: string;
  eventResult?: UserRecords;
  eventStats?: EventStats;
  preset: '1' | '2';
  eventId: string;
  goToNext?: () => void;
  goToPrev?: () => void;
  goToList: () => void;
  goToEventControl?: () => void;
}
const ResultView: React.FC<ResultProps> = props => {
  const { eventResult, eventStats } = props;
  const [scores, setScores] = React.useState<DataArray>([0, 0, 0, 0, 0]);
  const [deviations, setDeviations] = React.useState<DataArray>([
    0, 0, 0, 0, 0,
  ]);

  const [event] = useEventData(props.eventId);
  const [ranks, setRanks] = React.useState<DataArray>([0, 0, 0, 0, 0]);

  const text = [
    'text',
    `\
【受検結果】\n\
\n\
思考力の特性を測定する「SPECCスキル研修」を受検しました！\n\
\n\
多角的思考力：${getRankMessage(ranks[0])}\n\
論理的思考力：${getRankMessage(ranks[1])}\n\
発想力：${getRankMessage(ranks[2])}\n\
試行錯誤力：${getRankMessage(ranks[3])}\n\
表現力：${getRankMessage(ranks[4])}\n\
\n\
Produced by RIDDLER/松丸 亮吾\n\
`,
  ];
  const shareUrl = 'https://riddler.co.jp/';

  const twUrl = ['url', shareUrl];
  const twQuery = new URLSearchParams([text, twUrl]).toString();
  const TwitterUri = `https://twitter.com/intent/tweet?${twQuery}`;

  const fbUrl = ['u', shareUrl];
  const fbQuery = new URLSearchParams([fbUrl]).toString();
  const FacebookUri = `https://www.facebook.com/sharer/sharer.php?${fbQuery}`;

  React.useEffect(() => {
    if (!event) {
      return;
    }
    const _ranks = calcRanks(event.preset ?? '1', scores);
    setRanks(_ranks);
  }, [event, scores]);

  React.useEffect(() => {
    const res = eventResult;
    if (!res) {
      return;
    }
    setScores(
      stages.map(stage => res.calculatedScores[stage].score ?? -1) as DataArray
    );
    setDeviations(
      stages.map(stage => {
        const score = res.calculatedScores[stage].score;
        if (score == null) {
          return 0;
        }
        const eventAvg = eventStats?.[stage].avg ?? score;
        const eventStd = eventStats?.[stage].std ?? 1;
        if (eventStd == 0) {
          return 50;
        }
        return 50 + 10 * ((score - eventAvg) / eventStd);
      }) as DataArray
    );
  }, [eventResult, eventStats]);

  const loaded = eventResult !== undefined;
  return (
    <ResultWrapper>
      <OpenSansFont />
      <ResultHeader>
        <ResultTitle>
          <ResultTitleImg />
          <ResultTitleText>テスト結果</ResultTitleText>
        </ResultTitle>
        {props.goToEventControl && (
          <Button variant='main' onClick={props.goToEventControl}>
            テストイベント管理画面に戻る
          </Button>
        )}
      </ResultHeader>
      <ResultMain>
        <ContentCaption>
          {props.eventStartDate
            ? format(props.eventStartDate, 'YoMMMdo（E）', {
                locale: ja,
              })
            : 'No Date'}
          {' - '}
          {props.eventEndDate
            ? format(props.eventEndDate, 'YoMMMdo（E）', {
                locale: ja,
              })
            : 'No Date'}
          <br />
          {props.eventName ?? 'No Name'}
        </ContentCaption>
        <Content>
          <VideoWrapper>
            <video controls>
              <source
                src='https://storage.googleapis.com/specc-ct-movie/6-1%E8%A7%A3%E8%AA%AC_1.mp4'
                type='video/mp4'
              />
              <p>Your browser doesn't support HTML5 video.</p>
            </video>
          </VideoWrapper>
          <RadarChartWrapper>
            <RadarChart deviations={deviations} width={400} loaded={loaded} />
          </RadarChartWrapper>
          <TableWrapper>
            <ResultTable
              ranks={ranks}
              loaded={loaded}
              preset={props.preset}
              eventId={props.eventId}
            />
          </TableWrapper>
        </Content>
      </ResultMain>
      {/*
      <Share>
        <p>SNSでシェアする</p>
        <div>
          <TwitterButton
            size='large'
            color='positive'
            onClick={() => {
              window.open(TwitterUri, '_blank');
            }}
          >
            Twitter
          </TwitterButton>
          <FacebookButton
            size='large'
            color='positive'
            onClick={() => {
              window.open(FacebookUri, '_blank');
            }}
          >
            Facebook
          </FacebookButton>
        </div>
      </Share>
      */}
      <ResultFooter>
        <FooterLeft onClick={props.goToNext} disabled={!props.goToNext}>
          {'< 次のテスト結果'}
        </FooterLeft>
        <FooterCenter onClick={props.goToList}>過去の結果一覧</FooterCenter>
        <FooterRight onClick={props.goToPrev} disabled={!props.goToPrev}>
          {'前のテスト結果 >'}
        </FooterRight>
      </ResultFooter>
    </ResultWrapper>
  );
};

const ResultMain = styled.div`
  position: relative;
`;

const ContentCaption = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 1.6rem;
  line-height: 3.4rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ResultHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 2rem 0 0;
  padding-bottom: 1.3rem;
  border-bottom: 0.2rem solid ${colorWithAlpha('gray8', 1.0)};
`;

const ResultTitle = styled.div`
  display: flex;
  flex-direction: row;
`;

const ResultTitleText = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  margin-left: 2rem;
`;

const Button = styled(_Button)`
  height: 4rem;
  margin: 2.5rem;
  line-height: 3.8rem;
  font-size: 1.5rem;
  display: inline-block;
  min-width: 0;
`;

const TwitterButton = styled(MainButton)`
  background-color: #18c0f2 !important;
  box-shadow: 0px 1rem 0px 0px #1999d6;

  &:hover:hover {
    box-shadow: 0px 0.5rem 0px 0px #1999d6;
  }
`;
const FacebookButton = styled(MainButton)``;

const TableWrapper = styled.div``;
const RadarChartWrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
`;

const FooterButton = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  background: none;
  :hover {
    text-decoration: underline;
  }
  :disabled {
    text-decoration: none;
    cursor: default;
  }
`;
const FooterLeft = styled(FooterButton)`
  width: 13rem;
  margin-right: 1rem;
  text-align: right;
`;
const FooterCenter = styled(FooterButton)`
  width: 11rem;
  border-left: 0.1rem black solid;
  border-right: 0.1rem black solid;
`;
const FooterRight = styled(FooterButton)`
  width: 13rem;
  margin-left: 1rem;
  text-align: left;
`;

const ResultFooter = styled.div`
  display: flex;
  justify-content: center;
  height: 14rem;
  text-align: center;
  align-items: center;
  font-size: 1.4rem;
`;

const ResultWrapper = styled.div`
  margin: 0 4rem;
`;

const Share = styled.div`
  margin: 0 -4rem;
  margin-top: 4rem;
  background-color: ${colorWithAlpha('gray4', 0.25)};
  padding: 1rem 0 4rem 0;
  p {
    text-align: center;
    font-weight: bold;
    font-size: 2rem;
    margin: 2rem 0;
    color: ${Colors.primary};
  }
  > div {
    display: flex;
    width: 100%;
    justify-content: center;

    button {
      margin-right: 2rem;
    }
    button:last-child {
      margin-right: 0;
    }
  }
`;

const OpenSansFont = createGlobalStyle`@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
`;

const VideoWrapper = styled.div`
  width: 900px;
  height: 506px;
  margin: 4rem auto;
  margin-top: 80px;
  margin-bottom: 0;
  border: solid 2px #6c7680;

  video {
    width: 100%;
    height: 100%;
    margin-bottom: -4px;
  }
`;

export default ResultView;
