import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useRemoveAdminRoleMutation } from '../../api/__generated__/adminRole.generated';
import { useAdminAllOrganizations } from '../../api/organization';
import { UserDocument } from '../../api/user';
import { useRetryableMutationWithUI } from '../../lib/useRetryableMutationWithUI';
import { appActions } from '../../redux/actions/appActions';
import { useCurrentUser } from '../../redux/selectors/authSelectors';
import Colors from '../../styles/colors';
import { AdminRole } from '../admin/AdminAdministratorDetail';
import AdminPopup from '../admin/UIelements/AdminPopup';
import MenuPopup from '../admin/UIelements/MenuPopup';
import SortTH from '../admin/UIelements/sorter/SortTH';

type UserAccountsTableProps = {
  userDatas: UserDocument[];
  onJoin?: undefined;
  onAdminEdit: (userId: string) => void;
  onAdminControl: (userId: string) => void;
  showResult?: undefined;
  activeuserId?: undefined;
  sortObj: any;
  onChangeSort: (sortObj: any) => void;
  role: AdminRole;
};

const AdminAdministratorsTable: React.FC<UserAccountsTableProps> = props => {
  const userDatasSorted = props.userDatas;

  const listHeader = [
    { label: '氏名', key: 'fullName' },
    { label: '権限', key: 'role', disable: true },
  ];

  return (
    <UserAccountsTableWrapper>
      <Table>
        <THead>
          <TR>
            {listHeader.map(single => {
              return (
                <SortTH
                  key={single.key}
                  sortKey={single.key}
                  currentSortObj={props.sortObj}
                  onClick={props.onChangeSort}
                  disable={single.disable}
                >
                  {single.label}
                </SortTH>
              );
            })}
            <TH></TH>
          </TR>
        </THead>

        <TBody>
          {userDatasSorted.map(data => (
            <UserRow key={data.uid} userData={data} {...props} />
          ))}
        </TBody>
      </Table>
    </UserAccountsTableWrapper>
  );
};

type UserRowProps = UserAccountsTableProps & {
  userData: UserDocument;
};
const UserRow: React.FC<UserRowProps> = props => {
  const navigate = useNavigate();
  const onUserDetail = (userId?: string) => {
    navigate(`/admin/administrators/${userId}/`);
  };
  const user = props.userData;
  const roles: string[] = [];
  const [organizations] = useAdminAllOrganizations();
  const organizationNameTable: { [key: string]: string } = {};

  const myUser = useCurrentUser();
  const myId = myUser?.uid;

  if (organizations !== undefined) {
    organizations.forEach(doc => {
      organizationNameTable[doc.orgId] = doc.name;
    });
  }

  if (user.commonRoles !== undefined && user.commonRoles.length > 0) {
    if (user.commonRoles.includes('riddlerAdmin')) {
      roles.push('RIDDLER');
    } else if (user.commonRoles.includes('dentsuAdmin')) {
      roles.push('運営企業');
    } else {
      roles.push('リーダー');
    }
  } else if (user.orgAdmin !== undefined && user.orgAdmin.length > 0) {
    roles.push('リーダー');
  }

  const [removeAdminRole] = useRetryableMutationWithUI(
    useRemoveAdminRoleMutation,
    {
      hookOptions: {},
    }
  );
  const dispatch = useDispatch();

  const deletablePopupContents = [
    {
      title: '詳細',
      onClick: () => {
        onUserDetail(user.uid);
      },
    },
    {
      title: '削除する',
      onClick: () => {
        dispatch(
          appActions.setErrorOverlayState({
            errorType: 'CommonError',
            message: '本当に管理者を削除しますか。',
            backButtonText: 'いいえ',
            retryButtonText: 'はい',
            onRetry: () => {
              const role = user?.commonRoles?.includes('riddlerAdmin')
                ? 'riddlerAdmin'
                : user?.commonRoles?.includes('dentsuAdmin')
                ? 'dentsuAdmin'
                : 'organizationAdmin';
              if (role === 'riddlerAdmin' || role === 'dentsuAdmin') {
                removeAdminRole({
                  variables: {
                    input: {
                      uid: user.uid,
                      role,
                    },
                  },
                });
              }
              if (user.orgAdmin !== undefined && user.orgAdmin.length > 0) {
                removeAdminRole({
                  variables: {
                    input: {
                      uid: user.uid,
                      role: 'organizationAdmin',
                    },
                  },
                });
              }
            },
          })
        );
      },
      red: true,
    },
  ];

  const unDeletablePopupContents = [
    {
      title: '詳細',
      onClick: () => {
        onUserDetail(user.uid);
      },
    },
  ];

  return (
    <TR onClick={() => onUserDetail(user.uid)}>
      <TD>
        <Name>{user.fullName}</Name>
        <Mail>{user.email}</Mail>
      </TD>
      <TD>
        {roles.map((role, i) => (
          <p key={i}>{role}</p>
        ))}
      </TD>
      <TD>
        <AdminPopup
          width={100}
          key='popup'
          content={
            <MenuPopup
              contents={
                (!user.commonRoles?.includes('riddlerAdmin') ||
                  props.role === 'riddlerAdmin') &&
                user.uid !== myId
                  ? deletablePopupContents
                  : unDeletablePopupContents
              }
            />
          }
        >
          <InfoButton>
            <FontAwesomeIcon icon={faEllipsisV} />
          </InfoButton>
        </AdminPopup>
      </TD>
    </TR>
  );
};

const Name = styled.div`
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Mail = styled.div`
  font-size: 1.2rem;
  color: ${Colors.gray6};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const InfoButton = styled.div`
  border-radius: 99px;
  width: 36px;
  height: 36px;
  transition: 0.2s;
  color: ${Colors.gray6};
  opacity: 0;

  svg {
    margin: 9px;
    width: 18px !important;
    height: 18px;
  }

  &:hover {
    background: white;
  }
`;

const UserAccountsTableWrapper = styled.div``;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 0px;
`;

const THead = styled.thead`
  width: 100%;
  z-index: 1;
  position: relative;
`;

const TBody = styled.tbody`
  width: 100%;

  tr {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: ${Colors.gray2};

      & ${InfoButton} {
        opacity: 1;
      }
    }
  }
`;

const TD = styled.td`
  font-size: 1.4rem;
  border-bottom: 1px solid ${Colors.gray4};
  padding: 0.8rem 1.4rem;
`;
const TH = styled.th`
  position: relative;
  text-align: left;
  font-size: 1.3rem;
  border-bottom: 1px solid ${Colors.gray4};
  padding: 1.5rem 0 1.5rem 1.4rem;
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;

  td:nth-child(1) {
    width: 30%;
  }
  td:nth-child(2) {
  }
  td:nth-child(3) {
    width: 38px;
  }
`;

const CsvButton = styled.div`
  font-size: 1.3rem;
  color: ${Colors.accent};
  cursor: pointer;

  position: absolute;
  right: 1px;
  top: 14px;

  svg {
    margin-right: 0.6rem;
  }

  &:hover {
    opacity: 0.8;
  }
`;

export default AdminAdministratorsTable;
