import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import ja from 'date-fns/locale/ja';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { useChangeEventStatusMutation } from '../../../api/__generated__/event.generated';
import { EventWithMembersNonNull } from '../../../api/event';
import { presets } from '../../../lib/preset';
import { useRetryableMutationWithUI } from '../../../lib/useRetryableMutationWithUI';
import { appActions } from '../../../redux/actions/appActions';
import Colors from '../../../styles/colors';
import { EventStatus } from '../../../types';
import AdminPopup from '../UIelements/AdminPopup';
import MenuPopup from '../UIelements/MenuPopup';
import SortTH from '../UIelements/sorter/SortTH';
import { eventStatusShortText } from './AdminEventController';

type EventTableProps = {
  eventDatas: EventWithMembersNonNull[];
} & {
  onJoin?: undefined;
  onAdminEdit: (eventId: string) => void;
  onAdminCopy: () => void;
  onAdminControl: (eventId: string) => void;
  onAdminCreate: () => void;
  onAdminDelete: () => void;
  showResult?: undefined;
  activeEventId?: undefined;
  sortObj: any;
  onChangeSort: (sortObj: any) => void;
};

const AdminEventTable: React.FC<EventTableProps> = props => {
  const listHeader = [
    { label: '開催期間', key: 'scheduledStartTime' },
    { label: 'プリセット', key: 'preset' },
    { label: 'イベント名', key: 'name' },
    { label: 'ステータス', key: 'status' },
    { label: '会場', key: 'place' },
    { label: '参加者', key: 'participantsCount' },
  ];

  return (
    <EventTableWrapper>
      <Table>
        <THead>
          <TR>
            {listHeader.map(single => {
              return (
                <SortTH
                  key={single.key}
                  sortKey={single.key}
                  currentSortObj={props.sortObj}
                  onClick={props.onChangeSort}
                >
                  {single.label}
                </SortTH>
              );
            })}
            <TH></TH>
          </TR>
        </THead>

        <TBody>
          {props.eventDatas.map(data => (
            <EventRow key={data.eventId} eventData={data} {...props} />
          ))}
        </TBody>
      </Table>
    </EventTableWrapper>
  );
};

type EventRowProps = EventTableProps & {
  eventData: EventWithMembersNonNull;
};
const EventRow: React.FC<EventRowProps> = props => {
  const ev = props.eventData.event;
  const members = props.eventData.members;
  const eventId = ev.eventId;
  const participants = members.participants;
  const participantsCount = String(participants.length);

  const onDeleted = React.useCallback(() => {
    props.onAdminDelete();
    return;
  }, [props]);

  const [removeEvent] = useRetryableMutationWithUI(
    useChangeEventStatusMutation,
    {
      hookOptions: {
        variables: {
          input: {
            eventId: eventId,
            status: EventStatus.Removed,
          },
        },
        onCompleted: onDeleted,
      },
    }
  );

  const dispatch = useDispatch();

  const editableEventContents = [
    {
      title: '詳細',
      onClick: () => {
        props.onAdminControl(ev.eventId);
      },
    },
    {
      title: '編集',
      onClick: () => {
        // Task Edit Event *
        props.onAdminEdit(ev.eventId);
      },
    },
    {
      title: '削除する',
      onClick: () => {
        dispatch(
          appActions.setErrorOverlayState({
            errorType: 'CommonError',
            message: 'イベント「' + ev.name + '」を削除しますか。',
            backButtonText: 'いいえ',
            retryButtonText: 'はい',
            onRetry: () => {
              removeEvent();
            },
          })
        );
      },
      red: true,
    },
  ];
  const uneditableEventContents = [
    {
      title: '詳細',
      onClick: () => {
        props.onAdminControl(ev.eventId);
      },
    },
  ];

  return (
    <TR onClick={() => props.onAdminControl(ev.eventId)}>
      <TD>
        {
          <>
            <p>
              {format(ev.scheduledStartTime, 'yyyy年M月d日(E) HH:mm', {
                locale: ja,
              })}
            </p>
            <p>
              {' - '}
              {format(ev.scheduledEndTime, 'yyyy年M月d日(E) HH:mm', {
                locale: ja,
              })}
            </p>
          </>
        }
      </TD>
      <TD>
        <p>
          {presets.find(e => e.preset_id === (ev.preset ?? '1'))?.preset_name}
        </p>
      </TD>
      <TD>
        <p>{ev.name}</p>
      </TD>
      <TD>
        <p>{eventStatusShortText(ev)}</p>
      </TD>
      <TD>
        <p>{ev.place}</p>
      </TD>
      <TD>
        <p>{participantsCount}人</p>
      </TD>
      <TD>
        <AdminPopup
          width={100}
          key='popup'
          content={
            <MenuPopup
              contents={
                ev.status === EventStatus.Preparing
                  ? editableEventContents
                  : uneditableEventContents
              }
            />
          }
        >
          <InfoButton>
            <FontAwesomeIcon icon={faEllipsisV} />
          </InfoButton>
        </AdminPopup>
      </TD>
    </TR>
  );
};

const InfoButton = styled.div`
  display: none;
  border-radius: 99px;
  width: 36px;
  height: 36px;
  transition: 0.2s;
  color: ${Colors.gray6};

  svg {
    margin: 9px;
    width: 18px !important;
    height: 18px;
  }

  &:hover {
    background: white;
  }
`;

const EventTableWrapper = styled.div``;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 0px;
`;

const THead = styled.thead`
  width: 100%;
  z-index: 1;
  position: relative;
`;

const TBody = styled.tbody`
  width: 100%;

  tr {
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      background: ${Colors.gray2};

      & ${InfoButton} {
        display: block;
      }
    }
  }
`;

const TD = styled.td`
  font-size: 1.4rem;
  border-bottom: 1px solid ${Colors.gray4};
  padding: 0.8rem 1.4rem;
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  border-bottom: 1px solid ${Colors.gray4};
  padding: 1.5rem 0 1.5rem 1.4rem;
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;

  td:nth-child(1) {
    width: 18rem;
  }
  td:nth-child(2) {
    width: 7rem;
  }
  td:nth-child(3) {
    width: 15rem;
    p {
      white-space: initial;
    }
  }
  td:nth-child(4) {
    width: 10rem;
  }
  td:nth-child(5) {
    width: 10rem;
  }
  td:nth-child(6) {
    width: 6rem;
    padding-right: 0;
  }
  td:last-child {
    width: 38px;
  }
`;

export default AdminEventTable;
