import { FormElementType } from '@riddler-co-jp/specc-ui-components';
import {
  SelectOptions,
  SelectOverrideStyles,
} from '@riddler-co-jp/specc-ui-components';
import { OptionTypeBase, OptionsType } from 'react-select';
import { MenuPlacement } from 'react-select';

type ProfileFormDataBaseType<TType extends FormElementType, T> = {
  id: string;
  type: TType;
  label: string;
  required?: boolean;
  placeholder?: string;
  width?: string;
  overrideStyles?: SelectOverrideStyles;
  menuPlacement?: MenuPlacement;
};
export type ProfileSelectDataType<T = any> = ProfileFormDataBaseType<
  'select',
  T
> & {
  options: SelectOptions<T>;
};

export type ProfileTextDataType = ProfileFormDataBaseType<'text', string>;
export type ProfilePasswordDataType = ProfileFormDataBaseType<
  'password',
  string
>;
export type ProfileDateDataType = ProfileFormDataBaseType<'date', Date>;

export type ProfileFormDataType =
  | ProfileTextDataType
  | ProfilePasswordDataType
  | ProfileDateDataType
  | ProfileSelectDataType<any>;

const selectStyles: SelectOverrideStyles = {
  container: {
    width: '100% !important',
  },
};

export type Options<T extends OptionTypeBase> = OptionsType<T>;

export type OptionType = {
  value: string;
  label: string;
};

export const options_ind = [
  { value: '', label: '未選択' },
  { value: '1', label: 'IT・通信・インターネット' },
  { value: '2', label: 'メーカー（電気・電子・機械系）' },
  { value: '3', label: 'メーカー（素材・エネルギー・医薬品系）' },
  { value: '4', label: '商社' },
  { value: '5', label: '金融・保険' },
  { value: '6', label: 'メディア・広告・エンタメ' },
  { value: '7', label: 'サービス・レジャー' },
  { value: '8', label: '流通・小売' },
  { value: '9', label: '不動産・建設' },
  { value: '10', label: '運輸・交通・物流' },
  { value: '11', label: '生活・公共サービス' },
  { value: '99', label: 'その他' },
];

export const options_occ = [
  {
    options: [{ label: '未選択', value: '' }],
  },
  {
    label: '営業',
    options: [
      { label: '営業（既存顧客メイン）', value: '001' },
      { label: '営業（新規開拓メイン）', value: '002' },
      { label: '海外営業', value: '003' },
      { label: '営業推進・販売促進', value: '004' },
      { label: 'その他', value: '005' },
    ],
  },
  {
    label: '事務・管理',
    options: [
      { label: '人事・総務', value: '101' },
      { label: '経理・会計', value: '102' },
      { label: '知財・法務', value: '103' },
      { label: '一般事務・アシスタント', value: '104' },
      { label: 'その他', value: '105' },
    ],
  },
  {
    label: '企画・経営',
    options: [
      { value: '201', label: '宣伝・広報' },
      { value: '202', label: 'マーケティング・調査研究' },
      { value: '203', label: '企画・商品開発' },
      { value: '204', label: '経営企画' },
      { value: '205', label: 'その他' },
    ],
  },
  {
    label: 'クリエイティブ',
    options: [
      { value: '301', label: '編集・制作' },
      { value: '302', label: '記事・ライター' },
      { value: '303', label: 'ゲームクリエイター' },
      {
        value: '304',
        label: '広告プランナー・ディレクター・コピーライター',
      },
      {
        value: '305',
        label: 'グラフィック・広告デザイナー',
      },
      { value: '306', label: 'WEBデザイナー' },
      { value: '307', label: 'その他' },
    ],
  },
  {
    label: 'IT',
    options: [
      {
        value: '401',
        label: 'システムアナリスト・コンサルタント',
      },
      {
        value: '402',
        label: 'システムエンジニア',
      },
      {
        value: '403',
        label: 'プログラマー',
      },
      {
        value: '404',
        label: 'ネットワークエンジニア',
      },
      {
        value: '405',
        label: 'カスタマーエンジニア',
      },
      {
        value: '406',
        label: 'システム運用・保守',
      },
      {
        value: '407',
        label: 'その他',
      },
    ],
  },
  {
    label: '研究・開発・設計',
    options: [
      { value: '501', label: '基礎研究' },
      { value: '502', label: '応用研究・技術研究' },
      { value: '503', label: '生産・製造技術開発' },
      { value: '504', label: '機械・電子機器設計' },
      {
        value: '505',
        label: '品質・生産管理・メンテナンス',
      },
      { value: '506', label: 'その他' },
    ],
  },
  {
    label: '建築・土木',
    options: [
      { value: '601', label: '建築土木企画・設計・測量' },
      { value: '602', label: '施工管理' },
      { value: '603', label: 'その他' },
    ],
  },

  {
    label: '金融・保険',
    options: [
      { value: '604', label: 'トレーダー・ディーラー' },
      {
        value: '605',
        label: '資産運用・ファンドマネージャー',
      },
      { value: '606', label: '証券アナリスト' },
      { value: '607', label: 'アクチュアリー' },
      { value: '608', label: 'その他' },
    ],
  },
  {
    label: '販売・サービス',
    options: [
      { value: '701', label: 'スーパーバイザー' },
      { value: '702', label: 'バイヤー' },
      { value: '703', label: '店長・店舗運営' },
      { value: '704', label: '販売スタッフ・接客' },
      { value: '705', label: '店舗開発' },
      { value: '706', label: 'その他' },
    ],
  },
  {
    label: '専門職・士業',
    options: [
      { value: '801', label: 'コンサルタント' },
      {
        value: '802',
        label: '教師・インストラクター・講師・保育士',
      },
      { value: '803', label: '弁護士・会計士・税理士' },
      { value: '804', label: '薬剤師' },
      { value: '805', label: '介護士・ケアワーカー' },
      { value: '806', label: '管理栄養士' },
      { value: '807', label: '公務員・公共サービス' },
      { value: '808', label: 'その他' },
    ],
  },
  {
    label: 'その他',
    options: [{ value: '900', label: 'その他' }],
  },
];

export const profileFormData = {
  fullName: {
    id: 'fullName',
    type: 'text',
    label: '氏名',
    required: true,
  } as ProfileTextDataType,
  ruby: {
    id: 'ruby',
    type: 'text',
    label: 'よみがな',
    required: true,
  } as ProfileTextDataType,
  displayName: {
    id: 'displayName',
    type: 'text',
    label: '表示名',
    required: true,
  } as ProfileTextDataType,
  birthDay: {
    id: 'birthDay',
    type: 'date',
    label: '生年月日',
    required: true,
  } as ProfileDateDataType,
  startYear: {
    id: 'startYear',
    type: 'text',
    label: '入社年',
    required: true,
    width: '80px',
  } as ProfileTextDataType,
  department: {
    id: 'department',
    type: 'text',
    label: '部署',
  } as ProfileTextDataType,
  employeeId: {
    id: 'employeeId',
    label: '社員ID',
    type: 'text',
  } as ProfileTextDataType,
  industry: {
    id: 'industry',
    label: '業種',
    type: 'select',
    placeholder: '選択してください',
    options: options_ind,
    overrideStyles: selectStyles,
    menuPlacement: 'top',
  } as ProfileSelectDataType<any>,
  occupation: {
    id: 'occupation',
    label: '職種',
    type: 'select',
    placeholder: '選択してください',
    options: options_occ,
    overrideStyles: selectStyles,
    menuPlacement: 'top',
  } as ProfileSelectDataType<any>,
};
