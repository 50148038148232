import { MainButton } from '@riddler-co-jp/specc-ui-components';
import { TextWindow } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { useUserInfo } from '../../../../api/user';
import { useCurrentUser } from '../../../../redux/selectors/authSelectors';
import CommonBG from '../../../uiElements/CommonBG';
import { HeaderOptions } from '../../../uiElements/Header';

const headerOptions: HeaderOptions = {
  hideHomeButton: true,
};

interface UpdateEmailViewProps {
  onClick: () => void;
}

const UpdateEmailView: React.FC<UpdateEmailViewProps> = ({ onClick }) => {
  const user = useCurrentUser();
  const [userInfo] = useUserInfo(user?.uid);
  const first = React.useMemo(() => {
    return !userInfo?.email;
  }, [userInfo]);
  return (
    <CommonBG withHeader headerOptions={headerOptions} withFooter>
      <Wrapper>
        <Text>
          メールアドレスのご確認ありがとうございました。
          <br />
          メールアドレスの変更が完了しました。
        </Text>
        <TopButton size={'large'} color={'positive'} onClick={onClick}>
          ログインページへ
        </TopButton>
      </Wrapper>
    </CommonBG>
  );
};

const Wrapper = styled.div`
  position: relative;
  padding-top: 20rem;
`;

const Text = styled(TextWindow)`
  line-height: 4rem;
  margin: 0 auto;
  padding: 6rem;
`;

const TopButton = styled(MainButton)`
  margin: 4.5rem auto 0;
  display: block;
`;

export default UpdateEmailView;
