import axios, { AxiosResponse } from 'axios';

const uri = process.env.REACT_APP_FRONTEND_URI;

export const createTokenCookie = async (
  idToken: string
): Promise<AxiosResponse<any, any>> => {
  return axios.post(uri + '/api/createTokenCookie', {
    idToken,
  });
};

export const deleteTokenCookie = async (): Promise<AxiosResponse<any, any>> => {
  return axios.post(uri + '/api/deleteTokenCookie', {});
};
