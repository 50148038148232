import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Colors from '../../../styles/colors';
import SettingsItemTitle from '../SettingsItemTitle';

interface PrivacyProps {}
const Privacy: React.FC<PrivacyProps> = props => {
  const navigate = useNavigate();
  const inDialog = useLocation().pathname == '/dialog/privacy';

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <PrivacyHeader>
        <Link to={'/terms'}>
          {inDialog && (
            <BackButton
              onClick={() => {
                navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </BackButton>
          )}
        </Link>
        <SettingsItemTitle>{'プライバシーポリシー'}</SettingsItemTitle>
      </PrivacyHeader>

      <TermStyle>
        <p>
          RIDDLER株式会社（以下「当社」といいます）は、当社の運営する企業向け研修プログラムサービス「SPECCスキル研修」（以下「本サービス」といいます）における個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」といいます）を定めます。
        </p>
        <p>
          当社は、本サービスの利用者（以下「利用者」といいます）に本サービスを提供する際、本サービス運営のため必要な範囲で、本ポリシーに基づき利用者の個人情報等を取得することができるものとし、個人情報の保護に関する法律（以下「個人情報保護法」といいます）その他の関連法令等を遵守し、利用者の個人情報を厳正に管理します。
        </p>
        <h2>1．個人情報の取得</h2>
        <h3>
          1-1．当社は、以下に定める利用者に関する個人情報及びその他の情報（総称して以下「個人情報等」といいます）を取得します。
        </h3>
        <ul>
          <li>氏名（読み仮名を含む）</li>
          <li>生年月日</li>
          <li>メールアドレス</li>
          <li>所属企業並びに所属企業の入社年、業種、職種、部署、社員ID</li>
          <li>本サービスのアカウントID</li>
          <li>本サービスにおけるテスト成績及び回答内容等</li>
          <li>本サービスその他当社サイト内で個別に明示する情報</li>
        </ul>
        <h3>
          1-2．利用者による本サービスの利用過程でIPアドレス、クッキー、サービス利用記録及び機器情報が生成され又は当社により取得されることがあります。なお、クッキーについては、利用者は、利用者が使用するウェブブラウザ上の設定により無効にすることもできますが、この場合、利用者は、本サービスの一部を利用できなくなることがあります。
        </h3>
        <h2>2．個人情報の利用目的</h2>
        <p>
          当社は、本サービスに関連して取得した利用者の個人情報等を以下に定める目的で利用します。
        </p>
        <ul>
          <li>利用者の確認及び識別 利用者への意思確認その他利用者管理</li>
          <li>本サービスの開発、運営及び管理</li>
          <li>
            利用者からのお問合せ対応及び本サービスに関連する利用者へのご案内及び連絡
          </li>
          <li>
            本サービスの利用状況等に係る統計情報の作成、本サービス又は当社サイトにおける公表等
          </li>
          <li>
            利用規約若しくは関連規約等の規約違反又は本サービスの不正利用等の疑いがある場合の調査その他本サービスに関連する紛争処理のために必要な調査
          </li>
          <li>当社サービス又は当社商品の開発及びご案内</li>
          <li>その他本サービスその他当社サービス内で明示する目的</li>
        </ul>
        <h2>3．個人情報の第三者提供</h2>
        <h3>
          3-1．当社は、利用者の個人情報等を利用者の事前の承諾を得ることなく、第三者に開示、提供しません。但し、当社は、以下に定める場合には、利用者の個人情報等を第三者に提供することができるものとします。
        </h3>
        <ul>
          <li>本人の同意がある場合</li>
          <li>
            本サービスに関する当社の販売代理店である株式会社電通に開示する場合
          </li>
          <li>
            当社が行う本サービスに関連する業務の全部又は一部を第三者に委託する場合
          </li>
          <li>
            裁判所、検察庁、警察、税務署、弁護士会又はこれに準じた権限を有する機関から個人情報の開示を求められた場合その他法令等に基づき当社が提供義務を負う場合
          </li>
          <li>個人情報保護法その他の法令により認められる場合</li>
          <li>
            個人の生命、身体又は財産の保護のために必要な場合で、本人の同意を得ることが困難な場合
          </li>
          <li>当社の権利行使又は財産保護のために必要となる場合</li>
          <li>
            合併、営業譲渡その他の事由による事業の承継の際に、事業を承継する者に対して開示する場合
          </li>
          <li>その他本サービスその他当社サイト内で個別に明示する場合</li>
        </ul>
        <h3>
          3-2．本サービスその他当社サービスの開発及び運営に関する業務ついては一部の業務を外部に委託することがあり、それに伴い、利用目的の達成に必要な範囲内において、利用者の個人情報等を委託先に提供することがあります。この場合、当社は、個人情報保護体制が整備された委託先を選定するとともに、当該委託先との間に個人情報保護に関する契約を締結します。
        </h3>
        <h2>4．リンク先等での個人情報の取扱い</h2>
        本サービスその他当社サービス上で、第三者が運営する外部サイトへのリンクが表示されることがあります。リンク先の外部サイトで取得される個人情報については、外部サイトの運営者が定めるプライバシーポリシーが適用されるものとし、当社は、これらの外部サイトに関連して何らの責任も負わないものとします。
        <h2>5．法令等の遵守・安全管理の実施</h2>
        <h3>
          5-1．当社は、個人情報の取扱いに関する法令、国が定める指針その他の規範を遵守するとともに、当社の個人情報管理体制をこれらの法令、指針その他の規範に適合させます。
        </h3>
        <h3>
          5-2．当社は、個人情報への不正アクセス、個人情報の改竄及び漏洩等を防止するために適切な安全対策を行います。当社は、万が一個人情報の漏洩等があった場合は、速やかに適切な措置を講じます。
        </h3>
        <h2>6．利用者の個人情報の開示・訂正等</h2>
        <h3>
          6-1．当社は、利用者から取得した個人情報について、当該利用者本人から開示、訂正、削除等を求められた場合、利用者本人からの請求であることを確認のうえ、遅滞なく対応いたします。但し、以下に該当する場合において、その全部又は一部に対応しない旨の決定をしたとき、その旨を遅滞なく通知します。
        </h3>
        <ul>
          <li>利用者本人からの請求であることが確認できない場合</li>
          <li>
            利用者本人又は第三者の生命、身体、財産その他の権利利益を害する恐れがある場合
          </li>
          <li>業務の適切な実施に著しい支障を及ぼす恐れがある場合</li>
          <li>法令違反に該当する場合 当社の保有個人情報に該当しない場合</li>
        </ul>
        <h3>6-2．個人情報に関するお問合せ先は以下のとおりです。</h3>
        <ul>
          <li>事業者名：RIDDLER株式会社</li>
          <li>所在地：〒150-0002 東京都渋谷区渋谷1-3-15 BIZCORE渋谷7F</li>
          <li>連絡先メールアドレス：specctraining@riddler.co.jp</li>
        </ul>
        <h2>7．本ポリシーの改定</h2>
        <p>
          本ポリシーは変更される場合があり、その場合は変更内容及び変更日を本サービス又は当社サイト上で通知します。利用者は、定期的に本サービス又は当社サイトを確認し変更事項の有無及びその内容を確認する必要があります。利用者が変更日以降に本サービスを利用する場合、変更された本ポリシーに同意したものとみなします。
        </p>
        <Margin />
        <p>制定日：2021年9月1日</p>
      </TermStyle>
    </>
  );
};

const TermStyle = styled.div`
  font-size: 1.5rem;

  h2,
  h3 {
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 2rem;
  }

  li {
    margin: 0 3rem;
    margin-bottom: 2rem;
  }
  ol ul {
    padding-left: 2rem;
  }
`;
const Margin = styled.div`
  height: 2rem;
`;

const BackButton = styled.div`
  margin-left: -1rem;
  background: white;
  border-radius: 99px;
  padding: 1rem;
  margin-right: 0.6rem;
  transition: 0.2s;
  color: ${Colors.gray6};

  svg {
    width: 24px !important;
    height: 24px !important;
  }

  &:hover {
    background: ${Colors.gray4};
  }
`;

const PrivacyHeader = styled.div`
  display: flex;

  a {
    height: 55px;
    border-bottom: 0.2rem solid rgb(52, 58, 64);
  }
`;

export default Privacy;
