import firebase from 'firebase/app';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { createTokenCookie } from '../../api/sso';
import asContent from '../../hoc/asContent';
import { useCurrentUser } from '../../redux/selectors/authSelectors';

interface TokenRefreshProps {}
const TokenRefresh: FC<TokenRefreshProps> = () => {
  const navigate = useNavigate();
  const user = useCurrentUser();

  useEffect(() => {
    if (!user) {
      return;
    }
    user.getIdToken(true).then(token => {
      if (token) {
        createTokenCookie(token).then(() => {
          navigate('/');
        });
      } else {
        navigate('/logout');
      }
    });
  }, [user, navigate]);

  return <></>;
};

export default asContent(TokenRefresh);
