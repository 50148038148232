import { EventWithMembersNonNull } from '../../../../api/event';

export const adminEventsSorter: any = {
  scheduledStartTime: {
    ASC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      return (
        a.event.scheduledStartTime.getTime() -
        b.event.scheduledStartTime.getTime()
      );
    },
    DESC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      return (
        b.event.scheduledStartTime.getTime() -
        a.event.scheduledStartTime.getTime()
      );
    },
  },
  preset: {
    ASC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      return a.event.preset < b.event.preset ? -1 : 1;
    },
    DESC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      return a.event.preset > b.event.preset ? -1 : 1;
    },
  },
  name: {
    ASC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      return a.event.name < b.event.name ? -1 : 1;
    },
    DESC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      return a.event.name > b.event.name ? -1 : 1;
    },
  },
  status: {
    ASC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      return b.event.status < a.event.status ? -1 : 1;
    },
    DESC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      return b.event.status > a.event.status ? -1 : 1;
    },
  },
  place: {
    ASC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      if (a.event.place === null) a.event.place = '';
      if (b.event.place === null) b.event.place = '';
      return a.event.place < b.event.place ? -1 : 1;
    },
    DESC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      if (a.event.place === null) a.event.place = '';
      if (b.event.place === null) b.event.place = '';
      return a.event.place > b.event.place ? -1 : 1;
    },
  },
  participantsCount: {
    ASC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      const aValue = a.members.participants.length;
      const bValue = b.members.participants.length;
      return aValue - bValue;
    },
    DESC: (a: EventWithMembersNonNull, b: EventWithMembersNonNull) => {
      const aValue = a.members.participants.length;
      const bValue = b.members.participants.length;
      return bValue - aValue;
    },
  },
};
