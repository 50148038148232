import { TextWindow } from '@riddler-co-jp/specc-ui-components';
import { Window } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { Stage5Problem } from '../../../../lib/stage5problems';
import CorrectImg from '../../../../static/svg/Stage5_correct.svg';
import WrongImg from '../../../../static/svg/Stage5_wrong.svg';
import Colors, { colorWithAlpha } from '../../../../styles/colors';

interface GameAnswererViewProps {
  onUpdate: (ans: number) => void;
  currentAnswer: number | null;
  showingAnswer: boolean;
  problem: Stage5Problem;
}

const GameAnswererView: React.FC<GameAnswererViewProps> = ({
  onUpdate,
  currentAnswer,
  showingAnswer,
  problem,
}) => {
  return (
    <GameAnswererViewWrapper>
      <QNum>Q{problem.count}</QNum>
      <AnswererText bracket>{problem.text.answerer}</AnswererText>
      <AnsWindowWrapper>
        {[1, 2, 3, 4].map(i => (
          <AnsWindow
            index={i}
            key={i}
            onClick={() => onUpdate(i)}
            active={i === currentAnswer}
          >
            <AnsNumber active={i === currentAnswer}>{i}</AnsNumber>
            <OptionImage src={problem.imageSrc[i]} />
            {showingAnswer ? (
              i === problem.answer ? (
                <Correct />
              ) : (
                <Wrong />
              )
            ) : null}
          </AnsWindow>
        ))}
      </AnsWindowWrapper>
    </GameAnswererViewWrapper>
  );
};

const GameAnswererViewWrapper = styled.div``;

const QNum = styled.div`
  position: absolute;
  top: 8rem;
  left: calc(50% - 50rem);
  height: 7rem;
  width: 7rem;
  box-sizing: border-box;
  color: ${Colors.white};
  background-color: ${Colors.gray8};
  font-size: 2.5rem;
  line-height: 7rem;
  text-align: center;
`;

const AnswererText = styled(TextWindow)`
  position: absolute;
  top: 8rem;
  left: calc(50% - 50rem + 7rem + 1rem);
  height: 7rem;
  width: 92rem;
  line-height: 7rem;
  text-align: left;
  padding: 0 3rem;
  box-sizing: border-box;
  background-color: ${colorWithAlpha('gray4', 0.25)};
  font-weight: bold;
`;

const AnsWindowWrapper = styled.div`
  width: 100rem;
  height: 40rem;

  position: absolute;
  top: 17rem;
  left: 50%;
  transform: translateX(-50%);

  display: grid;
`;

interface AnsWindowProps {
  index: number;
  active: boolean;
}

const AnsWindow = styled(Window)`
  display: inline-block;
  grid-row: ${(p: AnsWindowProps) => Math.floor((p.index - 1) / 2) + 1};
  grid-column: ${(p: AnsWindowProps) => ((p.index - 1) % 2) + 1};
  margin-bottom: 2rem;
  position: relative;
  background-color: ${Colors.gray2};

  &:nth-child(2n + 1) {
    margin-right: 2rem;
  }

  ${(p: AnsNumberProps) =>
    p.active
      ? `
      border: 4px solid ${Colors.primary};
    `
      : `
      border: 4px solid rgba(255,255,255,0.0);
      `}
`;

interface ResultIndicatorProps {
  imgSrc: string;
}

const ResultIndicator = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${colorWithAlpha('white', 0.8)};
  position: absolute;
  top: 0;
  left: 0;

  &:after {
    width: 14rem;
    height: 14rem;
    position: absolute;
    top: calc(50% - 7rem);
    left: calc(50% - 7rem);
    content: '';
    background-image: url(${(p: ResultIndicatorProps) => p.imgSrc});
    background-size: contain;
    background-repeat: no-repeat;
  }
`;

const Correct = styled(ResultIndicator).attrs({
  imgSrc: CorrectImg,
})``;
const Wrong = styled(ResultIndicator).attrs({
  imgSrc: WrongImg,
})``;

interface AnsNumberProps {
  active: boolean;
}

const AnsNumber = styled.div`
  width: 3rem;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  font-size: 2.6rem;
  color: ${Colors.white};
  position: absolute;
  top: 0;
  left: 0;
  background-color: ${(p: AnsNumberProps) =>
    p.active ? Colors.primary : Colors.gray8};
  z-index: 5;
  margin-top: -4px;
  margin-left: -4px;
`;

const OptionImage = styled.img`
  width: 100%;
`;

export default GameAnswererView;
