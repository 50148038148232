import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { Data, Headers } from 'react-csv/components/CommonPropTypes';

import { EventDocument } from '../../../../api/event';
import { UserOrgInfo } from '../../../../api/firestoreTypes';
import { OrgMemberRecord } from '../../../../api/organization';
import { DataArray, getRankMessage } from '../../../../lib/stageResults';
import { options_ind, options_occ } from '../../../../lib/userProfiles';

export const useEventCsvCall = () => {
  const [csvClicked, setCsvClicked] = useState<boolean>(false);
  const onCsvDownloadButton = useCallback(() => {
    setCsvClicked(true);
  }, []);
  const onCsvDownload = useCallback(() => {
    setCsvClicked(false);
  }, []);

  return { onCsvDownload, csvClicked, onCsvDownloadButton };
};

type EventCsvParam = {
  averageDeviations: DataArray | undefined;
  averageRank: DataArray | undefined;
  csvClicked: boolean;
  event?: EventDocument | null;
  onCsvDownload: () => void;
  orgMemberDoc: Record<string, OrgMemberRecord | undefined> | undefined;
  participantUids: string[];
  userDeviations:
    | {
        [key: string]: DataArray;
      }
    | undefined;
  userOrgTable:
    | {
        [key: string]: UserOrgInfo;
      }
    | undefined;
  userRanks: { [key: string]: DataArray } | undefined;
};

export const useEventCsv = (params: EventCsvParam) => {
  const {
    averageDeviations,
    averageRank,
    csvClicked,
    event,
    onCsvDownload,
    orgMemberDoc,
    participantUids,
    userDeviations,
    userOrgTable,
    userRanks,
  } = params;
  const eventId = event?.eventId;
  const csv = useMemo(() => {
    if (event === undefined) {
      return undefined;
    }
    if (userOrgTable === undefined) {
      return undefined;
    }
    if (orgMemberDoc === undefined) {
      return undefined;
    }
    const data = participantUids.map(p => {
      const userOrg = userOrgTable[p];
      const member = orgMemberDoc[p];

      const industryName =
        options_ind.find(
          e => e.value.toString() === userOrg?.industryId?.toString()
        )?.label ?? '';
      const occupationName = userOrg?.occupationId
        ? options_occ
            .map((e: any) => e.options)
            .flat()
            .find(
              (e: any) =>
                e.value.toString() === userOrg?.occupationId?.toString()
            )?.label ?? ''
        : '';

      return {
        eventName: event?.name,
        presetName: event?.preset ?? 'CT1',
        fullName: member?.fullName ?? '',
        displayName: member?.displayName ?? '',
        ruby: member?.ruby ?? '',
        email: member?.email ?? '',
        startYear: '' + (userOrg?.startYear ?? ''),
        employeeId: userOrg?.employeeId ?? '',
        department: userOrg?.department ?? '',
        industryName,
        occupationName,
        rank1: getRankMessage(userRanks?.[p]?.[0] ?? -1),
        rank2: getRankMessage(userRanks?.[p]?.[1] ?? -1),
        rank3: getRankMessage(userRanks?.[p]?.[2] ?? -1),
        rank4: getRankMessage(userRanks?.[p]?.[3] ?? -1),
        rank5: getRankMessage(userRanks?.[p]?.[4] ?? -1),
        deviation1: userDeviations?.[p]?.[0]?.toFixed(1) ?? '-',
        deviation2: userDeviations?.[p]?.[1]?.toFixed(1) ?? '-',
        deviation3: userDeviations?.[p]?.[2]?.toFixed(1) ?? '-',
        deviation4: userDeviations?.[p]?.[3]?.toFixed(1) ?? '-',
        deviation5: userDeviations?.[p]?.[4]?.toFixed(1) ?? '-',
      };
    });

    data.unshift({
      eventName: event?.name,
      presetName: 'CT2',
      fullName: '受験者平均',
      displayName: '',
      ruby: '',
      email: '',
      startYear: '',
      employeeId: '',
      department: '',
      industryName: '',
      occupationName: '',
      rank1: getRankMessage(averageRank?.[0] ?? -1),
      rank2: getRankMessage(averageRank?.[1] ?? -1),
      rank3: getRankMessage(averageRank?.[2] ?? -1),
      rank4: getRankMessage(averageRank?.[3] ?? -1),
      rank5: getRankMessage(averageRank?.[4] ?? -1),
      deviation1: averageDeviations?.[0]?.toFixed(1) ?? '-',
      deviation2: averageDeviations?.[1]?.toFixed(1) ?? '-',
      deviation3: averageDeviations?.[2]?.toFixed(1) ?? '-',
      deviation4: averageDeviations?.[3]?.toFixed(1) ?? '-',
      deviation5: averageDeviations?.[4]?.toFixed(1) ?? '-',
    });

    return {
      data: data,
      headers: [
        { label: 'テストイベント名', key: 'eventName' },
        { label: 'プリセット名', key: 'presetName' },
        { label: '氏名', key: 'fullName' },
        { label: '表示名', key: 'displayName' },
        { label: 'よみがな', key: 'ruby' },
        { label: 'メールアドレス', key: 'email' },
        { label: '入社年', key: 'startYear' },
        { label: '社員ID', key: 'employeeId' },
        { label: '部署', key: 'department' },
        { label: '業種', key: 'industryName' },
        { label: '職種', key: 'occupationName' },
        { label: 'ステージ1ランク', key: 'rank1' },
        { label: 'ステージ2ランク', key: 'rank2' },
        { label: 'ステージ3ランク', key: 'rank3' },
        { label: 'ステージ4ランク', key: 'rank4' },
        { label: 'ステージ5ランク', key: 'rank5' },
        { label: 'ステージ1偏差値', key: 'deviation1' },
        { label: 'ステージ2偏差値', key: 'deviation2' },
        { label: 'ステージ3偏差値', key: 'deviation3' },
        { label: 'ステージ4偏差値', key: 'deviation4' },
        { label: 'ステージ5偏差値', key: 'deviation5' },
      ] as Headers,
      filename: `event_results_${eventId}.csv`,
      onCsvDownload,
    };
  }, [
    event,
    userOrgTable,
    orgMemberDoc,
    participantUids,
    averageRank,
    averageDeviations,
    eventId,
    onCsvDownload,
    userRanks,
    userDeviations,
  ]);

  const fetchDoneRef =
    useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  useEffect(() => {
    if (csv && csvClicked) {
      window.setTimeout(() => {
        fetchDoneRef.current?.link.click();
      }, 30);
    }
  }, [csv, csvClicked, fetchDoneRef]);

  return { csv, fetchDoneRef };
};
