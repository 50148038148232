import { gql } from '@apollo/client';
import firebase from 'firebase/app';
import { useEffect, useMemo, useState } from 'react';
import {
  DocumentDataHook,
  useCollection,
  useCollectionData,
  useDocumentData,
  useDocumentDataOnce,
} from 'react-firebase-hooks/firestore';
import { LoadingHook } from 'react-firebase-hooks/firestore/dist/util';

// import { useDocumentData } from '../lib/firebase/firestoreDataHook';
import { TS2DateType } from '../lib/firebase/firestoreTypeUtils';
import {
  Training as TrainingDocumentRaw,
  TrainingMember as TrainingMembersDocumentNoId,
  UserRecords,
} from './firestoreTypes';

export type TrainingDocumentNoId = TS2DateType<TrainingDocumentRaw>;
export type TrainingDocument = TrainingDocumentNoId & {
  trainingId: string;
};
export type TrainingMembersDocument = TrainingMembersDocumentNoId & {
  trainingId: string;
};

export type TrainingWithMembers = {
  trainingId: string;
  training: TrainingDocument | null;
  members: TrainingMembersDocument | null;
};

export type TrainingWithMembersNonNull = {
  trainingId: string;
  training: TrainingDocument;
  members: TrainingMembersDocument;
};

export const TrainingSchema = gql`
  query getTrainingParticipantUserData(
    $input: GetTrainingParticipantUserDataInput!
  ) {
    getTrainingParticipantUserData(input: $input) {
      uid
      displayName
      email
    }
  }

  mutation createTraining($input: CreateTrainingInput!) {
    createTraining(input: $input) {
      trainingId
    }
  }

  mutation removeTraining($input: TrainingInput!) {
    removeTraining(input: $input) {
      success
    }
  }

  mutation changeTrainingStatus($input: TrainingStatusInput!) {
    changeTrainingStatus(input: $input) {
      success
    }
  }

  mutation changeTrainingInformation($input: TrainingInformationInput!) {
    changeTrainingInformation(input: $input) {
      success
    }
  }

  mutation addTrainingParticipant($input: TrainingParticipantInput!) {
    addTrainingParticipant(input: $input) {
      rejectedUids
    }
  }

  mutation removeTrainingParticipant($input: TrainingParticipantInput!) {
    removeTrainingParticipant(input: $input) {
      success
    }
  }
`;

export const useAvailableTrainingIds = (
  user: firebase.User | null
): LoadingHook<string[], Error> => {
  const [memberTrainings, memberTrainingsLoading, memberTrainingsError] =
    useCollection(
      user !== null
        ? firebase
            .firestore()
            .collection('version')
            .doc('1')
            .collection('training_members')
            .where('participants', 'array-contains', user.uid)
        : undefined
    );
  const [ids, setIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!memberTrainingsLoading) {
      const memberTrainingIds = memberTrainings
        ? memberTrainings.docs.map((d: any) => d.id)
        : [];
      setIds(Array.from(new Set(memberTrainingIds)));
    }
    setLoading(memberTrainingsLoading);
  }, [memberTrainings, memberTrainingsLoading]);
  const error = memberTrainingsError;

  return [ids, loading, error];
};

export const useJoinableTrainingIds = (
  user: firebase.User | null
): LoadingHook<string[], Error> => {
  const [memberTrainings, loading, error] = useCollection(
    user !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('training_members')
          .where('participants', 'array-contains', user.uid)
      : undefined
  );

  const [memberTrainingIds, setMemberTrainingIds] = useState<string[]>([]);
  useEffect(() => {
    if (memberTrainings != null) {
      setMemberTrainingIds(memberTrainings.docs.map((d: any) => d.id));
    }
  }, [memberTrainings]);

  return [memberTrainingIds, loading, error];
};

export const useEditableTrainingIds = (
  user: firebase.User | null,
  orgId: string
): LoadingHook<string[], Error> => {
  const ref =
    user !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('training_members')
          .where('orgId', '==', orgId)
      : undefined;

  const ownerTrainingsResult = useCollectionData<TrainingMembersDocument>(ref, {
    idField: 'trainingId',
  });
  return useMemo(() => {
    const ids =
      ownerTrainingsResult[0]?.map(
        (d: TrainingMembersDocument & { trainingId: string }) => d.trainingId
      ) ?? [];
    const loading = ownerTrainingsResult[1];
    const error = ownerTrainingsResult[2];
    return [ids, loading, error];
  }, [ownerTrainingsResult]);
};

const isNonnull = <T>(x: T | null): x is T => x !== null;

export const useEndedTrainingIds = (
  user: firebase.User | null
): LoadingHook<string[], Error> => {
  const [availableIds, availableIdsLoading, error] =
    useAvailableTrainingIds(user);
  const [ids, setIds] = useState(availableIds);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (availableIdsLoading) setLoading(true);
  }, [availableIdsLoading]);
  useEffect(() => {
    if (user == null || availableIdsLoading || availableIds == null) return;
    Promise.all(availableIds.map(id => getTrainingData(id)))
      .then(results => {
        results
          .filter(isNonnull)
          .filter(tr => tr.status === 'ENDED')
          .sort(
            (a, b) =>
              b.scheduledStartTime.getTime() - a.scheduledStartTime.getTime()
          );
        setIds(
          availableIds.filter((_, idx) => results[idx]?.status === 'ENDED')
        );
      })
      .finally(() => setLoading(false));
  }, [setIds, availableIds, user, availableIdsLoading]);
  return [ids, loading, error];
};

export const useUserTrainingHistory = (
  userId: string | null
): LoadingHook<string[], Error> => {
  const [memberTrainings, memberTrainingsLoading, memberTrainingsError] =
    useCollection(
      userId !== null
        ? firebase
            .firestore()
            .collection('version')
            .doc('1')
            .collection('training_members')
            .where('participants', 'array-contains', userId)
        : undefined
    );
  const [ids, setIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!memberTrainingsLoading) {
      const memberTrainingIds = memberTrainings
        ? memberTrainings.docs.map((d: any) => d.id)
        : [];
      setIds(Array.from(new Set(memberTrainingIds)));
    }
    setLoading(memberTrainingsLoading);
  }, [memberTrainings, memberTrainingsLoading]);
  const error = memberTrainingsError;

  return [ids, loading, error];
};

const mapper = (raw: TrainingDocumentRaw): TrainingDocumentNoId => ({
  ...raw,
  scheduledStartTime: raw.scheduledStartTime.toDate(),
  scheduledEndTime: raw.scheduledEndTime.toDate(),
  createdOn: raw.createdOn.toDate(),
  updatedOn: raw.updatedOn.toDate(),
});

export const useTrainingData = (
  trainingId: string | null
): DocumentDataHook<TrainingDocumentNoId, 'trainingId'> => {
  const ref =
    trainingId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('trainings')
          .doc(trainingId)
      : undefined;

  return useDocumentData<TrainingDocumentNoId, 'trainingId'>(ref, {
    transform: mapper,
    idField: 'trainingId',
  });
};

export const useTrainingDataOnce = (
  trainingId: string | null
): DocumentDataHook<TrainingDocumentNoId, 'trainingId'> => {
  const ref =
    trainingId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('trainings')
          .doc(trainingId)
      : undefined;

  return useDocumentDataOnce<TrainingDocumentNoId, 'trainingId'>(ref, {
    transform: mapper,
    idField: 'trainingId',
  });
};

export const getTrainingData = async (
  trainingId: string | null
): Promise<TrainingDocument | null> => {
  if (trainingId === null) {
    return null;
  }

  const ref = firebase
    .firestore()
    .collection('version')
    .doc('1')
    .collection('trainings')
    .doc(trainingId);

  const snapshot = await ref.get();
  const data = snapshot.data() as TrainingDocumentRaw | undefined;
  if (!snapshot.exists || data === undefined) {
    return null;
  }

  const Training: TrainingDocument = {
    ...data,
    trainingId,
    scheduledStartTime: data.scheduledStartTime.toDate(),
    scheduledEndTime: data.scheduledEndTime.toDate(),
    createdOn: data.createdOn.toDate(),
    updatedOn: data.updatedOn.toDate(),
  };
  return Training;
};

const getTrainingMembersData = async (
  trainingId: string | null
): Promise<TrainingMembersDocument | null> => {
  if (trainingId === null) {
    return null;
  }

  const ref = firebase
    .firestore()
    .collection('version')
    .doc('1')
    .collection('training_members')
    .doc(trainingId);

  const snapshot = await ref.get();
  const data = snapshot.data() as TrainingMembersDocument | undefined;
  if (!snapshot.exists || data === undefined) {
    return null;
  }
  return {
    ...data,
    trainingId,
  };
};

export const getTrainingWithMemberData = async (
  trainingId: string | null
): Promise<TrainingWithMembers | null> => {
  if (trainingId === null) {
    return null;
  }
  const training = await getTrainingData(trainingId);
  const members = await getTrainingMembersData(trainingId);
  return {
    trainingId,
    training,
    members,
  };
};

export const useTrainingMembersData = (
  trainingId: string | null | undefined
): DocumentDataHook<Partial<TrainingMembersDocument>> => {
  return useDocumentData<Partial<TrainingMembersDocument>>(
    trainingId != null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('training_members')
          .doc(trainingId)
      : undefined
  );
};

export const useTrainingResult = (
  uid: string | null,
  trainingId: string | null
): DocumentDataHook<UserRecords> => {
  return useDocumentData<UserRecords>(
    uid !== null && trainingId !== null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('training_user_records')
          .doc(trainingId)
          .collection('users')
          .doc(uid)
      : undefined
  );
};

export const useOrgAllTrainings = (orgId: string | null) => {
  return useCollection(
    orgId
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('trainings')
          .where('orgId', '==', orgId)
      : undefined
  );
};
