import { Pagination } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  TrainingWithMembersNonNull,
  getTrainingWithMemberData,
  useEditableTrainingIds,
} from '../../../api/training';
import {
  useCurrentUser,
  useSelectedOrgId,
} from '../../../redux/selectors/authSelectors';
import { TrainingStatus } from '../../../types';
import AdminCommonBG from '../../admin/AdminCommonBG';
import AdminTrainingTable from '../../eventProvider/AdminTrainingTable';
import { HeaderButton } from '../UIelements/AdminNavigation';
import Filter from '../UIelements/Filter';
import { adminTrainingsSorter } from '../UIelements/sorter/AdminTrainingsSorter';

type FilterState = {
  Ongoing: boolean;
  Ended: boolean;
  Preparing: boolean;
  training_name?: string;
  place_name?: string;
};
interface AdminTrainingProps {}
const AdminTraining: React.FC<AdminTrainingProps> = props => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const orgId = useSelectedOrgId();
  const [trainingIds, loading, error] = useEditableTrainingIds(
    user,
    orgId ?? ''
  );

  const onAdminEdit = (trainingId?: string) => {
    navigate(`/admin/training/${trainingId}/edit`);
  };
  const onAdminControl = (trainingId?: string) => {
    navigate(`/admin/training/${trainingId}/control`);
  };
  const onAdminCreate = React.useCallback(() => {
    navigate(`/admin/training/create`);
  }, [navigate]);

  const onAdminDelete = React.useCallback(() => {
    navigate('/admin');
  }, [navigate]);

  const [sortKey, setSortKey] = React.useState({
    key: 'scheduledStartTime',
    sort: 'ASC',
  });

  const onChangeSort = (sortObj: any) => {
    setSortKey(sortObj);
  };

  const btnAdd: HeaderButton = {
    buttonText: '＋新規作成',
    event: onAdminCreate,
    disabled: false,
  };

  const [pageCursor, setPageCursor] = React.useState<number>(0);
  const pageSize = 10;

  const [trainingWithMemberDataList, setTrainingWithMemberDataList] =
    React.useState<TrainingWithMembersNonNull[]>([]);

  React.useEffect(() => {
    if (trainingIds == null) {
      return;
    }
    setTrainingWithMemberDataList([]);
    trainingIds.forEach(eid => {
      getTrainingWithMemberData(eid).then(data => {
        if (data === null) return;
        if (data.training === null) return;
        if (data.members === null) return;
        const nonNullData: TrainingWithMembersNonNull = {
          training: data.training,
          trainingId: data.trainingId,
          members: data.members,
        };
        setTrainingWithMemberDataList((prev: TrainingWithMembersNonNull[]) => {
          if (prev.some(e => e.trainingId === data.trainingId)) return prev;
          return [...prev, nonNullData];
        });
      });
    });
  }, [error, trainingIds, loading]);

  const [filter, setFilter] = React.useState<FilterState>({
    Ongoing: true,
    Ended: true,
    Preparing: true,
  });

  const sorter = adminTrainingsSorter[sortKey.key][sortKey.sort];
  const filteredTrainingDataList = React.useMemo(() => {
    return trainingWithMemberDataList
      .sort(sorter)
      .filter(
        e =>
          (!filter.training_name ||
            e.training.name.indexOf(filter.training_name) !== -1) &&
          (!filter.place_name ||
            (e.training.place &&
              e.training.place.indexOf(filter.place_name) !== -1)) &&
          ((filter.Ongoing && e.training.status == TrainingStatus.Ongoing) ||
            (filter.Ended && e.training.status == TrainingStatus.Ended) ||
            (filter.Preparing && e.training.status == TrainingStatus.Preparing))
      );
  }, [
    trainingWithMemberDataList,
    filter.Ongoing,
    filter.Ended,
    filter.Preparing,
    filter.training_name,
    filter.place_name,
    filter,
    sorter,
  ]);

  return (
    <AdminCommonBG title='スキルトレーニング' headerButtons={[btnAdd]}>
      <Filter
        item={[
          { type: 'input', name: 'training_name', label: '研修名' },
          { type: 'input', name: 'place_name', label: '会場名' },
          {
            type: 'checkbox',
            name: 'Ongoing',
            label: '開催中',
            defaultValue: true,
            value: true,
          },
          {
            type: 'checkbox',
            name: 'Preparing',
            label: '未開催',
            defaultValue: true,
            value: true,
          },
          {
            type: 'checkbox',
            name: 'Ended',
            label: '終了したイベント',
            defaultValue: true,
            value: true,
          },
        ]}
        onSend={params => {
          setFilter(params);
          setPageCursor(0);
        }}
      />
      <AdminTrainingWrapper>
        {
          <AdminTrainingTable
            trainingDatas={filteredTrainingDataList.filter(
              (_, i) => pageCursor <= i && i < pageCursor + pageSize
            )}
            onAdminEdit={onAdminEdit}
            onAdminControl={onAdminControl}
            onAdminCreate={onAdminCreate}
            onAdminDelete={onAdminDelete}
            sortObj={sortKey}
            onChangeSort={onChangeSort}
          />
        }
      </AdminTrainingWrapper>
      <Pagination
        count={filteredTrainingDataList.length}
        begin={pageCursor}
        end={Math.min(pageCursor + pageSize, filteredTrainingDataList.length)}
        onNext={() => {
          setPageCursor(pageCursor + pageSize);
        }}
        onPrev={() => {
          setPageCursor(Math.max(pageCursor - pageSize, 0));
        }}
      />
    </AdminCommonBG>
  );
};

const AdminTrainingWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export default AdminTraining;
