import { TextWindow } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Link as _Link, useMatch } from 'react-router-dom';
import styled from 'styled-components';

import { useEventResult } from '../../../api/event';
import { stageIds } from '../../../api/stages';
import { isStageDebug } from '../../../lib/env';
import useLocalStorage from '../../../lib/useLocalStorage';
import { gameActions } from '../../../redux/actions/gameActions';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import {
  useEventId,
  useStagesFinished,
} from '../../../redux/selectors/gameSelectors';
import Colors from '../../../styles/colors';
import StageNumber from '../../uiElements/StageNumber';

interface StageSelectorProps {
  preset: string;
}

const data: {
  [key: string]: {
    [key: number]: {
      url: string;
    };
  };
} = {
  '1': {
    1: {
      url: 'https://ct1-switch.specc-dev.riddler.co.jp/',
    },
    2: {
      url: 'https://ct1-program.specc-dev.riddler.co.jp/',
    },
    3: {
      url: 'https://ct1-create.specc-dev.riddler.co.jp/',
    },
    4: {
      url: 'https://ct1-energy.specc-dev.riddler.co.jp/',
    },
    5: {
      url: 'https://ct1-comm.specc-dev.riddler.co.jp/',
    },
  },
  '2': {
    1: {
      url: 'https://ct2-switch.specc-dev.riddler.co.jp/',
    },
    2: {
      url: 'https://ct2-program.specc-dev.riddler.co.jp/',
    },
    3: {
      url: 'https://ct2-create.specc-dev.riddler.co.jp/',
    },
    4: {
      url: 'https://ct2-energy.specc-dev.riddler.co.jp/',
    },
    5: {
      url: 'https://ct2-comm.specc-dev.riddler.co.jp/',
    },
  },
};

const StageSelector: React.FC<StageSelectorProps> = props => {
  const { preset } = props;
  const stagesFinished = useStagesFinished();
  const match = useMatch('/');
  const dispatch = useDispatch();
  const user = useCurrentUser();

  const eventId = useEventId();

  const [eventResult] = useEventResult(user?.uid ?? null, eventId);
  React.useEffect(() => {
    if (eventResult?.calculatedScores.stage1.score) {
      dispatch(gameActions.setHasStageFinished({ stage: 1, finished: true }));
    }
    if (eventResult?.calculatedScores.stage2.score) {
      dispatch(gameActions.setHasStageFinished({ stage: 2, finished: true }));
    }
    if (eventResult?.calculatedScores.stage3.score) {
      dispatch(gameActions.setHasStageFinished({ stage: 3, finished: true }));
    }
    if (eventResult?.calculatedScores.stage4.score) {
      dispatch(gameActions.setHasStageFinished({ stage: 4, finished: true }));
    }
    if (eventResult?.calculatedScores.stage5.score) {
      dispatch(gameActions.setHasStageFinished({ stage: 5, finished: true }));
    }
  }, [dispatch, eventResult]);

  return (
    <>
      <StageCaption>ステージを選んでください。</StageCaption>
      <StageList>
        {stageIds.map(i => (
          <StageListItem key={i} disabled={false}>
            <a
              key={i}
              href={
                stagesFinished[i]
                  ? `${data[preset][i].url}events/${eventId}/result`
                  : `${data[preset][i].url}events/${eventId}`
              }
            >
              <StageNumber stageNum={i} />
            </a>
          </StageListItem>
        ))}
      </StageList>
    </>
  );
};

const StageCaption = styled(TextWindow)`
  margin: 5rem 0;
  margin-bottom: 2.8rem;
  display: inline-block;
  width: 58rem;
  padding-left: 0;
  padding-right: 0;
  position: relative;

  &:before,
  &:after {
    content: '';
    display: block;
    border: 3px solid ${Colors.gray8};
    width: 10px;
    height: calc(100% - 6px);
    position: absolute;
    top: 0;
  }
  &:before {
    border-right: none;
    left: 0;
  }
  &:after {
    border-left: none;
    right: 0;
  }
`;

const StageList = styled.ul`
  list-style: none;
  display: flex;
  justify-content: space-between;
  width: 55rem;
  margin: 1rem auto;

  a {
    display: block;
    width: 84px;
    height: 80px;
  }
`;

interface StageItemProps {
  disabled: boolean;
}

const StageListItem = styled.li`
  pointer-events: ${(p: StageItemProps) => (p.disabled ? 'none' : 'initial')};

  svg {
    .cls-1 {
      transition: 0.2s;
      fill: ${(p: StageItemProps) =>
        p.disabled ? Colors.gray4 : Colors.primary};
    }
    .cls-2 {
      transition: 0.2s;
      fill: white;
    }
  }

  &:hover {
    svg {
      .cls-1 {
        fill: white;
        stroke: ${Colors.primary};
        stroke-width: 5px;
      }
      .cls-2 {
        fill: ${Colors.primary};
      }
    }
  }
`;

const Link = styled(_Link)`
  text-decoration: none;
`;

export default StageSelector;
