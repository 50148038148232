import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  CreateTrainingMutation,
  useAddTrainingParticipantMutation,
  useChangeTrainingInformationMutation,
  useCreateTrainingMutation,
  useRemoveTrainingParticipantMutation,
} from '../../../api/__generated__/training.generated';
import {
  TrainingDocument,
  useTrainingData,
  useTrainingMembersData,
} from '../../../api/training';
import { useRetryableMutationWithUI } from '../../../lib/useRetryableMutationWithUI';
import {
  appActions,
  useSetLoadingState,
} from '../../../redux/actions/appActions';
import {
  useCurrentUser,
  useSelectedOrgId,
} from '../../../redux/selectors/authSelectors';
import { useAdminTrainingId } from '../../../redux/selectors/gameSelectors';
import { TrainingStatus } from '../../../types';
import AdminCommonBG from '../../admin/AdminCommonBG';
import { HeaderButton } from '../UIelements/AdminNavigation';
import AdminTrainingEditor, {
  date2UnixSec,
  setTime,
} from './AdminTrainingEditor';

const trainingCreator = (orgId: string): TrainingDocument => ({
  trainingId: 'create',
  name: '新規研修',
  status: TrainingStatus.Preparing,
  scheduledStartTime: setTime(new Date(), { hour: 0, minute: 0 }),
  scheduledEndTime: setTime(new Date(), { hour: 23, minute: 59 }),
  curriculums: [],
  place: '',
  orgId: orgId,
  createdBy: '',
  createdOn: new Date(),
  updatedOn: new Date(),
});

interface AdminTrainingEditViewProps {
  create?: boolean;
}
const AdminTrainingEditView: React.FC<AdminTrainingEditViewProps> = props => {
  const { create } = props;
  const dispatch = useDispatch();
  const user = useCurrentUser();
  const orgId = useSelectedOrgId();
  const email = user?.email;
  const navigate = useNavigate();
  const [training, setTraining] = React.useState<TrainingDocument | null>(null);
  const [participants, setParticipants] = React.useState<string[]>([]);
  const trainingId = useAdminTrainingId();
  const [initTraining] = useTrainingData(trainingId);
  const [initTrainingMembers] = useTrainingMembersData(trainingId);
  const [valid, setValid] = React.useState<boolean>(true);

  const setLoadingState = useSetLoadingState();

  React.useEffect(() => {
    if (create && orgId && !initTraining) {
      setTraining(trainingCreator(orgId));
    }

    if (create && initTraining) {
      initTraining.name = initTraining.name + 'のコピー';
      initTraining.status = TrainingStatus.Preparing;
    }
    if (initTraining !== undefined) {
      setTraining(initTraining);
    }
  }, [create, initTraining, orgId]);

  React.useEffect(() => {
    if (initTrainingMembers !== undefined) {
      setParticipants(initTrainingMembers.participants ?? []);
    }
  }, [initTrainingMembers]);

  const [changeTrainingInformation] = useChangeTrainingInformationMutation();

  const [addParticipant] = useAddTrainingParticipantMutation();

  const [removeParticipant] = useAddTrainingParticipantMutation();

  const onCreateTrainingCompleted = React.useCallback(
    (res: CreateTrainingMutation) => {
      setLoadingState({
        visible: false,
      });
      navigate(`/admin/training`);
    },
    [navigate, setLoadingState]
  );

  const [createTraining] = useRetryableMutationWithUI(
    useCreateTrainingMutation,
    {
      hookOptions: {
        onCompleted: onCreateTrainingCompleted,
      },
    }
  );

  const onEditConfirm = React.useCallback(() => {
    if (training === null) {
      return;
    }

    if (create) {
      if (email == null) {
        return;
      }

      createTraining({
        variables: {
          input: {
            name: training.name,
            place: training.place,
            scheduledStartTime: date2UnixSec(training.scheduledStartTime),
            scheduledEndTime: date2UnixSec(training.scheduledEndTime),
            participantUids: participants,
            orgId: orgId ?? '',
            curriculums: training.curriculums,
          },
        },
      });
      return;
    }

    const operations: Promise<any>[] = [];
    operations.push(
      changeTrainingInformation({
        variables: {
          input: {
            trainingId: training.trainingId,
            name: training.name,
            scheduledStartTime: date2UnixSec(training.scheduledStartTime),
            scheduledEndTime: date2UnixSec(training.scheduledEndTime),
            place: training.place,
            orgId: orgId ?? '',
            curriculums: training.curriculums,
          },
        },
      })
    );

    // メンバー整理
    const originalParticipants = initTrainingMembers?.participants ?? [];
    const addedParticipants = participants.filter(
      p => !originalParticipants.includes(p)
    );
    const removedParticipants = originalParticipants.filter(
      (p: string) => !participants.includes(p)
    );

    if (addedParticipants.length > 0) {
      operations.push(
        addParticipant({
          variables: {
            input: {
              trainingId: training.trainingId,
              participantUids: addedParticipants,
            },
          },
        })
      );
    }
    if (removedParticipants.length > 0) {
      operations.push(
        removeParticipant({
          variables: {
            input: {
              trainingId: training.trainingId,
              participantUids: removedParticipants,
            },
          },
        })
      );
    }

    Promise.all(operations)
      .then(() => {
        dispatch(
          appActions.setErrorOverlayState({
            errorType: 'CommonError',
            message: 'スキルトレーニングの変更が保存されました。',
          })
        );
      })
      .catch(() => {
        dispatch(appActions.setErrorOverlayState({ errorType: 'CommonError' }));
      })
      .finally(() => {
        dispatch(appActions.setLoadingState({ visible: false }));
        window.location.reload();
      });
  }, [
    training,
    create,
    changeTrainingInformation,
    orgId,
    initTrainingMembers?.participants,
    participants,
    email,
    createTraining,
    addParticipant,
    removeParticipant,
  ]);

  const btnEdit: HeaderButton = {
    buttonText: create ? '作成する' : '更新する',
    event: onEditConfirm,
    disabled: !valid,
  };

  return (
    <AdminCommonBG
      backButton
      title={create ? 'イベント新規作成' : 'イベント編集'}
      headerButtons={[btnEdit]}
    >
      <AdminTrainingEditViewWrapper>
        {training && (
          <AdminTrainingEditor
            training={training}
            onUpdate={setTraining}
            participantUids={participants}
            onUpdateParticipantUids={setParticipants}
            changeValidCallback={v => {
              setValid(v);
            }}
          />
        )}
      </AdminTrainingEditViewWrapper>
    </AdminCommonBG>
  );
};

const AdminTrainingEditViewWrapper = styled.div``;

export default AdminTrainingEditView;
