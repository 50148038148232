import * as React from 'react';
import { ReactNode } from 'react';
import SVG, { Props as SVGProps } from 'react-inlinesvg';
import styled, { css } from 'styled-components';

import S_Icon_Communicate from '../../../static/svg/admin/category_communicate_h.svg';
import Icon_Communicate from '../../../static/svg/admin/category_communicate.svg';
import S_Icon_Create from '../../../static/svg/admin/category_create_h.svg';
import Icon_Create from '../../../static/svg/admin/category_create.svg';
import S_Icon_Energy from '../../../static/svg/admin/category_energy_h.svg';
import Icon_Energy from '../../../static/svg/admin/category_energy.svg';
import S_Icon_Program from '../../../static/svg/admin/category_program_h.svg';
import Icon_Program from '../../../static/svg/admin/category_program.svg';
import S_Icon_Switch from '../../../static/svg/admin/category_switch_h.svg';
import Icon_Switch from '../../../static/svg/admin/category_switch.svg';
import lock from '../../../static/svg/lock.svg';
import Colors from '../../../styles/colors';

interface SkillSubjectsProps {
  enabledSubject: boolean[];
  selectedSubject: boolean[];
  selectable?: boolean | false;
  onChanged?: (changed: boolean[]) => void;
}
const AdminSkillSubjects: React.FC<SkillSubjectsProps> = props => {
  const [showNote, setShowNote] = React.useState<boolean>(false);
  const [currentValues, setCurrentValues] = React.useState<boolean[]>(
    props.selectedSubject
  );

  React.useEffect(() => {
    props.onChanged?.(currentValues);
  }, [currentValues, props, props.onChanged]);

  const clicked = (n: number) => {
    if (props.selectable) {
      if (props.enabledSubject[n]) {
        const prev = currentValues;
        const next = [...prev];
        next[n] = !prev[n];
        setCurrentValues(next);
      } else {
        if (showNote == false) {
          setShowNote(true);
        }
      }
    }
  };

  return (
    <>
      <SkillSubjects selectable={props.selectable}>
        <SingleSkill
          enabled={props.enabledSubject[0]}
          selected={currentValues[0]}
          selectable={props.selectable}
        >
          <button onClick={() => clicked(0)}>
            <div>
              <SVG src={S_Icon_Switch} />
            </div>
            <div>
              <SVG src={Icon_Switch} />
            </div>
          </button>
          <span>
            <SVG src={lock} />
          </span>
        </SingleSkill>
        <SingleSkill
          enabled={props.enabledSubject[1]}
          selected={currentValues[1]}
          selectable={props.selectable}
        >
          <button onClick={() => clicked(1)}>
            <div>
              <SVG src={S_Icon_Program} />
            </div>
            <div>
              <SVG src={Icon_Program} />
            </div>
          </button>
          <span>
            <SVG src={lock} />
          </span>
        </SingleSkill>
        <SingleSkill
          enabled={props.enabledSubject[2]}
          selected={currentValues[2]}
          selectable={props.selectable}
        >
          <button onClick={() => clicked(2)}>
            <div>
              <SVG src={S_Icon_Create} />
            </div>
            <div>
              <SVG src={Icon_Create} />
            </div>
          </button>
          <span>
            <SVG src={lock} />
          </span>
        </SingleSkill>
        <SingleSkill
          enabled={props.enabledSubject[3]}
          selected={currentValues[3]}
          selectable={props.selectable}
        >
          <button onClick={() => clicked(3)}>
            <div>
              <SVG src={S_Icon_Energy} />
            </div>
            <div>
              <SVG src={Icon_Energy} />
            </div>
          </button>
          <span>
            <SVG src={lock} />
          </span>
        </SingleSkill>
        <SingleSkill
          enabled={props.enabledSubject[4]}
          selected={currentValues[4]}
          selectable={props.selectable}
        >
          <button onClick={() => clicked(4)}>
            <div>
              <SVG src={S_Icon_Communicate} />
            </div>
            <div>
              <SVG src={Icon_Communicate} />
            </div>
          </button>
          <span>
            <SVG src={lock} />
          </span>
        </SingleSkill>
      </SkillSubjects>
      <Note show={showNote}>
        このカリキュラムを利用するにはご購入が必要です。詳しくはお問合せください。
      </Note>
    </>
  );
};

interface noteProps {
  show: boolean;
}

const Note = styled.div`
  border-radius: 10px;
  background: ${Colors.gray2};
  transition: 0.3s;
  font-size: 1.4rem;

  ${(p: noteProps) =>
    p.show
      ? `
      padding: 10px 14px;
      margin-top: 5px;
      opacity: 1.0;
      transform: scaleY(1.0);
    `
      : `
      padding: 0px 14px;
      margin-top: 0px;
      opacity: 0.0;
      max-height: 0;
      transform: scaleY(0.0);
    `}
`;

interface cssProps {
  selectable?: boolean | false;
}
interface singleProps {
  enabled: boolean;
  selected: boolean;
  selectable?: boolean | false;
}

const SkillSubjects = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
  margin-top: 10px;

  ${(p: cssProps) =>
    p.selectable
      ? `
      button {
        cursor: pointer;
      }
      >div:nth-child(1) button {
        box-shadow: 0 5px 0 #0289e3;
      }
      >div:nth-child(2) button {
        box-shadow: 0 5px 0 #00B67F;
      }
      >div:nth-child(3) button {
        box-shadow: 0 5px 0 #D28400;
      }
      >div:nth-child(4) button {
        box-shadow: 0 5px 0 #C63583;
      }
      >div:nth-child(5) button {
        box-shadow: 0 5px 0 #6338DB;
      }`
      : ``}
`;

const SingleSkill = styled.div`
  flex: 1;
  position: relative;
  margin: 0 5px;
  margin-bottom: 10px;

  span {
    position: absolute;
    pointer-events: none;
    margin-left: calc(50% - 22px);
    top: 9px;

    svg {
      fill: #343a40;
      height: 40px;
      width: 44px;
    }
  }

  ${(p: singleProps) =>
    p.enabled
      ? `
      span {
        display: none;
      }
    `
      : `
  `}

  & button {
    background-color: ${Colors.gray2};
  }

  ${(p: singleProps) =>
    p.selected || p.enabled == false
      ? `
      &:nth-child(1) button {
        background-color: #40b1fc;
      }
      &:nth-child(2) button {
        background-color: #03f2aa;
      }
      &:nth-child(3) button {
        background-color: #ffb943;
      }
      &:nth-child(4) button {
        background-color: #ff6ebb;
      }
      &:nth-child(5) button {
        background-color: #956fff;
      }
    `
      : `
      button svg {
        opacity: 0.6;
      }
  `}

  button {
    transition: 0.2s;
    width: 100%;
    border-radius: 10px;
    border: none;
    height: 60px;
    display: flex;
    align-items: center;
    padding: 6px 20px;

    > div:first-child {
      display: block;
    }
    > div:nth-child(2) {
      display: none;
      border: 2px solid #343a40;
      border-radius: 99px;
      width: 36px;
      height: 36px;
    }

    /*
    @media screen and (max-width: 1540px) {
      justify-content: center;

      > div:first-child {
        display: none;
      }
      > div:nth-child(2) {
        display: block;
      }
    }
    */

    ${(p: singleProps) =>
      p.enabled
        ? `
        opacity: 1.0;
      `
        : `
        opacity: 0.4;
    `}

    ${(p: singleProps) =>
      p.selectable && p.selected == false && p.enabled
        ? `
        box-shadow: 0 5px 0 #CCC !important;`
        : `
      `}
  }
`;

export default AdminSkillSubjects;
