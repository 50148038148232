import firebase from 'firebase/app';
import * as React from 'react';
import { useDispatch } from 'react-redux';

import { isCustomClaimContent } from '../../api/firestoreTypes';
import * as AuthActions from '../../redux/actions/authActions';

interface AuthStateListenerProps {}
const AuthStateListener: React.FC<AuthStateListenerProps> = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    firebase.auth().onAuthStateChanged(user => {
      dispatch(AuthActions.setUser({ user }));
      user?.getIdTokenResult(true).then(result => {
        const claimContent = result.claims;
        if (!isCustomClaimContent(claimContent)) return;
        dispatch(
          AuthActions.setUserCustomClaim({
            claimContent,
          })
        );

        const orgIds = Object.keys(claimContent.roles);

        if (orgIds.length > 0) {
          /*
          dispatch(
            AuthActions.setSelectedOrgId({
              orgId: orgIds[0],
            })
          );*/
        }
      });
    });
  }, [dispatch]);

  return null;
};
export default AuthStateListener;
