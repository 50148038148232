import { SubButton } from '@riddler-co-jp/specc-ui-components';
import firebase from 'firebase/app';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { Link, useMatch, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { appActions } from '../../redux/actions/appActions';
import {
  useCurrentUser,
  useIsUserEmailVerified,
} from '../../redux/selectors/authSelectors';
import HomeImg from '../../static/svg/Icon-Home.svg';
import GearImg from '../../static/svg/Icon-Setting.svg';
import LogoImgCt from '../../static/svg/Specc-Logo-Ct.svg';
import LogoImgSt from '../../static/svg/Specc-Logo-St.svg';
import LogoImg from '../../static/svg/Specc-Logo.svg';
import Colors from '../../styles/colors';

export type LogoType = 'SPECC' | 'ST' | 'CT';

export type HeaderButtonType =
  | 'toLogin'
  | 'toRegister'
  | 'toMyPage'
  | 'logout'
  | 'close';
export interface HeaderOptions {
  buttonType?: HeaderButtonType;
  showEmail?: boolean;
  hideHomeButton?: boolean;
  hideSettingButton?: boolean;
  logoType?: LogoType;
}

interface HeaderProps {
  options?: HeaderOptions;
}
const Header: React.FC<HeaderProps> = props => {
  const navigate = useNavigate();
  const user = useCurrentUser();

  const goToMyPage = React.useCallback(() => {
    navigate('/');
  }, [navigate]);
  const goToLogin = React.useCallback(() => {
    navigate('/');
  }, [navigate]);
  const goToSettings = React.useCallback(() => {
    navigate('/settings/profile');
  }, [history]);

  const signOut = React.useCallback(() => {
    navigate('/logout');
  }, [navigate]);

  const isStageSelect = useMatch('/events/:eventId/stages') !== null;
  const emailVerified = useIsUserEmailVerified();
  const dispatch = useDispatch();
  return (
    <HeaderWrapper red={props.options?.logoType == 'ST'}>
      <LogoWrapper>
        {props.options?.hideHomeButton === true ? (
          <></>
        ) : (
          <HeaderBtn>
            {isStageSelect ? (
              <SVG
                src={HomeImg}
                onClick={() => {
                  dispatch(
                    appActions.setErrorOverlayState({
                      errorType: 'CommonError',
                      message: 'ホームに戻りますか？（進捗は保存されます）',
                      backButtonText: 'いいえ',
                      retryButtonText: 'はい',
                      onRetry: () => {
                        navigate('/');
                      },
                    })
                  );
                }}
              />
            ) : (
              <Link to={'/'}>
                <SVG src={HomeImg} />
              </Link>
            )}
          </HeaderBtn>
        )}
        <Link to={'/'}>
          {props.options?.logoType == 'CT' ? (
            <LogoSVG src={LogoImgCt} />
          ) : props.options?.logoType == 'ST' ? (
            <LogoSVG src={LogoImgSt} />
          ) : (
            <LogoSVG src={LogoImg} />
          )}
        </Link>
      </LogoWrapper>

      <RightContents>
        {props.options?.showEmail && <SubText>{user?.email ?? ''} </SubText>}
        {emailVerified && props.options?.hideSettingButton !== true && (
          <HeaderBtn onClick={goToSettings}>
            <SVG src={GearImg} />
          </HeaderBtn>
        )}
        {props.options?.buttonType === 'toMyPage' && (
          <SubButton size='medium' color='neutral' onClick={goToMyPage}>
            マイページに戻る
          </SubButton>
        )}
        {props.options?.buttonType === 'toLogin' && (
          <>
            <SubText>すでに登録がお済みの方はこちらから</SubText>
            <SubButton size='medium' color='neutral' onClick={goToLogin}>
              ログイン
            </SubButton>
          </>
        )}
        {props.options?.buttonType === 'logout' && (
          <SubButton size='medium' color='neutral' onClick={signOut}>
            ログアウト
          </SubButton>
        )}
        {props.options?.buttonType === 'close' && (
          <SubButton
            size='medium'
            color='neutral'
            onClick={() => {
              window.close();
            }}
          >
            閉じる
          </SubButton>
        )}
      </RightContents>
    </HeaderWrapper>
  );
};

const LogoSVG = styled(SVG)`
  margin: -25px 0;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  svg .cls-1 {
    fill: ${Colors.primary};
  }
`;

const Logo = styled.svg`
  height: 2.6rem;
  display: inline-block;
  margin-right: 2rem;
`;

const Home = styled.img`
  height: 2.6rem;
  display: inline-block;
  margin-right: 2rem;
`;

const SubText = styled.span`
  display: inline-block;
  color: ${Colors.gray8};
  margin-right: 1rem;
  font-size: 1.5rem;
  vertical-align: text-bottom;
`;

const RightContents = styled.div`
  display: flex;
  align-items: center;
  text-align: right;
`;

interface TypeProps {
  red: boolean;
}

const HeaderWrapper = styled.div`
  padding: 2rem 0;
  ${(p: TypeProps) => {
    return `
      border-bottom: ${p.red ? '#FF5414' : Colors.primary} 3px solid;
    `;
  }}
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  margin: 0 4rem;
`;

const HeaderBtn = styled.div`
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-right: 2rem;

  svg .cls-1 {
    fill: ${Colors.gray5};
    transition: 0.2s;
  }

  &:hover {
    svg .cls-1 {
      fill: ${Colors.primary};
    }
  }
`;

const AdminUserInfo = styled.div`
  background: white;
  width: 160px;
  padding: 0.8rem 1.4em;
  border-radius: 6px;
  margin: 0.2rem 0;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  cursor: pointer;

  border: 1px solid ${Colors.gray4};

  span {
    flex: 1;
    text-align: left;
    display: block;
  }

  &:hover {
    background: ${Colors.gray4};
  }
`;

const AdminBadge = styled.span`
  white-space: nowrap;
  padding: 0.4rem 0.6rem;
  font-size: 1.4rem;
  font-weight: bold;
  border-radius: 4px;
  color: ${Colors.primary};
  border: 2px solid ${Colors.primary};
`;
export default Header;
