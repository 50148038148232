import { TrainingWithMembersNonNull } from '../../../../api/training';

export const adminTrainingsSorter: any = {
  scheduledStartTime: {
    ASC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      return (
        a.training.scheduledStartTime.getTime() -
        b.training.scheduledStartTime.getTime()
      );
    },
    DESC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      return (
        b.training.scheduledStartTime.getTime() -
        a.training.scheduledStartTime.getTime()
      );
    },
  },
  name: {
    ASC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      return a.training.name < b.training.name ? -1 : 1;
    },
    DESC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      return a.training.name > b.training.name ? -1 : 1;
    },
  },
  status: {
    ASC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      return b.training.status < a.training.status ? -1 : 1;
    },
    DESC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      return b.training.status > a.training.status ? -1 : 1;
    },
  },
  place: {
    ASC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      if (a.training.place === null) a.training.place = '';
      if (b.training.place === null) b.training.place = '';
      return a.training.place < b.training.place ? -1 : 1;
    },
    DESC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      if (a.training.place === null) a.training.place = '';
      if (b.training.place === null) b.training.place = '';
      return a.training.place > b.training.place ? -1 : 1;
    },
  },
  subject: {
    ASC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      return a.training.curriculums.length < b.training.curriculums.length
        ? -1
        : 1;
    },
    DESC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      return a.training.curriculums.length > b.training.curriculums.length
        ? -1
        : 1;
    },
  },
  participantsCount: {
    ASC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      const aValue = a.members.participants.length;
      const bValue = b.members.participants.length;
      return aValue - bValue;
    },
    DESC: (a: TrainingWithMembersNonNull, b: TrainingWithMembersNonNull) => {
      const aValue = a.members.participants.length;
      const bValue = b.members.participants.length;
      return bValue - aValue;
    },
  },
};
