import * as React from 'react';
import { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import SettingsItemTitle from '../SettingsItemTitle';

interface TermViewProps {}
const TermView: React.FC<TermViewProps> = props => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const location = useLocation();

  return (
    <>
      <SettingsItemTitle>{'利用規約'}</SettingsItemTitle>
      <TermStyle>
        <p>
          この利用規約は、RIDDLER株式会社（以下「当社」といいます）が提供する企業向け研修プログラムサービス「SPECCスキル研修」についての利用者の利用条件を定めるものです。
        </p>
        <h2>第1条（定義）</h2>
        <ol>
          <li>本規約で使用する用語の定義は以下のとおりです。</li>
          <ul>
            <li>
              (1)関連規約：当社が本規約のほかに定める本サービスに関連する規約及び当社サイト上で別途定める利用条件等の総称をいいます。
            </li>
            <li>
              (2)承諾通知：当社が当社所定の方法で利用者に対して行う利用申込を承諾する旨の通知をいいます。
            </li>
            <li>(3)本規約：本サービスの利用規約をいいます。</li>
            <li>
              (4)本研修プログラム：当社が利用者に対して提供する本サービスを構成する研修プログラムをいいます。
            </li>
            <li>
              (5)本サービス：当社が提供する企業向け研修プログラムサービス「SPECCスキル研修」をいいます。
            </li>
            <li>
              (6)本サービス利用料：利用者が当社に支払う本サービスの利用料をいいます。
            </li>
            <li>
              (7)利用契約：利用者が当社と締結する本サービスの利用契約をいいます。
            </li>
            <li>
              (8)利用者：当社との間で本サービスの利用契約を締結し、本サービスを利用する者をいいます。
            </li>
            <li>
              (9)利用者情報：利用者が利用申込時及び本サービス利用時に当社に提供する利用者に関する情報の総称をいいます。
            </li>
            <li>
              (10)利用申込：利用者の当社に対する本サービスの利用契約の申込をいいます。
            </li>
          </ul>
          <li>
            本規約の用語の定義は前項で定める定義が優先的に適用されるものとし、前項で定義されていない本規約上の用語の定義は関連法令及び慣習に従うものとします。
          </li>
        </ol>
        <h2>第2条（本規約の適用範囲）</h2>
        <p>
          本規約は、本サービスの提供条件及び本サービスの利用に関する当社と利用者との間の権利義務関係を定めることを目的とし、利用者による本サービスの利用に関する一切の関係に適用されます。
        </p>
        <h2>第3条（本規約への同意）</h2>
        <p>
          利用者は、本規約及び関連規約に同意した上で、利用申込及び本サービスの利用をするものとします。利用者は、本規約に同意することなしに利用申込及び本サービスの利用をすることはできず、利用者が利用申込をした場合は、当社と利用者との間で法的拘束力を有する契約としての本規約に同意したものとみなされます。
        </p>
        <h2>第4条（本サービス）</h2>
        <ol>
          <li>
            本サービスは、当社と利用者との間で別段の合意がない限り、当社が利用者に対し、利用者が本サービスを利用するために必要となる本研修プログラムを提供することのみを内容するものであり、当社が本サービスを提供するにあたり行う業務は、原則として、利用者に対する本研修プログラムの提供及び本サービスに関する利用者からの問合せ対応に限られるものとします。本サービスの詳細は当社サイトをご確認ください。
          </li>
          <li>
            本サービスの内容及び仕様等については、別途当社が定めるものとします。
          </li>
          <li>
            本サービスの提供に必要となるインターネット等の環境は、利用者の費用及び責任において用意するものとします。
          </li>
        </ol>
        <h2>第5条（利用申込）</h2>
        <ol>
          <li>
            利用者は、第3条に基づき、本規約及び関連規約に同意した上で、当社サイト上に掲載する手続その他当社所定の手続に従って利用申込を行うものとし、正確且つ最新の利用者情報を当社所定の申込フォームに記載して当社に提供するものとします。
          </li>
          <li>
            利用申込資格の詳細は、当社が当社ウェブサイト等に別途定めるものとします。
          </li>
        </ol>
        <h2>第6条（利用申込の承諾及びアカウント・パスワード）</h2>
        <ol>
          <li>
            当社は、当社所定の方法により前条の利用申込を審査した上で、当該利用申込を承諾する場合は、当該利用申込を行った利用者に対し、本サービスの利用申込を承諾する旨その他本サービスに関する必要事項を記載した承諾通知を行うものとします。本サービスの利用契約は、当社が利用者に承諾通知を行った時点で成立するものとします。
          </li>
          <li>
            当社は、前項に基づき利用者との間に本サービスの利用契約が成立した場合は、当該利用者の本サービスのアカウントを作成するものとします。なお、本サービスのアカウントのパスワードは、利用者が自ら設定するものとします。
          </li>
          <li>
            利用者は、本サービスのアカウント及びパスワード等を第三者に利用させ、又は、貸与、譲渡、売買、質入等をすることはできないものとします。
          </li>
          <li>
            利用者は、本サービスのアカウント及びパスワード等を、第三者に知られたり利用されたりしないように、利用者の責任で管理するものとします。利用者は、本サービスのアカウント及びパスワード等を第三者に知られた場合その他第三者に使用されるおそれがある場合は、直ちにその旨を連絡するとともに、当社の指示がある場合にはこれに従うものとします。
          </li>
          <li>
            本サービスのアカウント又はパスワード等の管理不十分、使用上の過誤、第三者の使用等によって利用者又は第三者に生じた一切の損害は利用者が負うものとし、当社は、当社の責に帰すべき事由によるものを除き、一切責任を負わないものとします。
          </li>
        </ol>
        <h2>第7条（利用料の支払い）</h2>
        <ol>
          <li>本サービス利用料は、当社が別途定めるものとします。</li>
          <li>
            利用者は、当社が別途定める支払期限までに、当社所定の方法により本サービス利用料を支払うものとします。なお、振込手数料その他本サービス利用料の支払いに要する費用は利用者の負担とします。
          </li>
          <li>
            利用者が、前項の支払期限までに本サービス利用料を支払わなかった場合は、当該支払期限の経過をもって、利用契約は解除されたものとみなすものとします。
          </li>
        </ol>
        <h2>第8条（利用申込の変更・取消し）</h2>
        <ol>
          <li>
            利用者は、利用者情報等を変更する場合は、当社所定の方法により、利用者情報等の変更手続を行うものとします。
          </li>
          <li>
            利用者は、前条に基づき本サービス利用料を当社に支払った後は、理由の如何を問わず、利用契約を解除又は取消す（キャンセルする）ことはできないものとし、当社は、理由の如何を問わず、利用者から受領した本サービス利用料を返還しないものとします。
          </li>
        </ol>
        <h2>第9条（利用期間等）</h2>
        本サービスの利用時期及び利用期間は、当社が別途定めるとおりとします。
        <h2>第10条（コンテンツに関する権利帰属）</h2>
        <ol>
          <li>
            本サービスに関するコンテンツ（本研修プログラムを含みますがこれらに限られず、動画、画像、文章、音声、音楽、ソフトウェア、プログラム、コードその他の有形及び無形の一切の制作物を含みます）に関する著作権等の知的財産権その他一切の権利は、当社に帰属します。
          </li>
          <li>
            利用者は、本サービスに関する全てのコンテンツを、当社の事前の承諾なしに、本規約及び本サービスで明示的に許容されている方法を超えて利用することはできないものとします。
          </li>
        </ol>
        <h2>第11条（お問合せ）</h2>
        当社は、本サービスの利用について、原則として、利用者からの電話及びFAXによるお問合せは受け付けておりません。利用者から当社に対するお問合せは、当社のお問合せ用メールアドレス（specctraining@riddler.co.jp）宛てに行ってください。
        <h2>第12条（禁止事項等）</h2>
        <ol>
          <li>
            利用者は、本サービスの利用にあたって以下の行為を行ってはならないものとします。
          </li>
          <ul>
            <li>(1)利用申込時等に当社に虚偽の情報を提供する行為</li>
            <li>(2)第三者の情報を無断で使用して本サービスを利用する行為</li>
            <li>
              (3)第三者にアカウント及びパスワードを使用させる行為又は本サービスを使用させる行為
            </li>
            <li>
              (4)本研修プログラムに含まれる内容を複製、翻案（改変）、公衆送信、公開、公表等（本研修プログラムを転売又は転用する行為を含みますが、これらに限られません。また、紙媒体・電磁的媒体での配布、インターネット、SNS、ソーシャルメディア等への投稿その他方法・媒体の如何を問いません）する行為
            </li>
            <li>
              (5)当社から提供された本研修プログラムを本サービスの実施以外の目的で使用する行為
            </li>
            <li>
              (6)当社又は第三者の著作権等の知的財産権その他の権利を侵害し、制限し又は妨害する行為
            </li>
            <li>(7)本サービスの提供や運営を妨害し又はこれらに支障を来す行為</li>
            <li>
              (8)本サービスのネットワーク又はシステムに過度な負荷をかける行為
            </li>
            <li>
              (9)本サービスの変更、修正、又は、逆アセンブル、逆コンパイル、リバースエンジニアリングその他本サービスのソースコードを解析する行為
            </li>
            <li>
              (10)本サービスに接続するシステム全般に不正にアクセスする行為
            </li>
            <li>(11)当社又は第三者に損害を与える行為</li>
            <li>(12)当社又は第三者の名誉や信用を毀損し又は低下させる行為</li>
            <li>(13)法令又は公序良俗に反する行為</li>
            <li>
              (14)本規約、関連規約その他当社が規定した利用条件や手続等に違反する行為
            </li>
            <li>
              (15)前各号の行為に準じる行為及び前各号の行為に該当するおそれがある行為
            </li>
            <li>
              (16)その他本サービスの趣旨に鑑み、当社が不適切だと判断する行為
            </li>
          </ul>
          <li>
            前項第3号の規定にかかわらず、利用者は、本研修プログラムによる診断成績その他の診断結果に限り、公開又は公表することができるものとします。
          </li>
        </ol>
        <h2>第13条（利用契約の解除）</h2>
        <p>
          当社は、利用者が以下のいずれかに該当するものと当社が判断した場合は、催告等の手続を要せず、当該利用者との間の利用契約を解除することができるものとします。契約解除により利用者に損害が生じた場合においても当社は当該損害について一切責任を負わないものとします。
        </p>
        <ul>
          <li>
            (1)利用申込時に当社に提供した情報等に虚偽の内容が含まれていた場合
          </li>
          <li>
            (2)本規約又は関連規約に違反したことにより、過去に利用契約を解除されたことがある場合
          </li>
          <li>
            (3)不正な目的その他本サービスの趣旨に反する目的で本サービスを利用し又は利用しようとした場合
          </li>
          <li>
            (4)利用申込時に当社に提供した情報の変更等によって、利用者と連絡がとれなくなった場合
          </li>
          <li>
            (5)当社又は第三者の知的財産権、人格権等その他の権利を侵害した場合
          </li>
          <li>(6)前条の禁止行為を行った場合</li>
          <li>(7)本規約又は関連規約の一に違反した場合</li>
          <li>
            (8)合理的な理由によって、利用者による本サービスの利用が不適切であると当社が判断した場合
          </li>
        </ul>
        <h2>第14条（利用者に対する通知）</h2>
        <ol>
          <li>
            当社の利用者に対する通知は、別段の定めのある場合を除き、当社から利用者に対して電子メールを送信する方法又は当社サイト上に掲示する方法その他当社所定の方法により行われるものとします。
          </li>
          <li>
            前項の通知が電子メールを送信する方法で行なわれる場合には、利用者が利用申込時に登録した電子メールアドレスへの当社からの発信をもって通知が完了したものとみなします。利用者情報が正確又は最新でなかった場合は、当社からの通知が不到達となっても、本項に定める時点で到達したとみなされるものとします。
          </li>
          <li>
            第1項の通知が当社サイト上に掲示する方法で行なわれる場合は、当該通知が当社サイト上に掲示された時点をもって利用者への通知が完了したものとみなします。
          </li>
        </ol>
        <h2>第15条（個人情報・利用者情報の取扱い）</h2>
        <p>
          当社は、当社が別途定めるプライバシーポリシーに基づき、利用者の個人情報その他の利用者情報等を適切に取り扱います。詳細は
          <Link
            to={
              location.pathname === '/dialog/term'
                ? '/dialog/privacy'
                : '../privacy'
            }
            relative={'route'}
          >
            プライバシーポリシー
          </Link>
          をご参照ください。
        </p>
        <h2>第16条（損害賠償等）</h2>
        <ol>
          <li>
            利用者が本規約若しくは関連規約に違反したことにより又は本サービス若しくは本サービスに関連して、当社に対して損害を生じさせた場合は、利用者は、当社に対し、当社に生じた一切の損害を賠償するものとします。
          </li>
          <li>
            本サービス又は本サービスに関連して、利用者と第三者との間で紛争が生じた場合は、利用者は、利用者の責任と負担で、当該紛争を解決するものとし、当該紛争に関連して当社に損害が生じた場合は、当社に生じた一切の損害を賠償するものとします。
          </li>
        </ol>
        <h2>第17条（当社の責任）</h2>
        <ol>
          <li>
            当社は、利用者が本サービスの利用に関して被った損害については、債務不履行責任、不法行為責任その他の法律上の責任を問わず賠償の責任を負わないものとします。但し、利用者が本サービスの利用に関して当社の故意又は重大な過失により損害を被った場合については、この限りではありません。当社が利用者に対して賠償の責任を負う場合でも、損害賠償の累計総額は、債務不履行、不法行為その他請求原因のいかんにかかわらず、当該損害の発生に直接起因する利用契約に基づき当社が利用者から現実に受領した本サービス利用料の金額を上限とするものとします。
          </li>
          <li>
            本サービスの利用に関連して利用者又は第三者に発生した問題・紛争等については、利用者の責任と負担においてこれを解決するものとし、これらについて当社は一切責任を負わないものとします。
          </li>
        </ol>
        <h2>第18条（免責）</h2>
        <ol>
          <li>
            利用者は、本サービスの利用により提供される本研修プログラム等を自己の責任において利用するものとします。当社は、本サービスが利用者の要求を満足させるものであることについて何ら保証を行いません。また、本サービス及びその利用によりもたらされる成果の的確性、正確性、信頼性並びにその他一切に関して何らの保証を行いません。
          </li>
          <li>
            停電、通信回線の事故・天災等の不可抗力、通信事業者の不履行、インターネットインフラその他サーバー等のシステム上の不具合、緊急メンテナンスの発生などにより利用者による本サービスの利用が不能となった場合、当社はその責を負わないものとします。
          </li>
        </ol>
        <h2>第19条（反社会的勢力の排除）</h2>
        <ol>
          <li>
            利用者は、過去5年間、現在及び将来にわたって、次の各号のいずれかに該当しないことを表明し確約します。
          </li>
          <ul>
            <li>(1)暴力団</li>
            <li>(2)暴力団構成員・準構成員</li>
            <li>(3)暴力団関係企業</li>
            <li>
              (4)その他反社会的な行為や反社会的な行為により利益を得ることを目的とする個人又は団体及びその構成員
            </li>
            <li>(5)暴力団に関与し、又は交流を持つ者</li>
            <li>(6)前各号に準ずる者</li>
          </ul>
          <li>
            当社は、利用者が前各号の一に該当すると認める場合には、何らの催告を要することなく、当該利用者への本サービスの提供を中止し、利用契約を解除することができます。
          </li>
          <li>
            当社が、前項の規定により利用契約を解除した場合には、当社はそれにより利用者に生じた損害の一切について賠償する義務を負わず、また、利用者は、当社に生じた一切の損害を賠償するものとします。
          </li>
        </ol>
        <h2>第20条（本サービスの変更）</h2>
        <p>
          当社は、本サービス内容の決定、変更、維持、中止等に関する一切の権限を有します。当社が必要と判断した場合、当社は、本サービスの全部又は一部を廃止、変更、中止することができるものとします。
        </p>
        <h2>第21条（権利義務の譲渡禁止）</h2>
        <p>
          利用者は、本サービスの利用によって発生した全ての権利義務を第三者に譲渡、移転することや、担保権の設定などを行うことはできません。
        </p>
        <h2>第22条（本規約の効力と変更）</h2>
        <ol>
          <li>
            当社は、以下のいずれかに該当する場合、本規約の全部又は一部を任意に変更することができます。
          </li>
          <ul>
            <li>(1) 本規約の変更が、利用者の一般の利益に適合するとき</li>
            <li>
              (2)
              本規約の変更が、本規約所定の契約をした目的に反せず、かつ、変更の必要性、変更後の内容の相当性、変更の内容その他の変更に係る事情に照らして合理的なものであるとき
            </li>
          </ul>
          <li>
            当社が前項に基づき本規約を変更する場合は、当社は、事前に、変更後の本規約の内容及びその効力発生時期その他必要な事項を当社サイト上に掲示する方法その他適切な方法で周知するものとします。
          </li>
          <li>
            変更された本規約は当社が別途定める場合を除き、当社サイト上で掲示された時点で効力が発生します。
          </li>
          <li>
            変更された本規約の効力が発生した後に利用者が本サービスを利用する場合は、変更された本規約に同意したものとみなします。
          </li>
        </ol>
        <h2>第23条（専属的合意管轄）</h2>
        <p>
          本規約及び関連規約の準拠法は日本法とし、本規約、関連規約、本サービス又は本サービスに起因又は関連して利用者と当社との間に生じた一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
        <Margin />
        <p>制定：2021年9月1日</p>
      </TermStyle>
    </>
  );
};

const TermStyle = styled.div`
  font-size: 1.5rem;

  h2 {
    margin-bottom: 2rem;
  }
  p {
    margin-bottom: 2rem;
  }

  li {
    margin: 0 3rem;
    margin-bottom: 2rem;
  }

  ol ul {
    padding-left: 2rem;
  }
`;
const Margin = styled.div`
  height: 2rem;
`;

export default TermView;
