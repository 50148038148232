import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetTrainingParticipantUserDataQueryVariables = Types.Exact<{
  input: Types.GetTrainingParticipantUserDataInput;
}>;


export type GetTrainingParticipantUserDataQuery = (
  { __typename?: 'Query' }
  & { getTrainingParticipantUserData: (
    { __typename?: 'UserData' }
    & Pick<Types.UserData, 'uid' | 'displayName' | 'email'>
  ) }
);

export type CreateTrainingMutationVariables = Types.Exact<{
  input: Types.CreateTrainingInput;
}>;


export type CreateTrainingMutation = (
  { __typename?: 'Mutation' }
  & { createTraining: (
    { __typename?: 'CreateTrainingResult' }
    & Pick<Types.CreateTrainingResult, 'trainingId'>
  ) }
);

export type RemoveTrainingMutationVariables = Types.Exact<{
  input: Types.TrainingInput;
}>;


export type RemoveTrainingMutation = (
  { __typename?: 'Mutation' }
  & { removeTraining: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);

export type ChangeTrainingStatusMutationVariables = Types.Exact<{
  input: Types.TrainingStatusInput;
}>;


export type ChangeTrainingStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeTrainingStatus?: Types.Maybe<(
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  )> }
);

export type ChangeTrainingInformationMutationVariables = Types.Exact<{
  input: Types.TrainingInformationInput;
}>;


export type ChangeTrainingInformationMutation = (
  { __typename?: 'Mutation' }
  & { changeTrainingInformation: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);

export type AddTrainingParticipantMutationVariables = Types.Exact<{
  input: Types.TrainingParticipantInput;
}>;


export type AddTrainingParticipantMutation = (
  { __typename?: 'Mutation' }
  & { addTrainingParticipant: (
    { __typename?: 'AddTrainingParticipantResult' }
    & Pick<Types.AddTrainingParticipantResult, 'rejectedUids'>
  ) }
);

export type RemoveTrainingParticipantMutationVariables = Types.Exact<{
  input: Types.TrainingParticipantInput;
}>;


export type RemoveTrainingParticipantMutation = (
  { __typename?: 'Mutation' }
  & { removeTrainingParticipant: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);


export const GetTrainingParticipantUserDataDocument = gql`
    query getTrainingParticipantUserData($input: GetTrainingParticipantUserDataInput!) {
  getTrainingParticipantUserData(input: $input) {
    uid
    displayName
    email
  }
}
    `;

/**
 * __useGetTrainingParticipantUserDataQuery__
 *
 * To run a query within a React component, call `useGetTrainingParticipantUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTrainingParticipantUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTrainingParticipantUserDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetTrainingParticipantUserDataQuery(baseOptions: Apollo.QueryHookOptions<GetTrainingParticipantUserDataQuery, GetTrainingParticipantUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTrainingParticipantUserDataQuery, GetTrainingParticipantUserDataQueryVariables>(GetTrainingParticipantUserDataDocument, options);
      }
export function useGetTrainingParticipantUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTrainingParticipantUserDataQuery, GetTrainingParticipantUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTrainingParticipantUserDataQuery, GetTrainingParticipantUserDataQueryVariables>(GetTrainingParticipantUserDataDocument, options);
        }
export type GetTrainingParticipantUserDataQueryHookResult = ReturnType<typeof useGetTrainingParticipantUserDataQuery>;
export type GetTrainingParticipantUserDataLazyQueryHookResult = ReturnType<typeof useGetTrainingParticipantUserDataLazyQuery>;
export type GetTrainingParticipantUserDataQueryResult = Apollo.QueryResult<GetTrainingParticipantUserDataQuery, GetTrainingParticipantUserDataQueryVariables>;
export const CreateTrainingDocument = gql`
    mutation createTraining($input: CreateTrainingInput!) {
  createTraining(input: $input) {
    trainingId
  }
}
    `;
export type CreateTrainingMutationFn = Apollo.MutationFunction<CreateTrainingMutation, CreateTrainingMutationVariables>;

/**
 * __useCreateTrainingMutation__
 *
 * To run a mutation, you first call `useCreateTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrainingMutation, { data, loading, error }] = useCreateTrainingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTrainingMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrainingMutation, CreateTrainingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrainingMutation, CreateTrainingMutationVariables>(CreateTrainingDocument, options);
      }
export type CreateTrainingMutationHookResult = ReturnType<typeof useCreateTrainingMutation>;
export type CreateTrainingMutationResult = Apollo.MutationResult<CreateTrainingMutation>;
export type CreateTrainingMutationOptions = Apollo.BaseMutationOptions<CreateTrainingMutation, CreateTrainingMutationVariables>;
export const RemoveTrainingDocument = gql`
    mutation removeTraining($input: TrainingInput!) {
  removeTraining(input: $input) {
    success
  }
}
    `;
export type RemoveTrainingMutationFn = Apollo.MutationFunction<RemoveTrainingMutation, RemoveTrainingMutationVariables>;

/**
 * __useRemoveTrainingMutation__
 *
 * To run a mutation, you first call `useRemoveTrainingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTrainingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTrainingMutation, { data, loading, error }] = useRemoveTrainingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTrainingMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTrainingMutation, RemoveTrainingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTrainingMutation, RemoveTrainingMutationVariables>(RemoveTrainingDocument, options);
      }
export type RemoveTrainingMutationHookResult = ReturnType<typeof useRemoveTrainingMutation>;
export type RemoveTrainingMutationResult = Apollo.MutationResult<RemoveTrainingMutation>;
export type RemoveTrainingMutationOptions = Apollo.BaseMutationOptions<RemoveTrainingMutation, RemoveTrainingMutationVariables>;
export const ChangeTrainingStatusDocument = gql`
    mutation changeTrainingStatus($input: TrainingStatusInput!) {
  changeTrainingStatus(input: $input) {
    success
  }
}
    `;
export type ChangeTrainingStatusMutationFn = Apollo.MutationFunction<ChangeTrainingStatusMutation, ChangeTrainingStatusMutationVariables>;

/**
 * __useChangeTrainingStatusMutation__
 *
 * To run a mutation, you first call `useChangeTrainingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTrainingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTrainingStatusMutation, { data, loading, error }] = useChangeTrainingStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTrainingStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTrainingStatusMutation, ChangeTrainingStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTrainingStatusMutation, ChangeTrainingStatusMutationVariables>(ChangeTrainingStatusDocument, options);
      }
export type ChangeTrainingStatusMutationHookResult = ReturnType<typeof useChangeTrainingStatusMutation>;
export type ChangeTrainingStatusMutationResult = Apollo.MutationResult<ChangeTrainingStatusMutation>;
export type ChangeTrainingStatusMutationOptions = Apollo.BaseMutationOptions<ChangeTrainingStatusMutation, ChangeTrainingStatusMutationVariables>;
export const ChangeTrainingInformationDocument = gql`
    mutation changeTrainingInformation($input: TrainingInformationInput!) {
  changeTrainingInformation(input: $input) {
    success
  }
}
    `;
export type ChangeTrainingInformationMutationFn = Apollo.MutationFunction<ChangeTrainingInformationMutation, ChangeTrainingInformationMutationVariables>;

/**
 * __useChangeTrainingInformationMutation__
 *
 * To run a mutation, you first call `useChangeTrainingInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeTrainingInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeTrainingInformationMutation, { data, loading, error }] = useChangeTrainingInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeTrainingInformationMutation(baseOptions?: Apollo.MutationHookOptions<ChangeTrainingInformationMutation, ChangeTrainingInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeTrainingInformationMutation, ChangeTrainingInformationMutationVariables>(ChangeTrainingInformationDocument, options);
      }
export type ChangeTrainingInformationMutationHookResult = ReturnType<typeof useChangeTrainingInformationMutation>;
export type ChangeTrainingInformationMutationResult = Apollo.MutationResult<ChangeTrainingInformationMutation>;
export type ChangeTrainingInformationMutationOptions = Apollo.BaseMutationOptions<ChangeTrainingInformationMutation, ChangeTrainingInformationMutationVariables>;
export const AddTrainingParticipantDocument = gql`
    mutation addTrainingParticipant($input: TrainingParticipantInput!) {
  addTrainingParticipant(input: $input) {
    rejectedUids
  }
}
    `;
export type AddTrainingParticipantMutationFn = Apollo.MutationFunction<AddTrainingParticipantMutation, AddTrainingParticipantMutationVariables>;

/**
 * __useAddTrainingParticipantMutation__
 *
 * To run a mutation, you first call `useAddTrainingParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrainingParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrainingParticipantMutation, { data, loading, error }] = useAddTrainingParticipantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddTrainingParticipantMutation(baseOptions?: Apollo.MutationHookOptions<AddTrainingParticipantMutation, AddTrainingParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTrainingParticipantMutation, AddTrainingParticipantMutationVariables>(AddTrainingParticipantDocument, options);
      }
export type AddTrainingParticipantMutationHookResult = ReturnType<typeof useAddTrainingParticipantMutation>;
export type AddTrainingParticipantMutationResult = Apollo.MutationResult<AddTrainingParticipantMutation>;
export type AddTrainingParticipantMutationOptions = Apollo.BaseMutationOptions<AddTrainingParticipantMutation, AddTrainingParticipantMutationVariables>;
export const RemoveTrainingParticipantDocument = gql`
    mutation removeTrainingParticipant($input: TrainingParticipantInput!) {
  removeTrainingParticipant(input: $input) {
    success
  }
}
    `;
export type RemoveTrainingParticipantMutationFn = Apollo.MutationFunction<RemoveTrainingParticipantMutation, RemoveTrainingParticipantMutationVariables>;

/**
 * __useRemoveTrainingParticipantMutation__
 *
 * To run a mutation, you first call `useRemoveTrainingParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTrainingParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTrainingParticipantMutation, { data, loading, error }] = useRemoveTrainingParticipantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTrainingParticipantMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTrainingParticipantMutation, RemoveTrainingParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTrainingParticipantMutation, RemoveTrainingParticipantMutationVariables>(RemoveTrainingParticipantDocument, options);
      }
export type RemoveTrainingParticipantMutationHookResult = ReturnType<typeof useRemoveTrainingParticipantMutation>;
export type RemoveTrainingParticipantMutationResult = Apollo.MutationResult<RemoveTrainingParticipantMutation>;
export type RemoveTrainingParticipantMutationOptions = Apollo.BaseMutationOptions<RemoveTrainingParticipantMutation, RemoveTrainingParticipantMutationVariables>;