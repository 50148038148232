import { SubButton as _Button } from '@riddler-co-jp/specc-ui-components';
import format from 'date-fns/format';
import * as React from 'react';
import { CSVLink } from 'react-csv';
import SVG from 'react-inlinesvg';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useDeleteUserMutation } from '../../../api/__generated__/adminRole.generated';
import {
  EventDocument,
  EventStats,
  useOrgAllEvents,
  useOrgAllUserEventStats,
  useUserEventHistory,
} from '../../../api/event';
import {
  Event as EventDocumentRaw,
  Training as TrainingDocumentRaw,
  UserRecords,
} from '../../../api/firestoreTypes';
import { useOrganizationData } from '../../../api/organization';
import {
  TrainingDocument,
  useOrgAllTrainings,
  useUserTrainingHistory,
} from '../../../api/training';
import { useUserInfo, useUserOrgInfo } from '../../../api/user';
import {
  DataArray,
  calcAvgRank,
  calcRanks,
  getRankMessage,
  toDataArrayList,
} from '../../../lib/stageResults';
import { useRetryableMutationWithUI } from '../../../lib/useRetryableMutationWithUI';
import { options_ind, options_occ } from '../../../lib/userProfiles';
import { appActions } from '../../../redux/actions/appActions';
import {
  useCustomClaim,
  useSelectedOrgId,
} from '../../../redux/selectors/authSelectors';
import Icon_Communicate from '../../../static/svg/admin/category_communicate.svg';
import Icon_Create from '../../../static/svg/admin/category_create.svg';
import Icon_Energy from '../../../static/svg/admin/category_energy.svg';
import Icon_Program from '../../../static/svg/admin/category_program.svg';
import Icon_Switch from '../../../static/svg/admin/category_switch.svg';
import Colors from '../../../styles/colors';
import { EventStatus } from '../../../types';
import { AdminRole } from '../AdminAdministratorDetail';
import AdminCommonBG from '../AdminCommonBG';
import AdminEventHistory from '../adminEvents/AdminEventHistory';
import AdminTrainingHistory from '../adminTrainings/AdminTrainingHistory';
import { HeaderButton } from '../UIelements/AdminNavigation';
import { useUserCsv } from './hooks/useUserCsv';

export type UserEvents = {
  id: string;
  stats?: EventStats;
  info?: EventDocument;
  record: UserRecords;
  scores?: DataArray;
  deviations?: DataArray;
  allUserDeviations?: DataArray;
  chartValue?: DataArray;
  ranks?: DataArray;
};

export type UserTrainings = {
  id: string;
  info?: TrainingDocument;
};

const defaultDate = new Date('1990-01-01T12:00:00Z');
const formatDate = (d: Date) => format(d, 'yyyy-MM-dd');
const stages = ['stage1', 'stage2', 'stage3', 'stage4', 'stage5'] as const;

interface AdminUserDetailProps {}
const AdminUserDetail: React.FC<AdminUserDetailProps> = props => {
  const navigate = useNavigate();
  const { userId } = useParams<'userId'>();

  const [currentUserInfo] = useUserInfo(userId);
  const orgId = useSelectedOrgId();
  const [currentOrgInfo] = useUserOrgInfo(userId, orgId);
  const onAdminEdit = () => {
    navigate(`/admin/accounts/${userId}/edit`);
  };

  const customClaim = useCustomClaim();
  const role: AdminRole = customClaim?.commonRoles.includes('riddlerAdmin')
    ? 'riddlerAdmin'
    : customClaim?.commonRoles.includes('dentsuAdmin')
    ? 'dentsuAdmin'
    : 'organizationAdmin';

  const [events] = useOrgAllEvents(orgId);
  const [eventHistory, loading, error] = useUserEventHistory(userId ?? null);
  const [eventStats] = useOrgAllUserEventStats(orgId);
  const [organization] = useOrganizationData(orgId);
  const [trainingHistoryIds] = useUserTrainingHistory(userId ?? null);
  const [trainings] = useOrgAllTrainings(orgId);
  const userTrainingHistory = React.useMemo(() => {
    const userTrainingsObj: { [key: string]: UserTrainings } = {};
    trainingHistoryIds?.forEach(id => {
      userTrainingsObj[id] = {
        id,
      } as UserTrainings;
    });
    const convert = (data: TrainingDocumentRaw): TrainingDocument | undefined =>
      data
        ? ({
            ...data,
            scheduledStartTime: data.scheduledStartTime.toDate(),
            scheduledEndTime: data.scheduledEndTime.toDate(),
            createdOn: data.createdOn.toDate(),
            updatedOn: data.updatedOn.toDate(),
          } as TrainingDocument)
        : undefined;
    trainings?.forEach(doc => {
      if (userTrainingsObj[doc.id] !== undefined) {
        userTrainingsObj[doc.id].info = convert(
          doc.data() as TrainingDocumentRaw
        );
      }
    });
    const ret = Object.values(userTrainingsObj);
    console.log('userTrainingHistory', ret);
    return ret;
  }, [trainings, trainingHistoryIds]);

  const userEventHistory = React.useMemo(() => {
    const userEventsObj: { [key: string]: UserEvents } = {};
    eventHistory?.forEach(doc => {
      userEventsObj[doc.id] = {
        id: doc.id,
        record: doc.data() as UserRecords,
      } as UserEvents;
    });

    eventStats?.forEach(doc => {
      if (userEventsObj[doc.id] !== undefined) {
        userEventsObj[doc.id].stats = doc.data() as EventStats;
      }
    });

    events?.forEach(doc => {
      if (userEventsObj[doc.id] !== undefined) {
        const convert = (data: EventDocumentRaw) =>
          ({
            status: data.status,
            name: data.name,
            place: data.place,
            scheduledStartTime: data.scheduledStartTime.toDate(),
            scheduledEndTime: data.scheduledEndTime.toDate(),
            startedOn: data.startedOn?.toDate() ?? null,
            endedOn: data.endedOn?.toDate() ?? null,
            resultCalculatedOn: data.resultCalculatedOn?.toDate() ?? null,
          } as EventDocument);

        userEventsObj[doc.id].info = convert(doc.data() as EventDocumentRaw);
      }
    });

    const ret = Object.values(userEventsObj).sort(
      (a, b) =>
        -(a.info?.startedOn?.getTime() ?? 0) +
        (b.info?.startedOn?.getTime() ?? 0)
    );

    ret.forEach(e => {
      e.scores = stages.map(
        stage => e.record.calculatedScores?.[stage]?.score ?? -1
      ) as DataArray;
      e.deviations = stages.map(stage => {
        if (e.record.calculatedScores?.[stage]?.score == null) {
          return 0;
        }
        if (e.info?.status !== EventStatus.Ended) {
          return 0;
        }
        const score = e.record.calculatedScores[stage].score ?? 0;
        const eventAvg = e.stats?.[stage].avg ?? score;
        const eventStd = e.stats?.[stage].std ?? 1;
        return 50 + 10 * ((score - eventAvg) / eventStd);
      }) as DataArray;
      e.ranks = calcRanks(e.info?.preset ?? '1', e.scores);
    });

    return ret;
  }, [events, eventHistory, eventStats]);

  const userAvgRank = React.useMemo(() => {
    const preset1ScoreList = toDataArrayList(
      userEventHistory
        .filter(e => e.info?.preset !== '2')
        .filter(e => e.scores)
        .map(e => e.scores as DataArray)
    );
    const preset2ScoreList = toDataArrayList(
      userEventHistory
        .filter(e => e.info?.preset === '2')
        .filter(e => e.scores)
        .map(e => e.scores as DataArray)
    );
    const allScoreList = toDataArrayList(
      userEventHistory.filter(e => e.scores).map(e => e.scores as DataArray)
    );

    const avgRank1 = calcAvgRank('1', preset1ScoreList);
    const avgRank2 = calcAvgRank('2', preset2ScoreList);
    const weightedAvgRank1 = avgRank1.map(
      e => (e * preset1ScoreList.length) / allScoreList.length
    );
    const weightedAvgRank2 = avgRank2.map(
      e => (e * preset2ScoreList.length) / allScoreList.length
    );
    return [0, 0, 0, 0, 0].map((_, i) =>
      Math.round(weightedAvgRank1[i] + weightedAvgRank2[i])
    ) as DataArray;
  }, [userEventHistory]);

  const [fullName, setFullName] = React.useState('');
  const [ruby, setRuby] = React.useState('');
  const [displayName, setDisplayName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [birthday, setBirthday] = React.useState(defaultDate);
  const [createdOn, setCreatedOn] = React.useState(defaultDate);
  const [startYear, setStartYear] = React.useState(2020);
  const [department, setDepartment] = React.useState('');
  const [employeeId, setEmployeeId] = React.useState('');
  const [occupationId, setOccupationId] = React.useState('');
  const [industryId, setIndustryId] = React.useState('');

  const onCompleted = React.useCallback(() => {
    navigate('/admin/accounts');
    window.location.reload();
  }, [navigate]);

  const [deleteUser] = useRetryableMutationWithUI(useDeleteUserMutation, {
    hookOptions: {
      onCompleted,
    },
    error: {
      options: {
        errorType: 'CommonError',
        message: `現在開催中のイベントに参加しているアカウントは、削除することができません。`,
      },
    },
  });

  React.useEffect(() => {
    const c = currentUserInfo;

    if (c?.ruby) {
      setRuby(c.ruby);
    }
    if (c?.email) {
      setEmail(c.email);
    }
    if (c?.displayName) {
      setDisplayName(c.displayName);
    }
    if (c?.fullName) {
      setFullName(c.fullName);
    }
    if (c?.birthday) {
      setBirthday(c.birthday.toDate());
    }
    if (c?.createdOn) {
      setCreatedOn(c.createdOn.toDate());
    }
  }, [currentUserInfo]);

  React.useEffect(() => {
    const c = currentOrgInfo;

    if (c?.department) {
      setDepartment(c.department);
    }
    if (c?.employeeId) {
      setEmployeeId(c.employeeId);
    }
    if (c?.occupationId) {
      setOccupationId(c.occupationId);
    }
    if (c?.industryId) {
      setIndustryId(c.industryId);
    }
    if (c?.startYear) {
      setStartYear(c.startYear);
    }
  }, [currentOrgInfo]);

  const btnInvite: HeaderButton = {
    buttonText: '編集する',
    event: onAdminEdit,
  };

  const occupationName = React.useMemo(() => {
    const list = options_occ.map(e => e.options).flat();
    if (occupationId) {
      return (
        list?.find(e => e.value.toString() === occupationId.toString())
          ?.label ?? ''
      );
    }
    return '';
  }, [occupationId]);

  const insdustryName = React.useMemo(() => {
    if (industryId) {
      return (
        options_ind.find(e => e.value.toString() === industryId.toString())
          ?.label ?? ''
      );
    }
    return '';
  }, [industryId]);

  const { csv, csvClicked, fetchDoneRef, onCsvDownloadButton } = useUserCsv({
    currentUserInfo,
    currentOrgInfo,
    role,
    userAvgRank,
    userEventHistory,
    userId,
  });

  // Task Idのmapping
  const dispatch = useDispatch();
  return (
    <AdminCommonBG
      backButton
      title='アカウント詳細'
      headerButtons={[btnInvite]}
      downloadCsvButton={
        ['riddlerAdmin', 'dentsuAdmin'].includes(role)
          ? onCsvDownloadButton
          : undefined
      }
    >
      {csv && csvClicked && (
        <CSVLink
          data={csv.data}
          filename={csv.filename}
          headers={csv.headers}
          ref={fetchDoneRef}
        />
      )}
      <AdminEventControllerWrapper>
        <Row>
          <DetailTable>
            <THead>
              <TR>
                <TH>氏名</TH>
                <TH>よみがな</TH>
                <TH>表示名</TH>
                <TH>メールアドレス</TH>
                <TH>生年月日</TH>
              </TR>
            </THead>

            <TBody>
              <TR>
                <TD>{fullName}</TD>
                <TD>{ruby}</TD>
                <TD>{displayName}</TD>
                <TD>{email}</TD>
                <TD>{formatDate(birthday)}</TD>
              </TR>
            </TBody>
          </DetailTable>
        </Row>
        <Row>
          <DetailTable>
            <THead>
              <TR>
                <TH>所属企業</TH>
                <TH>入社年</TH>
                <TH>社員ID</TH>
                <TH>部署</TH>
                <TH>業種</TH>
                <TH>職種</TH>
                <TH>アカウント作成日時</TH>
              </TR>
            </THead>

            <TBody>
              <TR>
                <TD>{organization?.name ?? ''}</TD>
                <TD>{startYear}</TD>
                <TD>{employeeId}</TD>
                <TD>{department}</TD>
                <TD>{insdustryName}</TD>
                <TD>{occupationName}</TD>
                <TD>{formatDate(createdOn)}</TD>
              </TR>
            </TBody>
          </DetailTable>
        </Row>

        <Margin></Margin>

        <Row>
          <Item>
            <ItemTitle>平均ランク</ItemTitle>
            <ItemBody>
              <RankWrapper>
                <RankHead>
                  <RankItem>
                    <SVG src={Icon_Switch} />
                    <p>Switch</p>
                  </RankItem>
                  <RankItem>
                    <SVG src={Icon_Program} />
                    <p>Program</p>
                  </RankItem>
                  <RankItem>
                    <SVG src={Icon_Create} />
                    <p>Create</p>
                  </RankItem>
                  <RankItem>
                    <SVG src={Icon_Energy} />
                    <p>Energy</p>
                  </RankItem>
                  <RankItem>
                    <SVG src={Icon_Communicate} />
                    <p>Communicate</p>
                  </RankItem>
                </RankHead>
                <RankBody>
                  <BodyItem>{getRankMessage(userAvgRank[0])}</BodyItem>
                  <BodyItem>{getRankMessage(userAvgRank[1])}</BodyItem>
                  <BodyItem>{getRankMessage(userAvgRank[2])}</BodyItem>
                  <BodyItem>{getRankMessage(userAvgRank[3])}</BodyItem>
                  <BodyItem>{getRankMessage(userAvgRank[4])}</BodyItem>
                </RankBody>
              </RankWrapper>
            </ItemBody>
          </Item>
        </Row>

        <Row>
          <Item>
            <ItemTitle>イベント履歴</ItemTitle>
            <ItemBody>
              <SubHeader>チェックトライアル</SubHeader>
              <AdminEventHistory userEvents={userEventHistory} />
              <SubHeader>スキルトレーニング</SubHeader>
              <AdminTrainingHistory userTrainings={userTrainingHistory} />
            </ItemBody>
          </Item>
        </Row>

        <Buttons>
          <DeleteButton
            disabled={orgId === null}
            onClick={() => {
              // Task Remove User *
              dispatch(
                appActions.setErrorOverlayState({
                  errorType: 'CommonError',
                  message: '本当にアカウントを削除しますか。',
                  backButtonText: 'いいえ',
                  retryButtonText: 'はい',
                  onRetry: async () => {
                    if (orgId === null) {
                      return;
                    }
                    if (userId === undefined) {
                      return;
                    }
                    await deleteUser({
                      variables: {
                        input: {
                          uid: userId,
                          orgId,
                        },
                      },
                    });
                  },
                  onBack: () => {
                    console.log('close');
                  },
                })
              );
            }}
          >
            アカウントを削除する
          </DeleteButton>
        </Buttons>
      </AdminEventControllerWrapper>
    </AdminCommonBG>
  );
};

const AdminEventControllerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Margin = styled.div`
  margin-bottom: 1rem;
`;

const Row = styled.div`
  &:last-child {
    border-bottom: none;
  }
`;

const Item = styled.div`
  width: 100%;
`;

const ItemTitle = styled.div`
  font-size: 2.4rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const ItemBody = styled.div`
  width: 100%;
`;

const ItemText = styled.span`
  display: inline-block;
  font-size: 1.3rem;
`;

const Button = styled(_Button)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

const InlineButton = styled(Button)`
  display: inline-block;
  font-weight: bold;
`;

const Buttons = styled.div`
  width: 100%;
  text-align: right;
  box-sizing: border-box;
  margin-bottom: 2rem;
`;

const DetailTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    td:nth-child(1) {
      /*width: 40%;*/
    }
    td:nth-child(2) {
      width: 18rem;
    }
    td:nth-child(3) {
      width: 20rem;

      p {
        margin-right: 1rem;
      }
      p:last-child {
        margin-right: 0;
      }
    }

    td:last-child {
      width: 20rem;
      padding-right: 0;
    }
  }
`;

const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;

  tr:last-child {
    border: none !important;
  }
`;

const TD = styled.td`
  font-size: 1.4rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;
  border-bottom: 1px solid ${Colors.gray4};
`;

interface DeleteButton {
  disabled: boolean;
}

const DeleteButton = styled.div`
  color: ${Colors.error};
  cursor: pointer;
  display: initial;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  transition: 0.2s;

  &:hover {
    background: ${Colors.gray4};
  }

  ${(p: DeleteButton) =>
    p.disabled &&
    `
    cursor: default;
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      background: initial;
    }
    `}
`;

const RankWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;
const RankHead = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${Colors.gray4};
`;
const RankItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.2rem 0;

  &:last-child {
    margin-right: 0;
  }
`;
const RankBody = styled.div`
  width: 100%;
  display: flex;
`;
const BodyItem = styled.div`
  flex: 1;
  font-size: 2.8rem;
  margin-right: 1rem;
  text-align: center;
  font-weight: bold;
  color: ${Colors.accent};
  border-right: 1px solid ${Colors.gray4};
  margin: 1rem 0;

  &:last-child {
    border: none;
  }
`;
const SubHeader = styled.div`
  background: ${Colors.gray2};
  font-size: 1.4rem;
  padding: 0.2rem 1rem;
  margin: 0.8rem 0;
  margin-bottom: 0;
`;
export default AdminUserDetail;
