import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useOwnerOrganizations } from '../../api/organization';
import { useSetLoadingState } from '../../redux/actions/appActions';
import {
  useCurrentUser,
  useCustomClaim,
} from '../../redux/selectors/authSelectors';
import AdminAdministratorDetail from './AdminAdministratorDetail';
import AdminAdministrators from './AdminAdministrators';
import AdminAnalyze from './AdminAnalyze';
import AdminEventControlView from './adminEvents/AdminEventControlView';
import AdminEventEditView from './adminEvents/AdminEventEditView';
import AdminEventResult from './adminEvents/AdminEventResult';
import AdminEvents from './adminEvents/AdminEvents';
import AdminEventUserStatusView from './adminEvents/AdminEventUserStatusView';
import AdminOrganizationDetail from './adminOrganizations/AdminOrganizationDetail';
import AdminOrganizationView from './adminOrganizations/AdminOrganizationView';
import AdminOverview from './AdminOverview';
import AdminPreset from './AdminPreset';
import AdminPresetDetail from './AdminPresetDetail';
import AdminStage5Overview from './AdminStage5Overview';
import AdminTraining from './adminTrainings/AdminTraining';
import AdminTrainingControlView from './adminTrainings/AdminTrainingControlView';
import AdminTrainingEditView from './adminTrainings/AdminTrainingEditView';
import AdminUserAccounts from './adminUsers/AdminUserAccounts';
import AdminUserDetail from './adminUsers/AdminUserDetail';
import AdminUserEdit from './adminUsers/AdminUserEdit';
import AdminUserHistory from './adminUsers/AdminUserHistory';
import AdminUserTrainingHistory from './AdminUserTrainingHistory';

const AdminRouter: React.FC = () => {
  const user = useCurrentUser();
  const [ownerOrgs, loading] = useOwnerOrganizations(user);
  const setLoadingState = useSetLoadingState();
  const customClaim = useCustomClaim();

  React.useEffect(() => {
    setLoadingState({ visible: loading });
  }, [loading, setLoadingState]);

  console.log(loading, ownerOrgs);
  if (loading) return null;
  if (ownerOrgs === undefined || ownerOrgs.length === 0) {
    if (customClaim?.commonRoles.length === 0) {
      return <Navigate to='/' />;
    }
  }

  return (
    <Routes>
      <Route path='/organization' element={<AdminOrganizationView />} />
      <Route
        path='/organization/create'
        element={<AdminOrganizationDetail create />}
      />
      <Route
        path='/organization/:orgId'
        element={<AdminOrganizationDetail create={false} />}
      />
      <Route path='/events/create' element={<AdminEventEditView create />} />
      <Route
        path='/events/:eventId/copy'
        element={<AdminEventEditView create />}
      />
      <Route path='/events/' element={<AdminEvents />} />
      <Route
        path='/training/create'
        element={<AdminTrainingEditView create />}
      />
      <Route path='/training/' element={<AdminTraining />}></Route>
      <Route path='/accounts/' element={<AdminUserAccounts />} />
      <Route path='/accounts/:userId' element={<AdminUserDetail />} />
      <Route path='/accounts/:userId/edit' element={<AdminUserEdit />} />
      <Route
        path='/accounts/:userId/history/events/:eventId'
        element={<AdminUserHistory />}
      />
      <Route
        path='/accounts/:userId/history/training/:eventId'
        element={<AdminUserTrainingHistory />}
      />
      <Route path='/administrators' element={<AdminAdministrators />} />
      <Route
        path='/administrators/:userId'
        element={<AdminAdministratorDetail />}
      />
      <Route path='/events/:eventId/overview' element={<AdminOverview />} />
      <Route
        path='/events/:eventId/overview5'
        element={<AdminStage5Overview />}
      />
      <Route path='/events/:eventId/edit' element={<AdminEventEditView />} />
      <Route
        path='/events/:eventId/control'
        element={<AdminEventControlView />}
      />
      <Route
        path='/training/:eventId/edit'
        element={<AdminTrainingEditView />}
      />
      <Route
        path='/training/:eventId/copy'
        element={<AdminTrainingEditView create />}
      />
      <Route
        path='/training/:eventId/control'
        element={<AdminTrainingControlView />}
      />
      <Route
        path='/events/:eventId/control/:userId/status'
        element={<AdminEventUserStatusView />}
      />
      <Route
        path='/events/:eventId/result/:userId'
        element={<AdminEventResult />}
      />
      <Route path='/analyze' element={<AdminAnalyze />} />
      <Route path='/preset/:presetId' element={<AdminPresetDetail />} />
      <Route path='/preset' element={<AdminPreset />} />
      <Route path='/' element={<Navigate to='/admin/events' />}></Route>
      <Route path='/*' element={<Navigate to='/' />}></Route>
    </Routes>
  );
};

export default AdminRouter;
