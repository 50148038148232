import dummyThumb1 from '../static/png/admin/dummy_thumb_1.png';
import dummyThumb2 from '../static/png/admin/dummy_thumb_2.png';
import dummyThumb3 from '../static/png/admin/dummy_thumb_3.png';
import dummyThumb4 from '../static/png/admin/dummy_thumb_4.png';
import dummyThumb5 from '../static/png/admin/dummy_thumb_5.png';

export const presets = [
  {
    preset_id: '1',
    preset_name: 'CT1',
    preset_detail: 'CT1',
    tests: [
      {
        name: 'ナンバーパネルテスト',
        detail: '枠の中にパネルを自由に並べて、等式を作りなさい。',
        thumb: dummyThumb1,
      },
      {
        name: 'ロジカルミステリー',
        detail: '資料をもとに事件の犯人を推理しなさい。',
        thumb: dummyThumb2,
      },
      {
        name: 'ひらめき謎ノック',
        detail: '制限時間以内にできるだけ多くのナゾを解きなさい。',
        thumb: dummyThumb3,
      },
      {
        name: 'ライツトライアル',
        detail: 'すべてのライトを点灯させなさい。',
        thumb: dummyThumb4,
      },
      {
        name: 'アドリブプレゼン',
        detail: '表示されるお題を、仲間に的確に伝えなさい。',
        thumb: dummyThumb5,
      },
    ],
  },
  {
    preset_id: '2',
    preset_name: 'CT2',
    preset_detail: 'CT2',
    tests: [
      {
        name: 'スイッチングリドル',
        detail: '多角的な思考で法則問題を解きなさい。',
        thumb: dummyThumb1,
      },
      {
        name: 'ロジカルミステリー２',
        detail: '資料をもとに事件の犯人を推理しなさい。',
        thumb: dummyThumb2,
      },
      {
        name: 'ヒント謎ノック',
        detail:
          'ヒントを駆使しながら、制限時間内にできるだけ多くのナゾを解きなさい。',
        thumb: dummyThumb3,
      },
      {
        name: 'ラインリンク',
        detail: '隠されたルールを推測しながら、全ての点を線でつなぎなさい。',
        thumb: dummyThumb4,
      },
      {
        name: 'アドリブプレゼン２',
        detail: '表示されるお題を、仲間に的確に伝えなさい。',
        thumb: dummyThumb5,
      },
    ],
  },
];
