import firebase from 'firebase/app';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import {
  useSetErrorOverlayState,
  useSetLoadingState,
  useSetNoticeOverlayState,
} from '../../../../redux/actions/appActions';
import ConfirmPasswordResetView from './ConfirmPasswordResetView';

interface ConfirmPasswordResetProps {
  code: string;
  codeInfo: firebase.auth.ActionCodeInfo;
}
const ConfirmPasswordReset: React.FC<ConfirmPasswordResetProps> = ({
  code,
}) => {
  const navigate = useNavigate();

  const setNoticeOverlayState = useSetNoticeOverlayState();
  const setErrorOverlayState = useSetErrorOverlayState();
  const setLoadingState = useSetLoadingState();

  // [Create custom email action handlers | Firebase](https://firebase.google.com/docs/auth/custom-email-handler)
  const sendPw = React.useCallback(
    (pw: string) => {
      // Save the new password.

      setLoadingState({
        visible: true,
        text: '通信中...',
      });
      firebase
        .auth()
        .confirmPasswordReset(code, pw)
        .then(() => {
          setNoticeOverlayState({
            errorType: 'CommonError',
            message:
              'パスワードの変更が完了しました。トップページから再度ログインしてください。',
            onBack: () => navigate('/'),
            backButtonText: 'トップページへ',
          });
          // Password reset has been confirmed and new password updated.
        })
        .catch(() => {
          setErrorOverlayState({ errorType: 'CommonError' });
          // Error occurred during confirmation. The code might have expired or the
          // password is too weak.
        })
        .finally(() => {
          setLoadingState({ visible: false });
        });
    },
    [
      code,
      navigate,
      setErrorOverlayState,
      setLoadingState,
      setNoticeOverlayState,
    ]
  );

  return <ConfirmPasswordResetView sendPw={sendPw} />;
};

export default ConfirmPasswordReset;
