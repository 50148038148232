import firebase from 'firebase/app';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { createTokenCookie } from '../../api/sso';
import asContent from '../../hoc/asContent';
import {
  HandleFirebaseErrorResult,
  handleSignInWithEmailAndPasswordErrorCode,
} from '../../lib/firebase/firebaseErrorHandlers';
import { appActions } from '../../redux/actions/appActions';
import LoginView from './LoginView';

export const onCheckStoreIdentity = (checked: boolean): void => {
  const persistence = checked
    ? firebase.auth.Auth.Persistence.LOCAL
    : firebase.auth.Auth.Persistence.SESSION;

  firebase
    .auth()
    .setPersistence(persistence)
    .then(() => {
      return;
    });
};

interface LoginProps {}
const Login: React.FC<LoginProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = React.useState(false);

  const [error, setError] =
    React.useState<HandleFirebaseErrorResult | null>(null);

  const toPWReset = React.useCallback(() => {
    navigate('/password-reset');
  }, [navigate]);

  const onLogin = React.useCallback(
    (email: string, pw: string) => {
      dispatch(appActions.setLoadingState({ visible: true }));

      firebase
        .auth()
        .signInWithEmailAndPassword(email, pw)
        .then(async cred => {
          const token = await cred.user?.getIdToken(true);
          if (token) {
            await createTokenCookie(token);
          } else {
            setError({
              type: 'auth/general',
              message: '認証に失敗しました',
            });
          }
        })
        .catch(error => {
          setError(handleSignInWithEmailAndPasswordErrorCode(error.code));
        })
        .finally(() => {
          dispatch(appActions.setLoadingState({ visible: false }));
        });
    },
    [dispatch]
  );

  const onCheck = (checked: boolean) => {
    onCheckStoreIdentity(checked);
    setIsChecked(checked);
  };

  return (
    <LoginView
      onLogin={onLogin}
      toPWReset={toPWReset}
      onCheckStoreIdentity={onCheck}
      isCheckStoreIdentity={isChecked}
      error={error}
    />
  );
};

export default asContent(Login);
