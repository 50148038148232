import { SubButton as _Button } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { Data, Headers } from 'react-csv/components/CommonPropTypes';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { UserOrgInfo } from '../../../api/firestoreTypes';
import {
  useOrganizationMembers,
  useOrganizationUserOrgs,
} from '../../../api/organization';
import { TrainingDocument } from '../../../api/training';
import { options_ind, options_occ } from '../../../lib/userProfiles';
import { appActions } from '../../../redux/actions/appActions';
import Colors, { colorWithAlpha } from '../../../styles/colors';
import { GroupMembersInfo } from '../../../types';

type TrainingControlParticipantManagerProps = {
  type: 'control';
  participantUids: string[];
  onUpdateParticipantUids?: null;
  onMoveToResult: (uid: string) => void;
  training?: TrainingDocument | null;
  orgId: string;
  isAdmin: boolean;
};

type OrgMemberRecord = {
  displayName: string;
  fullName: string;
  email: string;
};
const stages = ['stage1', 'stage2', 'stage3', 'stage4', 'stage5'] as const;

export interface CsvData {
  data: Data;
  headers: Headers;
  filename: string;
  onCsvDownload: () => void;
}

const getIndustryName = (industryId: string) => {
  if (industryId) {
    return (
      options_ind.find(e => e.value.toString() === industryId.toString())
        ?.label ?? ''
    );
  }
  return '';
};

const getOccupationName = (occupationId: string) => {
  const list = options_occ.map(e => e.options).flat();
  if (occupationId) {
    return (
      list?.find(e => e.value.toString() === occupationId.toString())?.label ??
      ''
    );
  }
  return '';
};

export type AdminAllStage5Groups = Array<GroupMembersInfo>;

const TrainingControlParticipantManager: React.FC<TrainingControlParticipantManagerProps> =
  props => {
    const navigate = useNavigate();
    const { participantUids, type, orgId, isAdmin } = props;

    const [userOrgs] = useOrganizationUserOrgs(
      orgId === 'create' ? null : orgId
    );

    const userOrgTable = React.useMemo(() => {
      const ret: { [key: string]: UserOrgInfo } = {};
      if (userOrgs === undefined) {
        return undefined;
      }
      userOrgs.forEach(e => {
        ret[e.uid] = e;
      });
      return ret;
    }, [userOrgs]);

    const { orgMembers, orgMemberDoc } = useOrganizationMembers(
      orgId === 'create' ? null : orgId
    );
    const { orgMemberDoc: riddlerAdminMemberDoc } = useOrganizationMembers(
      isAdmin ? 'adminRiddler' : null
    );
    const { orgMemberDoc: dentsuAdminMemberDoc } = useOrganizationMembers(
      isAdmin ? 'adminDentsu' : null
    );

    const adminMemberDoc: Record<string, OrgMemberRecord | undefined> =
      React.useMemo(() => {
        return Object.fromEntries(
          Object.entries(dentsuAdminMemberDoc).concat(
            Object.entries(riddlerAdminMemberDoc)
          )
        );
      }, [dentsuAdminMemberDoc, riddlerAdminMemberDoc]);

    const [groups, setGroups] = React.useState<AdminAllStage5Groups>();

    const dispatch = useDispatch();
    return (
      <ParticipantManagerWrapper>
        <TableWrapper>
          <Table>
            <THead type={type}>
              <TR>
                <TH>氏名</TH>
                {
                  <>
                    <TH>入社年</TH>
                    <TH>社員ID</TH>
                    <TH>部署</TH>
                    <TH>業種</TH>
                    <TH>職種</TH>
                  </>
                }
                {isAdmin && (
                  <>
                    <TH>権限</TH>
                  </>
                )}
              </TR>
            </THead>
            <TBody type={type}>
              {!isAdmin && orgMembers === null ? (
                <TR>
                  <TD>
                    <LoadingText>読み込み中...</LoadingText>
                  </TD>
                </TR>
              ) : (
                <>
                  {participantUids.length == 0 ? (
                    <TR>
                      <TD>
                        <LoadingText>
                          {isAdmin ? '管理者' : '参加者'}がいません。
                        </LoadingText>
                      </TD>
                    </TR>
                  ) : (
                    <>
                      {participantUids.map((p, i) => (
                        <TR
                          key={i}
                          onClick={() => {
                            if (orgMemberDoc[p]?.enable) {
                              navigate(`/admin/accounts/${p}`);
                            } else {
                              dispatch(
                                appActions.setErrorOverlayState({
                                  errorType: 'CommonError',
                                  message:
                                    'このアカウントは削除されています。復旧をご希望される場合、お問い合わせよりご連絡ください',
                                })
                              );
                            }
                          }}
                        >
                          <TD>
                            <Name>
                              {orgMemberDoc[p]?.fullName ??
                                adminMemberDoc[p]?.fullName ??
                                `不明ユーザー (${p})`}
                            </Name>
                            <Mail>
                              {orgMemberDoc[p]?.email ??
                                adminMemberDoc[p]?.email ??
                                ''}
                            </Mail>
                          </TD>

                          {
                            <>
                              <TD>{userOrgTable?.[p]?.startYear ?? ''}</TD>
                              <TD>{userOrgTable?.[p]?.employeeId ?? ''}</TD>
                              <TD>{userOrgTable?.[p]?.department ?? ''}</TD>
                              <TD>
                                {getIndustryName(
                                  userOrgTable?.[p]?.industryId ?? ''
                                )}
                              </TD>
                              <TD>
                                {getOccupationName(
                                  userOrgTable?.[p]?.occupationId ?? ''
                                )}
                              </TD>
                            </>
                          }

                          {isAdmin && (
                            <>
                              <TD>
                                {riddlerAdminMemberDoc[p]
                                  ? 'RIDDLER'
                                  : dentsuAdminMemberDoc[p]
                                  ? '運営企業'
                                  : 'リーダー'}
                              </TD>
                            </>
                          )}
                        </TR>
                      ))}
                    </>
                  )}
                </>
              )}
            </TBody>
          </Table>
        </TableWrapper>
      </ParticipantManagerWrapper>
    );
  };

const ParticipantManagerWrapper = styled.div``;

const TableWrapper = styled.div`
  width: 100%;
  display: block;
  overflow-x: auto;
`;

const Table = styled.table`
  width: 100%;
  font-size: 1.4rem;
  text-align: left;
  display: table;

  th:nth-child(2),
  td:nth-child(2) {
    flex: initial;
    width: 7rem;
  }
  th:nth-child(3),
  td:nth-child(3) {
    flex: initial;
    width: 7rem;
  }
  th:nth-child(7),
  td:nth-child(7) {
    flex: initial;
    display: flex;
    width: 254px;
    margin-left: 1rem;
  }
`;

const THead = styled.thead`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.gray4};
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.6rem 0;

  th:first-child {
    ${(p: ParticipantsListItemProps) =>
      p.type === 'control' &&
      `
        width: 30%;
        /*min-width: 30%;*/
    `}
  }
`;

const TBody = styled.tbody`
  cursor: default;
  width: 100%;
  display: block;

  td:first-child {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    width: 30%;
    /*min-width: 30%;*/
  }

  tr {
    padding: 0.6rem 0;
    position: relative;
    border-bottom: 1px solid ${Colors.gray4};
    transition: 0.2s;
  }

  tr:hover {
    background-color: ${colorWithAlpha('gray4', 0.5)};

    ${(_: ParticipantsListItemProps) =>
      `
      & ${ParticipantRemoveButton} {
        display: block !important;
      }
      `}
    }
  }
`;

const TR = styled.tr`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TH = styled.th`
  flex: 1;
  margin-left: 1rem;

  &:last-child {
    display: flex;
    width: 254px;
    margin-left: 1rem;
  }
`;

const TD = styled.td`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 1rem;

  white-space: nowrap;
  overflow: hidden;
`;

const ParticipantRemoveButton = styled.div`
  cursor: pointer;
  position: absolute;
  display: none;
  line-height: 2.4rem;
  height: 2.4rem;
  right: 1.6rem;
  top: 1.6rem;
  transition: 0.2s;
  color: ${Colors.gray6};

  svg {
    width: 20px !important;
    height: 20px !important;
  }

  &:hover {
    color: ${Colors.error};
  }
`;

type ParticipantsListItemProps = Pick<
  TrainingControlParticipantManagerProps,
  'type'
>;

const Name = styled.div`
  font-size: 1.6rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Mail = styled.div`
  font-size: 1.2rem;
  color: ${Colors.gray6};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const LoadingText = styled.div`
  color: ${Colors.gray6};
  padding: 0.6rem 0;
  &:hover {
    background-color: initial;
  }
`;

const Rank = styled.div`
  width: 50px;
  line-height: 3.2rem;
  text-align: center;
  font-size: 2.8rem;
  font-weight: bold;
  color: ${Colors.accent};
  border-right: 1px solid ${Colors.gray4};
  height: 36px;

  &:last-child {
    border: none;
  }
`;

const Button = styled(_Button)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

export default TrainingControlParticipantManager;
