import * as React from 'react';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useResendRegisterMailMutation } from '../../api/__generated__/user.generated';
import { useUserInfo } from '../../api/user';
import { useRetryableMutationWithUI } from '../../lib/useRetryableMutationWithUI';
import {
  appActions,
  useSetErrorOverlayState,
  useSetLoadingState,
} from '../../redux/actions/appActions';
import { useCurrentUser } from '../../redux/selectors/authSelectors';
import VerifyEmailView from './VerifyEmailView';

interface VerifyEmailProps {}
const VerifyEmail: React.FC<VerifyEmailProps> = () => {
  const user = useCurrentUser();
  const [userInfo] = useUserInfo(user?.uid);
  const first = React.useMemo(() => {
    return !userInfo?.email;
  }, [userInfo]);
  const setLoadingState = useSetLoadingState();
  const setErrorOverlayState = useSetErrorOverlayState();

  const dispatch = useDispatch();
  const onResendComplete = useCallback(() => {
    dispatch(appActions.setLoadingState({ visible: false }));
  }, [dispatch]);
  const [resend] = useRetryableMutationWithUI(useResendRegisterMailMutation, {
    hookOptions: {
      onCompleted: onResendComplete,
    },
  });

  const onResend = React.useCallback(() => {
    setLoadingState({ visible: true });
    if (user?.uid && user.email) {
      resend({ variables: { input: { uid: user.uid, email: user.email } } });
    }
  }, [setErrorOverlayState, setLoadingState, user]);

  return <VerifyEmailView onResend={onResend} first={first} />;
};

export default VerifyEmail;
