import * as React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import SettingsMain from './SettingsMain';
import { settingsKeys, settingsPaths } from './settingsPaths';

interface SettingsProps {}
const Settings: React.FC<SettingsProps> = () => {
  return (
    <Routes>
      <Route path={'/*'} element={<SettingsMain />} />
      <Route path={'/'} element={<Navigate to={settingsPaths.profile} />} />
    </Routes>
  );
};

export default Settings;
