const CT1RankBorder = [
  [3, 6, 8, 12, 16, 22], // Stage1
  [4, 6, 10, 14, 18, 23], // Stage2
  [3, 5, 8, 14, 20, 26], // Stage3
  [1, 3, 6, 10, 15, 21], // Stage4
  [8.5, 9, 9.5, 10.5, 11, 12], // Stage5
];

const CT2RankBorder = [
  [2, 4, 6, 8, 10, 13], // Stage1
  [5, 9, 12, 15, 18, 21], // Stage2
  [2, 4, 8, 12, 16, 20], // Stage3
  [20, 30, 40, 50, 60, 70], // Stage4
  [8, 10, 12, 14, 16, 18], // Stage5
];

export const rankBorder = (preset: string, stage: number) => {
  if (preset === '1') {
    return CT1RankBorder[stage - 1];
  } else if (preset === '2') {
    return CT2RankBorder[stage - 1];
  }
  throw new Error('Invalid preset');
};

export const calcRank = (
  preset: string,
  score: number,
  idx: number
): number => {
  if (idx < 0 || 4 < idx) return -1;
  if (score < 0) return -1;
  return rankBorder(preset, idx + 1).reduce(
    (rank, border, i) => (rank === 6 && score < border ? i : rank),
    6
  );
};

export const calcRanks = (preset: string, scoreList: DataArray): DataArray => {
  return scoreList.map((e, i) => calcRank(preset, e, i)) as DataArray;
};

const f = (v: number) => {
  if (v <= 0.5) return Math.pow(v, 1.2);
  return 1 - Math.pow(1 - v, 1.2);
};

export const normalize = (v: number) => {
  if (v < 30) return 1 / 5;
  if (70 <= v) return 1;
  return f((v - 30) / 40) * 0.8 + 0.2;
};

export const toDataArrayList = (
  scoreList: (number | undefined | null)[][] | DataArray[] | undefined | null
): DataArray[] => {
  if (!scoreList) {
    return [];
  }
  return scoreList.map(
    e =>
      [e[0] ?? -1, e[1] ?? -1, e[2] ?? -1, e[3] ?? -1, e[4] ?? -1] as DataArray
  );
};

export const calcAvg = (scoreList: DataArray[]): DataArray => {
  const sizes = scoreList.reduce(
    (a, b) => [
      a[0] + (b[0] >= 0 ? 1 : 0),
      a[1] + (b[1] >= 0 ? 1 : 0),
      a[2] + (b[2] >= 0 ? 1 : 0),
      a[3] + (b[3] >= 0 ? 1 : 0),
      a[4] + (b[4] >= 0 ? 1 : 0),
    ],
    [0, 0, 0, 0, 0]
  );
  if (scoreList.length === 0) {
    return [-1, -1, -1, -1, -1];
  }
  return scoreList.reduce(
    (a, b) => {
      return b.map((e, i) =>
        sizes[i] === 0 ? -1 : e >= 0 ? a[i] + e / sizes[i] : a[i]
      ) as DataArray;
    },
    [0, 0, 0, 0, 0]
  );
};

export const calcAvgRank = (
  preset: string,
  scoreList: DataArray[]
): DataArray => {
  return calcAvg(scoreList).map((e, i) => calcRank(preset, e, i)) as DataArray;
};

export const Rank = ['C', 'C+', 'B', 'B+', 'A', 'A+', 'S'] as const;

export const getRankMessage = (rank: number): string => Rank[rank] ?? '-';

export const calcRankMessage = (preset: string, score: number, idx: number) =>
  getRankMessage(calcRank(preset, score, idx));

export type DataArray = [number, number, number, number, number];
