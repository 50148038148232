import * as React from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes, useMatch } from 'react-router-dom';

import { useStartTimesEffect } from '../../api/stages';
import { useAvailableTrainingIds } from '../../api/training';
import { appActions } from '../../redux/actions/appActions';
import { useCurrentUser } from '../../redux/selectors/authSelectors';
import TrainingInformation from '../mypage/TrainingInformation';
import TrainingStages from './trainingStageProvider/TrainingStages';

// import Stages from './stageProviders/Stages';

interface TrainingsRouterProps {}

export const TrainingsRouter: React.FC<TrainingsRouterProps> = () => {
  const user = useCurrentUser();
  const [trainingAvailable, trainingLoading] = useAvailableTrainingIds(
    user ?? null
  );
  const match = useMatch('/trainings/:trainingId');
  useStartTimesEffect();
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (trainingLoading) {
      dispatch(appActions.setLoadingState({ visible: true }));
    } else {
      dispatch(appActions.setLoadingState({ visible: false }));
    }
  }, [dispatch, trainingLoading]);

  if (trainingLoading) {
    return null;
  }
  if (!trainingAvailable) {
    return <Navigate to='/trainings' />;
  }

  return (
    <Routes>
      <Route
        path='/:trainingId'
        element={() => (
          <Navigate to={`/trainings/${match?.params.trainingId}/stages`} />
        )}
      />
      <Route path='/:trainingId/stages' element={<TrainingStages />} />
    </Routes>
  );
};

export default TrainingsRouter;
