import { format } from 'date-fns';
import { ja } from 'date-fns/locale';
import * as React from 'react';
import styled from 'styled-components';

import {
  TrainingDocument,
  getTrainingData,
  useTrainingMembersData,
} from '../../api/training';
import Colors from '../../styles/colors';
import Button from '../uiElements/deprecated/Button';

const trainingTableTypes = ['trainingsView'] as const;
type TrainingTableType = typeof trainingTableTypes[number];
const tableTopText: Record<TrainingTableType, string> = {
  trainingsView: '参加予定の研修一覧',
};

type TrainingTableProps = {
  trainingIds: string[];
} & {
  type: 'trainingsView';
  onJoin: (trainingId: string) => void;
  onAdminEdit?: undefined;
  onAdminControl?: undefined;
  onAdminCreate?: undefined;
  showResult?: undefined;
  activeTrainingId?: undefined;
};

const TrainingTable: React.FC<TrainingTableProps> = props => {
  const { trainingIds } = props;
  const [trainingDatas, setTrainingDatas] = React.useState<TrainingDocument[]>(
    []
  );
  const trainingDatasSorted = React.useMemo(
    () =>
      trainingDatas.sort(
        (a, b) =>
          b.scheduledStartTime.getTime() - a.scheduledStartTime.getTime()
      ),
    [trainingDatas]
  );

  React.useEffect(() => {
    setTrainingDatas([]);
    trainingIds.forEach(trainingId => {
      getTrainingData(trainingId).then(data => {
        if (data === null) return;
        setTrainingDatas(prev => {
          if (prev.some(e => e.trainingId === data.trainingId)) return prev;
          return [...prev, data];
        });
      });
    });
  }, [trainingIds]);

  return (
    <TrainingTableWrapper>
      <TopInfo>
        <TopText>{tableTopText[props.type]}</TopText>
      </TopInfo>
      <Table>
        <THead>
          <TR>
            <TH>開催期間</TH>
            <TH>イベント名</TH>
            <TH>会場</TH>
            <TH />
          </TR>
        </THead>

        <TBody>
          {trainingDatasSorted.map(data => (
            <TrainingRow key={data.trainingId} trainingData={data} {...props} />
          ))}
        </TBody>
      </Table>
    </TrainingTableWrapper>
  );
};

type TrainingRowProps = TrainingTableProps & {
  trainingData: TrainingDocument;
};
const TrainingRow: React.FC<TrainingRowProps> = props => {
  const tr = props.trainingData;
  const trainingId = tr.trainingId;
  const [initTrainingMembers] = useTrainingMembersData(trainingId);
  const participants = initTrainingMembers?.participants ?? null;
  const participantsCount =
    participants !== null ? String(participants.length) : '--';

  return (
    <TR>
      <TD>
        <p>
          {tr.scheduledStartTime &&
            format(tr.scheduledStartTime, 'yyyy年M月d日(E) HH:mm', {
              locale: ja,
            })}
        </p>
        <p>
          {' - '}
          {tr.scheduledEndTime &&
            format(tr.scheduledEndTime, 'yyyy年M月d日(E) HH:mm', {
              locale: ja,
            })}
        </p>
      </TD>
      <TD>
        <p>{tr.name}</p>
      </TD>
      <TD>
        <p>{tr.place}</p>
      </TD>
      <TD>
        <RowButton
          variant='dark'
          disabled={false}
          onClick={() => props.onJoin(tr.trainingId)}
        >
          研修をはじめる
        </RowButton>
      </TD>
    </TR>
  );
};

const TrainingTableWrapper = styled.div`
  overflow: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 2.4rem;
`;

const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;
`;

const TD = styled.td`
  font-size: 1.4rem;
  border-bottom: 1px solid ${Colors.gray8};
  padding: 0.8rem 1.4rem;
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  border-bottom: 1px solid ${Colors.gray8};
  padding: 1.5rem 0 1.5rem 1.4rem;
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;

  td:nth-child(1) {
    width: 12rem;
  }
  td:nth-child(2) {
    width: 10rem;
  }

  td:last-child {
    width: 15.5rem;
    padding-right: 0;
  }
`;

const RowButton = styled(Button).attrs({
  scale: 0.5,
})`
  border-radius: 5px;
  padding: 0.6rem 1.6rem;
  height: initial;
  width: 100%;
`;

const TopInfo = styled.div`
  width: 100%;
  margin-top: 1.8rem;
  border-bottom: 2px solid ${Colors.gray8};
  padding: 1.2rem 0;
  position: relative;
`;
const TopText = styled.div`
  font-size: 1.6rem;
  font-weight: 500;
  display: inline-block;
`;

export default TrainingTable;
