import firebase from 'firebase/app';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { deleteTokenCookie } from '../../api/sso';
import asContent from '../../hoc/asContent';

interface LogoutProps {}
const Logout: FC<LogoutProps> = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.localStorage.removeItem('selectedOrgId');
    window.localStorage.clear();
    deleteTokenCookie();
    firebase
      .auth()
      .signOut()
      .then(() => {
        navigate('/');
      });
  }, [navigate]);

  return <></>;
};

export default asContent(Logout);
