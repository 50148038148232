import { SubButton as _Button } from '@riddler-co-jp/specc-ui-components';
import format from 'date-fns/format';
import { ja } from 'date-fns/locale';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useOrganization } from '../../../api/organization';
import { TrainingDocument } from '../../../api/training';
import { curriculumsToSubject } from '../../../lib/organization';
import Colors from '../../../styles/colors';
import { TrainingStatus } from '../../../types';
import { AdminRole } from '../AdminAdministratorDetail';
import AdminSkillSubjects from '../UIelements/AdminSkillSubjects';
import TrainingControlParticipantManager from '../UIelements/TrainingControlParticipantManager';

interface AdminTrainingControllerProps {
  training: TrainingDocument;
  participantUids: string[];
  onCancel?: () => void;
  onStartTraining: () => void;
  onEndTraining: () => void;
  onRemoveTraining: () => void;
  role: AdminRole;
  onCsvDownload: () => void;
  csvClicked: boolean;
}

export const trainingStatusShortText = (tr: TrainingDocument) => {
  switch (tr.status) {
    case TrainingStatus.Ongoing:
      return '開催中';
    case TrainingStatus.Preparing:
      return '未開催';
    case TrainingStatus.Ended:
      return '終了したテスト';
    case TrainingStatus.Removed:
      return '削除済み';
    default:
      return '不明';
  }
};

const trainingStatusText = (tr: TrainingDocument) => {
  switch (tr.status) {
    case TrainingStatus.Ongoing:
      return 'このイベントは開催中です。';
    case TrainingStatus.Preparing:
      return 'このイベントは未開催の状態です。\n準備ができたら「テストを開催する」ボタンをクリックしてください。';
    case TrainingStatus.Ended:
      return 'このイベントは既に終了しています。';
    case TrainingStatus.Removed:
      return 'このイベントは削除済みです。';
    default:
      return '不明なイベント状態です。';
  }
};
const trainingStatusButtonText = (tr: TrainingDocument) => {
  switch (tr.status) {
    case TrainingStatus.Ongoing:
      return '終了する';
    case TrainingStatus.Preparing:
      return 'テストを開催する';
    default:
      return '';
  }
};

const AdminTrainingController: React.FC<AdminTrainingControllerProps> =
  props => {
    const training = props.training;
    const navigate = useNavigate();

    const {
      role,
      participantUids,
      onCancel,
      onRemoveTraining,
      onStartTraining,
      onEndTraining,
    } = props;

    const onMoveToResult = React.useCallback(
      (uid: string) => {
        navigate(`/admin/trainings/${training.trainingId}/result/${uid}`);
      },
      [training.trainingId, navigate]
    );

    const confirmRemoveTraining = React.useCallback(() => {
      if (window.confirm(`研修「${training.name}」を削除しますか？`)) {
        onRemoveTraining();
      }
    }, [onRemoveTraining, training.name]);

    const onTrainingStatusButton = React.useCallback(() => {
      if (training.status === TrainingStatus.Preparing) {
        // 開始
        onStartTraining();
      }
      if (training.status === TrainingStatus.Ongoing) {
        // 終了
        onEndTraining();
      }
    }, [training.status, onEndTraining, onStartTraining]);

    const [org] = useOrganization(training.orgId);

    return (
      <AdminTrainingControllerWrapper>
        <Row>
          <DetailTable>
            <THead>
              <TR>
                <TH>研修名</TH>
                <TH>開催期間</TH>
                <TH>会場</TH>
              </TR>
            </THead>

            <TBody>
              <TR>
                <TD>{training.name}</TD>
                <TD>
                  {training.scheduledStartTime &&
                    format(
                      training.scheduledStartTime,
                      'yyyy年M月d日(E) HH:mm',
                      {
                        locale: ja,
                      }
                    )}
                  {' - '}
                  {training.scheduledEndTime &&
                    format(training.scheduledEndTime, 'yyyy年M月d日(E) HH:mm', {
                      locale: ja,
                    })}
                </TD>
                <TD>
                  {training.place == null ? '(指定なし)' : training.place}
                </TD>
              </TR>
            </TBody>
          </DetailTable>
        </Row>

        <Row>
          <Item>
            <ItemTitle>ステータス</ItemTitle>
            <ItemBody>
              <CurrentStatus>
                <SingleStatus
                  active={training.status == TrainingStatus.Preparing}
                >
                  未開催
                </SingleStatus>
                <SingleStatus
                  active={training.status == TrainingStatus.Ongoing}
                >
                  開催中
                </SingleStatus>
                <SingleStatus active={training.status == TrainingStatus.Ended}>
                  テスト終了
                </SingleStatus>
              </CurrentStatus>
              {props.role !== 'organizationAdmin' &&
                trainingStatusButtonText(training) !== '' && (
                  <EventStatusText>
                    <p>{trainingStatusText(training)}</p>
                    <InlineButton
                      size='medium'
                      color='positive'
                      onClick={onTrainingStatusButton}
                    >
                      {trainingStatusButtonText(training)}
                    </InlineButton>
                  </EventStatusText>
                )}
            </ItemBody>
          </Item>
        </Row>

        <Row>
          <Item>
            <ItemTitle>カリキュラム</ItemTitle>
            <ItemBody>
              <AdminSkillSubjects
                enabledSubject={curriculumsToSubject(org?.curriculums ?? null)}
                selectedSubject={curriculumsToSubject(training.curriculums)}
              />
            </ItemBody>
          </Item>
        </Row>

        <Row>
          <Item>
            <ItemTitle>参加者</ItemTitle>
            <ItemBody>
              {
                <TrainingControlParticipantManager
                  type='control'
                  training={training}
                  participantUids={participantUids}
                  onMoveToResult={onMoveToResult}
                  orgId={training.orgId}
                  isAdmin={false}
                />
              }
            </ItemBody>
          </Item>
        </Row>
        <Buttons>
          <DeleteButton onClick={confirmRemoveTraining} disabled={false}>
            イベントを削除する
          </DeleteButton>
        </Buttons>
      </AdminTrainingControllerWrapper>
    );
  };

const AdminTrainingControllerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Row = styled.div`
  margin-bottom: 2rem;

  &:last-child {
    border-bottom: none;
  }
`;

const Item = styled.div`
  width: 100%;
`;

const ItemTitle = styled.div`
  font-size: 2.4rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const ItemBody = styled.div`
  width: 100%;
`;

const ItemText = styled.span`
  display: inline-block;
  font-size: 1.3rem;
`;

const Button = styled(_Button)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

const InlineButton = styled(Button)`
  display: inline-block;
  font-weight: bold;
`;

const Buttons = styled.div`
  width: 100%;
  text-align: right;
  padding: 0 2rem;
  box-sizing: border-box;
  margin-bottom: 2rem;
`;

const DetailTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1rem;

  tr {
    td:nth-child(1) {
      /*width: 40%;*/
    }
    td:nth-child(2) {
      width: 18rem;
    }
    td:nth-child(3) {
      width: 20rem;

      p {
        margin-right: 1rem;
      }
      p:last-child {
        margin-right: 0;
      }
    }

    td:last-child {
      width: 20rem;
      padding-right: 0;
    }
  }
`;

const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;

  tr:last-child {
    border: none !important;
  }
`;

const TD = styled.td`
  font-size: 1.4rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;
  border-bottom: 1px solid ${Colors.gray4};
`;

const EventStatusText = styled.div`
  padding: 1rem 1.6rem;
  white-space: pre-wrap;
  border-radius: 5px;
  font-size: 1.4rem;
  background: ${Colors.gray2};
  display: flex;

  p {
    flex: 1;
  }
`;

interface DeleteButton {
  disabled: boolean;
}

const DeleteButton = styled.div`
  color: ${Colors.error};
  cursor: pointer;
  display: initial;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  transition: 0.2s;

  &:hover {
    background: ${Colors.gray4};
  }

  ${(p: DeleteButton) =>
    p.disabled &&
    `
    cursor: default;
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      background: initial;
    }
    `}
`;

const CurrentStatus = styled.div`
  display: flex;
  border: 2px solid ${Colors.primary};
  border-radius: 5px;
  margin-top 2rem;
  margin-bottom: 1rem;
`;

interface SingleStatusProps {
  active?: boolean;
}
const SingleStatus = styled.div`
  flex: 1;
  padding: 1.8rem 1rem;
  text-align: center;
  font-weight: bold;
  font-size: 1.4rem;
  color: ${Colors.gray6};
  position: relative;

  border-right: 2px solid ${Colors.primary};

  &:last-child {
    border: none;
  }

  &:after {
    content: '';
    z-index: 1;
    height: 12px;
    width: 12px;
    display: block;
    position: absolute;
    border-top: 2px solid ${Colors.primary};
    border-right: 2px solid ${Colors.primary};
    transform: rotate(45deg);
    background: white;
    top: 22px;
    right: -8px;
  }

  &:last-child {
    &:after {
      display: none;
    }
  }

  ${(p: SingleStatusProps) =>
    p.active &&
    `
      background: ${Colors.primary};
      color: white;

      &:after {
        background: ${Colors.primary};
      }
    `}
`;

export default AdminTrainingController;
