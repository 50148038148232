import { gql } from '@apollo/client';
import firebase from 'firebase/app';
import {
  DocumentDataHook,
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';

import { UserInfo, UserOrgInfo } from '../api/firestoreTypes';
import { TS2DateType } from '../lib/firebase/firestoreTypeUtils';
import { Maybe } from '../types';
import { UserInfo as UserDocumentRaw } from './firestoreTypes';

export const userSchema = gql`
  query validateRegistrationToken($input: ValidateRegistrationTokenInput!) {
    validateRegistrationToken(input: $input) {
      organizationName
    }
  }

  mutation register($input: RegisterInput!) {
    register(input: $input) {
      uid
    }
  }

  mutation resendRegisterMail($input: ResendRegisterMailInput!) {
    resendRegisterMail(input: $input) {
      success
    }
  }

  mutation registerUserInfo($input: RegisterUserInfoInput!) {
    registerUserInfo(input: $input) {
      success
    }
  }

  mutation updateUserInfo($input: UpdateUserInfoInput!) {
    updateUserInfo(input: $input) {
      success
    }
  }

  mutation updateEmail($input: EmailInput!) {
    updateEmail(input: $input) {
      success
    }
  }

  mutation confirmUpdateEmail($input: EmailInput!) {
    confirmUpdateEmail(input: $input) {
      success
    }
  }
  mutation changeToVerifiedEmail($input: EmailInput!) {
    changeToVerifiedEmail(input: $input) {
      success
    }
  }
  mutation cancelUpdateEmail($input: EmailInput!) {
    cancelUpdateEmail(input: $input) {
      success
    }
  }
`;
export type UserDocumentNoId = TS2DateType<UserDocumentRaw>;
export type UserDocument = UserDocumentNoId & {
  uid: string;
};

export const useUserInfo = (
  userId?: Maybe<string>
): DocumentDataHook<UserInfo> => {
  const docRef =
    userId != null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('user_info')
          .doc(userId)
      : undefined;
  return useDocumentData<UserInfo>(docRef);
};

export const useUserOrgInfo = (
  userId?: Maybe<string>,
  orgId?: Maybe<string>
): DocumentDataHook<UserOrgInfo> => {
  const docRef =
    userId != null && orgId != null
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('user_info')
          .doc(userId)
          .collection('organizations')
          .doc(orgId)
      : undefined;
  return useDocumentData<UserOrgInfo>(docRef);
};

const mapper = (raw: UserDocumentRaw): UserDocumentNoId => ({
  ...raw,
  birthday: raw.birthday?.toDate() ?? null,
  createdOn: raw.createdOn?.toDate() ?? null,
});

export const useAllUserInfoWithOrgId = (orgId?: string | null) => {
  return useCollectionData<UserDocumentNoId, 'uid'>(
    orgId
      ? firebase
          .firestore()
          .collection('version')
          .doc('1')
          .collection('user_info')
          .where('enable', '==', true)
          .where('mainOrgId', '==', orgId)
      : undefined,
    {
      transform: mapper,
      idField: 'uid',
    }
  );
};

export const useAllAdminUserInfo = () => {
  return useCollectionData<UserDocumentNoId, 'uid'>(
    firebase
      .firestore()
      .collection('version')
      .doc('1')
      .collection('user_info')
      .where('enable', '==', true)
      .where('commonRoles', 'array-contains-any', [
        'riddlerAdmin',
        'dentsuAdmin',
        'organizationAdmin',
      ]),
    {
      transform: mapper,
      idField: 'uid',
    }
  );
};
