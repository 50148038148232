import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubButton } from '@riddler-co-jp/specc-ui-components';
import { Pagination } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useOrgAllRegistrationTokens } from '../../../api/adminRole';
import {
  UserOrgDocument,
  useOrganizationUserOrgs,
} from '../../../api/organization';
import { UserDocument, useAllUserInfoWithOrgId } from '../../../api/user';
import { options_ind, options_occ } from '../../../lib/userProfiles';
import { useSelectedOrgId } from '../../../redux/selectors/authSelectors';
import Colors from '../../../styles/colors';
import AdminUserAccountsTable from '../../eventProvider/AdminUserAccountsTable';
import AdminCommonBG from '../AdminCommonBG';
import { HeaderButton } from '../UIelements/AdminNavigation';
import AdminPopup from '../UIelements/AdminPopup';
import Filter from '../UIelements/Filter';
import { adminUserAccountsSorter } from '../UIelements/sorter/AdminUserAccountsSorter';

export type AccountParams = {
  userData: UserDocument;
  userOrg: UserOrgDocument;
  userId: string;
};

const frontendURI = process.env.REACT_APP_FRONTEND_URI;

const AdminUserAccounts: React.FC = () => {
  const navigate = useNavigate();
  const orgId = useSelectedOrgId();
  const [users, loading, error] = useAllUserInfoWithOrgId(orgId);
  const [userOrgs] = useOrganizationUserOrgs(orgId);

  const paramList = React.useMemo(() => {
    const ret: AccountParams[] = [];
    if (users !== undefined && userOrgs !== undefined) {
      const userTable: { [key: string]: UserDocument } = {};
      users.forEach(doc => {
        userTable[doc.uid] = doc;
      });
      userOrgs.forEach((doc: UserOrgDocument) => {
        if (!(doc.uid in userTable)) {
          return;
        }

        const param: AccountParams = {
          userId: doc.uid,
          userOrg: doc,
          userData: userTable[doc.uid],
        };
        ret.push(param);
      });
    }
    return ret;
  }, [users, userOrgs]);

  const onAdminEdit = (adminId?: string) => {
    navigate(`/admin/accounts/${adminId}/edit`);
  };
  const onAdminControl = (adminId?: string) => {
    navigate(`/admin/accounts/${adminId}/control`);
  };

  const [sortKey, setSortKey] = React.useState({
    key: 'fullName',
    sort: 'ASC',
  });

  const onChangeSort = (sortObj: any) => {
    setSortKey(sortObj);
  };

  const [inviteUrlCollection] = useOrgAllRegistrationTokens(orgId);

  const inviteUrl = React.useMemo(() => {
    if (inviteUrlCollection === undefined) {
      return undefined;
    }
    const token = inviteUrlCollection.filter(
      e => e.orgId === orgId && e.adminRole == null
    )[0];
    return frontendURI + '/register?token=' + (token?.token ?? '');
  }, [inviteUrlCollection, orgId]);

  const textAreaRef = React.useRef<HTMLDivElement>(null);
  const copyToClipboard = React.useCallback(
    (e: MouseEvent) => {
      if (inviteUrl) {
        navigator.clipboard.writeText(inviteUrl).then(e => {
          window.alert(`招待リンクをクリップボードにコピーしました。`);
        });
      }
    },
    [inviteUrl]
  );

  const UserInfoDetail = (
    <UserInfoPopup>
      <p>アカウント招待リンク</p>

      <InviteUrl ref={textAreaRef}>{inviteUrl}</InviteUrl>
      <ButtonWrap>
        <SubButton
          size={'medium'}
          color={'negative'}
          onMouseDown={copyToClipboard}
        >
          クリップボードにコピー
        </SubButton>
      </ButtonWrap>
    </UserInfoPopup>
  );

  const btnInvite: HeaderButton = {
    buttonDom: (
      <AdminPopup width={360} key='popup' content={UserInfoDetail}>
        <SubButton size={'medium'} color={'positive'}>
          招待する
          <BtnSvg>
            <FontAwesomeIcon icon={faChevronDown} />
          </BtnSvg>
        </SubButton>
      </AdminPopup>
    ),
    chevron: true,
  };

  let fixed_options_ind: any = [];
  fixed_options_ind = fixed_options_ind.concat({
    value: 'all',
    label: '全ての業種',
  });
  fixed_options_ind = fixed_options_ind.concat(options_ind);

  let fixed_options_occ: any = [];
  fixed_options_occ = fixed_options_occ.concat({
    label: '',
    options: [{ value: 'all', label: '全ての職種' }],
  });
  fixed_options_occ = fixed_options_occ.concat(options_occ);

  const [pageCursor, setPageCursor] = React.useState<number>(0);
  const pageSize = 10;

  const [filter, setFilter] = React.useState<any>({});
  const sorter = adminUserAccountsSorter[sortKey.key][sortKey.sort];
  const filteredParamList = React.useMemo(() => {
    console.log(filter, filter.industry, filter.occupation, paramList);
    const ret = paramList
      .sort(sorter)
      .filter(
        e =>
          (!filter?.name || e.userData.fullName?.includes(filter.name)) &&
          (!filter?.year || e.userOrg.startYear?.toString() === filter.year) &&
          (!filter?.department ||
            e.userOrg.department?.includes(filter.department)) &&
          ((filter?.industry ?? null) === null ||
            filter?.industry === 'all' ||
            (filter?.industry === '' && e.userOrg.industryId === '') ||
            ((filter?.industry ?? '') !== '' &&
              filter?.industry === e.userOrg.industryId)) &&
          ((filter?.occupation ?? null) === null ||
            filter?.occupation === 'all' ||
            (filter?.occupation === '' && e.userOrg.occupationId === '') ||
            ((filter?.occupation ?? '') !== '' &&
              filter?.occupation === e.userOrg.occupationId))
      )
      .map(e => {
        return e;
      });
    return ret;
  }, [
    filter.department,
    filter.industry,
    filter.name,
    filter.occupation,
    filter.year,
    paramList,
    sorter,
  ]);

  return (
    <AdminCommonBG
      title='アカウント'
      headerButtons={orgId === null ? [] : [btnInvite]}
    >
      <Filter
        onSend={params => {
          console.log(params);
          // Task Filter Account *
          setFilter(params);
          setPageCursor(0);
        }}
        item={[
          { type: 'input', name: 'name', label: '氏名' },
          { type: 'input', name: 'year', label: '入社年' },
          { type: 'input', name: 'department', label: '部署' },
          {
            type: 'select',
            name: 'industry',
            label: '業種',
            defaultValue: { value: 'all', label: '全ての業種' },
            options: fixed_options_ind,
          },
          {
            type: 'select',
            name: 'occupation',
            label: '職種',
            defaultValue: { value: 'all', label: '全ての職種' },
            options: fixed_options_occ,
          },
        ]}
      />
      <AdminUserAccountsWrapper>
        <AdminUserAccountsTable
          accountParams={filteredParamList.filter(
            (_, i) => pageCursor <= i && i < pageCursor + pageSize
          )}
          onAdminEdit={onAdminEdit}
          onAdminControl={onAdminControl}
          sortObj={sortKey}
          onChangeSort={onChangeSort}
        />
      </AdminUserAccountsWrapper>
      <Pagination
        count={filteredParamList.length}
        begin={pageCursor}
        end={Math.min(pageCursor + pageSize, filteredParamList.length)}
        onNext={() => {
          setPageCursor(pageCursor + pageSize);
        }}
        onPrev={() => {
          setPageCursor(Math.max(pageCursor - pageSize, 0));
        }}
      />
    </AdminCommonBG>
  );
};

const AdminUserAccountsWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const UserInfoPopup = styled.div`
  padding: 1rem 1.6rem;
  text-align: left;

  p {
    font-size: 1.8rem;
  }
  span {
    font-size: 1.4rem;
  }
`;

const ButtonWrap = styled.div`
  padding: 1rem 1.6rem;
  width: 100%;
  margin: 0 -1.6rem;
  margin-bottom: -1rem;
  button {
    display: block;
    margin-left: auto;
  }
`;

const BtnSvg = styled.span`
  margin-left: 1rem;
`;

const InviteUrl = styled.div`
  margin-top: 1rem;
  background: ${Colors.gray2};
  border: 2px solid ${Colors.gray4};
  border-radius: 5px;
  font-size: 1.4rem;
  padding: 1rem 1rem;
  overflow-x: scroll;
  white-space: nowrap;
  user-select: all;
`;

export default AdminUserAccounts;
