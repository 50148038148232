import { OrganizationDocument } from '../api/organization';

export const defaultCreateOrganization: OrganizationDocument = {
  orgId: 'create',
  name: '新規企業',
  members: [],
  admin: [],
  players: [],
  createdOn: new Date(),
  enable: true,
  imgSrc: null,
  curriculums: [],
  presets: [],
};

export const AllCurriculums = [
  'switch',
  'program',
  'create',
  'energy',
  'communicate',
] as const;
export type Curriculum = typeof AllCurriculums[number];

export const curriculumsToSubject = (
  curriculums: null | string[]
): boolean[] => {
  return AllCurriculums.map(e => curriculums?.includes(e) ?? false);
};

export const subjectToCurriculums = (subject: boolean[]): Curriculum[] => {
  return AllCurriculums.filter((e, i) => subject[i]);
};
