import { Validators } from '@riddler-co-jp/specc-ui-components';

export const displayNameValidators: () => Validators = () => [
  [val => !/^.{0,20}$/.test(val), '20文字以内で入力してください。'],
  [(_, state) => state.isFocused],
  [val => val === '', '表示名を入力してください'],
];
export const rubyValidators: () => Validators = () => [
  [(_, state) => state.isFocused],
  [val => val.length > 40, '40文字以内で入力してください。'],
  [val => !/^[ぁ-ゞー\u3000]*$/.test(val), '全角ひらがなで入力してください。'],
  [val => val === '', 'よみがなを入力してください'],
];
export const startYearValidators: () => Validators = () => [
  [val => !/^(19|20)\d{2}$/.test(val), '西暦(4桁の数字)で入力してください'],
  [(_, state) => state.isFocused],
  [val => val === '', '入社年を入力してください'],
];
export const fullNameValidators: () => Validators = () => [
  [(_, state) => state.isFocused],
  [val => val.length > 20, '20文字以内で入力してください。'],
  [val => val === '', '氏名を入力してください'],
];
export const employeeIdValidators: () => Validators = () => [
  [val => val.length > 20, '20文字以内で入力してください。'],
  [val => !/^[a-zA-Z0-9]*$/.test(val), '半角英数字で入力してください'],
];
