import { ErrorText } from '@riddler-co-jp/specc-ui-components';
import {
  FormElement,
  FormSubmitButton,
  Form as SettingsForm,
} from '@riddler-co-jp/specc-ui-components';
import {
  Validators,
  emailFormatValidator,
  pwFormatValidator,
  useValidator,
} from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { HandleFirebaseErrorResult } from '../../../lib/firebase/firebaseErrorHandlers';
import useFirebaseError from '../../../lib/firebase/useFirebaseError';
import { useCurrentUser } from '../../../redux/selectors/authSelectors';
import Colors from '../../../styles/colors';
import SettingsItemBody from '../SettingsItemBody';
import SettingsItemTitle from '../SettingsItemTitle';

interface MailChangeViewProps {
  done: boolean;
  error: HandleFirebaseErrorResult | null;
  onConfirmChange(PW: string, newMail: string): void;
}
const MailChangeView: React.FC<MailChangeViewProps> = props => {
  const { emailError, pwError, otherError, resetError } = useFirebaseError(
    props.error
  );

  const [currentMail, setCurrentMail] =
    React.useState<string | null | undefined>(null);
  const [newMail, setNewMail] = React.useState('');
  const [PW, setPW] = React.useState('');
  const user = useCurrentUser();
  const [hideError, setHideError] = React.useState(false);

  if (user !== undefined && currentMail === null) {
    setCurrentMail(user?.email);
  }

  const resetAll = React.useCallback(() => {
    setCurrentMail('');
    setNewMail('');
    setPW('');
  }, []);

  React.useEffect(() => {
    if (props.done) {
      resetAll();
    }
  }, [props.done, resetAll]);

  const PWonChange = React.useCallback(
    (val: string) => {
      setPW(val);
      resetError('pw');
      setHideError(true);
    },
    [setPW, resetError]
  );

  const MailValidators = React.useMemo<Validators>(
    () => [
      [(_, state) => state.isFocused],
      [val => val === ''],
      [() => !!emailError && hideError == false, emailError ?? ''],
      [val => val === currentMail, '新しいメールアドレスを入力してください。'],
      emailFormatValidator,
    ],
    [emailError, currentMail, hideError]
  );
  const [MailValidator, MailIsValid] = useValidator(MailValidators);

  const PWValidators = React.useMemo<Validators>(
    () => [
      [(_, state) => state.isFocused],
      [val => val === ''],
      [() => !!pwError && hideError == false, pwError ?? ''],
      pwFormatValidator,
    ],
    [pwError, hideError]
  );
  const [PWValidator, PWIsValid] = useValidator(PWValidators);

  const onConfirmChange = React.useCallback(() => {
    setHideError(false);
    props.onConfirmChange(PW, newMail);
  }, [PW, newMail, props]);

  const formElements = React.useMemo<FormElement[]>(
    () => [
      {
        id: 'new-mail',
        label: '新しいメールアドレス',
        type: 'text',
        value: newMail,
        onChange: v => {
          setNewMail(v);
          setHideError(true);
        },
        autoComplete: 'confirm-password',
        validator: MailValidator,
      },
      {
        id: 'confirm-password',
        label: '確認のため、パスワードを入力してください。',
        type: 'password',
        value: PW,
        onChange: PWonChange,
        autoComplete: 'confirm-password',
        validator: PWValidator,
      },
    ],
    [PW, PWValidator, PWonChange, newMail, MailValidator]
  );

  return (
    <>
      <SettingsItemTitle>{'メールアドレスの変更'}</SettingsItemTitle>
      <SettingsItemBody>
        <CurrentMailTitle>現在のメールアドレス</CurrentMailTitle>
        <CurrentMail>{currentMail}</CurrentMail>
        <SettingsForm elements={formElements} />
        <FormSubmitButton
          variant='main'
          size='large'
          color='positive'
          disabled={!PWIsValid || !MailIsValid}
          onClick={onConfirmChange}
        >
          変更
        </FormSubmitButton>
        <Errors>
          {emailError && <ErrorText>{emailError}</ErrorText>}
          {otherError && <ErrorText>{otherError}</ErrorText>}
        </Errors>
      </SettingsItemBody>
    </>
  );
};

const CurrentMailTitle = styled.div`
  font-size: 1.5rem;
  margin-bottom: 1rem;
`;
const CurrentMail = styled.div`
  overflow-wrap: anywhere;
  width: 100%;
  font-size: 1.8rem;
  box-sizing: border-box;
  border-style: solid;
  outline: 0;
  text-align: left;
  border-width: 0.2rem;
  border-radius: 0.5rem;
  border-color: ${Colors.gray4};
  background: ${Colors.gray2};

  line-height: 2.2rem;
  padding: 10px 14px;
  margin-bottom: 2rem;
`;
const Errors = styled.div`
  margin-top: 2rem;
`;
export default MailChangeView;
