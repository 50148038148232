import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';

import asContent from '../../../hoc/asContent';
import CommonBG from '../../uiElements/CommonBG';
import TrainingStageSelector from '../TrainingStageSelector';

interface StagesProps {}

const StagesRouter: React.FC<StagesProps> = props => {
  return (
    <Routes>
      <Route path={`*`} element={<TrainingStages {...props} />}></Route>
    </Routes>
  );
};

const TrainingStages: React.FC<StagesProps> = () => {
  return (
    <CommonBG
      withHeader
      withFooter
      headerOptions={{ buttonType: 'logout', logoType: 'ST' }}
    >
      <StagesWrapper>
        <TrainingStageSelector />
      </StagesWrapper>
    </CommonBG>
  );
};

const StagesWrapper = styled.div`
  padding-top: 5rem;
  text-align: center;
  margin: 0 40px;
`;

export default asContent(StagesRouter);
