import * as Types from '../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions =  {}
export type GetParticipantUserDataQueryVariables = Types.Exact<{
  input: Types.GetParticipantUserDataInput;
}>;


export type GetParticipantUserDataQuery = (
  { __typename?: 'Query' }
  & { getParticipantUserData: (
    { __typename?: 'UserData' }
    & Pick<Types.UserData, 'uid' | 'displayName' | 'email'>
  ) }
);

export type CreateEventMutationVariables = Types.Exact<{
  input: Types.CreateEventInput;
}>;


export type CreateEventMutation = (
  { __typename?: 'Mutation' }
  & { createEvent: (
    { __typename?: 'CreateEventResult' }
    & Pick<Types.CreateEventResult, 'eventId'>
  ) }
);

export type CopyEventMutationVariables = Types.Exact<{
  input: Types.CopyEventInput;
}>;


export type CopyEventMutation = (
  { __typename?: 'Mutation' }
  & { copyEvent: (
    { __typename?: 'CopyEventResult' }
    & Pick<Types.CopyEventResult, 'eventId'>
  ) }
);

export type ChangeEventStatusMutationVariables = Types.Exact<{
  input: Types.EventStatusInput;
}>;


export type ChangeEventStatusMutation = (
  { __typename?: 'Mutation' }
  & { changeEventStatus: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);

export type ChangeEventInformationMutationVariables = Types.Exact<{
  input: Types.EventInformationInput;
}>;


export type ChangeEventInformationMutation = (
  { __typename?: 'Mutation' }
  & { changeEventInformation: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);

export type AddParticipantMutationVariables = Types.Exact<{
  input: Types.ParticipantInput;
}>;


export type AddParticipantMutation = (
  { __typename?: 'Mutation' }
  & { addParticipant: (
    { __typename?: 'AddParticipantResult' }
    & Pick<Types.AddParticipantResult, 'rejectedUids'>
  ) }
);

export type RemoveParticipantMutationVariables = Types.Exact<{
  input: Types.ParticipantInput;
}>;


export type RemoveParticipantMutation = (
  { __typename?: 'Mutation' }
  & { removeParticipant: (
    { __typename?: 'GeneralExecutionResult' }
    & Pick<Types.GeneralExecutionResult, 'success'>
  ) }
);


export const GetParticipantUserDataDocument = gql`
    query getParticipantUserData($input: GetParticipantUserDataInput!) {
  getParticipantUserData(input: $input) {
    uid
    displayName
    email
  }
}
    `;

/**
 * __useGetParticipantUserDataQuery__
 *
 * To run a query within a React component, call `useGetParticipantUserDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParticipantUserDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParticipantUserDataQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetParticipantUserDataQuery(baseOptions: Apollo.QueryHookOptions<GetParticipantUserDataQuery, GetParticipantUserDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParticipantUserDataQuery, GetParticipantUserDataQueryVariables>(GetParticipantUserDataDocument, options);
      }
export function useGetParticipantUserDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParticipantUserDataQuery, GetParticipantUserDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParticipantUserDataQuery, GetParticipantUserDataQueryVariables>(GetParticipantUserDataDocument, options);
        }
export type GetParticipantUserDataQueryHookResult = ReturnType<typeof useGetParticipantUserDataQuery>;
export type GetParticipantUserDataLazyQueryHookResult = ReturnType<typeof useGetParticipantUserDataLazyQuery>;
export type GetParticipantUserDataQueryResult = Apollo.QueryResult<GetParticipantUserDataQuery, GetParticipantUserDataQueryVariables>;
export const CreateEventDocument = gql`
    mutation createEvent($input: CreateEventInput!) {
  createEvent(input: $input) {
    eventId
  }
}
    `;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const CopyEventDocument = gql`
    mutation copyEvent($input: CopyEventInput!) {
  copyEvent(input: $input) {
    eventId
  }
}
    `;
export type CopyEventMutationFn = Apollo.MutationFunction<CopyEventMutation, CopyEventMutationVariables>;

/**
 * __useCopyEventMutation__
 *
 * To run a mutation, you first call `useCopyEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyEventMutation, { data, loading, error }] = useCopyEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyEventMutation(baseOptions?: Apollo.MutationHookOptions<CopyEventMutation, CopyEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyEventMutation, CopyEventMutationVariables>(CopyEventDocument, options);
      }
export type CopyEventMutationHookResult = ReturnType<typeof useCopyEventMutation>;
export type CopyEventMutationResult = Apollo.MutationResult<CopyEventMutation>;
export type CopyEventMutationOptions = Apollo.BaseMutationOptions<CopyEventMutation, CopyEventMutationVariables>;
export const ChangeEventStatusDocument = gql`
    mutation changeEventStatus($input: EventStatusInput!) {
  changeEventStatus(input: $input) {
    success
  }
}
    `;
export type ChangeEventStatusMutationFn = Apollo.MutationFunction<ChangeEventStatusMutation, ChangeEventStatusMutationVariables>;

/**
 * __useChangeEventStatusMutation__
 *
 * To run a mutation, you first call `useChangeEventStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEventStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEventStatusMutation, { data, loading, error }] = useChangeEventStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeEventStatusMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEventStatusMutation, ChangeEventStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeEventStatusMutation, ChangeEventStatusMutationVariables>(ChangeEventStatusDocument, options);
      }
export type ChangeEventStatusMutationHookResult = ReturnType<typeof useChangeEventStatusMutation>;
export type ChangeEventStatusMutationResult = Apollo.MutationResult<ChangeEventStatusMutation>;
export type ChangeEventStatusMutationOptions = Apollo.BaseMutationOptions<ChangeEventStatusMutation, ChangeEventStatusMutationVariables>;
export const ChangeEventInformationDocument = gql`
    mutation changeEventInformation($input: EventInformationInput!) {
  changeEventInformation(input: $input) {
    success
  }
}
    `;
export type ChangeEventInformationMutationFn = Apollo.MutationFunction<ChangeEventInformationMutation, ChangeEventInformationMutationVariables>;

/**
 * __useChangeEventInformationMutation__
 *
 * To run a mutation, you first call `useChangeEventInformationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeEventInformationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeEventInformationMutation, { data, loading, error }] = useChangeEventInformationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeEventInformationMutation(baseOptions?: Apollo.MutationHookOptions<ChangeEventInformationMutation, ChangeEventInformationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeEventInformationMutation, ChangeEventInformationMutationVariables>(ChangeEventInformationDocument, options);
      }
export type ChangeEventInformationMutationHookResult = ReturnType<typeof useChangeEventInformationMutation>;
export type ChangeEventInformationMutationResult = Apollo.MutationResult<ChangeEventInformationMutation>;
export type ChangeEventInformationMutationOptions = Apollo.BaseMutationOptions<ChangeEventInformationMutation, ChangeEventInformationMutationVariables>;
export const AddParticipantDocument = gql`
    mutation addParticipant($input: ParticipantInput!) {
  addParticipant(input: $input) {
    rejectedUids
  }
}
    `;
export type AddParticipantMutationFn = Apollo.MutationFunction<AddParticipantMutation, AddParticipantMutationVariables>;

/**
 * __useAddParticipantMutation__
 *
 * To run a mutation, you first call `useAddParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addParticipantMutation, { data, loading, error }] = useAddParticipantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddParticipantMutation(baseOptions?: Apollo.MutationHookOptions<AddParticipantMutation, AddParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddParticipantMutation, AddParticipantMutationVariables>(AddParticipantDocument, options);
      }
export type AddParticipantMutationHookResult = ReturnType<typeof useAddParticipantMutation>;
export type AddParticipantMutationResult = Apollo.MutationResult<AddParticipantMutation>;
export type AddParticipantMutationOptions = Apollo.BaseMutationOptions<AddParticipantMutation, AddParticipantMutationVariables>;
export const RemoveParticipantDocument = gql`
    mutation removeParticipant($input: ParticipantInput!) {
  removeParticipant(input: $input) {
    success
  }
}
    `;
export type RemoveParticipantMutationFn = Apollo.MutationFunction<RemoveParticipantMutation, RemoveParticipantMutationVariables>;

/**
 * __useRemoveParticipantMutation__
 *
 * To run a mutation, you first call `useRemoveParticipantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveParticipantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeParticipantMutation, { data, loading, error }] = useRemoveParticipantMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveParticipantMutation(baseOptions?: Apollo.MutationHookOptions<RemoveParticipantMutation, RemoveParticipantMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveParticipantMutation, RemoveParticipantMutationVariables>(RemoveParticipantDocument, options);
      }
export type RemoveParticipantMutationHookResult = ReturnType<typeof useRemoveParticipantMutation>;
export type RemoveParticipantMutationResult = Apollo.MutationResult<RemoveParticipantMutation>;
export type RemoveParticipantMutationOptions = Apollo.BaseMutationOptions<RemoveParticipantMutation, RemoveParticipantMutationVariables>;