import { SubButton } from '@riddler-co-jp/specc-ui-components';
import { CheckBox } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useDeleteOrganizationMutation } from '../../../api/__generated__/organization.generated';
import { OrganizationDocument } from '../../../api/organization';
import { HandleFirebaseErrorResult } from '../../../lib/firebase/firebaseErrorHandlers';
import {
  curriculumsToSubject,
  subjectToCurriculums,
} from '../../../lib/organization';
import { presets } from '../../../lib/preset';
import { useRetryableMutationWithUI } from '../../../lib/useRetryableMutationWithUI';
import { appActions } from '../../../redux/actions/appActions';
import Colors from '../../../styles/colors';
import AdminSkillSubjects from '../UIelements/AdminSkillSubjects';
import EditParticipantManager from '../UIelements/EditParticipantManager';
import OrganizationIcon from '../UIelements/OrganizationIcon';

interface AdminOrganizationEditorProps {
  onUpdateParticipantUids: React.Dispatch<React.SetStateAction<string[]>>;
  create: boolean;
  participantUids: string[];
  organization: OrganizationDocument;
  error: HandleFirebaseErrorResult | null;
  onSelectImage: (e: any) => Promise<void>;
  imgSrc?: string;
}

const AdminOrganizationEditor: React.FC<AdminOrganizationEditorProps> =
  props => {
    const { participantUids, onUpdateParticipantUids } = props;
    const [name, setName] = React.useState<string>(props.organization.name);

    const nameOnChange = (e: any) => {
      setName(e.target.value);
      props.organization.name = e.target.value;
    };
    const navigate = useNavigate();

    const onDeleteOrganizationCompleted = React.useCallback(() => {
      navigate('/admin/organization');
    }, [navigate]);

    const [deleteOrganization] = useRetryableMutationWithUI(
      useDeleteOrganizationMutation,
      {
        hookOptions: {
          onCompleted: onDeleteOrganizationCompleted,
        },
      }
    );

    const selectedSubject = React.useMemo(() => {
      return curriculumsToSubject(props.organization.curriculums);
    }, [props.organization.curriculums]);

    const dispatch = useDispatch();

    const subjectChanged = React.useCallback(
      (subject: boolean[]) => {
        props.organization.curriculums = subjectToCurriculums(subject);
        console.log(props.organization.curriculums);
        return;
      },
      [props.organization]
    );

    const [selectedPresets, setSelectedPresets] = React.useState<string[]>(
      props.organization.presets ?? []
    );

    return (
      <AdminOrganizationEditorWrapper>
        <Main>
          <div>
            <Row>
              <Item width={'80%'}>
                <ItemTitle>企業名</ItemTitle>
                <ItemBody>
                  <LongInput value={name} onChange={nameOnChange}></LongInput>
                </ItemBody>
              </Item>
            </Row>
            <Row>
              <Item>
                <ItemTitle>アイコン</ItemTitle>
                <ItemBody>
                  <IconSection>
                    <OrganizationIcon size={42} src={props.imgSrc} />
                    <input type='file' onChange={props.onSelectImage} />
                  </IconSection>
                </ItemBody>
              </Item>
            </Row>
            <RowFull>
              <Item>
                <ItemTitle>使用プリセット</ItemTitle>
                <ItemBody>
                  <PresetList>
                    <SinglePreset>
                      <CheckBox
                        id='preset1'
                        label=''
                        value={selectedPresets.includes('1')}
                        onChange={() => {
                          // toggle array from '1'
                          if (props.organization.presets?.includes('1')) {
                            props.organization.presets =
                              props.organization.presets.filter(
                                preset => preset !== '1'
                              );
                          } else {
                            props.organization.presets = (
                              props.organization.presets ?? []
                            ).concat(['1']);
                          }
                          setSelectedPresets(props.organization.presets ?? []);
                        }}
                      />
                      <InnerSinglePreset>
                        <p>{presets[0].preset_name}</p>
                        <p>{presets[0].preset_detail}</p>
                      </InnerSinglePreset>
                    </SinglePreset>
                    <SinglePreset>
                      <CheckBox
                        id='preset2'
                        label=''
                        value={selectedPresets.includes('2')}
                        onChange={() => {
                          if (props.organization.presets?.includes('2')) {
                            props.organization.presets =
                              props.organization.presets.filter(
                                preset => preset !== '2'
                              );
                          } else {
                            props.organization.presets = (
                              props.organization.presets ?? []
                            ).concat(['2']);
                          }
                          setSelectedPresets(props.organization.presets ?? []);
                        }}
                      />
                      <InnerSinglePreset>
                        <p>{presets[1].preset_name}</p>
                        <p>{presets[1].preset_detail}</p>
                      </InnerSinglePreset>
                    </SinglePreset>
                  </PresetList>
                </ItemBody>
              </Item>
            </RowFull>
            <RowFull>
              <Item>
                <ItemTitle>使用カリキュラム</ItemTitle>
                <ItemBody>
                  <AdminSkillSubjects
                    enabledSubject={[true, true, true, true, true]}
                    selectedSubject={selectedSubject}
                    selectable
                    onChanged={subjectChanged}
                  />
                </ItemBody>
              </Item>
            </RowFull>
          </div>
          <div>
            <ItemTitle>管理者</ItemTitle>
            <ItemBody>
              <EditParticipantManager
                type='edit'
                participantUids={participantUids}
                orgId={props.organization.orgId}
                onUpdateParticipantUids={onUpdateParticipantUids}
                isAdmin={true}
                prevOrgAdmin={props.organization.admin}
              />
            </ItemBody>
          </div>
        </Main>

        {props.create == false && (
          <Buttons>
            <DeleteButton
              disabled={false}
              onClick={() => {
                dispatch(
                  appActions.setErrorOverlayState({
                    errorType: 'CommonError',
                    message: '本当に企業を削除しますか。',
                    backButtonText: 'いいえ',
                    retryButtonText: 'はい',
                    onRetry: () => {
                      deleteOrganization({
                        variables: {
                          input: {
                            orgId: props.organization.orgId,
                          },
                        },
                      });
                    },
                    onBack: () => {
                      console.log('close');
                    },
                  })
                );
              }}
            >
              企業を削除する
            </DeleteButton>
          </Buttons>
        )}
      </AdminOrganizationEditorWrapper>
    );
  };

const AdminOrganizationEditorWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-top: 2rem;
`;

const Main = styled.div`
  display: flex;

  > div {
    flex: 1;
  }
  > div:first-child {
    margin-right: 1rem;
  }
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
`;

const RowFull = styled.div`
  width: 100%;
  display: block;
  margin-bottom: 2rem;
`;

interface ItemStyleProps {
  width?: string;
  marginRight?: number;
}

const Item = styled.div`
  ${(p: ItemStyleProps) => (p.width != null ? `width: ${p.width};` : '')}
  ${(p: ItemStyleProps) =>
    p.marginRight != null ? `margin-right: ${p.marginRight}px;` : ''}

  input {
    font-size: 1.3rem;
    padding: 0.8rem 0.7rem;
    border: ${Colors.gray6} 2px solid;
    border-radius: 0.4rem;

    &::placeholder {
      color: ${Colors.gray4};
    }
  }
`;

const ItemTitle = styled.div`
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-bottom: 0.6rem;
  font-weight: 500;
`;

const ItemBody = styled.div`
  width: 100%;
`;

const LongInput = styled.input`
  width: 100%;
`;

const Button = styled(SubButton)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

const InlineButton = styled(Button)`
  display: inline-block;
`;

const Buttons = styled.div`
  border-top: 1px solid ${Colors.gray4};
  width: 100%;
  text-align: right;
  padding-top: 1rem;
  box-sizing: border-box;
  position: relative;
`;

interface DeleteButton {
  disabled: boolean;
}

const DeleteButton = styled.div`
  color: ${Colors.error};
  cursor: pointer;
  display: block;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  transition: 0.2s;
  right: 0;
  position: absolute;

  &:hover {
    background: ${Colors.gray4};
  }

  ${(p: DeleteButton) =>
    p.disabled &&
    `
    cursor: default;
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      background: initial;
    }
    `}
`;

const IconSection = styled.div`
  display: flex;

  >: first-child {
    margin-right: 1rem;
  }
`;

const PresetList = styled.div`
  width: 100%;
`;
const SinglePreset = styled.div`
  padding: 0.6rem 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.gray4};
  transition: 0.2s;
  cursor: pointer;

  &:first-child {
    border-top: 1px solid ${Colors.gray4};
  }

  &:hover {
    background: ${Colors.gray2};
  }
`;
const InnerSinglePreset = styled.div`
  p {
    font-size: 1.6rem;
  }
  p:last-child {
    font-size: 1rem;
    color: ${Colors.gray6};
  }
`;

export default AdminOrganizationEditor;
