import { SubButton as _Button } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useRemoveAdminRoleMutation } from '../../api/__generated__/adminRole.generated';
import { useAdminAllOrganizations } from '../../api/organization';
import { useUserInfo } from '../../api/user';
import { useRetryableMutationWithUI } from '../../lib/useRetryableMutationWithUI';
import { appActions } from '../../redux/actions/appActions';
import {
  useCurrentUser,
  useCustomClaim,
} from '../../redux/selectors/authSelectors';
import Colors from '../../styles/colors';
import AdminCommonBG from './AdminCommonBG';

export type AdminRole = 'organizationAdmin' | 'dentsuAdmin' | 'riddlerAdmin';
interface AdminAdministratorDetailProps {}

const AdminAdministratorDetail: React.FC<AdminAdministratorDetailProps> =
  () => {
    const { userId } = useParams<'userId'>();
    const adminCustomClaim = useCustomClaim();
    const adminRole: AdminRole = adminCustomClaim?.commonRoles.includes(
      'riddlerAdmin'
    )
      ? 'riddlerAdmin'
      : adminCustomClaim?.commonRoles.includes('dentsuAdmin')
      ? 'dentsuAdmin'
      : 'organizationAdmin';
    const user = useCurrentUser();
    const myId = user?.uid;

    const [currentUserInfo] = useUserInfo(userId);
    const role = React.useMemo(() => {
      return currentUserInfo?.commonRoles?.includes('riddlerAdmin')
        ? 'riddlerAdmin'
        : currentUserInfo?.commonRoles?.includes('dentsuAdmin')
        ? 'dentsuAdmin'
        : 'organizationAdmin';
    }, [currentUserInfo]);

    const adminName: { [key: string]: string } = {
      riddlerAdmin: 'RIDDLER',
      dentsuAdmin: '運営企業',
      organizationAdmin: 'リーダー',
    };

    const navigate = useNavigate();
    const onCompleted = React.useCallback(() => {
      navigate('/admin/administrators');
    }, [navigate]);

    const [removeAdminRole] = useRetryableMutationWithUI(
      useRemoveAdminRoleMutation,
      {
        hookOptions: {
          onCompleted,
        },
      }
    );
    const dispatch = useDispatch();

    const [organizations] = useAdminAllOrganizations();
    const organizationNameTable: { [key: string]: string } = {};

    if (organizations !== undefined) {
      organizations.forEach(doc => {
        organizationNameTable[doc.orgId] = doc.name;
      });
    }

    const adminOrganizationNameTable = React.useMemo(() => {
      const ret: { [key: string]: string } = {};
      if (organizations !== undefined && currentUserInfo !== undefined) {
        organizations.forEach(doc => {
          if (currentUserInfo.orgAdmin?.includes(doc.orgId)) {
            ret[doc.orgId] = doc.name;
          }
        });
      }
      return ret;
    }, [currentUserInfo, organizations]);

    return (
      <AdminCommonBG backButton title='管理者アカウント詳細'>
        <AdminEventControllerWrapper>
          <Row>
            <DetailTable>
              <THead>
                <TR>
                  <TH>氏名</TH>
                  <TH>権限</TH>
                  <TH>管理企業</TH>
                  <TH>メールアドレス</TH>
                </TR>
              </THead>

              <TBody>
                <TR>
                  <TD>{currentUserInfo?.displayName}</TD>
                  <TD>{adminName[role]}</TD>
                  <TD>
                    {Object.values(adminOrganizationNameTable).map((e, i) => (
                      <p key={i}>{e}</p>
                    ))}
                  </TD>
                  <TD>{currentUserInfo?.email}</TD>
                </TR>
              </TBody>
            </DetailTable>
          </Row>

          <Buttons>
            <DeleteButton
              disabled={
                (adminRole != 'riddlerAdmin' && role == 'riddlerAdmin') ||
                userId === myId
              }
              onClick={() => {
                dispatch(
                  appActions.setErrorOverlayState({
                    errorType: 'CommonError',
                    message: '本当に管理者を削除しますか。',
                    backButtonText: 'いいえ',
                    retryButtonText: 'はい',
                    onRetry: () => {
                      if (!userId) {
                        return;
                      }
                      removeAdminRole({
                        variables: {
                          input: {
                            uid: userId,
                            role,
                          },
                        },
                      });
                    },
                    onBack: () => {
                      console.log('close');
                    },
                  })
                );
              }}
            >
              管理者ロールを削除
            </DeleteButton>
          </Buttons>
        </AdminEventControllerWrapper>
      </AdminCommonBG>
    );
  };

const AdminEventControllerWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
`;

const Margin = styled.div`
  margin-bottom: 1rem;
`;

const Row = styled.div`
  &:last-child {
    border-bottom: none;
  }
`;

const Item = styled.div`
  width: 100%;
`;

const ItemTitle = styled.div`
  font-size: 2.4rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  font-weight: bold;
`;

const ItemBody = styled.div`
  width: 100%;
`;

const ItemText = styled.span`
  display: inline-block;
  font-size: 1.3rem;
`;

const Button = styled(_Button)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

const InlineButton = styled(Button)`
  display: inline-block;
  font-weight: bold;
`;

const Buttons = styled.div`
  border-top: 1px solid ${Colors.gray4};
  width: 100%;
  text-align: right;
  padding-top: 1rem;
  box-sizing: border-box;
  position: relative;
`;

const DetailTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  tr {
    td:nth-child(1) {
      /*width: 40%;*/
    }
    td:nth-child(2) {
      /*width: 18rem;*/
    }
  }
`;

const THead = styled.thead`
  width: 100%;
`;

const TBody = styled.tbody`
  width: 100%;

  tr:last-child {
    border: none !important;
  }
`;

const TD = styled.td`
  font-size: 1.4rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TH = styled.th`
  text-align: left;
  font-size: 1.3rem;
  padding: 1.5rem 0 1.5rem 0;
  padding-right: 1rem;

  &:last-child {
    padding-right: 0;
  }
`;
const TR = styled.tr`
  width: 100%;
  white-space: nowrap;
  border-bottom: 1px solid ${Colors.gray4};
`;

interface DeleteButton {
  disabled: boolean;
}

const DeleteButton = styled.div`
  color: ${Colors.error};
  cursor: pointer;
  display: block;
  padding: 1rem 1rem;
  font-size: 1.4rem;
  border-radius: 5px;
  transition: 0.2s;
  right: 0;
  position: absolute;

  &:hover {
    background: ${Colors.gray4};
  }

  ${(p: DeleteButton) =>
    p.disabled &&
    `
    cursor: default;
    opacity: 0.4;
    pointer-events: none;

    &:hover {
      background: initial;
    }
    `}
`;

const RankWrapper = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`;
const RankHead = styled.div`
  width: 100%;
  display: flex;
  border-bottom: 1px solid ${Colors.gray4};
`;
const RankItem = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.2rem 0;

  &:last-child {
    margin-right: 0;
  }
`;
const RankBody = styled.div`
  width: 100%;
  display: flex;
`;
const BodyItem = styled.div`
  flex: 1;
  font-size: 2.8rem;
  margin-right: 1rem;
  text-align: center;
  font-weight: bold;
  color: ${Colors.accent};
  border-right: 1px solid ${Colors.gray4};
  margin: 1rem 0;

  &:last-child {
    border: none;
  }
`;
export default AdminAdministratorDetail;
