import { MainButton as _Button } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useEndedEventIds, useJoinableEventIds } from '../../api/event';
import {
  useAvailableTrainingIds,
  useEndedTrainingIds,
} from '../../api/training';
import { useUserInfo } from '../../api/user';
import { useCurrentUser } from '../../redux/selectors/authSelectors';
import Colors from '../../styles/colors';
import CommonBG from '../uiElements/CommonBG';
import { HeaderOptions } from '../uiElements/Header';
import UserNameView from './UserNameView';

const headerOptions: HeaderOptions = {
  buttonType: 'logout',
  showEmail: false,
};

interface MyPageProps {}
const MyPage: React.FC<MyPageProps> = () => {
  const user = useCurrentUser();
  const navigate = useNavigate();
  const [calculatedEvents] = useEndedEventIds(user);
  const [endedTrainings] = useEndedTrainingIds(user);

  const goTo = (url: string) => {
    navigate(url);
  };
  const [userInfo] = useUserInfo(user?.uid);
  const admin =
    (userInfo?.commonRoles?.length ?? 0) > 0 ||
    (userInfo?.orgAdmin?.length ?? 0) > 0;
  const [eventIds] = useJoinableEventIds(user);
  const [endedIds] = useEndedEventIds(user);
  const [trainingIds] = useAvailableTrainingIds(user);

  const availableIds = React.useMemo(() => {
    if (!endedIds) {
      return eventIds;
    }
    return eventIds?.filter(e => !endedIds.includes(e));
  }, [eventIds, endedIds]);

  return (
    <CommonBG withHeader withFooter headerOptions={headerOptions}>
      <MyPageWrapper>
        <ContentWrapper>
          <UserNameWrapper>
            <UserNameView userName={user?.displayName ?? 'NoName'} />
            <SubText>{user?.email ?? ''} </SubText>
          </UserNameWrapper>

          <Buttons>
            <Button
              color='positive'
              size='large'
              disabled={
                (availableIds?.length ?? 0) === 0 &&
                (trainingIds?.length ?? 0) === 0
              }
              onClick={() => goTo('/events')}
            >
              テストをはじめる
            </Button>

            <Button
              color='positive'
              size='large'
              disabled={
                (calculatedEvents?.length ?? 0) === 0 &&
                (endedTrainings?.length ?? 0) === 0
              }
              onClick={() => goTo('/history')}
            >
              過去の研修一覧
            </Button>

            {admin && (
              <Button
                color='positive'
                size='large'
                onClick={() => goTo('/admin')}
              >
                管理画面
              </Button>
            )}
          </Buttons>
        </ContentWrapper>
      </MyPageWrapper>
    </CommonBG>
  );
};

const MyPageWrapper = styled.div``;

const ContentWrapper = styled.div`
  padding-bottom: 4rem;
`;

const UserNameWrapper = styled.div`
  width: 55rem;
  margin: 14rem auto 0;
`;

const Buttons = styled.div`
  width: 28rem;
  margin: 4rem auto 0;
`;

const Button = styled(_Button).attrs({
  variant: 'main',
})`
  width: 100%;
  margin-bottom: 4rem;
  text-align: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const SubText = styled.p`
  color: ${Colors.gray8};
  text-align: center;
  font-size: 1.5rem;
  margin-top: 2rem;
`;

export default MyPage;
