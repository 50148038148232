import * as React from 'react';
import styled from 'styled-components';

import NokoriJikanSvg from '../../../static/svg/timeGauge/nokorijikan.svg';
import Colors, { colorWithAlpha } from '../../../styles/colors';

interface TimeGaugeViewProps {
  remainingTime: number;
  duration: number;
  noTransition?: boolean;
}

const padZero = (n: number) => `00${n}`.slice(-2);

const TimeGaugeView: React.FC<TimeGaugeViewProps> = props => {
  const { remainingTime, duration } = props;

  const timeNumberText = `${Math.floor(remainingTime / 60)}:${padZero(
    remainingTime % 60
  )}`;

  let leftRatio = (remainingTime - 1) / (duration - 1);
  if (leftRatio < 0) leftRatio = 0;
  if (leftRatio > 1) leftRatio = 1;

  return (
    <TimeGaugeWrapper>
      <TimeText />
      <TimeNumber>{timeNumberText}</TimeNumber>
      <GaugeBody>
        <Gauge
          leftRatio={leftRatio}
          noTransition={!!props.noTransition}
          time={remainingTime}
        />
        <GaugeCover
          leftRatio={leftRatio}
          noTransition={!!props.noTransition}
          time={remainingTime}
        />
      </GaugeBody>
    </TimeGaugeWrapper>
  );
};

const TimeGaugeWrapper = styled.div`
  width: 100%;
  padding: 0 4rem 0 2rem;
  box-sizing: border-box;
  height: 4rem;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: ${Colors.gray2};
  display: flex;
  box-shadow: 0px 3px 6px ${colorWithAlpha('black', 0.16)};
`;

interface GaugeProps {
  leftRatio: number;
  noTransition: boolean;
  time: number;
}

const GaugeBody = styled.div`
  width: 100%;
  position: relative;
`;

const GAUGE_HEIGHT = '1.667rem';
const GAUGE_RIGHT_BAR_HEIGHT = '3.33rem';
const GAUGE_RIGHT_BAR_WIDTH = '1rem';
const GAUGE_BORDER_RADIUS = `calc(${GAUGE_HEIGHT} / 2)`;
const gaugeColors = (p: GaugeProps): [string, string] => {
  if (p.leftRatio * 6 <= 1 && p.time <= 60) {
    return [Colors.highlightError, Colors.error];
  }
  return [Colors.primary, Colors.accent];
};
const gaugeMainColor = (p: GaugeProps) => gaugeColors(p)[1];
const gaugeBackground = (p: GaugeProps) => {
  const [c1, c2] = gaugeColors(p);
  return `linear-gradient(-45deg, ${c2} 25%, ${c1} 25%, ${c1} 50%, ${c2} 50%, ${c2} 75%, ${c1} 75%, ${c1})`;
};

const GaugeCover = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  height: ${GAUGE_HEIGHT};
  background-color: ${Colors.white};
  transform: translateY(-50%);
  border-radius: 0 ${GAUGE_BORDER_RADIUS} ${GAUGE_BORDER_RADIUS} 0;
  width: ${(p: GaugeProps) => `calc(100% * ${1 - p.leftRatio})`};
  transition: ${(p: GaugeProps) => (p.noTransition ? 0 : 1)}s linear;

  &::after {
    content: '';
    position: absolute;
    display: block;
    height: ${GAUGE_RIGHT_BAR_HEIGHT};
    width: ${GAUGE_RIGHT_BAR_WIDTH};
    border-radius: calc(${GAUGE_RIGHT_BAR_WIDTH} / 2);
    background-color: ${gaugeMainColor};
    left: calc(-1 * ${GAUGE_RIGHT_BAR_WIDTH} / 2);
    top: calc(-1 * (${GAUGE_RIGHT_BAR_HEIGHT} - ${GAUGE_HEIGHT}) / 2);
  }
`;

const Gauge = styled.div`
  @keyframes stripeBg {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: 0 0;
    }
  }

  position: absolute;
  top: 50%;
  left: 0;
  height: ${GAUGE_HEIGHT};
  width: 100%;
  background: ${gaugeBackground};
  background-size: 3rem 3rem;
  animation: stripeBg 25s linear infinite;
  transform: translateY(-50%);
  border-radius: ${GAUGE_BORDER_RADIUS};
`;

const TimeText = styled.img.attrs({
  src: NokoriJikanSvg,
})`
  width: 7rem;
`;

const TimeNumber = styled.div`
  line-height: 3.8rem;
  margin: 0 1.2rem;
  font-size: 2.2rem;
  font-weight: bold;
  color: ${Colors.gray8};
`;

export default TimeGaugeView;
