import format from 'date-fns/format';
import { ja } from 'date-fns/locale';
import * as React from 'react';
import SVG from 'react-inlinesvg';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Icon_Communicate from '../../../static/svg/admin/category_communicate.svg';
import Icon_Create from '../../../static/svg/admin/category_create.svg';
import Icon_Energy from '../../../static/svg/admin/category_energy.svg';
import Icon_Program from '../../../static/svg/admin/category_program.svg';
import Icon_Switch from '../../../static/svg/admin/category_switch.svg';
import Colors, { colorWithAlpha } from '../../../styles/colors';
import { UserTrainings } from '../adminUsers/AdminUserDetail';

interface ParamTypes {
  userId: string;
}

interface AdminTrainingHistoryProps {
  userTrainings: UserTrainings[];
}
const AdminTrainingHistory: React.FC<AdminTrainingHistoryProps> = props => {
  const navigate = useNavigate();
  const { userId } = useParams<'userId'>();
  const userTrainings = props.userTrainings;

  return (
    <TableWrapper>
      <Table>
        <THead>
          <TR>
            <TH>開催期間</TH>
            <TH>トレーニング名</TH>
            <TH>カリキュラム</TH>
          </TR>
        </THead>
        <TBody>
          {userTrainings.length == 0 ? (
            <TR>
              <TD>
                <LoadingText>履歴がありません。</LoadingText>
              </TD>
            </TR>
          ) : (
            <>
              {userTrainings.map((ev, i) => (
                <TR
                  key={i}
                  /*
                  onClick={() => {
                    navigate(
                      `/admin/accounts/${userId}/history/training/${ev.id}`
                    );
                  }}*/
                >
                  <TD>
                    <p>
                      {ev.info?.scheduledStartTime &&
                        format(
                          ev.info.scheduledStartTime,
                          'yyyy年M月d日(E) HH:mm',
                          { locale: ja }
                        )}
                    </p>
                    <p>
                      {' - '}
                      {ev.info?.scheduledEndTime &&
                        format(
                          ev.info.scheduledEndTime,
                          'yyyy年M月d日(E) HH:mm',
                          { locale: ja }
                        )}
                    </p>
                  </TD>
                  <TD>{ev.info?.name}</TD>
                  <TD>
                    {/* WIP */}
                    <Rank selected={true /* ev.subject.switch */}>
                      <SVG src={Icon_Switch} />
                    </Rank>
                    <Rank selected={true /* ev.subject.program */}>
                      <SVG src={Icon_Program} />
                    </Rank>
                    <Rank selected={true /* ev.subject.create */}>
                      <SVG src={Icon_Create} />
                    </Rank>
                    <Rank selected={false /* ev.subject.energy */}>
                      <SVG src={Icon_Energy} />
                    </Rank>
                    <Rank selected={false /* ev.subject.communicate */}>
                      <SVG src={Icon_Communicate} />
                    </Rank>
                  </TD>
                </TR>
              ))}
            </>
          )}
        </TBody>
      </Table>
    </TableWrapper>
  );
};

const TableWrapper = styled.div`
  width: 100%;
  display: block;
  overflow-x: auto;
  margin-bottom: 2rem;
`;

const Table = styled.table`
  width: 100%;
  font-size: 1.4rem;
  text-align: left;
  display: table;

  th:nth-child(1),
  td:nth-child(1) {
    flex: initial;
    width: 20rem;
  }
  th:nth-child(3),
  td:nth-child(3) {
    flex: initial;
    width: 7rem;
  }
  th:last-child,
  td:last-child {
    flex: initial;
    display: flex;
    width: 254px;
    margin-left: 1rem;
  }
`;

const THead = styled.thead`
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${Colors.gray4};
  font-size: 1.4rem;
  font-weight: bold;
  padding: 0.6rem 0;
`;

const TBody = styled.tbody`
  cursor: default;
  width: 100%;
  /*min-height: 20rem;*/

  td:first-child {
    display: block;
    white-space: nowrap;
    overflow: hidden;
  }

  tr {
    padding: 0.6rem 0;
    position: relative;
    border-bottom: 1px solid ${Colors.gray4};
    transition: 0.2s;
  }

  tr:hover {
    background-color: ${colorWithAlpha('gray4', 0.5)};
  }
`;

const TR = styled.tr`
  display: flex;
  align-items: center;
  width: 100%;
`;

const TH = styled.th`
  flex: 1;
  margin-left: 1rem;

  &:last-child {
    display: flex;
    width: 254px;
    margin-left: 1rem;
  }
`;

const TD = styled.td`
  flex: 1;
  display: flex;
  align-items: center;
  margin-left: 1rem;

  white-space: nowrap;
  overflow: hidden;

  a {
    color: ${Colors.accent};
    font-weight: bold;
    text-decoration: none;

    svg {
      margin-left: 0.6rem;
    }
  }
  a:hover {
    opacity: 0.8;
  }
`;

const LoadingText = styled.div`
  color: ${Colors.gray6};
  padding: 0.6rem 0;
  &:hover {
    background-color: initial;
  }
`;

interface RankProps {
  selected: boolean;
}

const Rank = styled.div`
  width: 50px;
  line-height: 3.2rem;
  text-align: center;
  font-size: 2.8rem;
  font-weight: bold;
  color: ${Colors.accent};
  border-right: 1px solid ${Colors.gray4};
  height: 36px;
  float: left;

  svg {
    opacity: ${(p: RankProps) => (p.selected ? '1.0' : '0.3')};

    .cls-1 {
      fill: ${Colors.accent} !important;
    }
  }

  &:last-child {
    border: none;
  }
`;

export default AdminTrainingHistory;
