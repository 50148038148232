import {
  ApolloClient,
  ApolloProvider,
  NormalizedCacheObject,
} from '@apollo/client';
import firebase from 'firebase/app';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { bareClient, getClient } from './api/client';
import { useServerTimeOffsetEffect } from './api/common';
import { useUserInfo } from './api/user';
import AdminRouter from './components/admin/AdminRouter';
import AuthAction from './components/auth/authAction/AuthAction';
import CancelEmail from './components/auth/authAction/CancelEmail/CancelEmail';
import UpdateEmail from './components/auth/authAction/UpdateEmail/UpdateEmail';
import Login from './components/auth/Login';
import Logout from './components/auth/Logout';
import PasswordReset from './components/auth/PasswordReset';
import Register from './components/auth/Register';
import TokenRefresh from './components/auth/TokenRefresh';
import VerifyEmail from './components/auth/VerifyEmail';
import DevEnvIndicator from './components/dev/DevEnvIndicator';
import TestPage from './components/dev/TestPage';
import DialogPrivacy from './components/dialog/DialogPrivacyView';
import DialogTerm from './components/dialog/DialogTermView';
import EventsRouter from './components/eventProvider/EventsRouter';
import Stage1Tester from './components/eventProvider/stagePages/stage1/Stage1Tester';
import AuthStateListener from './components/global/AuthStateListener';
import ErrorConsole from './components/global/ErrorConsole';
import ErrorOverlayController from './components/global/overlays/error/ErrorOverlayController';
import LoadingOverlay from './components/global/overlays/loading/LoadingOverlay';
import PanicConsole from './components/global/PanicConsole';
import Events from './components/mypage/Events';
import EventsTrainings from './components/mypage/EventsTrainings';
import History from './components/mypage/History';
import MyPage from './components/mypage/MyPage';
import Trainings from './components/mypage/Trainings';
// import MyResults from './components/mypage/MyResults';
import Settings from './components/settings/Settings';
import TrainingsRouter from './components/trainingProvider/TrainingsRouter';
import Welcome from './components/uiElements/Welcome';
// import Preferences from './components/preferences/Preferences';
import {
  useCurrentUser,
  useIsUserEmailVerified,
  useIsUserInitialized,
} from './redux/selectors/authSelectors';
import Store from './redux/store';

export default function App() {
  const [client, setClient] =
    React.useState<ApolloClient<NormalizedCacheObject>>(bareClient);

  const updateApolloClient = () => {
    getClient().then(client => setClient(client));
  };
  React.useEffect(() => {
    updateApolloClient();

    firebase.auth().onAuthStateChanged(() => {
      updateApolloClient();
    });
  }, []);

  return (
    <ApolloProvider client={client}>
      <Provider store={Store}>
        <ErrorOverlayController />
        <LoadingOverlay />
        <AuthStateListener />
        <Router />
      </Provider>
    </ApolloProvider>
  );
}

interface ConditionedRouteRule {
  condition: boolean;
  redirectPathOnFail: string;
}
interface ConditionedRouteProps {
  rules: ConditionedRouteRule[];
}

const ConditionedRoute: React.FC<ConditionedRouteProps> = props => {
  for (const rule of props.rules) {
    if (!rule.condition) {
      return <Navigate to={rule.redirectPathOnFail} />;
    }
  }
  return <>{props.children}</>;
};

interface RouterProps {}
const Router: React.FC<RouterProps> = () => {
  const user = useCurrentUser();
  const isUserInitialized = useIsUserInitialized();
  const emailVerified = useIsUserEmailVerified();
  const [currentUserInfo] = useUserInfo(user?.uid);
  const profileRegistered =
    currentUserInfo === undefined || !!currentUserInfo.email; // WIP
  const signedIn = user !== null;
  useServerTimeOffsetEffect();

  const onlyValidUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    return [
      {
        condition: signedIn,
        redirectPathOnFail: '/',
      },
      {
        condition: emailVerified,
        redirectPathOnFail: '/verifyEmail',
      },
      {
        condition: profileRegistered,
        redirectPathOnFail: '/welcome',
      },
    ];
  }, [emailVerified, profileRegistered, signedIn]);

  const onlySignedInUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    return [
      {
        condition: signedIn,
        redirectPathOnFail: '/',
      },
    ];
  }, [signedIn]);

  const onlySignedOutUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    return [
      {
        condition: !signedIn,
        redirectPathOnFail: '/mypage',
      },
    ];
  }, [signedIn]);

  const onlyVerifyEmailUserRules = React.useMemo<ConditionedRouteRule[]>(() => {
    return [
      {
        condition: !emailVerified && signedIn,
        redirectPathOnFail: '/',
      },
    ];
  }, [emailVerified, signedIn]);

  const requireProfileSettingRules = React.useMemo<
    ConditionedRouteRule[]
  >(() => {
    return [
      {
        condition: !profileRegistered && signedIn,
        redirectPathOnFail: '/',
      },
    ];
  }, [profileRegistered, signedIn]);

  if (!isUserInitialized) {
    return null;
  }

  return (
    <BrowserRouter>
      <PanicConsole />
      <ErrorConsole />
      <DevEnvIndicator />
      <Routes>
        <Route path='/test' element={<TestPage />} />
        <Route
          path='/register'
          element={
            <ConditionedRoute rules={onlySignedOutUserRules}>
              <Register />
            </ConditionedRoute>
          }
        />
        <Route
          path='/admin/*'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <AdminRouter />
            </ConditionedRoute>
          }
        />
        <Route
          path='/settings/*'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <Settings />
            </ConditionedRoute>
          }
        />
        <Route
          path='/events'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <EventsTrainings />
            </ConditionedRoute>
          }
        />
        <Route
          path='/events/*'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <EventsRouter />
            </ConditionedRoute>
          }
        />
        <Route
          path='/trainings'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <Trainings />
            </ConditionedRoute>
          }
        />
        <Route
          path='/trainings/*'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <TrainingsRouter />
            </ConditionedRoute>
          }
        />
        <Route
          path='/history/*'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <History />
            </ConditionedRoute>
          }
        />
        <Route
          path='/dev/stage1test'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <Stage1Tester />
            </ConditionedRoute>
          }
        />
        <Route
          path='/verifyEmail'
          element={
            <ConditionedRoute rules={onlyVerifyEmailUserRules}>
              <VerifyEmail />
            </ConditionedRoute>
          }
        />
        <Route
          path='/updateEmail/:email'
          element={
            <ConditionedRoute rules={onlySignedInUserRules}>
              <UpdateEmail />
            </ConditionedRoute>
          }
        />
        <Route
          path='/cancelEmail/:email'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <CancelEmail />
            </ConditionedRoute>
          }
        />
        <Route
          path='/welcome'
          element={
            <ConditionedRoute rules={requireProfileSettingRules}>
              <Welcome />
            </ConditionedRoute>
          }
        />
        <Route
          path='/mypage'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <MyPage />
            </ConditionedRoute>
          }
        />
        <Route
          path='/password-reset'
          element={
            <ConditionedRoute rules={onlySignedOutUserRules}>
              <PasswordReset />
            </ConditionedRoute>
          }
        />
        <Route path='/auth-action' element={<AuthAction />} />
        <Route path='/__/auth/action' element={<AuthAction />} />
        <Route path='/dialog/term' element={<DialogTerm />} />
        <Route path='/dialog/privacy' element={<DialogPrivacy />} />
        <Route
          path='/token-refresh'
          element={
            <ConditionedRoute rules={onlyValidUserRules}>
              <TokenRefresh />
            </ConditionedRoute>
          }
        />
        <Route
          path='/logout'
          element={
            <ConditionedRoute rules={onlySignedInUserRules}>
              <Logout />
            </ConditionedRoute>
          }
        />
        <Route
          path='/'
          element={
            <ConditionedRoute rules={onlySignedOutUserRules}>
              <Login />
            </ConditionedRoute>
          }
        ></Route>
        <Route path='/*' element={<Navigate to='/' />} />
      </Routes>
    </BrowserRouter>
  );
};
