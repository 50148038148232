import { DatePicker } from '@riddler-co-jp/specc-ui-components';
import {
  Input,
  TimeNumber,
  TimeRangeNumber,
} from '@riddler-co-jp/specc-ui-components';
import { Validators, useValidator } from '@riddler-co-jp/specc-ui-components';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import * as React from 'react';
import { OptionTypeBase, ValueType } from 'react-select';
import styled from 'styled-components';

import { useOrganization } from '../../../api/organization';
import { TrainingDocument } from '../../../api/training';
import {
  curriculumsToSubject,
  subjectToCurriculums,
} from '../../../lib/organization';
import Colors from '../../../styles/colors';
import { TrainingStatus } from '../../../types';
import _Button from '../../uiElements/deprecated/Button';
import AdminSkillSubjects from '../UIelements/AdminSkillSubjects';
import EditParticipantManager from '../UIelements/EditParticipantManager';

interface AdminTrainingEditorProps {
  training: TrainingDocument;
  onUpdate?: React.Dispatch<React.SetStateAction<TrainingDocument | null>>;
  participantUids: string[];
  onUpdateParticipantUids: React.Dispatch<React.SetStateAction<string[]>>;
  onConfirm?: () => void;
  onCancel?: () => void;
  changeValidCallback: (valid: boolean) => void;
}

export const setTime = (d: Date, v: TimeNumber) =>
  setHours(setMinutes(d, v.minute), v.hour);
export const date2UnixSec = (d: Date) => Math.floor(d.getTime() / 1000);

type OnChangeType = (x: ValueType<OptionTypeBase, false>) => void;
type OptionType = {
  value: string;
  label: string;
};

const AdminTrainingEditor: React.FC<AdminTrainingEditorProps> = props => {
  const [startDateIsValid, setStartDateValid] = React.useState<boolean>(true);
  const [endDateIsValid, setEndDateValid] = React.useState<boolean>(true);
  const [dateIsValid, setDateValid] = React.useState<boolean>(true);
  const training = props.training;
  const [selectedSubjects, setSelectedSubjects] = React.useState<boolean[]>(
    curriculumsToSubject(training.curriculums)
  );
  const { onUpdate, participantUids, onUpdateParticipantUids } = props;

  const [org] = useOrganization(training.orgId);
  const onUpdateEvent = React.useCallback(
    (
      next: TrainingDocument | ((old: TrainingDocument) => TrainingDocument)
    ) => {
      if (typeof next === 'function') {
        onUpdate?.(next(training));
      } else {
        onUpdate?.(next);
      }
    },
    [training, onUpdate]
  );

  /**
   * MEMO:
   * TrainingDocument[K] が function type のときにバグるね...
   */
  const onUpdateValue = React.useCallback(
    <K extends keyof TrainingDocument>(
      key: K,
      next:
        | TrainingDocument[K]
        | ((old: TrainingDocument) => TrainingDocument[K])
    ) => {
      if (typeof next === 'function') {
        onUpdateEvent({ ...training, [key]: next(training) });
      } else {
        onUpdateEvent({ ...training, [key]: next });
      }
    },
    [training, onUpdateEvent]
  );

  const onUpdatePlace = React.useCallback(
    (e: string) => {
      onUpdateValue('place', e);
    },
    [onUpdateValue]
  );
  const onUpdateName = React.useCallback(
    (e: string) => {
      onUpdateValue('name', e);
    },
    [onUpdateValue]
  );
  const onUpdateStartDate = React.useCallback(
    (d: Date) => {
      onUpdateValue('scheduledStartTime', d);
      {
        const d1 = new Date();
        const d2 = new Date(d);
        d1.setHours(0, 0, 0, 0);
        d2.setHours(0, 0, 0, 0);
        setStartDateValid(d1.getTime() <= d2.getTime());
      }
      {
        const d1 = d;
        const d2 = endDate;
        d1.setHours(0, 0, 0, 0);
        setDateValid(d1.getTime() <= d2.getTime());
      }
    },
    [onUpdateValue]
  );

  const onUpdateEndDate = React.useCallback(
    (d: Date) => {
      d.setHours(23, 59, 59, 0);
      onUpdateValue('scheduledEndTime', d);
      {
        const d1 = new Date();
        const d2 = new Date(d);
        d1.setHours(0, 0, 0, 0);
        d2.setHours(0, 0, 0, 0);
        setEndDateValid(d1.getTime() <= d2.getTime());
      }
      {
        const d1 = startDate;
        const d2 = d;
        d1.setHours(0, 0, 0, 0);
        setDateValid(d1.getTime() <= d2.getTime());
      }
    },
    [onUpdateValue]
  );

  const onUpdateCurriculums = React.useCallback(
    changed => {
      const changedCurriculums = subjectToCurriculums(changed);
      if (
        changedCurriculums.length !== training.curriculums.length ||
        !changedCurriculums.every(e =>
          training.curriculums.includes(e as string)
        )
      ) {
        onUpdateValue('curriculums', changedCurriculums);
        setSelectedSubjects(changed);
      }
    },
    [onUpdateValue]
  );

  const startDate = React.useMemo(() => {
    return training.scheduledStartTime;
  }, [training]);
  const endDate = React.useMemo(() => {
    return training.scheduledEndTime;
  }, [training]);

  const onUpdateTimeRange = React.useCallback(
    (v: TimeRangeNumber) => {
      onUpdateEvent(ev => {
        const next: TrainingDocument = {
          ...ev,
          scheduledStartTime: setTime(startDate, v.start),
          scheduledEndTime: setTime(endDate, v.end),
        };

        console.log(new Date().getTime());
        console.log(
          setTime(startDate, v.start).getTime() -
            ((((setTime(startDate, v.start).getTime() + 3600 * 9) % 86400) +
              3600 * 15) %
              86400) *
              1000 +
            86400 * 1000
        );

        const d1 = new Date();
        const d2 = setTime(startDate, v.start);
        d1.setHours(0, 0, 0, 0);
        d2.setHours(0, 0, 0, 0);
        setDateValid(d1.getTime() <= d2.getTime());

        return next;
      });
    },
    [endDate, onUpdateEvent, startDate]
  );

  const timeRange: TimeRangeNumber = {
    start: {
      hour: startDate.getHours(),
      minute: startDate.getMinutes(),
    },
    end: {
      hour: endDate.getHours(),
      minute: endDate.getMinutes(),
    },
  };

  const nameValidators = React.useMemo<Validators>(
    () => [
      [val => !/^.{0,30}$/.test(val), '30文字以内で入力してください。'],
      [val => val === '', 'イベント名を入力してください。'],
    ],
    []
  );
  const [nameValidator, nameIsValid] = useValidator(nameValidators);

  const placeValidators = React.useMemo<Validators>(
    () => [[val => !/^.{0,20}$/.test(val), '20文字以内で入力してください。']],
    []
  );
  const [placeValidator, placeIsValid] = useValidator(placeValidators);

  React.useEffect(() => {
    props.changeValidCallback(
      nameIsValid && placeIsValid && startDateIsValid && endDateIsValid
    );
  }, [
    dateIsValid,
    nameIsValid,
    placeIsValid,
    props,
    startDateIsValid,
    endDateIsValid,
  ]);

  return (
    <AdminTrainingEditorWrapper>
      <Row>
        <Item width={360}>
          <ItemTitle>イベント名</ItemTitle>
          <ItemBody>
            <Input
              value={training.name}
              onChange={x => {
                onUpdateName(x);
              }}
              validator={nameValidator}
            />
          </ItemBody>
        </Item>
      </Row>
      <Row>
        <Item>
          <ItemTitle>カリキュラム</ItemTitle>
          <ItemBody>
            <AdminSkillSubjects
              selectable
              enabledSubject={curriculumsToSubject(org?.curriculums ?? null)}
              selectedSubject={selectedSubjects}
              onChanged={onUpdateCurriculums}
            />
          </ItemBody>
        </Item>
      </Row>
      <Row>
        <Item marginRight={20}>
          <ItemTitle>開始日</ItemTitle>
          <ItemBody>
            <DatePicker selected={startDate} onChange={onUpdateStartDate} />
          </ItemBody>
        </Item>
        <Item>
          <ItemTitle>終了日</ItemTitle>

          <ItemBody>
            <DatePicker selected={endDate} onChange={onUpdateEndDate} />
          </ItemBody>
        </Item>
      </Row>
      {dateIsValid && !(startDateIsValid && endDateIsValid) && (
        <Row>
          <Err>現在より未来の日付を設定してください。</Err>
        </Row>
      )}
      {!dateIsValid && (
        <Row>
          <Err>終了日は開始日より後の日付を設定してください。</Err>
        </Row>
      )}
      <Row>
        <Item width={360}>
          <ItemTitle>会場</ItemTitle>
          <ItemBody>
            <Input
              value={training.place ?? ''}
              onChange={x => {
                onUpdatePlace(x);
              }}
              validator={placeValidator}
            />
          </ItemBody>
        </Item>
      </Row>
      <Row>
        <Item width={420}>
          <ItemTitle>参加者</ItemTitle>
          <ItemBody>
            <EditParticipantManager
              type='edit'
              orgId={training.orgId}
              participantUids={participantUids}
              onUpdateParticipantUids={onUpdateParticipantUids}
              isAdmin={false}
              disableRemove={training.status === TrainingStatus.Ongoing}
            />
          </ItemBody>
        </Item>
      </Row>
    </AdminTrainingEditorWrapper>
  );
};

const AdminTrainingEditorWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding-top: 2rem;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 2rem;
`;

interface ItemStyleProps {
  width?: number;
  marginRight?: number;
}

const Item = styled.div`
  ${(p: ItemStyleProps) => (p.width != null ? `width: ${p.width}px;` : '')}
  ${(p: ItemStyleProps) =>
    p.marginRight != null ? `margin-right: ${p.marginRight}px;` : ''}

  input {
    font-size: 1.3rem;
    padding: 0.8rem 0.7rem;
    border: ${Colors.gray6} 2px solid;
    border-radius: 0.4rem;

    &::placeholder {
      color: ${Colors.gray4};
    }
  }
`;

const ItemTitle = styled.div`
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-bottom: 0.6rem;
  font-weight: 500;
`;

const ItemBody = styled.div`
  width: 100%;
`;

const LongInput = styled.input`
  width: 100%;
`;

const Button = styled(_Button)`
  min-width: 0;
  margin-left: 2rem;
  padding: 0 1.5rem;
  font-weight: 500;
`;

const InlineButton = styled(Button)`
  display: inline-block;
`;

const Buttons = styled.div`
  width: 100%;
  text-align: right;
  padding: 0 2rem;
  box-sizing: border-box;
`;

const Err = styled.div`
  color: red;
  margin-top: -1rem;
  font-size: 1.3rem;
`;

export default AdminTrainingEditor;
