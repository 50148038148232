// import Keyframes from '../../../../styles/keyframes';
import {
  MainButton as Button,
  Input,
  TextWindow,
} from '@riddler-co-jp/specc-ui-components';
import { Validators, useValidator } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import styled from 'styled-components';

import { stage3problems as problems } from '../../../../lib/Stage3Problems';
import IconCorrect from '../../../../static/svg/Stage3_correct.svg';
import IconWrong from '../../../../static/svg/Stage3_wrong.svg';
import { colorWithAlpha } from '../../../../styles/colors';

interface Stage3MainProps {
  onSend: (ans: string, problemId: number) => void;
  problemIdx: number;
  judge: 'correct' | 'wrong' | 'wrongHiragana' | null;
  setNextProblem: () => void;
}

const Stage3Main: React.FC<Stage3MainProps> = props => {
  const { onSend, problemIdx, judge: pjudge, setNextProblem } = props;
  const [ans, setAns] = React.useState('');
  const [btnDisabled, setBtnDisabled] = React.useState(false);

  const [judge, setJudge] =
    React.useState<'correct' | 'wrong' | 'wrongHiragana' | null>(pjudge);
  React.useEffect(() => {
    setAns('');
    setJudge(null);
  }, [problemIdx]);

  React.useEffect(() => {
    setJudge(pjudge);
  }, [pjudge]);

  const onChange = React.useCallback(
    (val: string) => {
      setBtnDisabled(false);
      setAns(val);
      setJudge(prev => (prev === 'correct' ? 'correct' : null));
    },
    [setAns]
  );

  const onSubmit = React.useCallback(() => {
    setBtnDisabled(true);
    if (!/^[ぁ-んー]*$/.test(ans)) {
      setJudge('wrongHiragana');
    } else {
      onSend(ans, problems[problemIdx].problemId);
    }
  }, [onSend, ans, problemIdx]);

  const onKeydown = React.useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
        if (judge !== 'correct') {
          onSubmit();
        } else {
          setNextProblem();
        }
      }
    },
    [judge, onSubmit, setNextProblem]
  );

  const validators = React.useMemo<Validators>(
    () => [
      /*[val => !/^[ぁ-んー]*$/.test(val), 'ひらがなで解答を入力してください'],*/
      [() => judge === 'wrongHiragana', 'ひらがなで解答を入力してください'],
      [() => judge === 'wrong', '残念、不正解です！'],
      [() => judge !== 'correct'],
    ],
    [judge]
  );
  const [validator] = useValidator(
    validators,
    '正解です！別の問題も解いてみましょう'
  );

  return (
    <Stage3MainWrapper>
      <Text bracket>ひらがなで解答を入力してください</Text>
      <Stage3MainProblem src={problems[problemIdx].problemImg} />
      <Content>
        <InputWrapper>
          <InputWrapperRow judge={judge}>
            <IconWrapper>
              {judge === 'correct' && <Icon src={IconCorrect} />}
              {(judge === 'wrong' || judge === 'wrongHiragana') && (
                <Icon src={IconWrong} />
              )}
            </IconWrapper>
            <Input
              placeholder={'ひらがなで入力してください'}
              type='text'
              fullWidth
              onChange={onChange}
              onKeyDown={onKeydown}
              validator={validator}
              value={ans}
            />
            <SubmitButton
              onClick={onSubmit}
              disabled={
                ans === '' || judge === 'correct' || btnDisabled === true
              }
            >
              送信
            </SubmitButton>
          </InputWrapperRow>
        </InputWrapper>
      </Content>
    </Stage3MainWrapper>
  );
};

const Stage3MainWrapper = styled.div`
  overflow-x: hidden;
  overflow-y: scroll;
  flex: 7;
  box-sizing: border-box;
`;

const Stage3MainProblem = styled.img`
  width: 75%;
  margin: 4rem auto 4rem;
  display: block;
  border: 1px solid #ced4da;
  box-shadow: 3px 3px 6px -2px #00000029;
`;

const Content = styled.div`
  width: 75%;
  margin: 0 auto;
  margin-top: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const Text = styled(TextWindow)`
  margin-top: 6rem;
  background-color: ${colorWithAlpha('gray4', 0.25)};
  margin-top: 7rem;
  margin-left: 9rem;
  margin-right: 9rem;
  padding: 0rem 2rem;
`;

const InputWrapper = styled.div`
  flex: 1 1 auto;
`;

interface InputTypeProps {
  judge: string | null;
}

const InputWrapperRow = styled.div`
  width: 100%;
  max-width: 420px;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  span {
    display: none;
  }

  input {
    margin-bottom: 0;
    text-align: center;
    padding: 2rem 1rem;

    ${(p: InputTypeProps) =>
      p.judge === null
        ? ``
        : p.judge === 'correct'
        ? `
          background: #D3F9D8;
          border: 2px solid #05AA70;
        `
        : `
          background: #FFE3E3;
          border: 2px solid #EA0E49;
        `}
  }

  > div {
    position: relative;

    > div:nth-child(2) {
      top: -36px;
      position: absolute;
    }
  }

  &:last-child {
    margin-bottom: 6rem;
    margin-left: auto;
    margin-right: auto;
  }
`;

const SubmitButton = styled(Button).attrs({
  variant: 'primary',
  size: 'medium',
  color: 'positive',
})`
  margin: 0 0 0 1.2rem;
  flex: 0 0 auto;
  line-height: 4rem;
`;

const Icon = styled.img`
  width: 100%;
  height: 100%;
`;
const IconWrapper = styled.div`
  width: 6.6rem;
  height: 4.4rem;
  top: -1px;
  position: relative;
  margin-right: 1.2rem;
`;

export default Stage3Main;
