import { TextWindow } from '@riddler-co-jp/specc-ui-components';
import firebase from 'firebase/app';
import * as React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  useRegisterMutation,
  useValidateRegistrationTokenQuery,
} from '../../api/__generated__/user.generated';
import { createTokenCookie } from '../../api/sso';
import asContent from '../../hoc/asContent';
import { HandleFirebaseErrorResult } from '../../lib/firebase/firebaseErrorHandlers';
import { useQueryParams } from '../../lib/useQueryParams';
import { useRetryableMutationWithUI } from '../../lib/useRetryableMutationWithUI';
import { appActions } from '../../redux/actions/appActions';
import CommonBG from '../uiElements/CommonBG';
import RegisterView from './RegisterView';

interface RegisterProps {}
const Register: React.FC<RegisterProps> = () => {
  const dispatch = useDispatch();
  const query = useQueryParams();
  const token = query.get('token');

  const [email, setEmail] = React.useState<string>('');
  const [pw, setPw] = React.useState<string>('');
  const { data, loading, error } = useValidateRegistrationTokenQuery({
    variables: {
      input: {
        // 本当はnullのときは問答無用でerrorにしたいけど，
        // conditionalにhooksを呼べないので，こうするしか無いのか..?
        token: token ?? '',
      },
    },
  });
  // null 許容しない
  const orgName = data?.validateRegistrationToken.organizationName ?? '';
  const isFailed = !loading && error !== undefined;

  const [
    firebaseError,

    // setFirebaseError,
    // TODO: 将来的に，GQLからエラー詳細を返す必要がありそう
    // 「このアドレスは使われています」系の
  ] = React.useState<HandleFirebaseErrorResult | null>(null);

  const onRegisterComplete = React.useCallback(() => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, pw)
      .then(async cred => {
        const token = await cred?.user?.getIdToken(true);
        if (token) {
          await createTokenCookie(token);
        }
      })
      .then(() => {
        dispatch(appActions.setLoadingState({ visible: false }));
      })
      .catch(e => {
        console.log(e);
        dispatch(appActions.setLoadingState({ visible: false }));
        dispatch(
          appActions.setErrorOverlayState({
            errorType: 'CommonError',
            message:
              '登録時にエラーが発生しました。管理者にお問い合わせください。',
          })
        );
      });
  }, [dispatch, email, pw]);

  const [register] = useRetryableMutationWithUI(useRegisterMutation, {
    hookOptions: {
      onCompleted: onRegisterComplete,
    },
  });

  const onRegister = React.useCallback(
    (email: string, pw: string, name: string) => {
      dispatch(appActions.setLoadingState({ visible: true }));

      if (token === null) {
        return;
      }

      setEmail(email);
      setPw(pw);

      register({
        variables: {
          input: {
            fullName: name,
            email,
            password: pw,
            token,
          },
        },
      });
    },
    [dispatch, register, token]
  );

  React.useEffect(() => {
    dispatch(appActions.setLoadingState({ visible: loading }));
  }, [dispatch, loading]);

  if (isFailed) {
    return (
      <CommonBG>
        <ErrorTexrtWindow>
          {'不正なURLです。\nもう一度ご確認ください。'}
        </ErrorTexrtWindow>
      </CommonBG>
    );
  }

  return (
    <RegisterView
      orgName={orgName}
      error={firebaseError}
      onRegister={onRegister}
    />
  );
};

const ErrorTexrtWindow = styled(TextWindow)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 50rem;
  max-width: 90vw;
  margin: 0 auto;
  padding: 2rem 2rem;
`;

export default asContent(Register);
