import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubButton } from '@riddler-co-jp/specc-ui-components';
import { Pagination } from '@riddler-co-jp/specc-ui-components';
import * as React from 'react';
import { MouseEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useAllRegistrationTokens } from '../../api/adminRole';
import { UserDocument, useAllAdminUserInfo } from '../../api/user';
import {
  useCustomClaim,
  useSelectedOrgId,
} from '../../redux/selectors/authSelectors';
import Colors from '../../styles/colors';
import AdminAdministratorsTable from '../eventProvider/AdminAdministratorsTable';
import { AdminRole } from './AdminAdministratorDetail';
import AdminCommonBG from './AdminCommonBG';
import { HeaderButton } from './UIelements/AdminNavigation';
import AdminPopup from './UIelements/AdminPopup';
import Filter from './UIelements/Filter';
import OrganizationSelector from './UIelements/OrganizationSelector';
import { administratorsSorter } from './UIelements/sorter/AdministratorsSorter';

const frontendURI = process.env.REACT_APP_FRONTEND_URI;

interface AdminAdministratorsProps {}
const AdminAdministrators: React.FC<AdminAdministratorsProps> = props => {
  const navigate = useNavigate();
  const [adminUsers] = useAllAdminUserInfo();

  const customClaim = useCustomClaim();
  const role: AdminRole = customClaim?.commonRoles.includes('riddlerAdmin')
    ? 'riddlerAdmin'
    : customClaim?.commonRoles.includes('dentsuAdmin')
    ? 'dentsuAdmin'
    : 'organizationAdmin';

  const adminUserList = React.useMemo(() => {
    return (
      adminUsers?.filter(
        (e: UserDocument) =>
          (e.orgAdmin !== undefined && e.orgAdmin.length > 0) ||
          (e.commonRoles !== undefined && e.commonRoles.length > 0)
      ) ?? []
    );
  }, [adminUsers]);

  const onAdminEdit = (userId?: string) => {
    navigate(`/admin/events/${userId}/edit`);
  };
  const onAdminControl = (userId?: string) => {
    navigate(`/admin/events/${userId}/control`);
  };

  const [selectedType, setSelectedType] = React.useState(role);

  const setRiddler = React.useCallback((e: MouseEvent) => {
    setSelectedType('riddlerAdmin');
    e.preventDefault();
  }, []);
  const setDentsu = React.useCallback((e: MouseEvent) => {
    setSelectedType('dentsuAdmin');
    e.preventDefault();
  }, []);
  const setCustomer = React.useCallback((e: MouseEvent) => {
    setSelectedType('organizationAdmin');
    e.preventDefault();
  }, []);

  const [sortKey, setSortKey] = React.useState({
    key: 'fullName',
    sort: 'ASC',
  });

  const onChangeSort = (sortObj: any) => {
    setSortKey(sortObj);
  };

  const currentSelectedOrgId = useSelectedOrgId();
  const [inviteUrlCollection] = useAllRegistrationTokens();
  const [selectedOrgId, setSelectedOrgId] =
    React.useState(currentSelectedOrgId);

  const tokens = React.useMemo(() => {
    if (inviteUrlCollection === undefined) {
      return [];
    }
    return inviteUrlCollection.filter(data => (data.adminRole ?? '') !== '');
  }, [inviteUrlCollection]);

  const textAreaRef = React.useRef<HTMLDivElement>(null);

  const getTokens = React.useCallback(
    (selectedType: string) => {
      if (selectedType === 'riddlerAdmin' && role !== 'riddlerAdmin') {
        return '';
      }
      const urls = tokens
        .filter(e => e.adminRole === selectedType)
        .filter(
          e => selectedType !== 'organizationAdmin' || e.orgId === selectedOrgId
        );
      if (urls.length == 0) {
        return '';
      }
      return frontendURI + '/register?token=' + urls[0].token;
    },
    [role, selectedOrgId, tokens]
  );

  const copyToClipboard = React.useCallback(() => {
    const inviteUrl = getTokens(selectedType);
    if (inviteUrl) {
      navigator.clipboard.writeText(inviteUrl).then(e => {
        window.alert(`招待リンクをクリップボードにコピーしました。`);
      });
    }
  }, [selectedType, getTokens]);

  const orgOnChange = React.useCallback(v => {
    setSelectedOrgId(v.id);
  }, []);

  const UserInfoDetail = (
    <UserInfoPopup>
      <p>管理者アカウント招待リンク</p>
      <InviteType>
        {role === 'riddlerAdmin' && (
          <SingleType
            onMouseDown={setRiddler}
            selected={selectedType == 'riddlerAdmin'}
          >
            RIDDLER
          </SingleType>
        )}
        <SingleType
          onMouseDown={setDentsu}
          selected={selectedType == 'dentsuAdmin'}
        >
          電通
        </SingleType>
        <SingleType
          onMouseDown={setCustomer}
          selected={selectedType == 'organizationAdmin'}
        >
          企業
        </SingleType>
      </InviteType>

      {selectedType == 'organizationAdmin' && (
        <OrgWrapper>
          <OrganizationSelector
            onChange={orgOnChange}
            initialSelectedOrgId={currentSelectedOrgId}
            isAdmin={true}
          />
        </OrgWrapper>
      )}

      <InviteUrl ref={textAreaRef}>{getTokens(selectedType)}</InviteUrl>
      <ButtonWrap>
        <SubButton
          size={'medium'}
          color={'negative'}
          onMouseDown={copyToClipboard}
        >
          クリップボードにコピー
        </SubButton>
      </ButtonWrap>
    </UserInfoPopup>
  );

  const btnInvite: HeaderButton = {
    buttonDom: (
      <AdminPopup width={360} key='popup' content={UserInfoDetail}>
        <SubButton size={'medium'} color={'positive'}>
          招待する
          <BtnSvg>
            <FontAwesomeIcon icon={faChevronDown} />
          </BtnSvg>
        </SubButton>
      </AdminPopup>
    ),
    chevron: true,
  };

  // Pagination
  const [pageCursor, setPageCursor] = React.useState<number>(0);
  const pageSize = 10;

  const [filter, setFilter] = React.useState<any>({});
  const sorter = administratorsSorter[sortKey.key][sortKey.sort];
  const filteredAdminUserList = React.useMemo(() => {
    console.log(adminUserList);
    return adminUserList
      .sort(sorter)
      .filter(
        e =>
          (filter.role === 'all' ||
            !filter.role ||
            e.commonRoles?.includes(filter.role) ||
            (filter.role === 'organizationAdmin' &&
              (e.orgAdmin?.length ?? 0) > 0)) &&
          (!filter.name ||
            e.displayName?.includes(filter.name) ||
            e.fullName?.includes(filter.name))
      );
  }, [adminUserList, sorter, filter.role, filter.name]);

  return (
    <AdminCommonBG title='管理者アカウント' headerButtons={[btnInvite]}>
      <Filter
        item={[
          { type: 'input', name: 'name', label: '氏名', width: 300 },
          {
            type: 'select',
            name: 'role',
            label: '権限',
            defaultValue: { value: 'all', label: '全ての権限' },
            options: [
              { value: 'all', label: '全ての権限' },
              { value: 'riddlerAdmin', label: 'RIDDLER' },
              { value: 'dentsuAdmin', label: '電通' },
              { value: 'organizationAdmin', label: 'リーダー' },
            ],
          },
        ]}
        onSend={params => {
          // Task Filter *
          setFilter(params);
          setPageCursor(0);
        }}
      />
      <AdminUserAccountsWrapper>
        <AdminAdministratorsTable
          userDatas={filteredAdminUserList.filter(
            (_, i) => pageCursor <= i && i < pageCursor + pageSize
          )}
          onAdminEdit={onAdminEdit}
          onAdminControl={onAdminControl}
          sortObj={sortKey}
          onChangeSort={onChangeSort}
          role={role}
        />
      </AdminUserAccountsWrapper>
      <Pagination
        count={filteredAdminUserList.length}
        begin={pageCursor}
        end={Math.min(pageCursor + pageSize, filteredAdminUserList.length)}
        onNext={() => {
          setPageCursor(pageCursor + pageSize);
        }}
        onPrev={() => {
          setPageCursor(Math.max(pageCursor - pageSize, 0));
        }}
      />
    </AdminCommonBG>
  );
};

const AdminUserAccountsWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

const UserInfoPopup = styled.div`
  padding: 1rem 1.6rem;
  text-align: left;

  p {
    font-size: 1.8rem;
  }
  span {
    font-size: 1.4rem;
  }
`;

const ButtonWrap = styled.div`
  padding: 1rem 1.6rem;
  width: 100%;
  margin: 0 -1.6rem;
  margin-bottom: -1rem;
  button {
    display: block;
    margin-left: auto;
  }
`;

const BtnSvg = styled.span`
  margin-left: 1rem;
`;

const InviteUrl = styled.div`
  margin-top: 1rem;
  background: ${Colors.gray2};
  border: 2px solid ${Colors.gray4};
  border-radius: 5px;
  font-size: 1.4rem;
  padding: 1rem 1rem;
  overflow-x: scroll;
  white-space: nowrap;
  user-select: all;
`;

const InviteType = styled.div`
  display: flex;
  font-size: 1.6rem;
  jystify-contents: center;
  background: ${Colors.gray2};
  padding: 0rem 1.6rem;
  width: 100%;
  margin: 0 -1.6rem;
  margin-top: 1rem;
`;
interface SingleTypeProps {
  selected?: boolean;
}
const SingleType = styled.div`
  flex: 1;
  text-align: center;
  padding: 1rem;
  transition: 0.2s;
  border-bottom: 5px solid transparent;

  ${(p: SingleTypeProps) =>
    p.selected === true
      ? `
      color: ${Colors.primary};
      border-color: ${Colors.primary};
      font-weight: bold;
      `
      : `
      &:hover {
        cursor: pointer;
        background: ${Colors.gray4};
      }
  `}
`;

const OrgWrapper = styled.div`
  margin: 0 -1.8rem;
  p {
    font-size: 1.6rem !important;
  }
`;

export default AdminAdministrators;
