import { faClone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as React from 'react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  useChangeTrainingStatusMutation,
  useRemoveTrainingMutation,
} from '../../../api/__generated__/training.generated';
import {
  TrainingDocument,
  useTrainingData,
  useTrainingMembersData,
} from '../../../api/training';
import { useRetryableMutationWithUI } from '../../../lib/useRetryableMutationWithUI';
import { useCustomClaim } from '../../../redux/selectors/authSelectors';
import { useAdminTrainingId } from '../../../redux/selectors/gameSelectors';
import Colors from '../../../styles/colors';
import { TrainingStatus } from '../../../types';
import AdminCommonBG from '../../admin/AdminCommonBG';
import { AdminRole } from '../AdminAdministratorDetail';
import { HeaderButton } from '../UIelements/AdminNavigation';
import AdminTrainingController from './AdminTrainingController';

interface AdminTrainingControlViewProps {}

export const TrainingResultCsvContext = React.createContext(null);

const AdminTrainingControlView: React.FC<AdminTrainingControlViewProps> =
  () => {
    const [training, setTraining] =
      React.useState<TrainingDocument | null>(null);
    const [participantUids, setParticipantUids] = React.useState<string[]>([]);
    const [csvClicked, setCsvClicked] = React.useState<boolean>(false);
    const trainingId = useAdminTrainingId();
    const [initTraining] = useTrainingData(trainingId);
    const [initTrainingMembers] = useTrainingMembersData(trainingId);
    const customClaim = useCustomClaim();
    const navigate = useNavigate();
    const role: AdminRole = customClaim?.commonRoles.includes('riddlerAdmin')
      ? 'riddlerAdmin'
      : customClaim?.commonRoles.includes('dentsuAdmin')
      ? 'dentsuAdmin'
      : 'organizationAdmin';

    React.useEffect(() => {
      if (initTraining !== undefined) {
        setTraining(initTraining);
      }
    }, [initTraining]);

    React.useEffect(() => {
      if (initTrainingMembers !== undefined) {
        setParticipantUids(initTrainingMembers.participants ?? []);
      }
    }, [initTrainingMembers]);

    const onCompleted = React.useCallback(() => {
      navigate(`/admin/training`);
    }, [navigate]);

    const [removeTraining] = useRetryableMutationWithUI(
      useRemoveTrainingMutation,
      {
        hookOptions: {
          variables: {
            input: {
              trainingId: training?.trainingId ?? '',
            },
          },
          onCompleted,
        },
      }
    );
    const onRemoveTraining = React.useCallback(() => {
      removeTraining();
    }, [removeTraining]);

    const onEdit = React.useCallback(() => {
      navigate(`/admin/training/${trainingId}/edit`);
    }, [trainingId, navigate]);

    const onCsvDownload = React.useCallback(() => {
      setCsvClicked(false);
    }, []);

    const [startTraining] = useRetryableMutationWithUI(
      useChangeTrainingStatusMutation,
      {
        hookOptions: {
          variables: {
            input: {
              trainingId: training?.trainingId ?? '',
              status: TrainingStatus.Ongoing,
            },
          },
        },
      }
    );

    const onStartTraining = React.useCallback(() => {
      console.log('onStartTraining');
      startTraining();
    }, [startTraining]);

    const [endTraining] = useRetryableMutationWithUI(
      useChangeTrainingStatusMutation,
      {
        hookOptions: {
          variables: {
            input: {
              trainingId: training?.trainingId ?? '',
              status: TrainingStatus.Ended,
            },
          },
        },
      }
    );
    const onEndTraining = React.useCallback(() => {
      endTraining();
    }, [endTraining]);

    const disableEditButton = useMemo(() => {
      if (!training) {
        return true;
      }
      return (
        training.status !== TrainingStatus.Preparing &&
        training.status !== TrainingStatus.Ongoing
      );
    }, [training]);

    const btnCopy: HeaderButton = {
      buttonText: 'コピーを作成',
      disabled: false,
      buttonDom: (
        <CopyButton
          key={`subbutton_copy`}
          onClick={() => {
            navigate(`/admin/training/${trainingId}/copy`);
          }}
        >
          <FontAwesomeIcon icon={faClone} />
          コピーを作成
        </CopyButton>
      ),
    };

    const btnEdit: HeaderButton = {
      buttonText: '編集する',
      event: onEdit,
      disabled: disableEditButton,
    };

    return (
      <AdminCommonBG
        title='トレーニング詳細'
        backButton
        headerButtons={[btnCopy, btnEdit]}
      >
        <AdminTrainingControlViewWrapper>
          {training && (
            <AdminTrainingController
              training={training}
              participantUids={participantUids}
              onRemoveTraining={onRemoveTraining}
              role={role}
              onCsvDownload={onCsvDownload}
              csvClicked={csvClicked}
              onStartTraining={onStartTraining}
              onEndTraining={onEndTraining}
            />
          )}
        </AdminTrainingControlViewWrapper>
      </AdminCommonBG>
    );
  };

const AdminTrainingControlViewWrapper = styled.div``;

export default AdminTrainingControlView;

const CopyButton = styled.div`
  font-size: 1.3rem;
  color: ${Colors.accent};
  cursor: pointer;
  margin-left: 1rem;

  svg {
    margin-right: 0.6rem;
  }

  &:hover {
    opacity: 0.8;
  }
`;
